import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginVerifyModel } from 'app/models/login-verify.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { LoginVerifyService } from './login-verify.service';

@Component({
  providers: [AppConstants],
  selector: 'igmite-login-verify',
  templateUrl: './login-verify.component.html',
  styleUrls: ['./login-verify.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginVerifyComponent extends BaseComponent<LoginVerifyModel> implements OnInit {
  public loginForm: FormGroup;
  public loginVerifyModel: LoginVerifyModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  display: any;
  emailUser: any
  img: any
  imgEmp:any
  allowedData: any
  interestsByStudent: any
  timmer: boolean = false
  current: any
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private loginVerifyService: LoginVerifyService,
    private zone: NgZone,

    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };

    // Redirect to home if already logged in
    if (this.authenticationService.authUser) {
      this.router.navigate(['/']);
    }

    this.authenticationService.resetLogin();

    // Set the default login Model
    this.loginVerifyModel = new LoginVerifyModel();
  }

  ngOnInit(): void {
    // reset login status
    this.current = JSON.parse(localStorage.getItem('type'));

    let element: any = document.querySelectorAll(".displayLogout");
    element.forEach(element => {
      element.setAttribute('style', 'display: none !important');
    });
    let elementIn: any = document.querySelectorAll(".withinlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });
    this.authenticationService.resetLogin();


    this.loginForm = this.createLoginForm();
    this.emailUser = JSON.parse(localStorage.getItem('SendEmailID'));
    let otpData = JSON.parse(localStorage.getItem('logResp'));
    let OtpExpirationDate = new Date(otpData);
    let currentDate = new Date();

    if (OtpExpirationDate < currentDate) {
      this.router.navigateByUrl(RouteConstants.LoginOTP);
    } else {
      this.timer(1);

    }

  }

  toggle(tab) {
    this.current = tab;
    this.loginForm.reset();

  }

  validateUserAuth() {
    if (this.current == 'Student') {
      this.loginVerifyModel.EmailID = this.emailUser;
      this.loginVerifyModel.EnteredOtp = this.loginForm.value.EnteredOtp;
      this.loginVerifyModel.RoleCode = 'ST'

    }
    if (this.current == 'Employer') {
      this.loginVerifyModel.EmailID = this.emailUser;
      this.loginVerifyModel.EnteredOtp = this.loginForm.value.EnteredOtp;
      this.loginVerifyModel.RoleCode = 'EMP'

    }

    this.authenticationService.VerifyOtpEmail(this.loginVerifyModel)
      .subscribe((logResp: any) => {
        if (logResp.Success && logResp.Errors.length == 0) {
          this.img = logResp.Result.PhotoUrl
          this.imgEmp =  logResp.Result.PhotoUrl

          var currentUser: UserModel = {
            UserTypeId: logResp.Result.UserTypeId,
            LoginId: logResp.Result.LoginId,
            Password: logResp.Result.Password,
            UserId: logResp.Result.UserId,
            UserName: logResp.Result.UserName,
            FirstName: logResp.Result.FirstName,
            LastName: logResp.Result.LastName,
            EmailId: logResp.Result.EmailId,
            Mobile: logResp.Result.Mobile,
            IsAdmin: false,
            Designation: logResp.Result.Designation,
            DateOfJoining: logResp.Result.DateOfJoining,
            DateOfAllocation: logResp.Result.DateOfAllocation,
            RoleCode: logResp.Result.RoleCode,
            DefaultStateId: logResp.Result.DefaultStateId,
            StateId: logResp.Result.StateId,
            DivisionId: logResp.Result.DivisionId,
            DistrictId: logResp.Result.DistrictId,
            BlockId: logResp.Result.BlockId,
            AccountType: logResp.Result.AccountType,
            LandingPageUrl: logResp.Result.LandingPageUrl,
            InvalidAttempt: logResp.Result.InvalidAttempt,
            IsLocked: logResp.Result.IsLocked,
            IsPasswordReset: logResp.Result.IsPasswordReset,
            LastLoginDate: logResp.Result.LastLoginDate,
            PasswordExpiredOn: logResp.Result.PasswordExpiredOn,
            PasswordResetToken: logResp.Result.PasswordResetToken,
            PasswordUpdateDate: logResp.Result.PasswordUpdateDate,
            TokenExpiredOn: logResp.Result.TokenExpiredOn,
            AuthToken: logResp.Result.AuthToken,
            InternshipId: logResp.Result.InternshipId,
            UserRoleId: logResp.Result.UserRoleId,
            PhotoUrl: logResp.Result.PhotoUrl,
            EmployerId: logResp.Result.EmployerId,
            EmpBranchID: logResp.Result.EmpBranchID,
            IsDirectMappingEnabled:logResp.Result.IsDirectMappingEnabled,
            InternshipActualStartDate: logResp.Result.InternshipActualStartDate,


            RoleTransactions: []
          };

          var logoutPram = {
            UserId: logResp.Result.LoginId,
            AuthToken: logResp.Result.AuthToken
          };
          localStorage.setItem('logoutUser', JSON.stringify(logoutPram));

          // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
          AppConstants.AuthToken = currentUser.AuthToken;
          if (currentUser.RoleCode == 'ST') {
            var data = {
              "DataId": currentUser.UserTypeId,
              "DataId1": currentUser.InternshipId === null ? '' : currentUser.InternshipId,
            }
            this.authenticationService.allowed(data).subscribe((response: any) => {
              this.allowedData = response.Result
              sessionStorage.setItem('allow', JSON.stringify(this.allowedData));

            }, error => {
            });


            var data1 = {
              "DataId": currentUser.UserTypeId,
            }
            this.authenticationService.GetInterestsByStudentId(data1).subscribe((response: any) => {
              if (response.Success) {
                sessionStorage.setItem('interests', JSON.stringify(true));

                this.interestsByStudent = response.Results

              } else {
                sessionStorage.setItem('interests', JSON.stringify(false));

              }

            }, error => {
            });
          }

          this.authenticationService.getUserTransactionsByIdOTP(this.loginVerifyModel)
            .subscribe((tranResp: any) => {
              if (tranResp.Success && tranResp.Errors.length == 0) {

                let userNavigations = [];
                let transHeader: any, menuItem: any;

                tranResp.Results.forEach(tranItem => {

                  if (tranItem.HeaderName != 'Main') {
                    let headerItem = userNavigations.find(ob => ob.title === tranItem.HeaderName);

                    if (headerItem === undefined) {
                      transHeader = {
                        id: tranItem.HeaderName.toLowerCase(),
                        title: tranItem.HeaderName,
                        translate: 'NAV.' + tranItem.HeaderName.toUpperCase(),
                        type: 'collapsable',
                        icon: 'receipt',
                        //url: tranItem.RouteUrl,
                        badge: [],
                        children: [],
                        isVissible: tranItem.IsHeaderMenu
                      };

                      let subMenuItems = tranResp.Results.filter(ob => ob.HeaderName === tranItem.HeaderName);

                      if (subMenuItems.length > 0) {
                        subMenuItems.forEach(tranSubItem => {

                          menuItem = {
                            id: tranSubItem.Name.toLowerCase(),
                            title: tranSubItem.PageTitle,
                            translate: '',
                            type: 'item',
                            icon: 'layers',
                            url: tranSubItem.RouteUrl,
                            badge: [],
                            children: [],
                            isVissible: tranSubItem.IsHeaderMenu
                          }

                          transHeader.children.push(menuItem);
                        });
                      }

                      userNavigations.push(transHeader);
                    }
                  }
                });

                sessionStorage.setItem('userNavigations', JSON.stringify(userNavigations));
                sessionStorage.setItem('userRoleTransactions', JSON.stringify(tranResp.Results));

                // if (this.loginModel.RememberMe) {
                //   this.cookieService.set('UserId', this.loginModel.UserId);
                //   this.cookieService.set('Password', this.loginModel.Password);
                // }
                // else {
                //   this.cookieService.set('UserId', '');
                //   this.cookieService.set('Password', '');
                // }

                // this.cookieService.set('RememberMe', this.loginModel.RememberMe.toString());
              }

              let passwordExpiredOn = new Date(logResp.Result.PasswordExpiredOn);
              let currentDate = new Date();

              if (passwordExpiredOn < currentDate) {
                this.router.navigateByUrl(RouteConstants.Account.ResetPassword);
              }
              else {
                // this.router.navigateByUrl(RouteConstants.ProfileUpload);

                if (currentUser.RoleCode == 'ST') {
                  // login successful so redirect to return url 
                  // this.router.navigateByUrl(currentUser.LandingPageUrl);
                  if (logResp.Result.IsFirstLogin == true) {
                    if (logResp.Result.InternshipId != null) {
                      this.router.navigateByUrl(RouteConstants.DashboardNew);
                      if (this.img != null) {
                        let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                        userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.img);
                      }

                    } else if (this.interestsByStudent.length != 0) {

                      this.router.navigateByUrl(RouteConstants.HomeMain);
                      // this.router.navigateByUrl(RouteConstants.OpprtunitiesListing);

                      if (this.img != null) {
                        let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                        userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.img);
                      }
                    } else {
                      // this.router.navigateByUrl(RouteConstants.Interests);
                      this.router.navigateByUrl(RouteConstants.HomeMain);

                      if (this.img != null) {
                        let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                        userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.img);
                      }
                    }

                  } else {
                    this.router.navigateByUrl(RouteConstants.ProfileUpload);

                  }
                }
                if (currentUser.RoleCode == 'EMP') {
                  this.router.navigateByUrl(RouteConstants.HomeEmployer);
                  if (this.imgEmp != null) {
                    let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                    userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.imgEmp);
                  }

                }

              }
            });
        }
        else {
          // this.showErrorMessage(
          //   'Invalid Otp',
          //   'info-snackbar'
          // );
          this.zone.run(() => {
            //@ts-ignore

            //@ts-ignore
            this.snackBar.open("Invalid Otp", "Dismiss", {
              duration: 50000000,
              panelClass: ["info-snackbar"]
            });
          });
        }
      });


  }

  //Create login form and returns {FormGroup}
  createLoginForm(): FormGroup {
    return this.formBuilder.group({
      EnteredOtp: new FormControl(this.loginVerifyModel.EnteredOtp, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    });
  }

  SendOTPEmail() {
    var data = {
      EmailID: this.emailUser
    }
    this.loginVerifyService.SendOTPEmail(data)
      .subscribe((logResp: any) => {
        if (logResp.Success == true) {
          this.timer(1)
          localStorage.setItem('logResp', JSON.stringify(logResp.Result.OtpExpirationDate));

          this.timmer = false

        }
        else {
          this.showErrorMessage(
            'Invalid EmailID ',
            'info-snackbar'
          );
        }
      });
  }

  // showErrorMessage(messageText: string, messageType: string) {
  //   this.snackBar.open(messageText, "Dismiss", {
  //     duration: 2000,
  //     verticalPosition: "bottom",
  //     horizontalPosition: "center",
  //     panelClass: [messageType]
  //   });
  // }
  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(timer);
        this.timmer = true

      }
    }, 1000);
  }
}
