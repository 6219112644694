import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class InternshipListingMobileV2Service {


    constructor(private http: BaseService) { }


    getCurrentInternshipDetailsForEmp(userId: string, pageIndex: number, pageSize: number): Observable<any> {
        let requestParams = {
            UserId: userId,
            pageIndex: pageIndex,
            pageSize: pageSize
        };

        return this.http
            .HttpPost(this.http.Services.HomeEmp.GetCurrentInternshipDetailsForEmp, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }


    getOngoingInternshipsForEmployer(userId: string, pageIndex: number, pageSize: number): Observable<any> {
        let requestParams = {
            UserId: userId,
            pageIndex: pageIndex,
            pageSize: pageSize
        };
        return this.http
            .HttpPost(this.http.Services.HomeEmp.GetOngoingInternshipsForEmployer, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }
    // GetInterestsByStudentId(formData: any) {
    //     return this.http
    //         .HttpPost(this.http.Services.Interests.GetInterestsByStudentId, formData)
    //         .pipe(
    //             retry(this.http.Services.RetryServieNo),
    //             catchError(this.http.HandleError),
    //             tap(response => {
    //                 return response;
    //             })
    //         );
    // }

    // handleError(error: HttpErrorResponse) {
    //     let errorMessage = "Unknown error!";
    //     if (error.error instanceof ErrorEvent) {
    //         // Client-side errors
    //         errorMessage = `Error: ${error.error.message}`;
    //     } else {
    //         // Server-side errors
    //         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    //     }
    //     window.alert(errorMessage);
    //     return throwError(errorMessage);
    // }
}
