import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { FinalInternshipReportService } from './final-internship-report.service';
import { FileUploadModel } from 'app/models/file.upload.model';
import { DatePipe } from '@angular/common'

@Component({
  providers: [AppConstants],
  selector: 'igmite-final-internship-report',
  templateUrl: './final-internship-report.component.html',
  styleUrls: ['./final-internship-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class FinalInternshipReportComponent extends BaseComponent<LoginModel> implements OnInit {
  public finalInternshipReporForm: FormGroup;
  public fileUploadModel: FileUploadModel;

  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  urls = [];
  BestPicture= [];
  Cetificate=[];
  RportPhotos=[];
  filesArray: any = [];
  date:any
  InternshipById:any

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private finalInternshipReportService: FinalInternshipReportService,
    public zone: NgZone,
    public datepipe: DatePipe,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);
    this.fileUploadModel = new FileUploadModel();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };
    this.finalInternshipReporForm = this.createFinalInternshipReporForm();


  }
 
  createFinalInternshipReporForm(): FormGroup {
    return this.formBuilder.group({
      ReportDetail: new FormControl(''),
      ActualEndDate: new FormControl('', [Validators.required]),
    });

  }
  ngOnInit(): void {
    var Inter=JSON.parse(sessionStorage.getItem('item'));
    if( Inter !== null ){
       this.InternshipById={ DataId:Inter}
       
    }else{
       this.InternshipById={ DataId:this.UserModel.InternshipId }
    }

    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');
    });

    let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
    employeeMenuItems.forEach(employeeMenuItems => {
      employeeMenuItems.setAttribute('style', 'display: none !important');
    });
    var final = {
       DataId: this.UserModel.UserTypeId ,
       DataId1: this.InternshipById.DataId ,
      }
    this.finalInternshipReportService.GetFinalInternshipReport(final).subscribe((finalResp: any) => {
      if (finalResp.Success == true) {
      
        if(finalResp.Result.listAttachments.length != 0){
          var date = this.datepipe.transform(finalResp.Result.ActualEndDate, 'yyyy-MM-dd');
          this.finalInternshipReporForm.controls['ReportDetail'].setValue(finalResp.Result.ReportDetail)
          this.finalInternshipReporForm.controls['ActualEndDate'].setValue(date)

          finalResp.Result.listAttachments.map(item => {

            if(item.SectionType == 'IRBestPicture'){
              // this.BestPicture.push(this.Constants.CommonImageUrl.FinalInternshipReports+item.AttachmentPath,item.AttachmentPath)
              this.BestPicture.push( {
                'url':this.Constants.CommonImageUrl.FinalInternshipReports+item.AttachmentPath
              ,'name':item.AttachmentPath
            })
              this.filesArray.push(item)
            }
            if(item.SectionType == 'IRCetificate'){
              this.Cetificate.push({
                'url':this.Constants.CommonImageUrl.FinalInternshipReports+item.AttachmentPath
              ,'name':item.AttachmentPath
            })
              this.filesArray.push(item)
  
            }
            if(item.SectionType == 'IRRportPhotos'){
              this.RportPhotos.push({
                'url':this.Constants.CommonImageUrl.FinalInternshipReports+item.AttachmentPath
              ,'name':item.AttachmentPath
            })
              this.filesArray.push(item)
  
            }
            
  
          })
        }
       
      
        this.zone.run(() => {
          // this.profileDetails = profileResp.Results[0]
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid UpdateStudentInternshipsStatus ',
        //   'info-snackbar'
        // );
      }
    }, error => {
    });

  }

  FinalInternshipReport() {
    var finalInternshipReport = {
      AuthUserId: this.UserModel.UserTypeId,
      RequestType: 0,
      IsActive: true,
      ReportDetail: this.finalInternshipReporForm.value.ReportDetail,
      ActualEndDate:this.finalInternshipReporForm.value.ActualEndDate,
      Sequence: '',      
      InternshipId: this.InternshipById.DataId,
      StudentID: this.UserModel.UserTypeId,
      listAttachments:  this.filesArray
    }
    this.finalInternshipReportService.CreateFinalInternshipReport(finalInternshipReport).subscribe((finalReportResp: any) => {
      if (finalReportResp.Success == true) {

        this.zone.run(() => {
          this.IsLoading = false;
          this.showActionMessage(
            this.Constants.Messages.RecordSavedMessage,
            this.Constants.Html.SuccessSnackbar
          );
          this.router.navigateByUrl(RouteConstants.DashboardNew);
        });
      }
      else {

        this.zone.run(() => {
          this.snackBar.open("Record Not Saved", "Dismiss", {
            duration: 50000000,
            panelClass: ["info-snackbar"]
          });
        });
      }


    }, error => {
    });
  }


  getFileBestPicture(event) {

    const file: File = event.target.files[0];
    console.log(file)
    if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png'){
       this.getUploadedFileData(event, this.Constants.DocumentType.IRBestPicture).then((response: FileUploadModel) => {
      this.fileUploadModel = response;

      this.filesArray.push({
        AuthUserId: this.UserModel.UserTypeId,
        RequestType: 0,
        IsActive: true,
        UserId: this.UserModel.UserTypeId,
        ContentId: ' 132e50f9 - 6531 - 4083 - a2e4 - a44cc32ba2cc',
        ContentType: "FinalInternshipReports",
        SectionType: response.ContentType,
        SectionId: '12345678-9012-3456-7890-123456789015',
        AttachmentType: 'Image',
        AttachmentDesc: 'Final Internship Reports ',
        Sequence: 1,
        FileName: response.FileName,
        FileType: response.FileType,
        FileSize: response.FileSize,
        FilePath: response.FilePath,
        AttachmentsFile: response
      })

    });
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.BestPicture.push(
            {
              'url':event.target.result
            ,'name':this.fileUploadModel.FileName
          }
          );
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }

    }else{
      this.showActionMessage(
        "Only accept image/jpeg, image/jpg, image/png this type",
        this.Constants.Html.SuccessSnackbar
      );
    }
    // this.getUploadedFileData(event, this.Constants.DocumentType.IRBestPicture).then((response: FileUploadModel) => {
    //   this.fileUploadModel = response;

    //   this.filesArray.push({
    //     AuthUserId: this.UserModel.UserTypeId,
    //     RequestType: 0,
    //     IsActive: true,
    //     UserId: this.UserModel.UserTypeId,
    //     ContentId: ' 132e50f9 - 6531 - 4083 - a2e4 - a44cc32ba2cc',
    //     ContentType: "FinalInternshipReports",
    //     SectionType: response.ContentType,
    //     SectionId: '12345678-9012-3456-7890-123456789015',
    //     AttachmentType: 'Image',
    //     AttachmentDesc: 'Final Internship Reports ',
    //     Sequence: 1,
    //     FileName: response.FileName,
    //     FileType: response.FileType,
    //     FileSize: response.FileSize,
    //     FilePath: response.FilePath,
    //     AttachmentsFile: response
    //   })

    // });
    // if (event.target.files && event.target.files[0]) {
    //   var filesAmount = event.target.files.length;
    //   for (let i = 0; i < filesAmount; i++) {
    //     var reader = new FileReader();
    //     reader.onload = (event: any) => {
    //       this.BestPicture.push(
    //         {
    //           'url':event.target.result
    //         ,'name':this.fileUploadModel.FileName
    //       }
    //       );
    //     }
    //     reader.readAsDataURL(event.target.files[i]);
    //   }
    // }
  }
  getFileCetificate(event) {
    const file: File = event.target.files[0];
    console.log(file)
    if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png'){
    this.getUploadedFileData(event, this.Constants.DocumentType.IRCetificate).then((response: FileUploadModel) => {
      this.fileUploadModel = response;
      this.filesArray.push({
        AuthUserId: this.UserModel.UserTypeId,
        RequestType: 0,
        IsActive: true,
        UserId: this.UserModel.UserTypeId,
        ContentId: ' 132e50f9 - 6531 - 4083 - a2e4 - a44cc32ba2cc',
        ContentType: "FinalInternshipReports",
        SectionType: response.ContentType,
        SectionId: '12345678-9012-3456-7890-123456789016',
        AttachmentType: 'Image',
        AttachmentDesc: 'Final Internship Reports ',
        Sequence: 1,
        FileName: response.FileName,
        FileType: response.FileType,
        FileSize: response.FileSize,
        FilePath: response.FilePath,
        AttachmentsFile: response
      })

    });
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.Cetificate.push(  {
            'url':event.target.result
          ,'name':this.fileUploadModel.FileName
        });
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }else{
    this.showActionMessage(
      "Only accept image/jpeg, image/jpg, image/png this type",
      this.Constants.Html.SuccessSnackbar
    );
  }

  }

  getFileRportPhotos(event) {
    const file: File = event.target.files[0];
    console.log(file)
    if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png'){
    this.getUploadedFileData(event, this.Constants.DocumentType.IRRportPhotos).then((response: FileUploadModel) => {
      this.fileUploadModel = response;
      this.filesArray.push({
        AuthUserId: this.UserModel.UserTypeId,
        RequestType: 0,
        IsActive: true,
        UserId: this.UserModel.UserTypeId,
        ContentId: ' 132e50f9 - 6531 - 4083 - a2e4 - a44cc32ba2cc',
        ContentType: "FinalInternshipReports",
        SectionType: response.ContentType,
        SectionId: '12345678-9012-3456-7890-123456789017',
        AttachmentType: 'Image',
        AttachmentDesc: 'Final Internship Reports ',
        Sequence: 1,
        FileName: response.FileName,
        FileType: response.FileType,
        FileSize: response.FileSize,
        FilePath: response.FilePath,
        AttachmentsFile: response
      })

    });
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.RportPhotos.push(  {
            'url':event.target.result
          ,'name':this.fileUploadModel.FileName
        });
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }else{
    this.showActionMessage(
      "Only accept image/jpeg, image/jpg, image/png this type",
      this.Constants.Html.SuccessSnackbar
    );
  }

  }


  removeSelectedFile(index,type,name) {
    var index1 = this.filesArray.findIndex(item => item.FileName == name || item.AttachmentPath == name);
    this.filesArray.splice(index1, 1);
  
    // Delete the item from fileNames list
    if(type =='IRBestPicture' ){
      // this.BestPicture[0].splice(index, 1);
      var indexB = this.BestPicture.findIndex(item => item.FileName == name || item.AttachmentPath == name);
      this.BestPicture.splice(indexB, 1);
    }
    if(type =='IRCetificate'){
      var indexC = this.Cetificate.findIndex(item => item.FileName == name || item.AttachmentPath == name);
      this.Cetificate.splice(indexB, 1);
    }
    if(type =='IRRportPhotos'){
      var indexR = this.RportPhotos.findIndex(item => item.FileName == name || item.AttachmentPath == name);
      this.RportPhotos.splice(indexR, 1);
    }
  }

}
