<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <!-- <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu-emp></igmite-side-menu-emp>


                </div> -->
                <div class="col-12 col-lg-12 right-content px-lg-35 py-18 pb-2 bg-white px-10 mb-lg-0 mb-60">


                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">



                        <div class="col-12 px-0 text-center">

                            <div
                                class="top-circle overflow-hidden mt-lg-0 bg-light-theme mx-auto d-flex justify-content-center align-items-center">
                                <img src="assets/upload/Skill-shine-icon.png" class="img-fluid object-fit-contain"
                                    height="" alt="" srcset="">
                            </div>
                            <p class="fs-22 text-black fw-900  line-h-22 mt-25 mb-0 px-10 text-center"> Your skills are
                                shining! </p>
                            <p class="fs-16 text-black fw-400 py-10 text-center">Your skills are now shining. Send a
                                thank-you note to your employers &amp; trainers telling them how they have made you
                                better! </p>
                        </div>



                        <div class="mt-20 position-relative mb-0 border-theme-2px border-radius-20 px-10 py-20">
                            <div class="radio-theme">




                                <ul class="row mx-0 px-0 w-100 mb-0 ">
                                    <li class="col-6 px-0 border-grey-light-right py-25" title="">
                                        <input type="radio" id="f-option" name="selector" (click)="toggle('Employer')">
                                        <label class="fs-15 fw-700 color1" for="f-option"
                                            (click)="toggle('Employer')">Employer</label>

                                        <div class="check"></div>
                                    </li>



                                    <li class="col-6 px-0 py-25" title="">
                                        <input type="radio" id="t-option" name="selector" (click)="toggle('Trainer')">
                                        <label class="fs-15 fw-700 color1" for="t-option"
                                            (click)="toggle('Trainer')">Trainer</label>

                                        <div class="check">
                                            <div class="inside"></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>


                            <div class="mt-30 max-w-800">
                                <div class="mb-20">
                                    <p class="fs-16 text-black mb-1 px-1 fw-900">Message </p>
                                    <div class="input-with-icon position-relative">
                                        <form action="" name="gratitudeCornerForm" novalidate
                                            [formGroup]="gratitudeCornerForm">
                                            <!-- <textarea name="" id="" cols="20" rows="5"
                                                class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea> -->

                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <mat-label></mat-label>
                                                <textarea matInput type="text" id="GratitudeDesc"
                                                    formControlName="GratitudeDesc" required name="name" cols="20"
                                                    rows="5"
                                                    class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"
                                                    required=""></textarea>
                                                <mat-error *ngIf="gratitudeCornerForm.controls.GratitudeDesc.invalid"
                                                    class="error text-danger mt-1 px-1 d-block ">
                                                    Gratitude Message is required
                                                </mat-error>
                                            </mat-form-field>

                                            <button (click)="saveOrUpdateGratitude()"
                                                class="d-block px-3 text-white max-w-420  border-radius-15 mx-auto mt-20 w-100 py-2 upto-down-gradient-clr-1 theme-btn box-shadow-light">Submit</button>

                                        </form>



                                    </div>
                                </div>

                            </div>

                            <!--End tabs container-->
                            <!--End tabs-->
                        </div>
                    </div>
                    <!-- <p class="text-center  fs-14 fw-800  color1 mt-30 mb-30"> GO TO HOME</p> -->
                    <a href="/dashboard" class="fs-15 mt-30 mb-10 color1 fw-900 d-block text-center">GO TO HOME</a>
                </div>
            </div>
        </div>
    </div>
</section>