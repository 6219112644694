<div class="fixed-left-navigation">
    <ul>
        <li>
            <a class=" fs-16 " routerLinkActive='active' [routerLink]="['/home-employer']">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="fill-color-1" height="48" width="48">
                    <path
                        d="M25.2 16.7V6.2Q25.2 5.25 25.85 4.6Q26.5 3.95 27.45 3.95H41.8Q42.75 3.95 43.4 4.6Q44.05 5.25 44.05 6.2V16.7Q44.05 17.7 43.4 18.35Q42.75 19 41.8 19H27.45Q26.5 19 25.85 18.35Q25.2 17.7 25.2 16.7ZM3.9 24.05V6.2Q3.9 5.25 4.575 4.6Q5.25 3.95 6.2 3.95H20.4Q21.35 3.95 22 4.6Q22.65 5.25 22.65 6.2V24.05Q22.65 25 22 25.65Q21.35 26.3 20.4 26.3H6.2Q5.25 26.3 4.575 25.65Q3.9 25 3.9 24.05ZM25.2 41.65V23.85Q25.2 22.85 25.85 22.2Q26.5 21.55 27.45 21.55H41.8Q42.75 21.55 43.4 22.2Q44.05 22.85 44.05 23.85V41.65Q44.05 42.65 43.4 43.325Q42.75 44 41.8 44H27.45Q26.5 44 25.85 43.325Q25.2 42.65 25.2 41.65ZM3.9 41.65V31.15Q3.9 30.2 4.575 29.55Q5.25 28.9 6.2 28.9H20.4Q21.35 28.9 22 29.55Q22.65 30.2 22.65 31.15V41.65Q22.65 42.65 22 43.325Q21.35 44 20.4 44H6.2Q5.25 44 4.575 43.325Q3.9 42.65 3.9 41.65Z" />
                </svg>

                Home</a>
        </li>

        <li>
            <a class="fs-16 " routerLinkActive='active' [routerLink]="['/internship-listing-mobile-v2']">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="fill-color-1 h-30" height="48"
                    width="48">
                    <path
                        d="M7 42Q5.8 42 4.9 41.1Q4 40.2 4 39V14.35Q4 13.15 4.9 12.25Q5.8 11.35 7 11.35H16.5V7Q16.5 5.8 17.4 4.9Q18.3 4 19.5 4H28.5Q29.7 4 30.6 4.9Q31.5 5.8 31.5 7V11.35H41Q42.2 11.35 43.1 12.25Q44 13.15 44 14.35V39Q44 40.2 43.1 41.1Q42.2 42 41 42ZM19.5 11.35H28.5V7Q28.5 7 28.5 7Q28.5 7 28.5 7H19.5Q19.5 7 19.5 7Q19.5 7 19.5 7Z" />
                </svg>
                Internships</a>
        </li>

        <li>

            <a class="fs-16 " routerLinkActive='active' [routerLink]="['/inter-listing']">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-color-1 h-30" height="48"
                    width="48" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z" />
                </svg>
                Interns</a>

        </li>

        <li>
            <a class="fs-16"  routerLinkActive='active' [routerLink]="['/employer-profile']">

                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"
                    class="fill-color-1 h-30" height="48" width="48" fill="#000000">
                    <g>
                        <rect fill="none" height="24" width="24" />
                        <rect fill="none" height="24" width="24" />
                    </g>
                    <g>
                        <path
                            d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,6c1.93,0,3.5,1.57,3.5,3.5S13.93,13,12,13 s-3.5-1.57-3.5-3.5S10.07,6,12,6z M12,20c-2.03,0-4.43-0.82-6.14-2.88C7.55,15.8,9.68,15,12,15s4.45,0.8,6.14,2.12 C16.43,19.18,14.03,20,12,20z" />
                    </g>
                </svg>

                Profile</a>

        </li>
    </ul>

    <div class="border-theme-2px mt-30 border-bottom-0 border-end-0 border-start-0 py-10 pl-20">
        <!-- <a class="d-block col-6  text-black fw-600 fs-15  py-2 w-100"> AboutUs</a> -->
        <a class="d-block col-6  text-black fw-600 fs-15 py-2 w-100" (click)="redirct()"> Contact Us</a>
    </div>


</div>