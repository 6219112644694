import { FuseUtils } from '@fuse/utils';

export class OpprtunitiesListingModel {
    AuthUserId: string;
    StudentName: string;
    StudentCityId: string;
    StudentCityName: string;
    IsActive:boolean;
    StudentId:string;
    RequestType:any;
    InterestId:string;
    StudentDistrictId:string;
    InterestName:string;
    InternshipId:string;
    SectorId:string;
    JobRoleId:string;
    Stream:string;
    InternshipAgreed:boolean;
    DoneInternshipEarlier:boolean;
    PlannedStartDate:Date;
    PlannedEndDate:Date;
    ActualStartDate:Date;
    ActualEndDate:Date;
    EmployerId:string;
    BranchId:string;
    BranchName:string;
    IsApplied:boolean;
    IsPlaced:boolean;
    IsApprovedByVT:boolean;
    VTId:string;
    Remarks:string;
    IsApprovedByEmployer:boolean;

    constructor(InterestsItem?: any) {
        InterestsItem = InterestsItem || {};
        this.AuthUserId = InterestsItem.AuthUserId || '';
        
        this.StudentName = InterestsItem.StudentName || '';
        this.StudentCityId = InterestsItem.StudentCityId || '';
        this.StudentId = InterestsItem.StudentId || '';
        this.StudentDistrictId = InterestsItem.StudentDistrictId || '';
        this.StudentCityName = InterestsItem.StudentCityName || '';
        this.InterestName = InterestsItem.InterestName || '';
        this.IsActive = true;
        this.RequestType = 0; // New 
        this.InternshipId = InterestsItem.InternshipId || '';
        this.SectorId = InterestsItem.SectorId || '';
        this.JobRoleId = InterestsItem.JobRoleId || '';
        this.Stream = InterestsItem.Stream || '';
        this.InternshipAgreed = true;
        this.DoneInternshipEarlier = true;
        this.PlannedStartDate = InterestsItem.PlannedStartDate || '';
        this.PlannedEndDate = InterestsItem.PlannedEndDate || '';
        this.ActualStartDate = InterestsItem.ActualStartDate || '';
        this.ActualEndDate = InterestsItem.ActualEndDate || '';
        this.EmployerId = InterestsItem.EmployerId || '';
        this.BranchId = InterestsItem.BranchId || '';
        this.BranchName = InterestsItem.BranchName || '';
        this.IsApplied = true;
        this.IsPlaced = false;
        this.IsApprovedByVT = false;
        this.IsApprovedByEmployer = false;
        this.VTId = InterestsItem.VTId || '';
        this.Remarks = 'Interests Applied';

        


    }
} 