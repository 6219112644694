import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class Gallery1Service {
   

    constructor(private http: BaseService) { }

    GetAttachmentsByAccountId(requestParams: any) {


        return this.http.HttpPost(this.http.Services.Gallery.GetAttachmentsByAccountId, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    GetGalleryAttachments(requestParams: any) {


        return this.http.HttpPost(this.http.Services.Gallery.GetGalleryAttachments, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }
    
    getProfileAttachmentsByAccountId(requestParams: any) {


        return this.http.HttpPost(this.http.Services.Gallery.GetProfileAttachmentsByAccountId, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }
    

    setIsAddedInProfile(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Gallery.SetIsAddedInProfile, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    removeIsAddedInProfile(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Gallery.RemoveIsAddedInProfile, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    GetBase64(imgUrl: string) {
        let requestParams = {
          AttachmentPath: imgUrl
          
        };
        return this.http.HttpPost(this.http.Services.Gallery.GetBase64, requestParams)
          .pipe(
            retry(this.http.Services.RetryServieNo),
            catchError(this.http.HandleError),
            tap(function (response: any) {
              return response.Results;
            })
          );
      }
   
}
