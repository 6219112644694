import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class ApplicantListingV2Service {


    constructor(private http: BaseService) { }

    getAllApplicantsForEmployer(userId: string, internshipId: string, pageIndex: number, pageSize: number): Observable<any> {
        let requestParams = {
            UserId: userId,
            InternshipGuid: internshipId,
            pageIndex: pageIndex,
            pageSize: pageSize
        };

        return this.http
            .HttpPost(this.http.Services.ApplicantListingV2.GetAllApplicantsForEmployer, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }


    getShortlistedApplicantsForEmployer(userId: string, internshipId: string, pageIndex: number, pageSize: number): Observable<any> {
        let requestParams = {
            UserId: userId,
            InternshipGuid: internshipId,
            pageIndex: pageIndex,
            pageSize: pageSize
        };

        return this.http
            .HttpPost(this.http.Services.ApplicantListingV2.GetShortlistedApplicantsForEmployer, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }


    shortlistApplicantByEmployer(studentId: string, internshipId: string) {
        let requestParams = {
            DataId: studentId,
            DataId1: internshipId
        };

        return this.http.HttpPost(this.http.Services.ApplicantListingV2.ShortlistApplicantByEmployer, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }



    hireStudent(studentId: string, internshipId: string) {
        let requestParams = {
            DataId: studentId,
            DataId1: internshipId
        };

        return this.http.HttpPost(this.http.Services.ApplicantListingV2.HireStudent, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }


    rejectApplicantByEmployer(studentId: string, internshipId: string) {
        let requestParams = {
            DataId: studentId,
            DataId1: internshipId
        };

        return this.http.HttpPost(this.http.Services.ApplicantListingV2.RejectApplicantByEmployer, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    // CreateInterest(formData: any) {
    //     return this.http
    //         .HttpPost(this.http.Services.Interests.CreateStudentInterests, formData)
    //         .pipe(
    //             retry(this.http.Services.RetryServieNo),
    //             catchError(this.http.HandleError),
    //             tap(response => {
    //                 return response;
    //             })
    //         );
    // }

    // GetInterestsByStudentId(formData: any) {
    //     return this.http
    //         .HttpPost(this.http.Services.Interests.GetInterestsByStudentId, formData)
    //         .pipe(
    //             retry(this.http.Services.RetryServieNo),
    //             catchError(this.http.HandleError),
    //             tap(response => {
    //                 return response;
    //             })
    //         );
    // }

    // handleError(error: HttpErrorResponse) {
    //     let errorMessage = "Unknown error!";
    //     if (error.error instanceof ErrorEvent) {
    //         // Client-side errors
    //         errorMessage = `Error: ${error.error.message}`;
    //     } else {
    //         // Server-side errors
    //         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    //     }
    //     window.alert(errorMessage);
    //     return throwError(errorMessage);
    // }
}
