import { AfterViewInit, Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { AppConstants } from 'app/app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { DOCUMENT } from '@angular/common';

import * as $ from 'jquery';
import { timeout } from 'rxjs/operators';
import { RouteConstants } from 'app/constants/route.constant';
import { AuthenticationService } from 'app/services/authentication.service';

@Component({
  providers: [AppConstants],
  selector: 'igmite-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class HomeComponent extends BaseComponent<LoginModel> implements OnInit, AfterViewInit {
  public appInfo = environment;
  homeData: any
  public slides = [
    { 'SlidePreviewUrl': 'assets/upload/Video-1.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" },
    { 'SlidePreviewUrl': 'assets/upload/Video-2.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" },
    { 'SlidePreviewUrl': 'assets/upload/Video-3.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" },
    { 'SlidePreviewUrl': 'assets/upload/Video-4.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" }
  ];

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    public formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) document: Document) {
    super(commonService, router, routeParams, snackBar);
    let localTest = JSON.parse(localStorage.getItem('logoutUser'));
    if (!localTest) {
      var logoutPram = '';
      localStorage.setItem('logoutUser', JSON.stringify(logoutPram));
    }
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    // const firstTime = localStorage.getItem('key')
    // if (!firstTime) {
    //   localStorage.setItem('key', 'loaded')
    //   window.location.reload();

    // } else {
    //   localStorage.removeItem('key')
    // }
  }

  ngOnInit(): void {
    let element: any = document.querySelectorAll(".displayLogout");
    element.forEach(element => {
      element.setAttribute('style', 'display: none !important');
    });
    let elementIn: any = document.querySelectorAll(".withinlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });
    this.commonService.GetAllHome().subscribe(response => {

      this.homeData = response.Results
      this.IsLoading = false;
    }, error => {
    });

    let localTest = JSON.parse(localStorage.getItem('logoutUser'));

    if (localTest != '') {
      let logoutParam = {
        UserId: localTest.UserId,
        AuthToken: localTest.AuthToken
      };

      var AuthToken = {
        AuthToken: localTest.AuthToken
      }

      //   this.logout();
      this.authenticationService.logoutUser(logoutParam, AuthToken.AuthToken).subscribe((logResp: any) => {
        localStorage.clear();
        var logoutPram = '';
        localStorage.setItem('logoutUser', JSON.stringify(logoutPram));

      });

    }
  }

  scrollToSection() {
    let internshipSection: any = $('.internship-section');
    internshipSection.scrollIntoView();
  }

  scrollPoint1(el: HTMLElement) {
    // el.scrollIntoView({behavior: "smooth"});
    el.scrollIntoView();
  }

  ngAfterViewInit() {


  }

  redirct() {
    this.router.navigateByUrl(RouteConstants.ContactUs1);
  }
}
