import { Component, OnInit, ViewEncapsulation, NgZone, Input, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';

import { BaseListComponent } from 'app/common/base-list/base.list.component';


import * as $ from 'jquery';
import { AttendanceV2Service } from './attendance-v2.service';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
//import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
//import { AttendanceV2Model } from './attendance-v2.model';
import { MatCalendar, MatCalendarCellCssClasses, MatDatepicker, MatDatepickerModule } from "@angular/material/datepicker";
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { AttendanceV2Model } from './attendance-v2.model';




@Component({
  providers: [AppConstants],
  selector: 'igmite-attendance-v2',
  templateUrl: './attendance-v2.component.html',
  styleUrls: ['./attendance-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AttendanceV2Component extends BaseComponent<LoginModel> implements OnInit {
  public attendanceV2Form: FormGroup;
  public loginModel: LoginModel;
  //public attendanceV2Model: AttendanceV2Model;
  attendanceV2Model: AttendanceV2Model;

  selectedId: any = [];
  selectedData: any = [];
  // toggle = true;
  toggleLog = false;
  checked: boolean = false;
  interestsByStudent: any;
  current = 'AllApplications';

  allApplicationData: any;
  shartlistedStudentData: any;
  totalHired: number;
  profileDetails: any
  InternshipById = this.UserModel.InternshipId
  InternshipId: string;
  internshipDetails: any = {};
  selected: Date | null;
  selectedDate = new Date();
  todayDate: Date;
  DailyDiary: string;
  ActiveTab = 'Close'
  ActiveTabCancle = 'open'
  flag: any;
  listDiaryEntry: any;

  endDate: any;
  startDate: any;
  selecteAttendanceDate: any;
  endAttendanceDate: string;
  startAttendanceDate: string;
  listStudentAttandance: any;
  totalDays: any;
  public studAttandanceDates: any = [];
  hoursSpent: any;
  filesArray: any[];
  studentId: any;
  public studAttandanceDates1: any[];
  @ViewChild("calendar", { static: false }) calendar: MatCalendar<Date>;
  getInternshipId: string;
  getStudentId: string;
  imageBasePath : any
  IsDiaryEntry: string;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private attendanceV2Service: AttendanceV2Service,
    private dialogService: DialogService,
    private elRef: ElementRef,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private renderer: Renderer2) {
    super(commonService, router, routeParams, snackBar);
    this.attendanceV2Model = new AttendanceV2Model();
    this.attendanceV2Form = this.createAttendanceForm();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  toggle(tab) {
    this.current = tab;
    if (this.current == 'AllApplications') {
      this.AllApplicants();
    } else {
      this.ShortlistedApplicants();
    }
  }
  ngOnInit(): void {
    this.imageBasePath=this.Constants.CommonImageUrl.Attachments
    this.InternshipId = sessionStorage.getItem("InternshipId");
    this.getInternshipId = sessionStorage.getItem("InternshipId");
    this.getStudentId = sessionStorage.getItem("StudentId");
    this.IsDiaryEntry = sessionStorage.getItem("IsDiaryEntry");
    if (this.IsDiaryEntry == 'DiaryEntry') {
      this.current = 'Shortlisted';
      this.toggle(this.current);
    } else {
      this.current = 'AllApplications';
      this.toggle(this.current);
    }
    this.route.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.InternshipId = params.get('internshipId');
      }
    });

    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    this.AllApplicants();

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }

    var proId = { DataId: this.UserModel.UserTypeId }
    let endDate = this.endDate = moment(this.selectedData).endOf('month').toDate();
    let startAttendanceDate = this.endDate = moment(this.selectedData).startOf('month').toDate();
    this.startAttendanceDate = this.datepipe.transform(startAttendanceDate, 'yyyy-MM-dd');
    this.endAttendanceDate = this.datepipe.transform(endDate, 'yyyy-MM-dd');
  
    var studentAttandanceDetails = {
      "StudentID": this.getStudentId,
      "InternshipId": this.getInternshipId,
      "StartDateTime": '2021-01-01',
      "EndDateTime": this.endAttendanceDate
    }

    this.DailyDiary = ''
    this.todayDate = this.selectedDate
    this.attendanceV2Service.GetStudentAttandanceDetails(studentAttandanceDetails).subscribe((profileResp: any) => {
      if (profileResp.Success == true) {
        this.zone.run(() => {
          this.profileDetails = profileResp.Result;
          this.profileDetails.StudentProfilePhoto =this.Constants.CommonImageUrl.Account + this.profileDetails.StudentProfilePhoto;

          this.studAttandanceDates1 = profileResp.Result.StudentAttandanceDaystList;
          this.totalDays = profileResp.Result.TotalDays;
          this.hoursSpent = profileResp.Result.HoursSpent;
          this.zone.run(() => {

          });
        });
      }
    }, error => {
    });
  }

  onSelect(event) {
    this.selectedDate = event;
    let endDate = this.endDate = moment(event.value).endOf('month').toDate();
    this.startDate = this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd');
    this.endDate = this.datepipe.transform(endDate, 'yyyy-MM-dd');
    var dayEntriy = {
      "StudentID": this.getStudentId,
      "InternshipId": this.getInternshipId,
      "StartDateTime": this.startDate,
      "EndDateTime": this.startDate
    }
    this.DailyDiary = ''
    this.todayDate = this.selectedDate
    this.attendanceV2Service.GetDailyDiaryEntries(dayEntriy).subscribe((dashboardResp: any) => {

      if (dashboardResp.Success == true) {
        this.ActiveTab = 'edit'
        this.ActiveTabCancle = 'open'
        this.zone.run(() => {
          this.flag = dashboardResp.Results[0].listEntries
          this.listDiaryEntry = dashboardResp.Results;
        });
      } else { }
    }, error => {
    });

  }

  dateClass = (date: Date): MatCalendarCellCssClasses => {
    let endDate = this.endDate = moment(this.selectedData).endOf('month').toDate();
    let startAttendanceDate = this.endDate = moment(this.selectedData).startOf('month').toDate();
    this.startAttendanceDate = this.datepipe.transform(startAttendanceDate, 'yyyy-MM-dd');
    this.endAttendanceDate = this.datepipe.transform(endDate, 'yyyy-MM-dd');
    var dayEntriy = {
      "StudentID": this.getStudentId,
      "InternshipId": this.getInternshipId,
      "StartDateTime": '2022-01-01',
      "EndDateTime": this.endAttendanceDate
    }

    this.DailyDiary = ''
    this.todayDate = this.selectedDate
    this.attendanceV2Service.GetStudentAttandanceDetails(dayEntriy).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.studAttandanceDates1 = dashboardResp.Result.StudentAttandanceDaystList;
        for (const element of this.studAttandanceDates1) {
          let dateC = new Date(date)
          let dateA = new Date(element.AttendanceDate)
          let dateIndex = dateA.getDate() - 1;
          if (dateC.getFullYear() === dateA.getFullYear() && dateC.getMonth() === dateA.getMonth() && dateC.getDate() === dateA.getDate()) {
            $($('.mat-calendar .mat-calendar-table tr td .mat-calendar-body-cell-content')[dateA.getDate() - 1]).addClass("scheduled-date");
            //return 'scheduled-date';
          }
          dateIndex++;
        }
        this.totalDays = dashboardResp.Result.TotalDays;
        this.hoursSpent = dashboardResp.Result.HoursSpent;
        this.zone.run(() => {
        });
      }
    }, error => {
    });
    return '';
  };

  createAttendanceForm(): FormGroup {
    return this.formBuilder.group({
      Comments: new FormControl('', [Validators.maxLength(150), Validators.required]),
    });

  }

  CreateAttendanceV2() {
    let Question = {
      EmpAccountId: this.UserModel.UserTypeId,
      StudentID: this.getStudentId,
      UserId: this.UserModel.UserTypeId,
      UserRole: this.UserModel.RoleCode,
      AuthUserId: this.UserModel.UserTypeId,
      Comments: this.attendanceV2Form.controls['Comments'].value,
      InternshipId: this.getInternshipId,
      RequestType: 0,
      IsActive: true,

    }
    this.attendanceV2Service.CreateOrUpdateEmpCommentsForInternshipsDetails(Question).subscribe((highlightOneResp: any) => {
      if (highlightOneResp.Success == true) {
        this.zone.run(() => {
          sessionStorage.setItem('EntryDate', JSON.stringify(this.todayDate));
          this.filesArray = [];
          this.IsLoading = false;
          this.showActionMessage(
            this.Constants.Messages.RecordSavedMessage,
            this.Constants.Html.SuccessSnackbar
          );
        });
      }
      else {
        this.showErrorMessage(
          // 'Invalid CreateQuestion ',
          highlightOneResp.Errors[0],
          'info-snackbar'
        );
      }
    }, error => {
    });
  }

  AllApplicants() {
    this.attendanceV2Service.getAllApplicantsForEmployer(this.UserModel.LoginId, this.InternshipId, 0, 10000).subscribe(response => {
      // this.internshipDetails = {
      this.internshipDetails.Title = response.Results[0].Title,
        this.internshipDetails.StartDate = response.Results[0].StartDate,
        this.internshipDetails.Duration = response.Results[0].Duration,
        this.internshipDetails.PublishDate = response.Results[0].PublishDate,
        // }
        //this.allApplicationData = response.Results;
        this.allApplicationData = response.Results.filter(x => x.StudentId != "00000000-0000-0000-0000-000000000000");
    });
  }

  ShortlistedApplicants() {
    this.attendanceV2Service.getShortlistedApplicantsForEmployer(this.UserModel.LoginId, this.InternshipId, 0, 10000).subscribe(response => {
      //this.shartlistedStudentData = response.Results;
      this.totalHired = response.Results.filter(x => x.IsPlaced == true).length;
      this.shartlistedStudentData = response.Results.filter(x => x.StudentId != "00000000-0000-0000-0000-000000000000");
    });
  }

  callStudentId(item) {
    sessionStorage.setItem('StudentID', item);
    if (item) {
      this.router.navigateByUrl(RouteConstants.ProfileMobile);
    }
  }


}
