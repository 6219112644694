import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { SurveyForm1Service } from './survey-form1.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';


import { SurveyForm1Model } from './survey-form1.model';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';


@Component({
  providers: [AppConstants],
  selector: 'igmite-survey-form1',
  templateUrl: './survey-form1.component.html',
  styleUrls: ['./survey-form1.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SurveyForm1Component extends BaseListComponent<SurveyForm1Model> implements OnInit {
  public interestsForm: FormGroup;
  public loginModel: LoginModel;
  public surveyForm1Model: SurveyForm1Model;

  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  selectedName: any = []
  InterestsData: any
  selectedId: any = [];
  selectedData: any = [];
  // toggle = true;
  toggleLog = false;
  checked: boolean = false;
  interestsByStudent: any;
  current = 'AllApplications';

  allApplicationData: any;
  shartlistedStudentData: any;
  totalHired: number;
  ApiBaseUrlImage: any;

  InternshipId: string;
  internshipDetails: any = {};
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private applicantListingV2Service: SurveyForm1Service,
    private dialogService: DialogService,
    public zone: NgZone,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);


    this.surveyForm1Model = new SurveyForm1Model();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  toggle(tab) {
    this.current = tab;
  
    if (this.current == 'AllApplications') {
      this.AllApplicants();
    } else {
      this.ShortlistedApplicants();
    }
  }
  ngOnInit(): void {
    this.ApiBaseUrlImage=this.Constants.CommonImageUrl.Account

    this.InternshipId = sessionStorage.getItem("InternshipId");
    this.route.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.InternshipId = params.get('internshipId');
      }
    });

    // let elementIn: any = document.querySelectorAll(".withoutlogin");
    // elementIn.forEach(elementIn => {
    //   elementIn.setAttribute('style', 'display: none !important');

    // });

    let elementIn1: any = document.querySelectorAll(".withoutlogin");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    let elementIn2: any = document.querySelectorAll(".withinlogin");
    elementIn2.forEach(elementIn2 => {
      elementIn2.setAttribute('style', 'display: none !important');

    });

    this.AllApplicants();

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }





  }
  AllApplicants() {
    this.applicantListingV2Service.getAllApplicantsForEmployer(this.UserModel.LoginId, this.InternshipId, 0, 10000).subscribe(response => {
      // this.internshipDetails = {
      this.internshipDetails.Title = response.Results[0].Title,
        this.internshipDetails.StartDate = response.Results[0].StartDate,
        this.internshipDetails.Duration = response.Results[0].Duration,
        this.internshipDetails.PublishDate = response.Results[0].PublishDate,
        // this.totalHired = response.Results.filter(x => x.IsPlaced == true).length;
        this.totalHired = response.Results[0].TotalHire;
      // }
      //this.allApplicationData = response.Results;
      this.allApplicationData = response.Results.filter(x => x.StudentId != "00000000-0000-0000-0000-000000000000");
    });
  }


  ShortlistedApplicants() {
    this.applicantListingV2Service.getShortlistedApplicantsForEmployer(this.UserModel.LoginId, this.InternshipId, 0, 10000).subscribe(response => {
      //this.shartlistedStudentData = response.Results;
      // this.totalHired = response.Results.filter(x => x.IsPlaced == true).length;
      this.totalHired = response.Results[0].TotalHire;
      this.shartlistedStudentData = response.Results.filter(x => x.StudentId != "00000000-0000-0000-0000-000000000000");
    });
  }


  onClickHireStudent(studentId: string, internshipId: string) {
    this.applicantListingV2Service.hireStudent(studentId, internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.SuccessSnackbar
          );
          // this.ShortlistedApplicants();
          this.ngOnInit();
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.ErrorSnackbar,
          );
          // this.ShortlistedApplicants();
        });
      }

    });
  }


  onClickShortListApplication(studentId: string, internshipId: string) {
    this.applicantListingV2Service.shortlistApplicantByEmployer(studentId, internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.SuccessSnackbar
          );
          // this.ShortlistedApplicants();
          this.ngOnInit();
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.ErrorSnackbar,
          );
          // this.ShortlistedApplicants();
        });
      }

    });
  }

  onClickRejectApplicant(studentId: string, internshipId: string) {
    this.applicantListingV2Service.rejectApplicantByEmployer(studentId, internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.SuccessSnackbar
          );
          // this.ShortlistedApplicants();
          this.ngOnInit();
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.ErrorSnackbar,
          );
          // this.ShortlistedApplicants();
        });
      }

    });
  }

  callStudentId(item) {
    sessionStorage.setItem('StudentID', item);
    if (item) {
      this.router.navigateByUrl(RouteConstants.ProfileMobile);
    }
  }












}
