import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { BaseListComponent } from 'app/common/base-list/base.list.component';


import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { debug } from 'console';
import { EmployerProfileEditModel } from './employer-profile-edit.model';
import { EmployerProfileEditService } from './employer-profile-edit.service';
import { FileUploadModel } from 'app/models/file.upload.model';


@Component({
  providers: [AppConstants],
  selector: 'igmite-employer-profile-edit',
  templateUrl: './employer-profile-edit.component.html',
  styleUrls: ['./employer-profile-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class EmployerProfileEditComponent extends BaseListComponent<EmployerProfileEditModel> implements OnInit {
  public empProfileDetailsForm: FormGroup;
  public loginModel: LoginModel;
  empProfileDetails: any;
  filesArray: any = [];
  FileName: string;
  public employerProfileEditModel: EmployerProfileEditModel;
  fileUploadModel: FileUploadModel;
  disabledField: boolean = true;
  FileName1: any;
  PhotoUrl: any;

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private employerProfileEditService: EmployerProfileEditService,
    private dialogService: DialogService,
    public zone: NgZone,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);


    this.employerProfileEditModel = new EmployerProfileEditModel();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {

    let elementIn: any = document.querySelectorAll(".withinlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    let elementIn1: any = document.querySelectorAll(".withoutlogin");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    // let elementIn1: any = document.querySelectorAll(".withinlogin");
    // elementIn1.forEach(elementIn1 => {
    //   elementIn1.setAttribute('style', 'display: none !important');

    // });

    // let elementIn2: any = document.querySelectorAll(".withinlogin");
    // elementIn2.forEach(elementIn2 => {
    //   elementIn2.setAttribute('style', 'display: none !important');

    // });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }

    var EmpBranchID = { DataId: this.UserModel.EmpBranchID }

    this.employerProfileEditService.GetEmployerProfileConfirmationById(EmpBranchID).subscribe((profileResp: any) => {
      if (profileResp.Success == true) {
        this.zone.run(() => {
          this.employerProfileEditModel = profileResp.Results[0];
          this.empProfileDetails = profileResp.Results[0];
          this.PhotoUrl = this.Constants.CommonImageUrl.Employer + this.empProfileDetails.PhotoUrl;
          //this.empProfileDetails.PhotoUrl = this.Constants.CommonImageUrl.Employer + this.empProfileDetails.PhotoUrl;

          this.FileName = profileResp.Results[0].PhotoUrl;
          this.empProfileDetailsForm.controls['POCName'].patchValue(this.empProfileDetails.POCName);
          this.empProfileDetailsForm.controls['POCMobile'].patchValue(this.empProfileDetails.POCMobile);
          this.empProfileDetailsForm.controls['OrganizationName'].patchValue(this.empProfileDetails.OrganizationName);
          this.empProfileDetailsForm.controls['BranchAddress'].patchValue(this.empProfileDetails.BranchAddress);
          this.empProfileDetailsForm.controls['LoginId'].patchValue(this.empProfileDetails.POCEmailId);
          //this.empProfileDetailsForm=this.createEmpProfileDetailsForm();
        });
      }
    }, error => {
    });
    this.empProfileDetailsForm = this.createEmpProfileDetailsForm()

  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      this.getFileDetails(event)

    } ``
  }

  getFileDetails(e) {
    this.getUploadedFileData(e, this.Constants.DocumentType.Attachments).then((response: FileUploadModel) => {
      this.fileUploadModel = response;

      this.FileName = response.FileName
      this.filesArray.push({
        UserId: this.UserModel.EmpBranchID,
        RequestType: 0,
        IsActive: true,
        AttatchmentId: "00000000-0000-0000-0000-000000000000",
        SectionType: "DailyDiary",
        SectionId: "12345678-9012-3456-7890-123456789012",
        AttachmentType: "Image",
        AttachmentPath: " this.FileName",
        AttachmentDesc: "Testing Image Attachments",
        Sequence: 1,
        AccountId: this.UserModel.UserTypeId,
        AttachmentsFile: this.fileUploadModel,
      })
      this.PhotoUrl = response.Base64Data;
    });
  }

  removeImg() {

    this.filesArray.splice(1);
    this.FileName = undefined;
    if (this.PhotoUrl != null || this.PhotoUrl == '') {
      this.PhotoUrl = this.Constants.CommonImageUrl.Employer + this.empProfileDetails.PhotoUrl;
      //this.FileName =  this.Constants.CommonImageUrl.Employer + this.empProfileDetails.PhotoUrl;
    }
    else {
      this.PhotoUrl = this.Constants.CommonImageUrl.Employer + null;

    }

  }

  createProfilePic() {
    if (this.filesArray.length != 0) {
      if (this.FileName == undefined || this.FileName == this.empProfileDetails.PhotoUrl) {
        let employerProfileRequestParam = {
          EmployersBranchesId: this.UserModel.EmpBranchID,
          POCName: this.empProfileDetailsForm.controls['POCName'].value,
          POCMobile: this.empProfileDetailsForm.controls['POCMobile'].value,
          BranchAddress: this.empProfileDetailsForm.controls['BranchAddress'].value,
          LoginId: this.empProfileDetails.POCEmailId,
          DistrictCode: this.empProfileDetails.DistrictCode,
          DistrictName: this.empProfileDetails.DistrictName,
          Pincode: this.empProfileDetails.Pincode,
          StateCode: this.empProfileDetails.StateCode,
          //PhotoFileURL: (this.FileName == undefined ? this.empProfileDetails.PhotoUrl : companyLogoImgResp.Result)
          PhotoFileURL: this.empProfileDetails.PhotoUrl
        }

        this.employerProfileEditService.createOrUpdateEmployerProfileInfo(employerProfileRequestParam)
          .subscribe((empProInfoResp: any) => {

            if (empProInfoResp.Success) {
              this.zone.run(() => {
                // this.showActionMessage(
                //   empProInfoResp.Result,
                //   this.Constants.Html.SuccessSnackbar
                // );
                if (this.empProfileDetails.PhotoUrl != null) {
                  var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                  currentUser.PhotoUrl = this.empProfileDetails.PhotoUrl
                  sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
                  let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                  userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.empProfileDetails.PhotoUrl);
                }
                let EmployerProfileEdit = 'EmployerProfileEdit'
                sessionStorage.setItem('ThankYou', EmployerProfileEdit);
                this.router.navigateByUrl(RouteConstants.ThankYou);
                //this.router.navigate([RouteConstants.EmployerProfile]);
              });
            }
            else {
              var errorMessages = this.getHtmlMessage(empProInfoResp.Errors)
              this.dialogService.openShowDialog(errorMessages);
            }
          }, error => {
          });
      }
      else {
        this.fileUploadModel.UserId = this.UserModel.EmpBranchID;
        this.fileUploadModel.ContentType = 'Employer'
        let imgRequestPrama = {
          LoginId: this.UserModel.LoginId,
          PhotoFile: this.fileUploadModel
        }

        this.employerProfileEditService.companyLogoImg(imgRequestPrama)
          .subscribe((companyLogoImgResp: any) => {

            if (companyLogoImgResp.Success) {
              this.zone.run(() => {
                // this.showActionMessage(
                //   this.Constants.Messages.ImageUploadMessage,
                //   this.Constants.Html.SuccessSnackbar
                // );
                //this.FileName1 = companyLogoImgResp.Result;
                if (!this.empProfileDetailsForm.valid) {
                  this.validateAllFormFields(this.empProfileDetailsForm);
                  return;
                }

                this.setValueFromFormGroup(this.empProfileDetailsForm, this.employerProfileEditModel);
                let employerProfileRequestParam = {
                  EmployersBranchesId: this.UserModel.EmpBranchID,
                  POCName: this.empProfileDetailsForm.controls['POCName'].value,
                  POCMobile: this.empProfileDetailsForm.controls['POCMobile'].value,
                  BranchAddress: this.empProfileDetailsForm.controls['BranchAddress'].value,
                  LoginId: this.empProfileDetails.POCEmailId,
                  DistrictCode: this.empProfileDetails.DistrictCode,
                  DistrictName: this.empProfileDetails.DistrictName,
                  Pincode: this.empProfileDetails.Pincode,
                  StateCode: this.empProfileDetails.StateCode,
                  //PhotoFileURL: (this.FileName == undefined ? this.empProfileDetails.PhotoUrl : companyLogoImgResp.Result)
                  PhotoFileURL: companyLogoImgResp.Result
                }

                this.employerProfileEditService.createOrUpdateEmployerProfileInfo(employerProfileRequestParam)
                  .subscribe((empProInfoResp: any) => {

                    if (empProInfoResp.Success) {
                      this.zone.run(() => {
                        // this.showActionMessage(
                        //   empProInfoResp.Result,
                        //   this.Constants.Html.SuccessSnackbar
                        // );
                        if (this.empProfileDetails.PhotoUrl != null) {
                          var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                          currentUser.PhotoUrl = companyLogoImgResp.Result
                          sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
                          let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                          userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.empProfileDetails.PhotoUrl);
                        }
                        let EmployerProfileEdit = 'EmployerProfileEdit'
                        sessionStorage.setItem('ThankYou', EmployerProfileEdit);
                        this.router.navigateByUrl(RouteConstants.ThankYou);
                        //this.router.navigate([RouteConstants.EmployerProfile]);
                      });
                    }
                    else {
                      var errorMessages = this.getHtmlMessage(empProInfoResp.Errors)
                      this.dialogService.openShowDialog(errorMessages);
                    }
                  }, error => {
                  });

              });

            }
            else {
              var errorMessages = this.getHtmlMessage(companyLogoImgResp.Errors)
              this.dialogService.openShowDialog(errorMessages);
            }
          }, error => {
          });
      }


    }
    else {
      this.setValueFromFormGroup(this.empProfileDetailsForm, this.employerProfileEditModel);
      let employerProfileRequestParam = {
        EmployersBranchesId: this.UserModel.EmpBranchID,
        POCName: this.empProfileDetailsForm.controls['POCName'].value,
        POCMobile: this.empProfileDetailsForm.controls['POCMobile'].value,
        BranchAddress: this.empProfileDetailsForm.controls['BranchAddress'].value,
        LoginId: this.empProfileDetails.POCEmailId,
        DistrictCode: this.empProfileDetails.DistrictCode,
        DistrictName: this.empProfileDetails.DistrictName,
        Pincode: this.empProfileDetails.Pincode,
        StateCode: this.empProfileDetails.StateCode,
        //PhotoFileURL: (this.FileName == undefined ? this.PhotoUrl : this.FileName)
        PhotoFileURL: this.FileName
      }

      this.employerProfileEditService.createOrUpdateEmployerProfileInfo(employerProfileRequestParam)
        .subscribe((empProInfoResp: any) => {

          if (empProInfoResp.Success) {
            this.zone.run(() => {
              // this.showActionMessage(
              //   empProInfoResp.Result,
              //   this.Constants.Html.SuccessSnackbar
              // );
              if (this.PhotoUrl != null) {
                var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                currentUser.PhotoUrl = this.empProfileDetails.PhotoUrl
                sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
                let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.PhotoUrl);
              }
              let EmployerProfileEdit = 'EmployerProfileEdit'
              sessionStorage.setItem('ThankYou', EmployerProfileEdit);
              this.router.navigateByUrl(RouteConstants.ThankYou);
              //this.router.navigateByUrl(RouteConstants.EmployerProfile);
            });
          }
          else {
            var errorMessages = this.getHtmlMessage(empProInfoResp.Errors)
            this.dialogService.openShowDialog(errorMessages);
          }
        }, error => {
        });
    }
  }

  saveOrUpdateEmployerProfileInfo() {
    this.createProfilePic();

  }




  createEmpProfileDetailsForm(): FormGroup {
    return this.formBuilder.group({
      POCName: new FormControl({ value: this.employerProfileEditModel.POCName }, [Validators.required]),
      POCMobile: new FormControl({ value: this.employerProfileEditModel.POCMobile }, [Validators.required]),
      LoginId: new FormControl({ value: this.employerProfileEditModel.POCEmailId, disabled: true }, [Validators.required]),
      OrganizationName: new FormControl({ value: this.employerProfileEditModel.OrganizationName, disabled: true }, [Validators.required]),
      BranchAddress: new FormControl({ value: this.employerProfileEditModel.BranchAddress }, [Validators.required]),
    });

  }




}
