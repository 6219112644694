
export class LoginModel {
    UserId: string;
    Password: string;
    RememberMe: boolean;
    AuthToken: string;
    RoleCode: string;


    constructor() {
        this.UserId = '';
        this.Password = '';        
        this.RememberMe = false;
        this.AuthToken = '';
        this.RoleCode = '';

    }
}
