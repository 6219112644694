<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu-emp></igmite-side-menu-emp>

                </div>

                <div class="col-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
                    <div class="row px-0 mx-0 align-items-lg-center max-w-800 mx-auto mb-5">

                        <div class="col-12 px-0 text-center">

                            <div
                                class="bg-theme-light-grey top-circle overflow-hidden mt-lg-0 mx-auto d-flex justify-content-center align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1" height="60px"
                                    viewBox="0 0 24 24" width="60px" fill="#000000">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path
                                        d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <p class="fs-22 text-black fw-900  line-h-22 mt-15 mb-30 px-10 text-center"> Working Interns
                    </p>

                    <div class="border-theme-2px border-radius-20 px-10 py-15 mb-30 max-w-600 mx-auto"
                        *ngFor="let ongoingInterns of ongoingInternshipData;index as i;">
                        <div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                    <img [src]="ApiBaseUrlImage + ongoingInterns.ProfilePhoto"
                                        class="rounded-circle border-theme-2px mr-10" alt="" srcset="" width="40"
                                        height="40">
                                    <div>
                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                            {{ongoingInterns.StudentName}}</p>
                                        <p class="fs-14 fw-600 text-black mb-0 mt-0"> {{ongoingInterns.Title}}</p>
                                    </div>
                                </div>

                                <a (click)="callStudentId(ongoingInterns.StudentId)" class="ml-auto d-block">
                                    <i
                                        class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                </a>

                            </div>

                            <div class="border-radius-25 bg-light-theme px-15 py-15 mt-20 ">
                                <div class="align-items-center row">

                                    <a (click)="attendance(ongoingInterns.StudentId, ongoingInterns.InternshipId)"
                                        class="col-6 d-flex align-items-center border border-2 border-start-0 border-top-0 border-bottom-0 border-end pr-5">

                                        <svg class="fill-color-1 mr-10" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24"
                                            width="30px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24" />
                                            </g>
                                            <g>
                                                <path
                                                    d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z" />
                                            </g>
                                        </svg>

                                        <div>
                                            <p class="fs-13 text-black fw-600 mb-0 line-h-17">Attendance</p>
                                            <p class="fs-17 color1 fw-900 mb-0">{{ongoingInterns.TotalAttendance}} D</p>
                                        </div>
                                    </a>

                                    <a (click)="redirectOnDiaryEntry(ongoingInterns.StudentId, ongoingInterns.InternshipId)" class="col-6 d-flex align-items-center">
                                        <svg class="fill-color-1 mr-10" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24"
                                            width="30px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24" />
                                            </g>
                                            <g>
                                                <g />
                                                <g>
                                                    <path
                                                        d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
                                                    <g>
                                                        <path
                                                            d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z" />
                                                        <path
                                                            d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z" />
                                                        <path
                                                            d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <div>
                                            <p class="fs-13 text-black fw-600 mb-0 line-h-17">Diary Entry</p>
                                            <p class="fs-17 color1 fw-900 mb-0">{{ongoingInterns.Totalhours /
                                                80 *100 | number}}%</p>
                                        </div>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="border-theme-2px border-radius-20 px-10 py-15 mb-30 max-w-600 mx-auto">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                <img src="../Upload/Manisha-Mishra.png" class="rounded-circle border-theme-2px mr-10"
                                    alt="" srcset="" width="40" height="40">
                                <div>
                                    <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">Roshni Jha</p>
                                    <p class="fs-14 fw-600 text-black mb-0 mt-0">Web Designer</p>
                                </div>

                            </div>
                            <a href="../Employer/student-profile-detail.html" class="ml-auto d-block">
                                <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                            </a>

                        </div>


                        <div class="border-radius-25 bg-light-theme px-15 py-15 mt-20 ">
                            <div class="align-items-center row">

                                <a href="../Employer/attendance-v2.html"
                                    class="col-6 d-flex align-items-center border border-2 border-start-0 border-top-0 border-bottom-0 border-end pr-5">

                                    <svg class="fill-color-1 mr-10" xmlns="http://www.w3.org/2000/svg"
                                        enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24" width="30px"
                                        fill="#000000">
                                        <g>
                                            <rect fill="none" height="24" width="24" />
                                        </g>
                                        <g>
                                            <path
                                                d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z" />
                                        </g>
                                    </svg>

                                    <div>
                                        <p class="fs-13 text-black fw-600 mb-0 line-h-17">Attendance</p>
                                        <p class="fs-17 color1 fw-900 mb-0">12 D</p>
                                    </div>
                                </a>

                                <a href="../Employer/attendance-v2.html" class="col-6 d-flex align-items-center">
                                    <svg class="fill-color-1 mr-10" xmlns="http://www.w3.org/2000/svg"
                                        enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24" width="30px"
                                        fill="#000000">
                                        <g>
                                            <rect fill="none" height="24" width="24" />
                                        </g>
                                        <g>
                                            <g />
                                            <g>
                                                <path
                                                    d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
                                                <g>
                                                    <path
                                                        d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z" />
                                                    <path
                                                        d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z" />
                                                    <path
                                                        d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>
                                        <p class="fs-13 text-black fw-600 mb-0 line-h-17">diary Entry</p>
                                        <p class="fs-17 color1 fw-900 mb-0">45%</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class="border-theme-2px border-radius-20 px-10 py-15 mb-30 max-w-600 mx-auto">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                <img src="../Upload/Mohan-Singh.png" class="rounded-circle border-theme-2px mr-10"
                                    alt="" srcset="" width="40" height="40">
                                <div>
                                    <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">Mohan Singh</p>
                                    <p class="fs-14 fw-600 text-black mb-0 mt-0">Mobile Repair</p>
                                </div>

                            </div>
                            <a href="../Employer/student-profile-detail.html" class="ml-auto d-block">
                                <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                            </a>
                        </div>

                        <div class="border-radius-25 bg-light-theme px-15 py-15 mt-20 ">
                            <div class="align-items-center row">

                                <div
                                    class="col-6 d-flex align-items-center border border-2 border-start-0 border-top-0 border-bottom-0 border-end pr-5">

                                    <svg class="fill-color-1 mr-10" xmlns="http://www.w3.org/2000/svg"
                                        enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24" width="30px"
                                        fill="#000000">
                                        <g>
                                            <rect fill="none" height="24" width="24" />
                                        </g>
                                        <g>
                                            <path
                                                d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z" />
                                        </g>
                                    </svg>

                                    <div>
                                        <p class="fs-13 text-black fw-600 mb-0 line-h-17">Attendance</p>
                                        <p class="fs-17 color1 fw-900 mb-0">18 D</p>
                                    </div>
                                </div>

                                <div class="col-6 d-flex align-items-center">
                                    <svg class="fill-color-1 mr-10" xmlns="http://www.w3.org/2000/svg"
                                        enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24" width="30px"
                                        fill="#000000">
                                        <g>
                                            <rect fill="none" height="24" width="24" />
                                        </g>
                                        <g>
                                            <g />
                                            <g>
                                                <path
                                                    d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
                                                <g>
                                                    <path
                                                        d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z" />
                                                    <path
                                                        d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z" />
                                                    <path
                                                        d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                    <div>
                                        <p class="fs-13 text-black fw-600 mb-0 line-h-17">diary Entry</p>
                                        <p class="fs-17 color1 fw-900 mb-0">45%</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="border-theme-2px border-radius-20 px-10 py-15 mb-30 max-w-600 mx-auto">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                <img src="../Upload/Roshni-Jha.png" class="rounded-circle border-theme-2px mr-10" alt=""
                                    srcset="" width="40" height="40">
                                <div>
                                    <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">Akshra Singh</p>
                                    <p class="fs-14 fw-600 text-black mb-0 mt-0">Electrician</p>
                                </div>

                            </div>
                            <a href="../Employer/student-profile-detail.html" class="ml-auto d-block">
                                <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                            </a>

                        </div>

                        <div class="border-radius-25 bg-light-theme px-15 py-15 mt-20 ">
                            <div class="align-items-center row">

                                <div
                                    class="col-6 d-flex align-items-center border border-2 border-start-0 border-top-0 border-bottom-0 border-end pr-5">

                                    <svg class="fill-color-1 mr-10" xmlns="http://www.w3.org/2000/svg"
                                        enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24" width="30px"
                                        fill="#000000">
                                        <g>
                                            <rect fill="none" height="24" width="24" />
                                        </g>
                                        <g>
                                            <path
                                                d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z" />
                                        </g>
                                    </svg>

                                    <div>
                                        <p class="fs-13 text-black fw-600 mb-0 line-h-17">Attendance</p>
                                        <p class="fs-17 color1 fw-900 mb-0">16 D</p>
                                    </div>
                                </div>

                                <div class="col-6 d-flex align-items-center">


                                    <svg class="fill-color-1 mr-10" xmlns="http://www.w3.org/2000/svg"
                                        enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24" width="30px"
                                        fill="#000000">
                                        <g>
                                            <rect fill="none" height="24" width="24" />
                                        </g>
                                        <g>
                                            <g />
                                            <g>
                                                <path
                                                    d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
                                                <g>
                                                    <path
                                                        d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z" />
                                                    <path
                                                        d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z" />
                                                    <path
                                                        d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                    <div>
                                        <p class="fs-13 text-black fw-600 mb-0 line-h-17">diary Entry</p>
                                        <p class="fs-17 color1 fw-900 mb-0">15%</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> -->
                </div>

            </div>
        </div>
    </div>
</section>