import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { DateService } from 'app/common/date-dialog/date.service';



@Component({
  providers: [AppConstants],
  selector: 'igmite-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginComponent extends BaseComponent<LoginModel> implements OnInit {
  public loginForm: FormGroup;
  public loginModel: LoginModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  current = ''
  isInvalidUser: boolean;
  img: any
  allowedData: any
  interestsByStudent: any;
  imgEmp: any;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private zone: NgZone,private dateService: DateService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    // Redirect to home if already logged in
    if (this.authenticationService.authUser) {
      this.router.navigate(['/']);
    }

    this.authenticationService.resetLogin();

    // Set the default login Model
    this.loginModel = new LoginModel();
    // this.current = 'Student'
    var tab = JSON.parse(localStorage.getItem('currentTab'));
    if (tab != null) {
      this.current = tab

    } else {
      this.current = 'Student'
      localStorage.setItem('currentTab', JSON.stringify(this.current));

    }


  }

  ngOnInit(): void {
    // this.current = 'Student'
    var tab = JSON.parse(localStorage.getItem('currentTab'));
    if (tab != null) {
      this.current = tab
    } else {

      this.current = 'Student'
      localStorage.setItem('currentTab', JSON.stringify(this.current));

    }
    // reset login status
    let element: any = document.querySelectorAll(".displayLogout");
    element.forEach(element => {
      element.setAttribute('style', 'display: none !important');
    });
    let elementIn: any = document.querySelectorAll(".withinlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    this.authenticationService.resetLogin();

    // Get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    if (window.location.hostname === 'localhost') {
      //this.loginModel.UserId = 'ritesh.gtmcs@gmail.com';
      this.loginModel.UserId = 'ram@testmail.com';
      this.loginModel.Password = 'Ram_Patil@01/04/2011';
    }

    //this.cookieService.set('UserId', this.loginModel.UserId);
    let rememberMe = (this.cookieService.get('RememberMe') == 'true');

    if (rememberMe) {
      this.loginModel.UserId = this.cookieService.get('UserId');
      this.loginModel.Password = this.cookieService.get('Password');
      this.loginModel.RememberMe = true;
    }

    this.loginForm = this.createLoginForm();

  }

  setVisiblePassword(): void {
    this.isVisiblePassword = !this.isVisiblePassword;
  }

  toggle(tab) {
    this.current = tab;
    localStorage.setItem('currentTab', JSON.stringify(this.current));
    this.loginForm.reset();

  }

  validateUserAuth() {
    if (this.current == 'Student') {
      this.loginModel.UserId = this.loginForm.value.UserId;
      this.loginModel.Password = this.loginForm.value.Password;
      this.loginModel.RememberMe = false;
      this.loginModel.RoleCode = 'ST'
    }
    if (this.current == 'Employer') {
      this.loginModel.UserId = this.loginForm.value.UserId;
      this.loginModel.Password = this.loginForm.value.Password;
      this.loginModel.RememberMe = false;
      this.loginModel.RoleCode = 'EMP'
    }


    this.authenticationService.loginUser(this.loginModel)
      .subscribe((logResp: any) => {
        console.log(logResp.Result)
        if (logResp.Success && logResp.Errors.length == 0) {
          this.isInvalidUser = false;
          this.img = logResp.Result.PhotoUrl
          this.imgEmp = logResp.Result.PhotoUrl
          var currentUser: UserModel = {
            UserTypeId: logResp.Result.UserTypeId,
            LoginId: logResp.Result.LoginId,
            Password: logResp.Result.Password,
            UserId: logResp.Result.UserId,
            UserName: logResp.Result.UserName,
            FirstName: logResp.Result.FirstName,
            LastName: logResp.Result.LastName,
            EmailId: logResp.Result.EmailId,
            Mobile: logResp.Result.Mobile,
            IsAdmin: false,
            Designation: logResp.Result.Designation,
            DateOfJoining: logResp.Result.DateOfJoining,
            DateOfAllocation: logResp.Result.DateOfAllocation,
            RoleCode: logResp.Result.RoleCode,
            DefaultStateId: logResp.Result.DefaultStateId,
            StateId: logResp.Result.StateId,
            DivisionId: logResp.Result.DivisionId,
            DistrictId: logResp.Result.DistrictId,
            BlockId: logResp.Result.BlockId,
            AccountType: logResp.Result.AccountType,
            LandingPageUrl: logResp.Result.LandingPageUrl,
            InvalidAttempt: logResp.Result.InvalidAttempt,
            IsLocked: logResp.Result.IsLocked,
            IsPasswordReset: logResp.Result.IsPasswordReset,
            LastLoginDate: logResp.Result.LastLoginDate,
            PasswordExpiredOn: logResp.Result.PasswordExpiredOn,
            PasswordResetToken: logResp.Result.PasswordResetToken,
            PasswordUpdateDate: logResp.Result.PasswordUpdateDate,
            TokenExpiredOn: logResp.Result.TokenExpiredOn,
            AuthToken: logResp.Result.AuthToken,
            InternshipId: logResp.Result.InternshipId,
            UserRoleId: logResp.Result.UserRoleId,
            PhotoUrl: logResp.Result.PhotoUrl,
            EmployerId: logResp.Result.EmployerId,
            EmpBranchID: logResp.Result.EmpBranchID,
            InternshipActualStartDate: logResp.Result.InternshipActualStartDate,
            IsDirectMappingEnabled:logResp.Result.IsDirectMappingEnabled,
            RoleTransactions: []
          };

          var logoutPram = {
            UserId: logResp.Result.LoginId,
            AuthToken: logResp.Result.AuthToken
          };
          localStorage.setItem('logoutUser', JSON.stringify(logoutPram));//for logout

          // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
          AppConstants.AuthToken = currentUser.AuthToken;
          if (currentUser.RoleCode == 'ST') {
            var data = {
              "DataId": currentUser.UserTypeId,
              "DataId1": currentUser.InternshipId === null ? '' : currentUser.InternshipId,
            }
            this.authenticationService.allowed(data).subscribe((response: any) => {
              this.allowedData = response.Result
              sessionStorage.setItem('allow', JSON.stringify(this.allowedData));

            }, error => {
            });


            var data1 = {
              "DataId": currentUser.UserTypeId,
            }
            this.authenticationService.GetInterestsByStudentId(data1).subscribe((response: any) => {
              if (response.Success) {
                sessionStorage.setItem('interests', JSON.stringify(true));

                this.interestsByStudent = response.Results

              } else {
                sessionStorage.setItem('interests', JSON.stringify(false));

              }

            }, error => {
            });
          }


          this.authenticationService.getUserTransactionsById(this.loginModel)
            .subscribe((tranResp: any) => {
              if (tranResp.Success && tranResp.Errors.length == 0) {

                let userNavigations = [];
                let transHeader: any, menuItem: any;

                tranResp.Results.forEach(tranItem => {

                  if (tranItem.HeaderName != 'Main') {
                    let headerItem = userNavigations.find(ob => ob.title === tranItem.HeaderName);

                    if (headerItem === undefined) {
                      transHeader = {
                        id: tranItem.HeaderName.toLowerCase(),
                        title: tranItem.HeaderName,
                        translate: 'NAV.' + tranItem.HeaderName.toUpperCase(),
                        type: 'collapsable',
                        icon: 'receipt',
                        //url: tranItem.RouteUrl,
                        badge: [],
                        children: [],
                        isVissible: tranItem.IsHeaderMenu
                      };

                      let subMenuItems = tranResp.Results.filter(ob => ob.HeaderName === tranItem.HeaderName);

                      if (subMenuItems.length > 0) {
                        subMenuItems.forEach(tranSubItem => {

                          menuItem = {
                            id: tranSubItem.Name.toLowerCase(),
                            title: tranSubItem.PageTitle,
                            translate: '',
                            type: 'item',
                            icon: 'layers',
                            url: tranSubItem.RouteUrl,
                            badge: [],
                            children: [],
                            isVissible: tranSubItem.IsHeaderMenu
                          }

                          transHeader.children.push(menuItem);
                        });
                      }

                      userNavigations.push(transHeader);
                    }
                  }
                });

                sessionStorage.setItem('userNavigations', JSON.stringify(userNavigations));
                sessionStorage.setItem('userRoleTransactions', JSON.stringify(tranResp.Results));

                if (this.loginModel.RememberMe) {
                  this.cookieService.set('UserId', this.loginModel.UserId);
                  this.cookieService.set('Password', this.loginModel.Password);
                }
                else {
                  this.cookieService.set('UserId', '');
                  this.cookieService.set('Password', '');
                }

                this.cookieService.set('RememberMe', this.loginModel.RememberMe.toString());
              }

              let passwordExpiredOn = new Date(logResp.Result.PasswordExpiredOn);
              let currentDate = new Date();

              // if (passwordExpiredOn < currentDate) {
              //   this.zone.run(() => {
                         
              //     this.snackBar.open("Password Expired", "Dismiss", {
              //       duration: 50000000,
              //       panelClass: ["info-snackbar"]
              //     });
              //   });
              //   // this.onClickChangePassword(this.loginModel)
              // } 
              // else {

                if (currentUser.RoleCode == 'ST') {
                  // login successful so redirect to return url 
                  // this.router.navigateByUrl(currentUser.LandingPageUrl);
                  if(currentUser.IsDirectMappingEnabled == true){
                    if(logResp.Result.IsFirstLogin == true){
                      if(logResp.Result.InternshipId != null){
                        this.router.navigateByUrl(RouteConstants.DashboardNew);
                        if (this.img != null) {
                          let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                          userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.img);
                        }
                      }else{

                        this.router.navigateByUrl(RouteConstants.HomeMain);
                      }
                        
                    }else{
                      this.router.navigateByUrl(RouteConstants.ProfileUpload);
                    }
                  } else{
                   
                    if(logResp.Result.IsFirstLogin == true){
                      if(logResp.Result.InternshipId != null){
                        if (this.img != null) {
                          let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                          userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.img);
                        }
                        this.router.navigateByUrl(RouteConstants.DashboardNew);
                      }else{
                        if(this.interestsByStudent.length == 0){
                          if (this.img != null) {
                            let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                            userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.img);
                          }
                          this.router.navigateByUrl(RouteConstants.Interests);

                        }else{
                          if (this.img != null) {
                            let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                            userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.img);
                          }
                          this.router.navigateByUrl(RouteConstants.OpprtunitiesListing);
                        }
                      }

                    }else{
                      this.router.navigateByUrl(RouteConstants.ProfileUpload);

                    }

                  }
                  // if (logResp.Result.IsFirstLogin == true) {
                  //   if (logResp.Result.InternshipId != null) {
                  //     this.router.navigateByUrl(RouteConstants.DashboardNew);

                  //     if (this.img != null) {
                  //       let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                  //       userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.img);
                  //     }

                  //   } else if (currentUser.IsDirectMappingEnabled == false) {
                  //     if(this.interestsByStudent.length != 0){
                  //       this.router.navigateByUrl(RouteConstants.HomeMain);

                  //     }
                  //     // this.router.navigateByUrl(RouteConstants.OpprtunitiesListing);
                  //     if (this.img != null) {
                  //       let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                  //       userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.img);
                  //     }
                  //   } else {
                  //     // this.router.navigateByUrl(RouteConstants.Interests);
                  //     if(currentUser.IsDirectMappingEnabled == true){
                  //       this.router.navigateByUrl(RouteConstants.DashboardNew);

                  //     }else{
                  //       this.router.navigateByUrl(RouteConstants.HomeMain);

                  //     }
                  //     this.router.navigateByUrl(RouteConstants.DashboardNew);


                  //     if (this.img != null) {
                  //       let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                  //       userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.img);
                  //     }
                  //   }

                  // } else {
                  //   this.router.navigateByUrl(RouteConstants.ProfileUpload);

                  // }
                }


                if (currentUser.RoleCode == 'EMP') {
                  this.router.navigateByUrl(RouteConstants.HomeEmployer);
                  if (this.imgEmp != null) {
                    let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
                    userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.imgEmp);
                  }
                }



              }
            // }
          );
        }
        else {
          this.isInvalidUser = true;
          this.zone.run(() => {
            //@ts-ignore

            //@ts-ignore
            this.snackBar.open("No data found..", "Dismiss", {
              duration: 50000000,
              panelClass: ["info-snackbar"]
            });
          });
        }
      });
  }

  //Create login form and returns {FormGroup}
  createLoginForm(): FormGroup {
    return this.formBuilder.group({
      UserId: new FormControl({ value: this.loginModel.UserId, disabled: false }, [Validators.maxLength(100), Validators.pattern(this.Constants.Regex.Email)]),
      Password: new FormControl(this.loginModel.Password, [Validators.required, Validators.maxLength(50), Validators.pattern(this.Constants.Regex.Password)]),
      RememberMe: [this.loginModel.RememberMe]
    });
  }

  // showErrorMessage(messageText: string, messageType: string) {
  //   this.snackBar.open(messageText, "Dismiss", {
  //     duration: 20000000000,
  //     verticalPosition: "bottom",
  //     horizontalPosition: "center",
  //     panelClass: [messageType]
  //   });
  // }
  onClickChangePassword(item) {
    this.dateService
      .openChangeDialog(item)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          console.log(confResp)
          // this.dateUpdatedBystudent = confResp.startDate.value.StartDate;
          // var data1 = {
          //   "DataId": this.UserModel.UserTypeId,
          //   "DataId1": item.InternshipId,

          // }
          var loginData={
            
              "UserId": confResp.UserId,
              "OldPassword": confResp.Password,
              "NewPassword":  confResp.NewPassword,
              "ConfirmPassword": confResp.ConfirmPassword
          
          
          }
          this.authenticationService.ChangePasswordForLoginByUserId(loginData)
            .subscribe((logResp: any) => {
            if (logResp.Success && logResp.Errors.length == 0) {
              this.zone.run(() => {
                //@ts-ignore
        
                //@ts-ignore
                this.snackBar.open("Change Password Successfully..", "Dismiss", {
                  duration: 50000000,
                  panelClass: ["info-snackbar"]
                });
              });
            }else{
              this.zone.run(() => {
                //@ts-ignore
        
                //@ts-ignore
                this.snackBar.open("Password Not Chnage..", "Dismiss", {
                  duration: 50000000,
                  panelClass: ["info-snackbar"]
                });
              });
            }
          })
        }
      });

  }
}
