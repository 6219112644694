import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { Internship3Service } from './internship-3.service';
import { FileUploadModel } from 'app/models/file.upload.model';
import { DatePipe } from '@angular/common'
declare var $: any;

@Component({
  providers: [AppConstants],
  selector: 'igmite-internship-3',
  templateUrl: './internship-3.component.html',
  styleUrls: ['./internship-3.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class Internship3Component extends BaseComponent<LoginModel> implements OnInit {
  StudentDashboardInternshipData: any
  EmployerBranchDetails: any
  DailyDiaryEntryByStudentId: any
  StudentID: any;
  stuId: any
  StudentProfileConfirmationById: any;
  TopPerformerStudentList: any;
  widthVal = 0;
  highData = [] as any;
  high=true
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private Internship3Service: Internship3Service,
    public zone: NgZone,
    public datepipe: DatePipe,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


    const firstTime = localStorage.getItem('key')
    if (!firstTime) {
      localStorage.setItem('key', 'loaded')
      window.location.reload();

    } else {
      localStorage.removeItem('key')
    }



  }



  ngOnInit(): void {
    this.StudentID = sessionStorage.getItem("StudentID");
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');
    });

    let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
    employeeMenuItems.forEach(employeeMenuItems => {
      employeeMenuItems.setAttribute('style', 'display: none !important');
    });

    this.GetStudentDashboardInternshipData()
    this.GetEmployerBranchDetails()
    this.GetDailyDiaryEntryByStudentId()
    this.GetStudentProfileConfirmationById();
  }

  GetStudentDashboardInternshipData() {
    if (this.StudentID) {
      this.stuId = { DataId: this.StudentID }

    } else {
      this.stuId = { DataId: this.UserModel.UserTypeId }
    }
    this.Internship3Service.GetStudentDashboardInternshipData(this.stuId).subscribe((Resp: any) => {
      if (Resp.Success == true) {
        this.zone.run(() => {
          this.StudentDashboardInternshipData = Resp.Results[0];
          var data = this.StudentDashboardInternshipData.Hours.split(":");
          if (data[0] < 80) {
            this.widthVal = data[0]
          } else {
            this.widthVal = 100
          }
          this.IsLoading = false;

        });
      }
    }, error => {
    });
  }

  GetEmployerBranchDetails() {
    if (this.StudentID) {
      this.stuId = { DataId: this.StudentID }

    } else {
      this.stuId = { DataId: this.UserModel.UserTypeId }
    }
    this.Internship3Service.GetEmployerBranchDetails(this.stuId).subscribe((Resp: any) => {
      if (Resp.Success == true) {
        this.zone.run(() => {
          this.EmployerBranchDetails = Resp.Results[0]
          // this.EmployerBranchDetails.PhotoUrl = this.Constants.CommonImageUrl.Account + this.EmployerBranchDetails.PhotoUrl;

          this.IsLoading = false;

        });
      }
    }, error => {
    });
  }

  GetDailyDiaryEntryByStudentId() {
    if (this.StudentID) {
      this.stuId = { DataId: this.StudentID }

    } else {
      this.stuId = { DataId: this.UserModel.UserTypeId }
    }
    this.Internship3Service.GetLatestHighlight(this.stuId).subscribe((Resp: any) => {
      if (Resp.Success == true) {
        this.zone.run(() => {
          this.DailyDiaryEntryByStudentId = Resp.Results

          this.IsLoading = false;
          if(this.DailyDiaryEntryByStudentId.length == 0){
            this.high=false;
          }
          
            $(document).ready(evt => {
              $('.home-video-single').owlCarousel({
                loop: true,
                margin: 10,
                nav: false,
                dots: true,
                center: true,
                responsive: {
                  0: { items: 1 },
                  600: { items: 1 },
                  1000: { items: 1 }
                }
              });
            });
          


        });
      }
    }, error => {
    });
  }



  GetStudentProfileConfirmationById() {
    if (this.StudentID) {
      this.stuId = { DataId: this.StudentID }

    } else {
      this.stuId = { DataId: this.UserModel.UserTypeId }
    }
    this.Internship3Service.GetStudentProfileConfirmationById(this.stuId).subscribe((Resp: any) => {
      if (Resp.Success == true) {
        this.zone.run(() => {
          this.StudentProfileConfirmationById = Resp.Results[0]
          this.StudentProfileConfirmationById.PhotoUrl = this.Constants.CommonImageUrl.Account + this.StudentProfileConfirmationById.PhotoUrl;

          this.IsLoading = false;

        });
      }
    }, error => {
    });
  }



  GetTopPerformerStudentList() {
    if (this.StudentID) {
      this.stuId = { DataId: this.StudentID }

    } else {
      this.stuId = { DataId: this.UserModel.UserTypeId }
    }
    this.Internship3Service.GetTopPerformerStudentList(this.stuId).subscribe((Resp: any) => {
      if (Resp.Success == true) {
        this.zone.run(() => {
          this.TopPerformerStudentList = Resp.Results

          this.IsLoading = false;

        });
      }
    }, error => {
    });
  }








}
