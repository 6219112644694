import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginOTPModel } from 'app/models/login-otp.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { ResetPasswordService } from './reset-password.service';

@Component({
    selector: 'igmite-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordComponent extends BaseComponent<LoginOTPModel> implements OnInit {
    public loginForm: FormGroup;
  public loginOTPModel: LoginOTPModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private resetPasswordService: ResetPasswordService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
      
        sidepanel: {
          hidden: true
        }
      }
    };

    // Redirect to home if already logged in
    if (this.authenticationService.authUser) {
      this.router.navigate(['/']);
    }

    this.authenticationService.resetLogin();
    
    // Set the default login Model
    this.loginOTPModel = new LoginOTPModel();
  }

  ngOnInit(): void {
    let element: any = document.querySelectorAll(".displayLogout");
    element.forEach(element => {
      element.setAttribute('style', 'display: none !important');
    });
    let elementIn: any = document.querySelectorAll(".withinlogin");
    elementIn.forEach(elementIn => {
    elementIn.setAttribute('style', 'display: none !important');

    });
    let elementIn1: any = document.querySelectorAll(".within-employee-login");
    elementIn1.forEach(elementIn1 => {
    elementIn1.setAttribute('style', 'display: none !important');

    });
    // reset login status
    this.authenticationService.resetLogin();

    // Get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';


    this.loginForm = this.createLoginForm();

  }

  SendOTPEmail() {
    // this.loginOTPModel.EmailID = this.loginForm.value.EmailID;
    var data={
      EmailID:this.loginForm.value.EmailID
    }
    this.resetPasswordService.SendOTPEmail(data)
      .subscribe((logResp: any) => {
        if (logResp.Success == true) {
            localStorage.setItem('ResetEmailID', JSON.stringify(this.loginForm.value.EmailID));
            localStorage.setItem('logResp', JSON.stringify(logResp.Result.OtpExpirationDate));

            this.router.navigateByUrl(RouteConstants.ResetPasswordOTP);
        }
        else {
          this.showErrorMessage(
            'Invalid EmailID ',
            'info-snackbar'
          );
        }
      });
  }

  //Create login form and returns {FormGroup}
  createLoginForm(): FormGroup {
    return this.formBuilder.group({
      EmailID: new FormControl({ value: this.loginOTPModel.EmailID, disabled: false }, [Validators.maxLength(100), Validators.pattern(this.Constants.Regex.Email)]),
    });
  }

  showErrorMessage(messageText: string, messageType: string) {
    this.snackBar.open(messageText, "Dismiss", {
      duration: 2000,
      verticalPosition: "bottom",
      horizontalPosition: "center",
      panelClass: [messageType]
    });
  }
}
