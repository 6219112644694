import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { Gallery1Service } from './gallery-1.service';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { ImageService } from './image.service';

@Component({
  providers: [AppConstants],
  selector: 'igmite-gallery-1',
  templateUrl: './gallery-1.component.html',
  styleUrls: ['./gallery-1.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class Gallery1Component extends BaseComponent<LoginModel> implements OnInit {
  public loginForm: FormGroup;
  public loginModel: LoginModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  current = 'Files'
  DailyDiaryImageAttachments: { date: string; Gallery: any; }[];
  galleryData: any;
  ImageGalleryData = [] as any;
  dateArray = [{}] as any;
  IRRportPhotos: any;

  IRCetificate: any;
  IRBestPicture: any;
  img = this.Constants.CommonImageUrl.Account;
  //imageAttachmentsFA = environment.ApiBaseUrlImageAttachments;
  imageAttachments = this.Constants.CommonImageUrl.Attachments;
  FinalInternshipImageBaseURL = this.Constants.CommonImageUrl.FinalInternshipReports;
  //FinalInternshipImageBaseURL = environment.ApiBaseUrlImageAttachments;
  DailyDiary: any;
  galleryData1: any;
  profile: string;
  docs: string;
  image: any;
  attachmentId: any;
  docsArray: any = [];
  internship: string;
  allPhotos: string;
  showButton: string;
  galleryData3: any;
  showCheckbox: string;
  attachmentIdArray: any = [];
  imgData: any;
  urlC: string;
  urlB: string;
  imageExtension: string;
  imgPath: any;



  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private gallery1Service: Gallery1Service,
    private cookieService: CookieService,
    public zone: NgZone,
    private dialogService: DialogService,
    private deviceService: DeviceDetectorService,
    private imageService: ImageService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {

    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    let gratitudeListing: any = document.querySelectorAll(".within-employee-login");
    gratitudeListing.forEach(gratitudeListing => {
      gratitudeListing.setAttribute('style', 'display: none !important');

    });

    //this.getAttachmentsByAccountId();
    this.getGalleryAttachments();
    this.getprofilePic()
  }



  getGalleryAttachments() {
    let data = {
      "UserTypeId": this.UserModel.UserTypeId,
      "PageIndex": 0,
      "PageSize": 10000
    }

    this.gallery1Service.GetGalleryAttachments(data).subscribe(response => {
      // this.displayedColumnsGallery = ['InternshipImage', 'CertificatePicture', 'ProjectReport', 'ReportDetail', 'Actions'];
      this.galleryData1 = response.Results;

      for (const i of this.galleryData1) {
        // for (let i = 0; i < response.Results.length; i++) {
        //   const element = response.Results[i];
        this.image = i.listAttachments.filter(gA => gA.AttachmentType == 'Image' || gA.AttachmentType == 'image/png' || gA.AttachmentType == 'image/jpeg');
        this.docsArray = i.listAttachments.filter(gAD => gAD.AttachmentType == 'application/vnd.openxmlformats-officedocument' || gAD.AttachmentType == 'Document');
        //}
      }




      this.IsLoading = false;
    }, error => {
    });

  }

  getprofilePic() {
    let data = {
      "UserTypeId": this.UserModel.UserTypeId,
      "PageIndex": 0,
      "PageSize": 10000
    }

    this.gallery1Service.getProfileAttachmentsByAccountId(data).subscribe(response => {
      // this.displayedColumnsGallery = ['InternshipImage', 'CertificatePicture', 'ProjectReport', 'ReportDetail', 'Actions'];
      this.galleryData3 = response.Results;
      this.IsLoading = false;
    }, error => {
    });

  }


  scrollPoint1(textMsg) {
    if (textMsg == "Profile") {
      this.profile = 'Profile';
      this.docs = '';
      this.internship = '';
      this.allPhotos = '';
    }
    if (textMsg == 'Docs') {
      this.docs = 'Docs';
      this.profile = '';
      this.internship = '';
      this.allPhotos = '';
    }
    if (textMsg == 'Internship') {
      this.docs = '';
      this.profile = '';
      this.internship = 'Internship';
      this.allPhotos = '';
    }
    if (textMsg == 'AllPhotos') {
      this.docs = '';
      this.profile = '';
      this.internship = '';
      this.allPhotos = 'AllPhotos';
    }
  }

  toggle(tab) {
    this.current = tab;

  }

  onSelectPicture(attachmentId, type) {
    console.log(attachmentId, type)
    if (type == 'Image') {
      this.showButton
      if (this.attachmentId == 'AttatchmentId') {
        this.showButton = 'ShowOnProfile';

      }
      else {
        this.attachmentIdArray.push({
          AttachmentId: attachmentId
        })
        console.log(this.attachmentIdArray)
        //this.attachmentId = attachmentId;
        this.showButton = 'ShowOnProfile';
      }
    } else {
      this.showActionMessage(
        "Only accept image this type",
        this.Constants.Html.SuccessSnackbar
      );
    }

  }

  onSelect(showCheckbox) {
    this.showCheckbox = showCheckbox;
  }

  onSelectShowprofile() {
    for (const i of this.attachmentIdArray) {
      let setIsAddedInProfileParam = {
        "DataId": i.AttachmentId,
      }

      this.gallery1Service.setIsAddedInProfile(setIsAddedInProfileParam)
        .subscribe((galleryResp: any) => {

          if (galleryResp.Success) {

            this.zone.run(() => {
              this.showActionMessage(
                galleryResp.Messages,
                this.Constants.Html.SuccessSnackbar
              );
              this.attachmentIdArray = [];
              this.ngOnInit();
              //this.router.navigate([RouteConstants.EmployerProfile]);
            });
          }
          else {
            var errorMessages = this.getHtmlMessage(galleryResp.Errors)
            this.dialogService.openShowDialog(errorMessages);
          }
        }, error => {
          this.dialogService.openShowDialog(error);
        });
    }

  }

  onSelectRemove() {
    for (const i of this.attachmentIdArray) {
      let removeIsAddedInProfileParam = {
        "DataId": i.AttachmentId,
      }

      this.gallery1Service.removeIsAddedInProfile(removeIsAddedInProfileParam)
        .subscribe((galleryResp: any) => {

          if (galleryResp.Success) {

            this.zone.run(() => {
              this.showActionMessage(
                galleryResp.Messages,
                this.Constants.Html.SuccessSnackbar
              );
              this.attachmentIdArray = [];
              this.ngOnInit();
              //this.router.navigate([RouteConstants.EmployerProfile]);
            });
          }
          else {
            var errorMessages = this.getHtmlMessage(galleryResp.Errors)
            this.dialogService.openShowDialog(errorMessages);
          }
        }, error => {
          this.dialogService.openShowDialog(error);
        });
    }
  }
  downloadImage(url) {
    this.imageExtension = this.imageService.getImageExtension(url);
    this.gallery1Service.GetBase64(url).subscribe(response => {
      let xx = 'data:image/jpeg;base64,' + response.Result;
      this.imgData = xx;
      this.imageService.download(this.imgData);
      // if (this.imageExtension == "jpg") {
      //   let xx = 'data:image/jpg;base64,' + response.Result;
      //   this.imgData = xx;
      //   this.imageService.download(this.imgData);
      // }
      // if (this.imageExtension == "jpeg") {
      //   let xx = 'data:image/jpeg;base64,' + response.Result;
      //   this.imgData = xx;
      //   this.imageService.download(this.imgData);
      // }
      // if (this.imageExtension == "png") {
      //   let xx = 'data:image/png;base64,' + response.Result;
      //   this.imgData = xx;
      //   this.imageService.download(this.imgData);
      // }
      // if (this.imageExtension == "gif") {
      //   let xx = 'data:image/gif;base64,' + response.Result;
      //   this.imgData = xx;
      //   this.imageService.download(this.imgData);
      // }
    });
  }
}
