<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu-emp></igmite-side-menu-emp>


                </div>
                <div class="col-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">



                        <div class="col-12 px-0 text-center">

                            <div
                                class="bg-theme-light-grey top-circle overflow-hidden mt-lg-0 mx-auto d-flex justify-content-center align-items-center">

                                <svg xmlns="http://www.w3.org/2000/svg" height="45" class="fill-color-1" width="45">
                                    <path
                                        d="M7 42q-1.2 0-2.1-.9Q4 40.2 4 39V15q0-1.2.9-2.1.9-.9 2.1-.9h9V7q0-1.2.9-2.1.9-.9 2.1-.9h10q1.2 0 2.1.9.9.9.9 2.1v5h9q1.2 0 2.1.9.9.9.9 2.1v24q0 1.2-.9 2.1-.9.9-2.1.9Zm12-30h10V7H19Z" />
                                </svg>




                            </div>

                        </div>




                    </div>


                    <p class="fs-22 text-black fw-900  line-h-22 mt-25 mb-20 px-10 text-center"> Current Openings </p>

                    <div id="current-opening-list" style="max-height: 373px;overflow-y: auto;">
                        <div class=" py-15 w-100 bg-light-theme2 border-radius-25 mx-auto pb-0 px-0 mb-30"
                            *ngFor="let currentOpening of currentOpeningData;index as i;">


                            <div class="d-flex justify-content-between align-items-center px-15">
                                <div style="width: 50%;">
                                    <p class="fs-16 text-black fw-600 mb-0 fw-900" style="min-width: 300px;">
                                        {{currentOpening.Title}}</p>
                                    <p class="fs-13 fw-400 text-black mb-0">{{currentOpening.PublishDate | date:"dd MMM
                                        yyyy" }}</p>
                                </div>


                                <div class="ml-auto w-100 d-flex justify-content-end px-10 ">
                                    <a (click)="GetInternshipId(currentOpening.InternshipId)">
                                        <!-- <a [routerLink]="['/applicant-listing-v2',currentOpening.InternshipId]"> -->
                                        <i
                                            class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35">

                                        </i>
                                    </a>

                                </div>
                            </div>


                            <div class="border-radius-25 bg-color1 px-15 py-15 mt-20 box-shadow-light">
                                <div class="align-items-center row">

                                    <div
                                        class="col-6 d-flex align-items-center border-light border-2 border-start-0 border-top-0 border-bottom-0 border-end pr-5">

                                        <svg class="mr-10" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="34px"
                                            height="34px" viewBox="0 0 44 44" enable-background="new 0 0 44 44"
                                            xml:space="preserve">
                                            <path fill="none" stroke="#fff" stroke-width="2.5" stroke-linecap="round"
                                                stroke-linejoin="round" d="M23.834,9.167H38.5" />
                                            <path fill="none" stroke="#fff" stroke-width="2.5" stroke-linecap="round"
                                                stroke-linejoin="round" d="M23.834,16.5H33" />
                                            <path fill="none" stroke="#fff" stroke-width="2.5" stroke-linecap="round"
                                                stroke-linejoin="round" d="M23.834,27.5H38.5" />
                                            <path fill="none" stroke="#fff" stroke-width="2.5" stroke-linecap="round"
                                                stroke-linejoin="round" d="M23.834,34.834H33" />
                                            <path fill="none" stroke="#fff" stroke-width="2.5" stroke-linecap="round"
                                                stroke-linejoin="round" d="M7.333,7.333h7.333
        c1.013,0,1.833,0.821,1.833,1.833V16.5c0,1.013-0.821,1.833-1.833,1.833H7.333c-1.013,0-1.833-0.821-1.833-1.833V9.167
        C5.5,8.154,6.321,7.333,7.333,7.333z" />
                                            <path fill="none" stroke="#fff" stroke-width="2.5" stroke-linecap="round"
                                                stroke-linejoin="round" d="M7.333,25.666h7.333
        c1.013,0,1.833,0.821,1.833,1.834v7.334c0,1.012-0.821,1.832-1.833,1.832H7.333c-1.013,0-1.833-0.82-1.833-1.832V27.5
        C5.5,26.487,6.321,25.666,7.333,25.666z" />
                                        </svg>

                                        <div>
                                            <p class="fs-13 text-white fw-600 mb-0 line-h-17">Applications</p>
                                            <p class="fs-20 text-white fw-900 mb-0">{{currentOpening.TotalApplications}}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-6 d-flex align-items-center">




                                        <svg class="mr-10" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px"
                                            height="40px" viewBox="0 0 44 44" enable-background="new 0 0 44 44"
                                            xml:space="preserve">
                                            <path fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" d="M10.083,9.167h7.333
          c0.506,0,0.917,0.411,0.917,0.917v7.333c0,0.506-0.411,0.917-0.917,0.917h-7.333c-0.506,0-0.917-0.411-0.917-0.917v-7.333
          C9.167,9.577,9.577,9.167,10.083,9.167z" />
                                            <path fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" d="M10.083,25.666h7.333
          c0.506,0,0.917,0.411,0.917,0.918v7.332c0,0.507-0.411,0.918-0.917,0.918h-7.333c-0.506,0-0.917-0.411-0.917-0.918v-7.332
          C9.167,26.077,9.577,25.666,10.083,25.666z" />
                                            <path fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" d="M25.666,27.5l5.5,5.5
          l5.5-5.5" />
                                            <path fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" d="M31.166,33V11" />
                                        </svg>


                                        <div>
                                            <p class="fs-13 text-white fw-600 mb-0 line-h-17">Shortlisted</p>
                                            <p class="fs-20 text-white fw-900 mb-0">{{currentOpening.ShortlistedByEmp}}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>





                    <a href="/unpublished-interships"
                        class="bg-color2 px-0 py-0 position-relative d-flex align-items-center border-radius-30 box-shadow-light mt-40">
                        <!-- <img src="assets/upload/megaphone.png" alt="Find opportunities" srcset="" class="mt--20 mr-20"> -->
                        <img src="assets/upload/megaphone.png" alt="Find opportunities" srcset="" class="mt--20 mr-20">
                        <h3 class="fs-20 line-h-25 fw-900 mb-0 text-black mr-auto mr-20">Post <br> Internship</h3>

                        <div class="ml-auto w-100 d-flex justify-content-end px-10  ">
                            <i
                                class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35"></i>
                        </div>
                    </a>



                    <div class="max-w-800 w-100 mx-auto px-0 mb-5" id="ongoing-internships">
                        <p
                            class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-20 line-h-30 mt-50 ">
                            Ongoing
                            Internships</p>


                        <div class="border-theme-2px border-radius-30 overflow-hidden">
                            <div class="px-15 py-15 bg-light-theme2 border-radius-30">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p class="fs-16 text-black fw-600 mb-0 fw-900">Working Interns
                                            ({{workingInterns}})</p>
                                        <!-- <p class="fs-13 fw-400 text-black mb-0"> {{InternshipStartDate | date:"dd MMM yyyy"}}</p> -->
                                        <!-- <p class="fs-13 fw-400 text-black mb-0">05 Aug 2022 - 10 Sep 2022</p> -->
                                    </div>

                                    <a class="ml-auto d-block">
                                        <i
                                            class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35"></i>
                                    </a>
                                </div>
                            </div>





                            <div class="px-10 py-15" style="max-height: 1000px;overflow-y: auto;">
                                <div *ngFor="let ongoingInterns of ongoingInternshipData;index as i;">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div
                                            class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                            <img [src]="ApiBaseUrlImage + ongoingInterns.ProfilePhoto"
                                                class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                width="40" height="40" />
                                            <div>
                                                <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                    {{ongoingInterns.StudentName}}</p>
                                                <p class="fs-14 fw-600 color1 mb-0 mt-0">
                                                    {{ongoingInterns.StudentMobile}}</p>
                                            </div>

                                        </div>
                                        <a (click)="callStudentId(ongoingInterns.StudentId)" class="ml-auto d-block">
                                            <i
                                                class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                        </a>

                                    </div>

                                    <div>
                                        <p class="mb-0 line-h-25 mt-0"
                                            style="color: #f99e06; margin-right: 5%;margin-left: 6%;">
                                            <span>{{ongoingInterns.Title}}</span>
                                            <span class="fs-14 fw-600 color1 mb-0 mt-0">
                                                - {{ongoingInterns.StartDate| date:"dd MMM yyyy"}}
                                            </span>
                                        </p>
                                        <!-- <p class="fs-14 fw-600 color1 mb-0 mt-0">
                                            {{ongoingInterns.StartDate| date:"dd MMM yyyy"}}</p> -->
                                    </div>

                                    <div class="row mx-0 px-0">

                                        <a class="col-4 py-10 px-10">


                                            <p class="fs-13 fw-400 mt-1 text-black mb-0">Attendance</p>

                                            <strong
                                                class="fs-16 line-h-15 fw-900 text-black ">{{ongoingInterns.TotalAttendance}}
                                                D</strong>

                                        </a>

                                        <!-- <a href="../Student/diary-entry.html" class="col-4 py-10 px-10 "> -->
                                        <!-- <a href="/diary-entry-listing" class="col-4 py-10 px-10 "> -->
                                        <a (click)="redirectOnDiaryEntryPage(ongoingInterns.StudentId,ongoingInterns.InternshipId)"
                                            class="col-4 py-10 px-10 ">

                                            <p class="fs-13 fw-400 mt-1 text-black mb-0">Internship</p>

                                            <strong
                                                class="fs-16 line-h-15 fw-900 text-black ">{{ongoingInterns.Totalhours}}
                                                H</strong>

                                        </a>

                                        <a class="col-4 py-10 px-10 ">

                                            <p class="fs-13 fw-400 mt-1 text-black mb-0">Diary Entry</p>

                                            <strong
                                                class="fs-16 line-h-15 fw-900 text-black ">{{ongoingInterns.Totalhours /
                                                80 *100 | number}}%</strong>

                                        </a>

                                    </div>
                                    <div class="border-end-0 border-top-0 border-start-0 border-theme-2px mb-25 mt-20">
                                    </div>
                                </div>

                                <button (click)="viewMoreInternship()"
                                    class="fs-14 fw-900 color1 d-block text-center bg-none border-0 mx-auto text-center mt-10 mb-5px">View
                                    More</button>

                            </div>

                            <!-- <button (click)="viewMoreInternship()"
                                class="fs-14 fw-900 color1 d-block text-center bg-none border-0 mx-auto text-center mt-10 mb-5px">View
                                More</button> -->
                        </div>


                        <div class="px-15 py-20 bg-light-theme2 border-radius-30 mt-30">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <p class="fs-18 text-black fw-600 mb-0 fw-900">Past Internship</p>
                                </div>

                                <!-- <a href="../Employer/past-internship.html" class="ml-auto d-block"> -->
                                <a class="ml-auto d-block" [routerLink]="['/past-internship']">
                                    <i
                                        class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35"></i>
                                </a>
                            </div>
                        </div>



                    </div>








                </div>
            </div>
        </div>
    </div>
</section>