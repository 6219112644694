export const RouteConstants = {
    Login: 'login',
    LoginOTP: 'login-otp',
    LoginVerify: 'login-verify',
    ResetPassword: 'reset-password',
    ResetPasswordOTP: 'reset-password-otp',
    NewPassword: 'new-password',


    DashboardNew: 'dashboard',
    CollectExtraPoints: 'collect-extra-points',
    Congratulation: 'congratulation',
    Scorecard: 'scorecard',
    StudyMaterials: 'study-materials',
    Interests: 'interests',
    ProfileUpload: 'profile-upload',
    HighlightOne: 'highlight-1',
    HighlightTwo: 'highlight-2',
    OpprtunitiesListing: 'opprtunities-listing',
    ProfileDetailCorrection: 'profile-detail-correction',
    ProfileMobile: 'profile-mobile',
    RaiseSafetyConcer: 'raise-safety-concer',
    RightsDetails: 'rights-details',
    StudentsRight: 'students-right',
    DiaryEntryListing: 'diary-entry-listing',


    Resources: 'resources',
    AboutPathways: 'about-pathways',
    Certificate: 'certificate',
    FinalInternshipReport: 'final-internship-report',
    Gallery1: 'gallery-1',
    Notification: 'notification',

    ContactUs: 'contact-us1',


    ContactUs1: 'contact-us',
    UnpublishedInterships: 'unpublished-interships',
    ApplicantListingV2: 'applicant-listing-v2',
    InternshipListingMobileV2: 'internship-listing-mobile-v2',
    PastInternship: 'past-internship',
    InterListing: 'inter-listing',
    AttendanceV2: 'attendance-v2',
    EmployerProfile: 'employer-profile',
    EmployerProfileEdit: 'employer-profile-edit',
    InternshipDetail: 'internship-detail',
    GetHelp: 'get-help',
    GratitudeListing: 'gratitude-listing',
    CertificateListing: 'certificate-listing',
    ThankYou: 'thank-you',
    ThankYouReset: 'thank-you-reset',
    GratitudeCorner: 'gratitude-corner',
    Home: 'home',
    Sample: 'sample',
    Settings: 'settings',
    PageNotFound: 'page-not-found',
    HomeMain: 'home-student',
    HomeEmployer: 'home-employer',
    SurveyListing: 'survey-listing',
    SurveyForm1: 'survey-form1',
    SurveyForm2: 'survey-form2',
    internship3: 'internship-3',
    VideoMaterials: 'video-materials',
    Account: {
        List: 'users',
        New: 'users/:actionType',
        Edit: 'users/:actionType/:accountId',
        ChangePassword: 'change-password',
        ForgotPassword: 'forgot-password',
        ResetPassword: 'reset-password',
        ResetPasswordByToken: 'reset-password/:accessToken',
        ChangeLogin: 'change-login',
    },

    Country: {
        List: 'countries',
        New: 'countries/:actionType',
        Edit: 'countries/:actionType/:countryCode'
    },
    DataType: {
        List: 'data-types',
        New: 'data-types/:actionType',
        Edit: 'data-types/:actionType/:dataTypeId'
    },
    CourseMaterial: {
        List: 'course-materials',
        New: 'course-materials/:actionType',
        Edit: 'course-materials/:actionType/:courseMaterialId'
    },
    DataValue: {
        List: 'data-values',
        New: 'data-values/:actionType',
        Edit: 'data-values/:actionType/:dataValueId'
    },



    Report: {

    },


    MatTableServer: {
        List: 'mat-table-server',
    },

    SummaryDashboard: {
        Dashboard: 'summary-dashboard',
        CourseMaterial: 'dashboards/course-material'
    },

    CompareDashboard: {
        CompareDashboard: 'compare-dashboard',
    },

    IssueManagementDashboard: {
        IssueManagementDashboard: 'issue-management-dashboard',
    },



}
