import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { HighlightOneService } from './highlight-one.service';


@Component({
  providers: [AppConstants],
  selector: 'igmite-highlight-one',
  templateUrl: './highlight-one.component.html',
  styleUrls: ['./highlight-one.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class HighlightOneComponent extends BaseComponent<LoginModel> implements OnInit {

  public appInfo = environment;
  HighlightOne:any
  public highlightOneForm: FormGroup;
  EntryDate:any
  InternshipById:any
  
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private highlightOneService: HighlightOneService,
    public zone: NgZone,

    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
      
        sidepanel: {
          hidden: true
        }
      }
    };
    this.highlightOneForm = this.createhighlightOneForm();
    

  }

  createhighlightOneForm(): FormGroup {
    return this.formBuilder.group({
      AnswerText: new FormControl('',[Validators.maxLength(150),Validators.required]),
    });
  }

  ngOnInit(): void {
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
    elementIn.setAttribute('style', 'display: none !important');

    });
    if(this.UserModel.PhotoUrl != null){
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src',  this.Constants.CommonImageUrl.Account+this.UserModel.PhotoUrl);
    }else{
      this.UserModel.PhotoUrl=''
    }
     this.EntryDate=JSON.parse(sessionStorage.getItem('EntryDate'));
     var Inter=JSON.parse(sessionStorage.getItem('item'));
     if( Inter !== null ){
        this.InternshipById=Inter
        
     }else{
        this.InternshipById=this.UserModel.InternshipId 
     }

      this.highlightOneService.GetHighlightOne().subscribe((highlightOneResp: any) => {
        if (highlightOneResp.Success == true) {
          this.zone.run(() => {
            this.HighlightOne=highlightOneResp.Results[0]
            this.IsLoading = false;
            // this.showActionMessage(
            //   this.Constants.Messages.RecordSavedMessage,
            //   this.Constants.Html.SuccessSnackbar
            // );
          });
          
        }
        else {
          // this.showErrorMessage(
          //   'Invalid HighlightOne ',
          //   'info-snackbar'
          // );
        }

      
      }, error => {
      });
  }

  CreateQuestionResponse(){
   
    let Question={
      AnswerText:this.highlightOneForm.value.AnswerText,
      QuestionId:this.HighlightOne.QuestionId,
      UserId:this.UserModel.UserTypeId,
      UserRole:this.UserModel.RoleCode,
      QuestionResponseId:'00000000-0000-0000-0000-000000000000',
      AuthUserId:this.UserModel.UserTypeId,
      QuestionAnswerOptionId:'00000000-0000-0000-0000-000000000000',
      Remarks:'Question Answer',
      InternshipId:this.InternshipById,
      EntryDate:this.EntryDate,
      RequestType:0,
      IsActive:true,
      StudentID: this.UserModel.UserTypeId,
      UserRoleId:this.UserModel.UserRoleId,

    }
    this.highlightOneService.CreateQuestionResponse(Question).subscribe((highlightOneResp: any) => {
      if (highlightOneResp.Success == true) {
        this.router.navigateByUrl(RouteConstants.DashboardNew);
         
        this.zone.run(() => {
          this.IsLoading = false;
          this.showActionMessage(
            this.Constants.Messages.RecordSavedMessage,
            this.Constants.Html.SuccessSnackbar
          );
        });
      }
      else {
        // this.showErrorMessage(
        //   'Invalid CreateQuestion ',
        //   'info-snackbar'
        // );
        this.snackBar.open("Record already exists", "Dismiss", {
          duration: 50000000,
          panelClass: ["info-snackbar"]
      });
      }

    
    }, error => {
    });
  }
}
