<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0">
            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu></igmite-side-menu>
                </div>

                <div class="col-md-9 col-lg-10 right-content px-lg-1 overflow-hidden px-5px pb-5 bg-white py-5px">
                 

                    <div class="owl-carousel owl-theme home-video-single cstm-owl-dots">
                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="../assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="../assets/upload/Video-1.png" alt="" srcset="">
                                </a>
                            </div>
                        </div>

                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="../assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="assets/upload/Video-2.png" alt="" srcset="">
                                </a>
                            </div>
                        </div>

                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="../assets/upload/Video-3.png" alt="" srcset="">
                                </a>
                            </div>
                        </div>

                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="../assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="../assets/upload/Video-4.png" alt="" srcset="">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="IsDirectMappingEnabled == false" id="internship-section"
                        class="internship-section border-theme-2px border-radius-40 mt-50 max-w-800 mx-auto">
                        <a href="/opprtunities-listing"
                            class="bg-color2 px-20 py-15 position-relative d-flex align-items-center border-radius-30 box-shadow-light justify-content-between">
                            <img src="../assets/upload/find-opportunities-icon.png" alt="Find opportunities" srcset=""
                                class="mt--50">

                            <h3 class="fs-20 line-h-25 fw-900 mb-0 text-black mr-auto">
                                Find Internship Opportunities
                            </h3>

                            <div class="ml-auto">
                                <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35" ></i>
                            </div>
                        </a>

                        <div class="row px-15 mx-0 h-100 mb-30 mt-20">
                            <div class="col-4 h-100 " *ngFor="let interests of homeData;let index = index">

                                <a 
                                    class="bg-light-theme d-block text-center align-items-start border-radius-10 py-lg-3">
                                    <div class="h-70 d-flex justify-content-center align-items-center px-2 py-1">
                                        <img class="mb-0" [src]="Constants.CommonImageUrl.Account+interests.InterestIconUrl"
                                            alt="Internship" srcset="" height="45px">
                                    </div>
                                </a>
                                <a 
                                    class="fs-lg-15 fs-13 color1 text-center mb-0 line-h-15 mt-10 fw-600 d-block">
                                   {{interests.InterestName}}
                                </a>
                            </div>
                         
                        </div>
                    </div>

                    <section>
                        <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-lg-60 mt-50 mb-0 text-center">
                            Student Success Stories
                        </h2>

                        <div class="row mx-0 p-0 mt-lg-30">
                            <div class="col-md-6 mb-lg-0 mb-30 px-vs-0">
                                <div class="owl-carousel owl-theme student-testimonial h-100">
                                    <div class="item h-100">

                                        <div
                                            class="student-text-testimonial px-3 py-25 border-grey-light border-radius-10 h-100">

                                            <img src="../assets/upload/testimonial-example.png" alt="" class="mb-10"
                                                srcset="">
                                            <p class="fs-17 text-black mb-5px fw-600 text-center mb-10">
                                                Rashmi
                                                Thakur
                                            </p>
                                            <p class="fs-13 fw-600 mb-10 text-center">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit. Sed vitae ipsum vitae felis uet dictum
                                                diam eget sagittis.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="item h-100">

                                        <div
                                            class="student-text-testimonial px-3 py-10 pb-0 py-lg-30 border-grey-light border-radius-10 h-100">

                                            <img src="../assets/upload/testimonial-example.png" alt="" class="mb-10"
                                                srcset="">
                                            <p class="fs-17 text-black mb-5px fw-600 text-center mb-10">
                                                Rashmi
                                                Thakur
                                            </p>
                                            <p class="fs-13 mb-10 text-center">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit. Sed vitae ipsum vitae felis uet dictum
                                                diam eget sagittis.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 px-vs-0 cstm-dots-mob">
                                <div class="owl-carousel owl-theme student-testimonial h-100">
                                    <div class="item h-100">
                                        <div
                                            class="student-video-testimonial border-theme-2px border-radius-30  overflow-hidden h-100">

                                            <iframe class="fade-overlay border-radius-0" width="100%" height="220"
                                                src="https://www.youtube.com/embed/6ehIYXsNMXY?rel=0"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen>
                                                <p>jfbdjfdjfnfdb</p>
                                            </iframe>
                                            <p class="Video-heading px-15 py-15 mt-0 fs-17 fw-600 text-center">
                                                Multi Skill Vocational Education in Schools
                                            </p>
                                        </div>
                                    </div>

                                    <div class="item h-100">
                                        <div
                                            class="student-video-testimonial border-theme-2px border-radius-30  overflow-hidden h-100 ">

                                            <iframe class="fade-overlay border-radius-0" width="100%" height="220"
                                                src="https://www.youtube.com/embed/oi3QcHUeQqw?rel=0"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                            <p class="Video-heading px-15 py-15 mt-0 fs-17 fw-600 text-center">
                                                Skills on Wheels - in Himachal Pradesh
                                            </p>
                                        </div>
                                    </div>

                                    <div class="item h-100">
                                        <div
                                            class="student-video-testimonial border-theme-2px border-radius-30  overflow-hidden h-100">

                                            <iframe class="fade-overlay border-radius-0" width="100%" height="220"
                                                src="https://www.youtube.com/embed/6ehIYXsNMXY?rel=0"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen>
                                                <p>jfbdjfdjfnfdb</p>
                                            </iframe>
                                            <p class="Video-heading px-15 py-15 mt-0 fs-17 fw-600 text-center">
                                                Multi Skill Vocational Education in Schools
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="resource-section">
                        <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-lg-60 mt-70 mb-20 text-center">
                            Resources
                        </h2>
                        <div class="resources-section">

                            <div class="row mx-0 p-0 max-w-800 mx-auto">
                                <div class="col-md-6 col-6 px-1">
                                    <a href="/resources">
                                        <div
                                        class=" px-10 py-20 study-material-block bg-light-theme h-100 border-radius-30">
                                        <p class="fs-17 fw-600 mb-10 max-w-180" style="color: #212529;">
                                            Download Study Material
                                        </p>
                                        <div>
                                            <i
                                                class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mb-30" href="/login"></i>
                                        </div>
                                    </div>
                                    </a>
                                   
                                </div>

                                <div class="col-md-6 col-6 px-1">
                                    <a href="/resources">
                                    <div
                                        class=" px-10 py-20 video-tutorial-block bg-light-theme h-100 border-radius-30">
                                        <p class="fs-17 fw-600 mb-10 max-w-180" style="color: #212529;">
                                            Watch Video
                                            Tutorials
                                        </p>
                                        <div>
                                            <i
                                                class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mb-30" href="/login"></i>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div class="col-md-12 col-12  mb-lg-0 mt-20 px-1">
                                    <a href="/resources">

                                    <div
                                        class=" px-10 py-20 student-support-block bg-light-theme h-100 border-radius-30">
                                        <p class="fs-17 fw-600 mb-10 max-w-180" style="color: #212529;">
                                            Student Support
                                        </p>
                                        <div>
                                            <i
                                                class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mb-30" href="/login"></i>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <div class="container">
    <div class="row align-items-center mx-0 d-lg-none header__mob">
        <div class="col-3 text-start pl-vs-0">
            <div class="menu-toggle">
                <button class="nav-toggle">
                    <span class="bar-top"></span>
                    <span class="bar-mid"></span>
                    <span class="bar-bot"></span>
                </button>
            </div>
        </div>
        <div class="col-6 text-center">
            <img src="../assets/images/Pathway-logo-white.png" alt="Pathway" class="img-fluid" srcset="">
        </div>
        <div class="col-3 text-end pr-vs-0">
            <a href="/notification" class="withhout-login-notificaton">
                <svg class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="#fff"
                    viewBox="0 0 50 50" height="27" width="27">
                    <path
                        d="M8.3 39.1Q7.25 39.1 6.6 38.425Q5.95 37.75 5.95 36.8Q5.95 35.75 6.6 35.125Q7.25 34.5 8.3 34.5H10.6V20.4Q10.6 15.9 13.275 12.175Q15.95 8.45 20.45 7.35V6.15Q20.45 4.65 21.475 3.675Q22.5 2.7 24 2.7Q25.5 2.7 26.525 3.675Q27.55 4.65 27.55 6.15V7.35Q32.1 8.4 34.825 12.125Q37.55 15.85 37.55 20.4V34.5H39.8Q40.75 34.5 41.425 35.125Q42.1 35.75 42.1 36.75Q42.1 37.8 41.425 38.45Q40.75 39.1 39.8 39.1ZM24.05 45.1Q22.25 45.1 20.95 43.825Q19.65 42.55 19.65 40.75H28.4Q28.4 42.6 27.125 43.85Q25.85 45.1 24.05 45.1Z">
                    </path>
                </svg>
            </a>

        </div>
    </div>

    <div class="header__desk  row mx-0 align-items-center">
        <div class="col-md-2 d-none d-lg-flex">
            <img src="../assets/images/Pathway-logo-white.png" alt="Pathway" class="img-fluid">
        </div>

        <div class="col-md-10 text-end d-flex align-items-center justify-content-end ">
            <ul class="d-lg-none text-decoration-none mb-0 p-0 header__Navigation text-start">
                <li class="withoutlogin">
                    <a class="mr-20 text-white fs-16" href="/home">Home</a>
                </li>
                <li class="withinlogin">
                    <a class="mr-20 text-white fs-16" href="/home-student">Home</a>
                </li>
                <li class="withoutlogin">
                    <a class="mr-20 text-white fs-16" href="/login-otp">Login with OTP</a>
                </li>
                <li class="withoutlogin">
                    <a class="mr-20 text-white fs-16" href="/login">Login with Email</a>
                </li>

                <li class="withinlogin">
                    <a class="mr-20 text-white fs-16" href="/interests">Interests</a>
                </li>
                <li class="withinlogin">
                    <a class="mr-20 text-white fs-16" href="/resources">Resources</a>
                </li>
                <li class="withinlogin">
                    <a class="mr-20 text-white fs-16" href="/profile-mobile">Profile
                    </a>
                </li>
                <li class="withinlogin">
                    <a class="mr-20 text-white fs-16" href="/final-internship-report">Final Internship
                        Report
                    </a>
                </li>
                <li class="withinlogin">
                    <a class="mr-20 text-white fs-16" href="/gallery-1">Gallery
                    </a>
                </li>
                <li class="withinlogin">
                    <a class="mr-20 text-white fs-16" href="/gratitude-corner">Gratitude
                    </a>
                </li>
                <li class="withinlogin">
                    <a href="/certificate">Certificates</a> 
                </li>
                <li class="withinlogin">
                    <a class="mr-20 text-white fs-16" href="/login">Logout
                    </a>
                </li>

                <span id="emp-menu">
                    <li class="within-employee-login">
                        <a class="mr-20 text-white fs-16" href="/home-employer">Home</a>
                    </li>
                    <li class="within-employee-login">
                        <a class="mr-20 text-white fs-16"
                            href="/internship-listing-mobile-v2">Internships</a>
                    </li>
                    <li class="within-employee-login">
                        <a class="mr-20 text-white fs-16" href="/inter-listing">Interns
                        </a>
                    </li>
                    <li class="within-employee-login">
                        <a class="mr-20 text-white fs-16" href="/gratitude-listing">Gratitude
                        </a>
                    </li>
                    <li class="within-employee-login">
                        <a href="/certificate-listing">Certificates</a>
                    </li>

                    <li class="within-employee-login">
                        <a class="mr-20 text-white fs-16" href="/login">Logout
                        </a>
                    </li>
                </span>
                <div
                    class="d-flex border-theme-2px mt-30 border-bottom-0 border-end-0 border-start-0 py-10 d-lg-none">
                    <a
                        class="d-block col-6 text-center text-black fw-600 fs-15 border-theme-2px border-bottom-0 border-start-0 border-top-0 py-10">
                        About Us</a>
                    <a href="/contact-us" class="d-block col-6 text-center text-black fw-600 fs-15 py-10">
                        Contact Us</a>


                </div>
            </ul>

          
            <a href="/notification" class="withhout-login-notificaton">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 50 50"
                    class="cursor-pointer mr-15 d-lg-block d-none" height="40" width="40">
                    <path
                        d="M8.3 39.1Q7.25 39.1 6.6 38.425Q5.95 37.75 5.95 36.8Q5.95 35.75 6.6 35.125Q7.25 34.5 8.3 34.5H10.6V20.4Q10.6 15.9 13.275 12.175Q15.95 8.45 20.45 7.35V6.15Q20.45 4.65 21.475 3.675Q22.5 2.7 24 2.7Q25.5 2.7 26.525 3.675Q27.55 4.65 27.55 6.15V7.35Q32.1 8.4 34.825 12.125Q37.55 15.85 37.55 20.4V34.5H39.8Q40.75 34.5 41.425 35.125Q42.1 35.75 42.1 36.75Q42.1 37.8 41.425 38.45Q40.75 39.1 39.8 39.1ZM24.05 45.1Q22.25 45.1 20.95 43.825Q19.65 42.55 19.65 40.75H28.4Q28.4 42.6 27.125 43.85Q25.85 45.1 24.05 45.1Z">
                    </path>
                </svg>

            </a>


            <div href="../Student/login.html" class="dropdown">
                <img class="rounded-circle img-fluid student-login-image d-none d-lg-flex üser-profile-image"
                    src="assets/upload/student.jpg" alt="">

                <ul class="dropdown-content displayLogout">
                    <li class="withinlogin">
                        <a href="/gratitude-corner"><svg xmlns="http://www.w3.org/2000/svg" height="27"
                                class="mr-5" width="27" viewBox="0 0 24 24" style="color:#000">
                                <path fill="none" stroke="currentColor" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="1.5"
                                    d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V3a.75.75 0 0 1 .75-.75A2.25 2.25 0 0 1 16.5 4.5c0 1.152-.26 2.243-.723 3.218c-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715c.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48a4.53 4.53 0 0 1-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602c.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665a8.97 8.97 0 0 0 .654 3.375Z">
                                </path>
                            </svg>Gratitude</a>
                    </li>
                    <li class="withinlogin">
                        <a href="/certificate"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
                                height="27" class="mr-5" width="27">
                                <path
                                    d="m17.3 45-3.8-6.5-7.55-1.55.85-7.35L2 24l4.8-5.55-.85-7.35 7.55-1.55L17.3 3 24 6.1 30.7 3l3.85 6.55 7.5 1.55-.85 7.35L46 24l-4.8 5.6.85 7.35-7.5 1.55L30.7 45 24 41.9Zm1.35-3.95L24 38.8l5.5 2.25 3.35-5 5.85-1.5-.6-5.95 4.05-4.6-4.05-4.7.6-5.95-5.85-1.4-3.45-5L24 9.2l-5.5-2.25-3.35 5-5.85 1.4.6 5.95L5.85 24l4.05 4.6-.6 6.05 5.85 1.4ZM24 24Zm-2.15 6.65L33.2 19.4l-2.25-2.05-9.1 9-4.75-4.95-2.3 2.25Z">
                                </path>
                            </svg>Certificates</a>
                    </li>
                    <li class="withinlogin">
                        <a href="/gallery-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
                                height="27" class="mr-5" width="27">
                                <path
                                    d="M17.25 29.15H36.8l-6.35-8.55-5.15 6.75L21.9 23ZM13 38q-1.2 0-2.1-.9-.9-.9-.9-2.1V7q0-1.2.9-2.1.9-.9 2.1-.9h28q1.2 0 2.1.9.9.9.9 2.1v28q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h28V7H13v28Zm-6 9q-1.2 0-2.1-.9Q4 42.2 4 41V10h3v31h31v3Zm6-37v28V7Z" />
                            </svg>Gallery</a>
                    </li>
                    <li class="within-employee-login">
                        <a href="/gratitude-listing"><svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 50 50" height="27" class="mr-5" width="27">
                                <path
                                    d="M32.3 25.1Q29.95 23 27.825 21 25.7 19 24.1 17.1t-2.55-3.675Q20.6 11.65 20.6 10q0-2.6 1.75-4.35Q24.1 3.9 26.7 3.9q1.5 0 2.975.825T32.3 6.95q1.15-1.4 2.625-2.225Q36.4 3.9 37.9 3.9q2.6 0 4.35 1.75Q44 7.4 44 10q0 1.65-.95 3.425T40.5 17.1Q38.9 19 36.775 21T32.3 25.1Zm0-4.15q3.3-3 6-5.95t2.7-5q0-1.35-.875-2.225T37.9 6.9q-.85 0-1.675.4-.825.4-1.675 1.5l-2.25 2.75-2.25-2.75q-.85-1.1-1.675-1.5-.825-.4-1.675-.4-1.35 0-2.225.875T23.6 10q0 2.05 2.7 5t6 5.95Zm-4 23.95-15.55-4.45v2.85H2V23.6h15.45l12.75 4.8q1.35.5 2.275 1.625.925 1.125.925 3.225h5.7q2.1 0 3.5 1.5T44 38.8v1.3ZM5 40.3h4.7V26.6H5Zm23.1 1.5 12.8-3.9q-.3-.95-.75-1.3-.45-.35-1.05-.35H28.75q-1.5 0-2.775-.2t-2.425-.55l-4.05-1.25 1.1-2.9 3.65 1.2q1.25.4 2.375.55 1.125.15 3.575.15 0-.6-.225-1.175-.225-.575-.775-.825L16.95 26.6h-4.2v10.7ZM9.7 33.45Zm20.5-.2Zm-20.5.2Zm3.05 0ZM32.3 13.9Z">
                                </path>
                            </svg>Gratitude</a>
                    </li>
                    <li class="within-employee-login">
                        <a href="/certificate-listing"><svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 50 50" height="27" class="mr-5" width="27">
                                <path
                                    d="m17.3 45-3.8-6.5-7.55-1.55.85-7.35L2 24l4.8-5.55-.85-7.35 7.55-1.55L17.3 3 24 6.1 30.7 3l3.85 6.55 7.5 1.55-.85 7.35L46 24l-4.8 5.6.85 7.35-7.5 1.55L30.7 45 24 41.9Zm1.35-3.95L24 38.8l5.5 2.25 3.35-5 5.85-1.5-.6-5.95 4.05-4.6-4.05-4.7.6-5.95-5.85-1.4-3.45-5L24 9.2l-5.5-2.25-3.35 5-5.85 1.4.6 5.95L5.85 24l4.05 4.6-.6 6.05 5.85 1.4ZM24 24Zm-2.15 6.65L33.2 19.4l-2.25-2.05-9.1 9-4.75-4.95-2.3 2.25Z">
                                </path>
                            </svg>Certificates</a>
                    </li>
                    <li>
                        <a href="/login">
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                height="27" class="mr-5" width="27" fill="#000000bd" style="color:#000">
                                <g>
                                    <path d="M0,0h24v24H0V0z" fill="none" />
                                </g>
                                <g>
                                    <path
                                        d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
                                </g>
                            </svg>
                            Logout</a>
                    </li>
                </ul>
            </div>


        </div>
    </div>
</div> -->