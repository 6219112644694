<section class="mb-lg-0 mb-20">
    <div class="container">
        <div class="content-section mt-0">
            <div class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-5 overflow-hidden max-w-800 mx-auto">
                <section class="mt-20 position-relative mb-0">
                    <div>
                        <img src="assets/upload/reset-circle.png" class="img-fluid d-block text-center mx-auto"
                            alt="Reset Password" srcset="">
                        <p class="fs-22 text-black fw-800 text-center  line-h-22 mt-15 mb-15"> Reset Password </p>
                    </div>

                    <div class="px-20 py-40 mt-40 text-black bg-light-theme border-radius-20">
                        <p class="fs-14 fw-500 text-black mb-0">You can reset your passwod</p>
                    </div>

                    <form name="changePasswordForm" [formGroup]="changePasswordForm">
                        <div class="border-theme-2px px-15 border-radius-20 py-40 mt-30 mb-5">
    
                            <div class="mb-15">
                                <p class="fs-16 text-black mb-0 px-1">New Password</p>
                               
                                <div class="input-with-icon position-relative">
                                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                        <!-- <mat-label>New Password</mat-label> -->
                                        <!-- <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility> -->
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                        class="fill-color-2 ml-10 start-0 start-0 position-absolute translate-middle-y top-50"
                                        height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                        <path
                                            d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z">
                                        </path>
                                    </svg>
                                        <input matInput #password type="password" name="newPassword"
                                            formControlName="NewPassword" required 
                                            matTooltip="1) Your password must be between 8 and 50 characters.
                                            2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                                            3) Your password must contain at least one lowercase letter.
                                            4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                                            5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                                            matTooltipPosition='above' matTooltipClass="allow-cr"  class="border-radius-10 border-theme-2px fs-18 py-2 w-100 px-5">
                                        <!-- <mat-hint align="end" aria-live="polite">
                                            {{changePasswordForm.controls.NewPassword.value.length}} / {{passwordComponent.max}}
                                        </mat-hint> -->
                                        <mat-error *ngIf="changePasswordForm.controls.NewPassword.invalid">
                                            {{getErrorMessage(changePasswordForm, 'NewPassword')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                
                            </div>
        
        
        
                            <div class="mb-15">
                                <p class="fs-16 text-black mb-0 px-1">Confirm Password</p>
                             
                               
                               
                                <div class="input-with-icon position-relative">
                                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                        <!-- <mat-label>New Password</mat-label> -->
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                        class="fill-color-2 ml-10 start-0 start-0 position-absolute translate-middle-y top-50"
                                        height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                        <path
                                            d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z">
                                        </path>
                                    </svg>
                                        <input matInput #password [type]="hideConfirmPassword ? 'password' : 'text'" name="confirmPassword"
                                        formControlName="ConfirmPassword" required  
                                            matTooltip="1) Your password must be between 8 and 50 characters.
                                            2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                                            3) Your password must contain at least one lowercase letter.
                                            4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                                            5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                                            matTooltipPosition='above' matTooltipClass="allow-cr"  class="border-radius-10 border-theme-2px fs-18 py-2 w-100 px-5">
                                            <mat-icon matSuffix (click)="setVisibleConfirmPassword()">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}
                                            </mat-icon>
                
                                            <mat-error *ngIf="changePasswordForm.controls.ConfirmPassword.invalid">
                                                {{getErrorMessage(changePasswordForm, 'ConfirmPassword')}}
                                            </mat-error>
                                            <mat-error *ngIf="changePasswordForm.controls.ConfirmPassword.errors">Password Not
                                                Matched</mat-error>
                                    </mat-form-field>
                                </div>
                                
                            </div>
        
                            
        
                        </div>
                    </form>
                    <button
                                class="d-block px-3 text-white max-w-180 text-uppercase border-radius-15 mx-auto mt-50 w-100 py-2 upto-down-gradient-clr-1 theme-btn" [disabled]="changePasswordForm.invalid"
                                (click)="saveOrUpdateChangePasswordDetails()" >Submit</button>
                </section>
            </div>
        </div>
    </div>
</section>