<section class="">
    <div class="container">

        <div class="content-section">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu></igmite-side-menu>


                </div>



                <div class="col-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">



                        <div class="col-12 px-0 text-center">
                            <div
                                class="bg-theme-light-grey top-circle overflow-hidden mt-lg-0 mx-auto d-flex justify-content-center align-items-center">

                                <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="50px"
                                    viewBox="0 0 24 24" width="50px" fill="#000000">
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 12.5v-9l6 4.5-6 4.5z">
                                    </path>
                                </svg>
                            </div>
                            <p class="fs-22 text-black fw-900  line-h-22 mt-25 mb-20 px-10 text-center"> Videos</p>
                        </div>


                        <mat-accordion class="px-0 max-w-500 mx-auto">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                class="border-theme-2px border-radius-20 px-10 mb-15"
                                style="margin-bottom: 15px !important;"
                                *ngFor="let studyMaterialCategory of studyMaterialData ; let i=index">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{studyMaterialCategory.Category}}
                                    </mat-panel-title>
                                    <!-- <mat-panel-description>
                                        <mat-icon *ngIf="!panelOpenState">add</mat-icon>
                                        <mat-icon *ngIf="panelOpenState">remove</mat-icon>
                                      </mat-panel-description>  -->
                                </mat-expansion-panel-header>
                                <div class="" *ngFor="let videoItems of studyMaterialCategory.ResourcesViewModel">
                                    <div class="border-theme-1px mb-20"></div>

                                    <div class="d-flex mb-20">
                                        <div class="col-5 px-0 col-md-2">
                                            <a class="venobox auto-play fs-14 fw-900 color1 just-open fade-overlay d-block vbox-item"
                                                data-vbtype="video" href="{{videoItems.ResourceLink}}">

                                                <iframe width="100%" height="85px" class="border-radius-15"
                                                    src="https://www.youtube.com/embed/Wy5SjWRLuiE"
                                                    title="YouTube video player" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                            </a>
                                        </div>

                                        <div class="col-7 col-md-5" style="padding-left: 2%;">
                                            <p class="fs-14 text-black mb-0 fw-600">
                                                <!-- Garudmaachi Revisited -->
                                                {{videoItems.ResourceTitle}}

                                            </p>

                                            <a class="venobox auto-play fs-14 fw-900 color1 just-open fade-overlayd-block vbox-item"
                                                target="_blank" data-vbtype="video" href="{{videoItems.ResourceLink}}">

                                                Watch Video

                                            </a>
                                        </div>
                                    </div>

                                    <!-- <div class="border-theme-1px mb-20"></div> -->


                                </div>

                            </mat-expansion-panel>

                        </mat-accordion>



                    </div>





                    <div class="px-10 mb-5">
                        <a href="study-materials"
                            class="bg-theme-light-grey border-radius-20 d-flex align-items-center px-15 py-15 cursor-pointer max-w-500 mx-auto mb-20">

                            <div class="mr-10">
                                <!-- <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="35px"
                                    viewBox="0 0 24 24" width="35px" fill="#000000">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path
                                        d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 12.5v-9l6 4.5-6 4.5z" />
                                </svg> -->

                                <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" eight="35px"
                                    viewBox="0 0 24 24" width="35px" fill="#000000">
                                    <g>
                                        <rect fill="none" height="24" width="24"></rect>
                                        <rect fill="none" height="24" width="24"></rect>
                                    </g>
                                    <g>
                                        <path
                                            d="M17.5,4.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5c-1.45,0-2.99,0.22-4.28,0.79C1.49,5.62,1,6.33,1,7.14 l0,11.28c0,1.3,1.22,2.26,2.48,1.94C4.46,20.11,5.5,20,6.5,20c1.56,0,3.22,0.26,4.56,0.92c0.6,0.3,1.28,0.3,1.87,0 c1.34-0.67,3-0.92,4.56-0.92c1,0,2.04,0.11,3.02,0.36c1.26,0.33,2.48-0.63,2.48-1.94l0-11.28c0-0.81-0.49-1.52-1.22-1.85 C20.49,4.72,18.95,4.5,17.5,4.5z M21,17.23c0,0.63-0.58,1.09-1.2,0.98c-0.75-0.14-1.53-0.2-2.3-0.2c-1.7,0-4.15,0.65-5.5,1.5V8 c1.35-0.85,3.8-1.5,5.5-1.5c0.92,0,1.83,0.09,2.7,0.28c0.46,0.1,0.8,0.51,0.8,0.98V17.23z">
                                        </path>
                                        <g></g>
                                        <path
                                            d="M13.98,11.01c-0.32,0-0.61-0.2-0.71-0.52c-0.13-0.39,0.09-0.82,0.48-0.94c1.54-0.5,3.53-0.66,5.36-0.45 c0.41,0.05,0.71,0.42,0.66,0.83c-0.05,0.41-0.42,0.71-0.83,0.66c-1.62-0.19-3.39-0.04-4.73,0.39 C14.13,10.99,14.05,11.01,13.98,11.01z">
                                        </path>
                                        <path
                                            d="M13.98,13.67c-0.32,0-0.61-0.2-0.71-0.52c-0.13-0.39,0.09-0.82,0.48-0.94c1.53-0.5,3.53-0.66,5.36-0.45 c0.41,0.05,0.71,0.42,0.66,0.83c-0.05,0.41-0.42,0.71-0.83,0.66c-1.62-0.19-3.39-0.04-4.73,0.39 C14.13,13.66,14.05,13.67,13.98,13.67z">
                                        </path>
                                        <path
                                            d="M13.98,16.33c-0.32,0-0.61-0.2-0.71-0.52c-0.13-0.39,0.09-0.82,0.48-0.94c1.53-0.5,3.53-0.66,5.36-0.45 c0.41,0.05,0.71,0.42,0.66,0.83c-0.05,0.41-0.42,0.7-0.83,0.66c-1.62-0.19-3.39-0.04-4.73,0.39 C14.13,16.32,14.05,16.33,13.98,16.33z">
                                        </path>
                                    </g>
                                </svg>

                            </div>


                            <div class="mr-5">
                                <p class="fs-16 fw-900 mb-0 line-h-20 color1">Study Material</p>

                            </div>

                            <div class="ml-5 mr-l-auto"> <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-13 h-30 w-30 mr-l-auto"></i>
                            </div>
                        </a>


                        <div
                            class="bg-theme-light-grey border-radius-20 d-flex align-items-center px-15 py-15 cursor-pointer max-w-500 mx-auto mb-20">

                            <div class="mr-10">

                                <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="35px" width="35px"
                                    viewBox="0 9 46 27">
                                    <path
                                        d="M15.5 34H26.15Q26.8 34 27.225 33.575Q27.65 33.15 27.65 32.5Q27.65 31.85 27.225 31.425Q26.8 31 26.15 31H15.5Q14.85 31 14.425 31.425Q14 31.85 14 32.5Q14 33.15 14.425 33.575Q14.85 34 15.5 34ZM15.5 25.5H32.5Q33.15 25.5 33.575 25.075Q34 24.65 34 24Q34 23.35 33.575 22.925Q33.15 22.5 32.5 22.5H15.5Q14.85 22.5 14.425 22.925Q14 23.35 14 24Q14 24.65 14.425 25.075Q14.85 25.5 15.5 25.5ZM15.5 17H32.5Q33.15 17 33.575 16.575Q34 16.15 34 15.5Q34 14.85 33.575 14.425Q33.15 14 32.5 14H15.5Q14.85 14 14.425 14.425Q14 14.85 14 15.5Q14 16.15 14.425 16.575Q14.85 17 15.5 17ZM9 42Q7.75 42 6.875 41.125Q6 40.25 6 39V9Q6 7.75 6.875 6.875Q7.75 6 9 6H19.25Q19.5 4.25 20.85 3.125Q22.2 2 24 2Q25.8 2 27.15 3.125Q28.5 4.25 28.75 6H39Q40.25 6 41.125 6.875Q42 7.75 42 9V39Q42 40.25 41.125 41.125Q40.25 42 39 42ZM24 8.15Q24.7 8.15 25.225 7.625Q25.75 7.1 25.75 6.4Q25.75 5.7 25.225 5.175Q24.7 4.65 24 4.65Q23.3 4.65 22.775 5.175Q22.25 5.7 22.25 6.4Q22.25 7.1 22.775 7.625Q23.3 8.15 24 8.15Z">
                                    </path>
                                </svg>


                            </div>


                            <div class="mr-5">
                                <p class="fs-16 fw-900 mb-0 line-h-20 color1">Forms</p>

                            </div>

                            <div class="ml-5 mr-l-auto"> <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-13 h-30 w-30 mr-l-auto"></i>
                            </div>
                        </div>




                        <a href="gallery-1"
                            class="bg-theme-light-grey border-radius-20 d-flex align-items-center px-15 py-15 cursor-pointer max-w-500 mx-auto mb-20">

                            <div class="mr-10">

                                <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="35px" width="35px"
                                    viewBox="0 9 46 27">
                                    <path
                                        d="M11 36Q9.8 36 8.9 35.1Q8 34.2 8 33V7Q8 5.8 8.9 4.9Q9.8 4 11 4H23.25L26.25 7H43Q44.2 7 45.1 7.9Q46 8.8 46 10V33Q46 34.2 45.1 35.1Q44.2 36 43 36ZM40.5 42H5Q3.8 42 2.9 41.1Q2 40.2 2 39V10H5V39Q5 39 5 39Q5 39 5 39H40.5ZM16.7 27.45H37.3L30.7 18.65L25.2 25.95L21.25 21.65Z" />
                                </svg>


                            </div>


                            <div class="mr-5">
                                <p class="fs-16 fw-900 mb-0 line-h-20 color1">Gallery</p>

                            </div>

                            <div class="ml-5 mr-l-auto"> <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-13 h-30 w-30 mr-l-auto"></i>
                            </div>
                        </a>


                        <a href="students-right"
                            class="bg-theme-light-grey border-radius-20 d-flex align-items-center px-15 py-15 cursor-pointer max-w-500 mx-auto mb-20">

                            <div class="mr-10">

                                <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="35px" width="35px"
                                    viewBox="3 11 42 27">
                                    <path
                                        d="M24 31.1Q28.5 27 29.75 25.4Q31 23.8 31 22.15Q31 20.55 29.875 19.425Q28.75 18.3 27.15 18.3Q26.2 18.3 25.375 18.675Q24.55 19.05 24 19.7Q23.45 19.05 22.625 18.675Q21.8 18.3 20.85 18.3Q19.25 18.3 18.125 19.425Q17 20.55 17 22.15Q17 22.95 17.225 23.675Q17.45 24.4 18.2 25.35Q18.95 26.3 20.325 27.65Q21.7 29 24 31.1ZM24 43.95Q17 42.2 12.5 35.825Q8 29.45 8 21.85V9.95L24 3.95L40 9.95V21.85Q40 29.45 35.5 35.825Q31 42.2 24 43.95Z">
                                    </path>
                                </svg>

                            </div>


                            <div class="mr-5">
                                <p class="fs-16 fw-900 mb-0 line-h-20 color1">Student Rights</p>

                            </div>

                            <div class="ml-5 mr-l-auto"> <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-13 h-30 w-30 mr-l-auto"></i>
                            </div>
                        </a>


                        <div
                            class="bg-theme-light-grey border-radius-20 d-flex align-items-center px-15 py-15 cursor-pointer max-w-500 mx-auto mb-20">

                            <div class="mr-10">


                                <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="35px" width="35px"
                                    viewBox="0 9 46 27">
                                    <path
                                        d="M22.65 34H25.65V22H22.65ZM24 18.3Q24.7 18.3 25.175 17.85Q25.65 17.4 25.65 16.7Q25.65 16 25.175 15.5Q24.7 15 24 15Q23.3 15 22.825 15.5Q22.35 16 22.35 16.7Q22.35 17.4 22.825 17.85Q23.3 18.3 24 18.3ZM24 44Q19.75 44 16.1 42.475Q12.45 40.95 9.75 38.25Q7.05 35.55 5.525 31.9Q4 28.25 4 24Q4 19.8 5.525 16.15Q7.05 12.5 9.75 9.8Q12.45 7.1 16.1 5.55Q19.75 4 24 4Q28.2 4 31.85 5.55Q35.5 7.1 38.2 9.8Q40.9 12.5 42.45 16.15Q44 19.8 44 24Q44 28.25 42.45 31.9Q40.9 35.55 38.2 38.25Q35.5 40.95 31.85 42.475Q28.2 44 24 44Z" />
                                </svg>




                            </div>


                            <div class="mr-5">
                                <p class="fs-16 fw-900 mb-0 line-h-20 color1">Others</p>

                            </div>

                            <div class="ml-5 mr-l-auto"> <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-13 h-30 w-30 mr-l-auto"></i>
                            </div>
                        </div>





                    </div>

                </div>

            </div>
        </div>
    </div>

</section>