

<section class="mb-lg-0 mb-20">
    <div class="container">
        <div class="mx-auto max-w-500">
            <p class="fs-22 text-white fw-800  line-h-22 mt-25 mb-15"> Interests <span class="fs-14 fw-600 text-white">
                    (Select up to 3) </span> </p>
        </div>
  
        <div class="custum-serach-w-icon bg-transparent mb-25 max-w-420 mx-auto">
            <form  class="" [formGroup]="interestsForm">
                <input type="text" formControlName="filterText"
                    class="border-0 px-4 placeholder-white opacity-100 py-3 text-white border-radius-20 bg-transparent inset-shadow-dark"
                    placeholder="Search">
                <button type="submit">
                    <i class="fa-solid text-white search-icon fa-magnifying-glass fs-20 mr-10"></i>
  
                </button>
            </form>
  
        </div>
  
        <div class="content-section mt-0 ">
  
  
            <div class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 interest-selector " >
                <div class="overT">
                    <div *ngFor="let interests of InterestsData;let index = index">
                        <div   (click)="onForInterestId(interests,index)" [ngClass]="interests.isChecked === true ? 'selected' : ''" class=" bottom bg-theme-light-grey intereset-options border-radius-25 d-flex align-items-center px-10 py-10 cursor-pointer max-w-500 mx-auto "  >
                            <div  class="bg-white px-20 py-20 border-radius-20 mr-15">
                                <img [src]="'assets/icons/'+interests.IconUrl" class="" alt="" width="50" height="50" srcset="">
                            </div>
          
                            <div>
                                <p class="fs-18 fw-900 mb-5px line-h-20 text-black">{{interests.InterestName}}</p>
                                <p class="fs-14 fw-600 mb-0 text-black">{{interests.Description}}</p>
                            </div>
                            <md-checkbox  (change)="onForInterestId(interests,index,$event)"></md-checkbox>
                        </div> 
                    </div>
                </div>
               
                  
             
                <p class="fs-16 fw-600 color5 text-center mt-20 mb-10">Selected - <span>{{selectedId.length }}/3</span></p>
  
  
                <button title="Selecte up to 3 Interests"  class="max-w-400 w-100 mt-30 py-10 px-10 text-white fs-17 fw-500 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-15 box-shadow-hard mb-10 max-w-320" [disabled]="selectedId.length == 0 || selectedId.length >= 4" (click)="onInterestId(InterestsData)">SAVE</button>
  
            </div>
            <div>
                
            </div>
  
  
        </div>
    </div>
</section>

 
