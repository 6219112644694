import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { StudentsRightService } from './students-right.service';

@Component({
  providers: [AppConstants],
  selector: 'igmite-students-right',
  templateUrl: './students-right.component.html',
  styleUrls: ['./students-right.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class StudentsRightComponent extends BaseComponent<LoginModel> implements OnInit {
  public loginForm: FormGroup;
  public loginModel: LoginModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  studentsRightModel: any;

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private studentsRightService: StudentsRightService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {

    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
    employeeMenuItems.forEach(employeeMenuItems => {
      employeeMenuItems.setAttribute('style', 'display: none !important');
    });
    let requestParams = {
      "UserId": this.UserModel.LoginId, //loginId
      "UserTypeId": this.UserModel.UserTypeId, //UserTypeId
      "PageIndex": 0,
      "PageSize": 1000,
      "ResourceType": "StudentRights",
      "RoleCode": this.UserModel.RoleCode
    }

    this.studentsRightService.GetResourcesByTypeAndCategory(requestParams)
      .subscribe((response: any) => {
        this.studentsRightModel = response.Results.filter(sR => sR.ResourceType == 'StudentRights')
        // this.desc="<h2><strong>Right 1</strong></h2><p>Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit.</p><h2><strong>Right 2</strong></h2><ol><li><strong>Right 2.1</strong></li></ol><p>Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit.</p><ol><li><strong>Right 2.2</strong></li></ol><p>Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit.</p><ol><li><strong>Right 2.3</strong></li></ol><p>Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit.</p><ol><li><strong>Right 2.4</strong></li></ol><p>Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit.</p><h2><strong>Right 3</strong></h2><p><strong>Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit.</strong></p><p><strong>Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit.</strong></p>";
        //this.desc = this.studentsRightModel.ResourceDescription

      });
    document.querySelector('p').innerText = truncateText('p', 50);

    function truncateText(selector, maxLength) {
      var element = document.querySelector(selector),
        truncated = element.innerText;

      if (truncated.length > maxLength) {
        truncated = truncated.substr(0, maxLength) + '...';
      }
      return truncated;
    }

  }



  getStudentRightsDesc(resourceId) {
    sessionStorage.setItem('ResourceId', resourceId);
    if (resourceId) {
      this.router.navigateByUrl(RouteConstants.RightsDetails);
    }
  }


}
