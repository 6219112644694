<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu-emp></igmite-side-menu-emp>


                </div>
                <div class="col-12 col-lg-10 right-content px-lg-35 px-0 pb-5 bg-white py-18">

                    <!-- <div
                        class=" px-10  pb-3 bg-white py-18 pb-0 overflow-hidden max-w-800 mx-auto ">

                        <h1
                            class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-15  line-h-30 mb-30">
                            Attendance Received</h1>

                        <div class="col-12 col-lg-10 right-content px-lg-35 px-0 pb-5 bg-white py-18"> -->

                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">

                        <div class="col-12 px-0 text-center mt-25">

                            <div class="user-profile-dash mt-lg-0">
                                <img [src]="PhotoUrl" *ngIf="FileName != null;else other_content"
                                    class="theme-bx-shadow" alt="Profile Image" srcset>
                                <ng-template #other_content><img src="assets/upload/student.jpg" class="theme-bx-shadow"
                                        alt="Profile Image" srcset></ng-template>
                                <!-- <img src="#" alt="" srcset="">-->
                                <!-- <a href="#">
                                    <img [src]="empProfileDetails.PhotoUrl" alt="" srcset=""
                                        onError="assets/upload/insta-icon.png">
                                </a> -->
                            </div>
                        </div>


                    </div>
                    <div class="user-name text-center mt-15 max-w-800 mx-auto">
                        <h2 class="color2 fw-900 fs-28"> {{empProfileDetails.EmployerName}} </h2>
                        <div class="d-flex justify-content-center align-items-center"
                            *ngIf="empProfileDetails.IsPreferred==true">
                            <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 50 50"
                                class="fill-color-1 mr-5">
                                <path
                                    d="m13.5 38.5-6.2-1.25q-.55-.1-.925-.575T6.1 35.6l.7-6L2.85 25q-.4-.4-.4-1t.4-1l3.95-4.55-.7-6q-.1-.6.275-1.075T7.3 10.8l6.2-1.25 3.1-5.35q.3-.5.85-.7.55-.2 1.1.05L24 6.1l5.45-2.55q.55-.25 1.125-.1t.825.7l3.15 5.4 6.15 1.25q.55.1.925.575t.275 1.075l-.7 6L45.15 23q.4.4.4 1t-.4 1l-3.95 4.6.7 6q.1.6-.275 1.075t-.925.575l-6.15 1.25-3.15 5.35q-.25.55-.825.7-.575.15-1.125-.1L24 41.9l-5.45 2.55q-.55.25-1.1.05t-.85-.7Zm7.3-8.9q.45.45 1.05.45.6 0 1.05-.45l9.25-9.15q.4-.4.375-1.025-.025-.625-.475-1.075-.45-.45-1.075-.425-.625.025-1.125.475l-8 7.95-3.65-3.85q-.45-.5-1.125-.475-.675.025-1.125.525-.45.5-.475 1.15-.025.65.475 1.1Z">
                                </path>
                            </svg>
                            <p class="text-black fw-900 mb-0 fs-17 ">Preferred Partner</p>
                        </div>
                    </div>
                    <!-- </div> -->

                    <div class="px-0 mt-20 max-w-800 mx-auto">
                        <div class="bg-light-theme px-20 py-25 border-radius-20 position-relative">
                            <a [routerLink]="['/employer-profile-edit']" class="position-absolute top-0 end-0">
                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1 m-3" height="25" width="25"
                                    viewBox="0 0 45 45">
                                    <path
                                        d="m39.7 14.7-6.4-6.4 2.1-2.1q.85-.85 2.125-.825 1.275.025 2.125.875L41.8 8.4q.85.85.85 2.1t-.85 2.1ZM7.5 42q-.65 0-1.075-.425Q6 41.15 6 40.5v-4.3q0-.3.1-.55.1-.25.35-.5L31.2 10.4l6.4 6.4-24.75 24.75q-.25.25-.5.35-.25.1-.55.1Z">
                                    </path>
                                </svg>
                            </a>

                            <div class="d-flex row mx-0 px-0 mb-10">
                                <div class="col-2 col-md-1 px-0">
                                    <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="30" width="30"
                                        viewBox="0 0 24 24" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                        <path
                                            d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm1 17H7V4h10v14zm-3 3h-4v-1h4v1z">
                                        </path>
                                    </svg>

                                </div>
                                <div class="col-10 px-0">
                                    <p class="mb-0 line-h-10 fs-14 text-black fw-400"> Mobile Number</p>
                                    <p class="mb-0 fs-18 text-black fw-900">{{empProfileDetails.POCMobile}}</p>
                                </div>
                            </div>

                            <div class="d-flex row mx-0 px-0">
                                <div class="col-2 col-md-1 px-0">
                                    <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="30" width="30"
                                        viewBox="0 0 24 24" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                        <path
                                            d="M21.99 8c0-.72-.37-1.35-.94-1.7L12 1 2.95 6.3C2.38 6.65 2 7.28 2 8v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2l-.01-10zM12 13L3.74 7.84 12 3l8.26 4.84L12 13z">
                                        </path>
                                    </svg>

                                </div>
                                <div class="col-10 px-0">
                                    <p class="mb-0 line-h-10 fs-14 text-black fw-400"> Email Id</p>
                                    <p class="mb-0 fs-18 text-black fw-900">{{empProfileDetails.POCEmailId}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- employment section -->
                    <div class="px-0 mt-30 max-w-800 mx-auto">
                        <div
                            class="d-flex align-items-center border-theme-2px border-start-0 border-top-0 border-end-0 py-10 mb-15">
                            <svg class="fill-color-2 mr-10" xmlns="http://www.w3.org/2000/svg" height="24" width="24"
                                viewBox="2 0 42 42">
                                <path
                                    d="M7 42q-1.2 0-2.1-.9Q4 40.2 4 39V15q0-1.2.9-2.1.9-.9 2.1-.9h9V7q0-1.2.9-2.1.9-.9 2.1-.9h10q1.2 0 2.1.9.9.9.9 2.1v5h9q1.2 0 2.1.9.9.9.9 2.1v24q0 1.2-.9 2.1-.9.9-2.1.9Zm12-30h10V7H19Z">
                                </path>
                            </svg>
                            <p class="mb-0 fs-18 color2 fw-900">Employment</p>
                        </div>
                        <div class="row mx-0 px-0">
                            <div class="col-6 px-10">
                                <div class="border-theme-2px border-radius-25 px-10 py-10 bg-light-theme">
                                    <div class="px-4 py-30 w-100 text-center bg-white border-radius-25 mx-auto">
                                        <p class="fs-40 fw-900 color2 mb-0 line-h-40">
                                            {{empProfileDetails.TotalInterns}}
                                        </p>
                                    </div>
                                    <p class="text-center fs-16 text-black line-h-20 mt-10 mb-0 fw-600">Total
                                        <br />Interns
                                    </p>
                                </div>
                            </div>

                            <div class="col-6 px-10">
                                <div class="border-theme-2px border-radius-25 px-10 py-10 bg-light-theme">
                                    <div class="px-4 py-30 w-100 text-center bg-white border-radius-25 mx-auto">
                                        <p class="fs-40 fw-900 color2 mb-0 line-h-40">
                                            {{empProfileDetails.ActiveInternships}}
                                        </p>
                                    </div>
                                    <p class="text-center fs-16 text-black line-h-20 mt-10 mb-0 fw-600">Active
                                        <br />Internships
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Office Details -->
                    <div class="px-0 mt-30 max-w-800 mx-auto ">
                        <div
                            class="d-flex align-items-center border-theme-2px border-start-0 border-top-0 border-end-0 py-10 mb-15 position-relative">
                            <img src="assets/upload/buliding-icon.png" class="mr-10" alt>
                            <p class="mb-0 fs-18 color2 fw-900">Office Details</p>
                            <a [routerLink]="['/employer-profile-edit']"
                                class="position-absolute top-50 end-0 translate-middle-y cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1 m-3" height="25" width="25"
                                    viewBox="0 0 45 45">
                                    <path
                                        d="m39.7 14.7-6.4-6.4 2.1-2.1q.85-.85 2.125-.825 1.275.025 2.125.875L41.8 8.4q.85.85.85 2.1t-.85 2.1ZM7.5 42q-.65 0-1.075-.425Q6 41.15 6 40.5v-4.3q0-.3.1-.55.1-.25.35-.5L31.2 10.4l6.4 6.4-24.75 24.75q-.25.25-.5.35-.25.1-.55.1Z">
                                    </path>

                                </svg>
                            </a>
                        </div>
                        <div class="d-flex">
                            <div
                                class="height-50 w-50 p-1 d-flex justify-content-center align-items-center border-theme-2px border-radius-5 mr-10">
                                <img [src]="PhotoUrl" class="border-theme-2px img-fluid" alt srcset />
                            </div>
                            <div class="mb-20">
                                <p class="mb-0 line-h-20 fs-13 text-black fw-600">Company Name</p>
                                <p class="mb-0 fs-16 text-black fw-900">
                                    {{empProfileDetails.OrganizationName}}
                                </p>
                            </div>
                        </div>
                        <div class="mb-20">
                            <p class="mb-0 line-h-20 fs-13 text-black fw-600">Company Address</p>
                            <p class="mb-0 fs-16 text-black fw-900">
                                {{empProfileDetails.BranchAddress}}
                            </p>
                        </div>
                        <!-- <div class="mb-15">
                            <p class="mb-2 line-h-20 fs-13 text-black fw-600">Google Map Location</p>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56031.80536897835!2d77.04814386257709!3d28.630126676605723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1adada2199ed%3A0xc651acf29d9bd805!2sLend%20a%20hand!5e0!3m2!1sen!2sin!4v1672915053167!5m2!1sen!2sin"
                                width="100%" height="300" style="border:0;" allowfullscreen loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div> -->
                        <div class="px-10 mt-30 max-w-800 mx-auto">
                            <div
                                class="d-flex align-items-center border-theme-2px border-start-0 border-top-0 border-end-0 py-10 mb-15">
                                <img src="assets/upload/Aspiration-Achievement.png" class="mr-10" srcset>
                                <p class="mb-0 fs-18 color2 fw-900">Aspiration & Achievement</p>
                            </div>
                            <div class="border-theme-2px border-radius-20 px-10 py-20"
                                *ngIf="certificateListingData.length != 0">
                                <div 
                                    *ngFor="let certificateList of certificateListingData; let i=index">
                                    <p class="fs-16 text-black text-center fw-900 mb-5px">
                                        {{certificateList.CertificateType}}</p>
                                    <p class="fs-13 text-black fw-400 read-more-less-text mb-0 text-center"
                                        data-read-more="{ visible: false, useStyledContainer: true }">
                                        {{certificateList.OrganizationName}}</p>
                                    <div class="text-center mt-15 mb-15">
                                        <img [src]="ApiBaseUrlCertificateImage+certificateList.CertificateImage" alt
                                            srcset>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <a href="{{ApiBaseUrlCertificateImage + certificateList.CertificateImage }}">
                                            <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="25"
                                                width="25" viewBox="0 9 46 27">
                                                <path
                                                    d="M14 34h20v-3H14Zm9.85-6.3 7.9-7.85-2.1-2.1-4.25 4.2V12h-3v9.95l-4.25-4.2-2.1 2.1ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z">

                                                </path>
                                            </svg>
                                            <span class="fs-14 fw-900 color1">Download Certificate</span>
                                        </a>

                                        <a href>
                                            <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="25"
                                                width="25" viewBox="0 9 46 27">
                                                <path
                                                    d="M36.35 44q-2.35 0-4.025-1.675Q30.65 40.65 30.65 38.3q0-.35.075-.825t.225-.875L15.8 27.8q-.75.85-1.85 1.375t-2.25.525q-2.35 0-4.025-1.675Q6 26.35 6 24q0-2.4 1.675-4.05T11.7 18.3q1.15 0 2.2.45 1.05.45 1.9 1.3l15.15-8.7q-.15-.35-.225-.8-.075-.45-.075-.85 0-2.4 1.675-4.05T36.35 4q2.4 0 4.05 1.65t1.65 4.05q0 2.35-1.65 4.025-1.65 1.675-4.05 1.675-1.15 0-2.225-.375Q33.05 14.65 32.3 13.8l-15.15 8.4q.1.4.175.925.075.525.075.875t-.075.75q-.075.4-.175.8l15.15 8.6q.75-.7 1.75-1.125 1-.425 2.3-.425 2.4 0 4.05 1.65t1.65 4.05q0 2.35-1.65 4.025Q38.75 44 36.35 44Z">
                                                </path>
                                            </svg>
                                            <span class="fs-14 fw-900 color1">Share</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="border-theme-2px border-radius-20 px-10 py-20" *ngIf="certificateListingData.length == 0"
                                >
                                <span>No certificate List available for now! </span>


                            </div>
                            <a href="/certificate-listing" class="fs-14 fw-900 color1 d-block text-center mt-10">View
                                All Certificate</a>
                            <div class="mt-30" *ngIf="studentGratitudeCornerData.length != 0">
                                <div class="border-theme-2px border-radius-30 overflow-hidden px-15 py-20"
                                    *ngFor="let gratitute of studentGratitudeCornerData; let i=index">
                                    <p
                                        class="fs-16 fw-400 mb-10 text-center border-theme-2px border-start-0 border-end-0 border-top-0 pb-20 mb-10">
                                        
                                        {{gratitute?.GratitudeDesc}}.
                                    </p>
                                    <div class="ranker-name-profile d-flex align-items-center">
                                        <img [src]="ApiBaseUrlImage + gratitute?.StudentPhoto"
                                            class="rounded-circle border-theme-2px mr-10" alt="" srcset="" width="40"
                                            height="40" style="height: 40px;
                                    width: 40px !important;">
                                        <div>
                                            <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                {{gratitute?.StudentName}}</p>
                                            <p class="fs-14 fw-400 text-black mb-0 mt-0">
                                                {{gratitute?.StudentAddress1}}...</p>
                                        </div>
                                    </div>
                                </div>
                                <a href="/gratitude-listing" class="fs-14 fw-900 color1 d-block text-center mt-10">View
                                    More</a>
                            </div>

                            <div class="mt-30" *ngIf="studentGratitudeCornerData.length == 0">
                                <div class="border-theme-2px border-radius-30 overflow-hidden px-15 py-20">
                                    <p
                                        class="fs-16 fw-400 mb-10 text-center border-theme-2px border-start-0 border-end-0 border-top-0 pb-20 mb-10">
                                        <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae ipsum vitae
                                        felis uet dictum diam eget sagittis. -->
                                        <!-- {{gratitute?.GratitudeDesc}}. -->
                                    </p>
                                    <div class="ranker-name-profile d-flex align-items-center">
                                        <!-- <img [src]="ApiBaseUrlImage + gratitute?.StudentPhoto"
                                            class="rounded-circle border-theme-2px mr-10" alt="" srcset="" width="40"
                                            height="40" style="height: 40px;
                                    width: 40px !important;"> -->
                                        <div>
                                            <!-- <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                {{gratitute?.StudentName}}</p> -->
                                            <p class="fs-14 fw-400 text-black mb-0 mt-0">
                                                <span>No Gratitude messages from students available for now! </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <a href="/gratitude-listing" class="fs-14 fw-900 color1 d-block text-center mt-10">View
                                    More</a>
                            </div>
                            <div
                                class="d-block px-3 text-center text-black max-w-420 mt-30 border-radius-15 mx-auto w-100 py-10 fs-14 fw-900 bg-light-theme">
                                Need Some Corrections <a [routerLink]="['/contact-us']"
                                    class="text-decoration-underline">Contact Us</a>
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</section>