import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { AppConstants } from 'app/app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import * as $ from 'jquery';
import { timeout } from 'rxjs/operators';
import { OpprtunitiesListingService } from '../opprtunities-listing/opprtunities-listing.service';
import { RouteConstants } from 'app/constants/route.constant';

@Component({
  providers: [AppConstants],
  selector: 'igmite-side-menu-emp',
  templateUrl: './side-menu-emp.component.html',
  styleUrls: ['./side-menu-emp.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SideMenuEmpComponent extends BaseComponent<LoginModel> implements OnInit, AfterViewInit {
  public appInfo = environment;

  allowedData: any
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private opprtunitiesListingService: OpprtunitiesListingService,

    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  ngOnInit(): void {

    let elementIn1: any = document.querySelectorAll(".withoutlogin");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    let elementIn2: any = document.querySelectorAll(".withinlogin");
    elementIn2.forEach(elementIn2 => {
      elementIn2.setAttribute('style', 'display: none !important');

    });
    var data = {
      "DataId": this.UserModel.UserTypeId,

    }

    this.opprtunitiesListingService.GetStudentsInternshipApprovalStatus(data).subscribe((response: any) => {

    }, error => {
    });
    this.allowedData = sessionStorage.getItem("allow");
    if(this.UserModel.RoleCode == 'ST'){
      if (this.UserModel.PhotoUrl != null) {
        let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
        // userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
        userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
      } else {
        this.UserModel.PhotoUrl = ''
      }
    }else{
      if (this.UserModel.PhotoUrl != null) {
        let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
        // userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
        userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.UserModel.PhotoUrl);
      } else {
        this.UserModel.PhotoUrl = ''
      }
    }
  
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });
  }



  ngAfterViewInit() {


  }
  redirct() {
    this.router.navigateByUrl(RouteConstants.ContactUs1);
  }
}
