<section class="mb-lg-0 mb-20">
    <div class="container">



        <div class="text-center mt-20">
            <img src="assets/upload/edit-profile.png" alt="Edit Profile" srcset="">

        </div>


        <div class="content-section mt-0">

            <div
                class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 overflow-hidden max-w-800 mx-auto mt-20">

                <p class="fs-22 text-black fw-800 text-center line-h-30 mt-25 mb-15 mb-0 text-center">Let Us Know
                    What Corrections Are Required?</p>

                <form name="profileCorrectionForm" novalidate [formGroup]="profileCorrectionForm">
                    <div class="mb-0 mt-20">

                        <div class="input-with-icon position-relative">
                            <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                <textarea matInput name="requestDesc" formControlName="RequestDesc" cols="30" rows="6" class="border-radius-10 text-black border-theme-2px fw-400 fs-16 py-10 w-100 px-15" required></textarea>
                                <mat-error *ngIf="profileCorrectionForm.controls.RequestDesc.invalid" >
                                    {{getErrorMessage(profileCorrectionForm, 'RequestDesc')}}
                                </mat-error>
                            </mat-form-field>
                            <!-- <textarea name="" id="" cols="30" rows="6" class="border-radius-10 text-black border-theme-2px fw-400 fs-16 py-10 w-100 px-15"></textarea> -->
                            <button class="d-block px-3 text-white max-w-180 border-radius-15 mx-auto mt-30 w-100 py-2 upto-down-gradient-clr-1 theme-btn" [disabled]="profileCorrectionForm.invalid" (click)="ProfileCorrection()">SUBMIT</button>

                        </div>
                    </div>
                </form>



            </div>

        </div>
    </div>
</section>