<section class="mb-lg-0 mb-20">
    <div class="container">
        <div class="login-vector text-center mt-20 mt-lg-0">
            <img src="assets/upload/login-vector-1.png" class="img-fluid" alt="" srcset="">
        </div>

        <div class="content-section mt-0">
            <div class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 overflow-hidden max-w-800 mx-auto">
                <section class="mt-20 position-relative mb-0">

                    <div id="tabs-design-2" class="filter-tabs px-10">
                        <ul
                            class="mx-auto p-0 py-0 row mb-0 d-flex filter-tabs-key justify-content-between align-items-center">
                            <li
                                class="col-6 p-0 h-100 d-flex border-grey-light-right justify-content-center align-items-center">
                                <a class="fs-15 tabs-caller py-15 fw-700 color1 h-100 d-flex justify-content-center align-items-center"
                                     title="" (click)="toggle('Student')" [ngClass]="current === 'Student' ? 'tabulous_active' : ''" >
                                    <svg class="mr-10 fill-color-1" xmlns="http://www.w3.org/2000/svg" height="24px"
                                        viewBox="0 0 24 24" width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path
                                            d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z" />
                                    </svg>

                                    Student
                                </a>
                            </li>

                            <li class="col-6 p-0 h-100 d-flex justify-content-center align-items-center">
                                <a
                                    class="fs-15 tabs-caller py-15  fw-700 color1 h-100 d-flex justify-content-center align-items-center "
                                     title=""  [ngClass]="current === 'Employer' ? 'tabulous_active' : ''"  (click)="toggle('Employer');">
                                    <svg class="mr-10 fill-color-1" xmlns="http://www.w3.org/2000/svg" height="24px"
                                        viewBox="0 0 24 24" width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path
                                            d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z" />
                                    </svg>

                                    Employer
                                </a>
                            </li>
                        </ul>

                        <div id="tabs_container" class="filter-tabs-pannel px-0 mt-30 ">
                            <div id="tabs-1" class="filter-tabs-slide max-w-420 mx-auto">

                                <p class="fs-22 text-black fw-800  line-h-22 mt-25 mb-15"> Login with OTP </p>

                                <form name="loginForm" novalidate [formGroup]="loginForm">
                                    <div class="mb-15">
                                        <p class="fs-16 text-black mb-0 px-1">Email</p>
                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                                <!-- <mat-label >User Id</mat-label> -->
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="fill-color-2 ml-10 start-0 start-0 position-absolute translate-middle-y top-50"
                                                    height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z">
                                                    </path>
                                                </svg>
                                                <input matInput name="EmailID" formControlName="EmailID"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 px-5" required>
                                                <mat-error *ngIf="loginForm.controls.EmailID.invalid" >
                                                    {{getErrorMessage(loginForm, 'EmailID')}}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                    </div>

                                   

                                    
                                </form>

                                <button
                                    class="d-block px-3 text-white max-w-180 border-radius-15 mx-auto mt-30 w-100 py-2 upto-down-gradient-clr-1 theme-btn"  [disabled]="loginForm.invalid" (click)="SendOTPEmail()">Send
                                    OTP</button>
                                <div class="pt-4">
                                    <a href="/login"
                                        class="text-center d-block px-3 color1 max-w-400 border-radius-15 mx-auto mt-5 w-100 py-2 bg-light-theme fw-500 theme-btn line-h-30 text-uppercase max-w-300">Login
                                        With Password</a>
                                </div>
                            </div>
                        </div>
                        <!--End tabs container-->
                    </div>
                    <!--End tabs-->
                </section>
            </div>
        </div>
    </div>
</section>