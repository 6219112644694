import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { ProfileDetailCorrectionService } from './profile-detail-correction.service';
import { ProfileDetailCorrectionModel } from './profile-detail-correction.model';



@Component({
  providers: [AppConstants],
  selector: 'igmite-profile-detail-correction',
  templateUrl: './profile-detail-correction.component.html',
  styleUrls: ['./profile-detail-correction.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ProfileDetailCorrectionComponent extends BaseComponent<ProfileDetailCorrectionModel> implements OnInit {
  public profileCorrectionForm: FormGroup;
  public loginModel: LoginModel;
  public profileDetailCorrectionModel: ProfileDetailCorrectionModel;

  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  interestsByStudent:any
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private profileDetailCorrectionService: ProfileDetailCorrectionService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
      
        sidepanel: {
          hidden: true
        }
      }
    };

      // Set the default login Model
    this.profileDetailCorrectionModel = new ProfileDetailCorrectionModel();

    this.profileCorrectionForm = this.createprofileCorrectionForm();

  }


  ngOnInit(): void {
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.RoleCode == 'ST') {
      let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
      employeeMenuItems.forEach(employeeMenuItems => {
        employeeMenuItems.setAttribute('style', 'display: none !important');
      });
    }
    if(this.UserModel.PhotoUrl != null){
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src',  this.Constants.CommonImageUrl.Account+this.UserModel.PhotoUrl);
    }else{
      this.UserModel.PhotoUrl=''
    }

    var  stuId={ DataId:this.UserModel.UserTypeId }
    this.profileDetailCorrectionService.GetInterestsByStudentId(stuId).subscribe((response:any) => {
        console.log(response)
        this.interestsByStudent=response.Results
      
        this.IsLoading = false;
    }, error => {
      console.log(error);
    });

      
  }

  ProfileCorrection() {
    console.log(this.profileCorrectionForm.value.RequestDesc)
    this.profileDetailCorrectionModel.RequestDesc = this.profileCorrectionForm.value.RequestDesc;
    this.profileDetailCorrectionModel.AuthUserId = this.UserModel.UserTypeId;
    this.profileDetailCorrectionModel.RequestFrom =this.UserModel.UserTypeId;
    this.profileDetailCorrectionModel.IsApproved =false;
    this.profileDetailCorrectionModel.IsActionTaken =false;
    this.profileDetailCorrectionService.CreateOrUpdateRequests(this.profileDetailCorrectionModel)
      .subscribe((logResp: any) => {
        console.log(logResp)
        if (logResp.Success == true) { 
            // this.router.navigateByUrl(RouteConstants.Interests);
            // if(this.interestsByStudent.length !=0){
            //   this.router.navigateByUrl(RouteConstants.OpprtunitiesListing);
        
            // }else{
            //   this.router.navigateByUrl(RouteConstants.Interests);
        
            // }
            if(this.UserModel.InternshipId != null){
                this.router.navigateByUrl(RouteConstants.DashboardNew);
        
            }else{
              this.router.navigateByUrl(RouteConstants.HomeMain);
            }
            this.zone.run(() => {
              this.showActionMessage(
                this.Constants.Messages.RecordSavedMessage,
                this.Constants.Html.SuccessSnackbar
              );
            })
        }
        else {
          this.showErrorMessage(
            'Invalid  Profile Correction',
            'info-snackbar'
          );
        }
      });
  }

    //Create profileCorrectionForm and returns {FormGroup}
    createprofileCorrectionForm(): FormGroup {
      return this.formBuilder.group({
        RequestDesc: new FormControl(this.profileDetailCorrectionModel.RequestDesc, [Validators.required]),
      });
    }


}
