import { UserModel } from "./user.model";

export class SearchFilterModel {
    UserId: string;
    UserTypeId: string;
    Name: string;
    CharBy: string;
    Filter: any;

    SortOrder: string;
    PageIndex: number;
    PageSize: number;
    TotalResults: number;
    PageSizeOptions: any;
    ShowFirstLastButtons: boolean;

    IgnoreCriteria: boolean;

    constructor(userModel?: UserModel) {

        this.UserId = userModel.UserId || '';
        this.UserTypeId = userModel.UserTypeId || '';
        this.Name = null;
        this.CharBy = null;
        this.Filter = {};

        this.SortOrder = 'asc';
        this.PageIndex = 0;
        this.PageSize = 10000;
        this.TotalResults = 0;
        this.PageSizeOptions = [5, 10, 25, 50, 100, 200];
        this.ShowFirstLastButtons = true;
    }
}

