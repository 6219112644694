<section class="mb-lg-0 mb-20">
    <div class="container">






        <div class="content-section mt-0">
    
            <div
                class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-10 pb-0 overflow-hidden max-w-800 mx-auto mt-20">
    
                <div class="highlight-section px-20 py-10 border-radius-25 max-w-420 mx-auto">
                    <p class="fs-22 text-white fw-800 text-center line-h-35 mt-10 mb-15 mb-0 text-center">Highlights
                    </p>
    
                    <p class="fs-15 text-white fw-800 text-center mb-0"> 22 Feb 2022</p>
                    <div class="px-15 border-radius-20 py-15 mt-15 bg-white pb-5">
                        <p class="mb-0 fs-14 fw-600 text-black mb-5">Today, for the first time, I have participated
                            in the assembly line for product packaging under the supervision of my manager.</p>
    
    
                    </div>
                    <div class="ribbon-section d-flex align-items-center">
                        <img src="assets/upload/Suraj-Kumar-150.png" class="student-picture mr-10 ml-10" alt=""
                            srcset="">
                        <div>
                            <p class="fs-15 text-black fw-900 mb-0 line-h-15">Suraj Kumar</p>
                            <p class="fw-400 mb-0 text-black fs-12">Nordic Mechanical Services</p>
                        </div>
                    </div>
    
                </div>
    
    
                <p class="text-black fs-16 fw-900 mb-0 text-center mt-40">Share With Friends</p>
                <!-- Go to www.addthis.com/dashboard to customize your tools -->
                <script type="text/javascript"
                    src="assets//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-62459324bb09bc74"></script>
    
                <!-- Go to www.addthis.com/dashboard to customize your tools -->
                <div class="addthis_inline_share_toolbox text-center mt-10"></div>
    
    
    
                <a href="../Student/dashboard.html" class="fs-15 mt-70 mb-10 color1 fw-900 d-block text-center">GO
                    TO HOME</a>
            </div>
    
        </div>
    </div>
</section>

