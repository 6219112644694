import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class EmployerProfileEditService {


    constructor(private http: BaseService) { }

    GetEmployerProfileConfirmationById(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Account.GetEmployerProfileConfirmationById, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    createOrUpdateEmployerProfileInfo(formData: any) {
        return this.http
            .HttpPost(this.http.Services.EmployerProfileInfo.CreateOrUpdateEmployerProfileInfo, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    companyLogoImg(formData: any) {
        return this.http
            .HttpPost(this.http.Services.EmployerProfileInfo.CompanyLogoImg, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    RemoveCompanyLogoImg(formData: any) {
        return this.http
            .HttpPost(this.http.Services.EmployerProfileInfo.RemoveCompanyLogoImg, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
}
