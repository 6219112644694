import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';

import { BaseListComponent } from 'app/common/base-list/base.list.component';


import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { debug } from 'console';
import { EmployerProfileModel } from './employer-profile.model';
import { EmployerProfileService } from './employer-profile.service';
import { GratitudeListingService } from '../gratitude-listing/gratitude-listing.service';
import { CertificateListingService } from '../certificate-listing/certificate-listing.service';


@Component({
  providers: [AppConstants],
  selector: 'igmite-employer-profile',
  templateUrl: './employer-profile.component.html',
  styleUrls: ['./employer-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class EmployerProfileComponent extends BaseListComponent<EmployerProfileModel> implements OnInit {
  public loginModel: LoginModel;
  //public attendanceV2Model: AttendanceV2Model;
  employerProfileModel: EmployerProfileModel;
  empProfileDetails: any;
  FileName: any;
  PhotoUrl: string;
  data: any;
  studentGratitudeCornerData = [] as any;
  certificateListingData = [] as any;
  ApiBaseUrlImage: string = this.Constants.CommonImageUrl.Account;
  ApiBaseUrlCertificateImage: string = this.Constants.CommonImageUrl.CertificateImage;
  cerData: any;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private employerProfileService: EmployerProfileService,
    private gratitudeListingService: GratitudeListingService,
    private certificateListingService: CertificateListingService,
    private dialogService: DialogService,
    public zone: NgZone,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);


    this.employerProfileModel = new EmployerProfileModel();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {

    let elementIn: any = document.querySelectorAll(".withinlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    let elementIn1: any = document.querySelectorAll(".withoutlogin");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    // let elementIn1: any = document.querySelectorAll(".withinlogin");
    // elementIn1.forEach(elementIn1 => {
    //   elementIn1.setAttribute('style', 'display: none !important');

    // });

    // let elementIn2: any = document.querySelectorAll(".withinlogin");
    // elementIn2.forEach(elementIn2 => {
    //   elementIn2.setAttribute('style', 'display: none !important');

    // });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    var EmpBranchID = { DataId: this.UserModel.EmpBranchID }

    this.employerProfileService.GetEmployerProfileConfirmationById(EmpBranchID).subscribe((profileResp: any) => {
      if (profileResp.Success == true) {
        this.zone.run(() => {

          if (profileResp.Results[0].IsPreferred == true) {
            this.empProfileDetails = profileResp.Results[0]
            this.PhotoUrl = this.Constants.CommonImageUrl.Employer + this.empProfileDetails.PhotoUrl;
            this.FileName = profileResp.Results[0].PhotoUrl;
          }
          else {
            this.empProfileDetails = profileResp.Results[0]
            this.PhotoUrl = this.Constants.CommonImageUrl.Employer + this.empProfileDetails.PhotoUrl;
            this.FileName = profileResp.Results[0].PhotoUrl;
          }
          //this.empProfileDetails.PhotoUrl = this.Constants.CommonImageUrl.Employer + this.empProfileDetails.PhotoUrl;

        });

      }



    }, error => {
    });
    this.gratitudeCorner();
    this.certificates();
  }

  gratitudeCorner() {
    // "903a3712-4f5a-4f61-80c1-3259afae263a" this.UserModel.UserTypeId
    this.gratitudeListingService.getVTApprovedEmployerGratitudeByCriteria(this.UserModel.EmployerId, 0, 10000).subscribe(response => {
      this.data = response.Results;
      if (response.Results.length != 0) {
        for (let i = 0; i < 1; i++) {
          // this.gratituteListArray.push(this.gratituteListingData[0]);
          this.studentGratitudeCornerData.push(this.data[i]);
        }

      }

    });
  }

  certificates() {
    let certificateParams = {
      EmployerGuid: this.UserModel.EmployerId,
      PageIndex: 0,
      PageSize: 1000
    }
    this.certificateListingService.GetCertificateByEmployerId(certificateParams).subscribe((response:any) => {
      this.cerData = response.Results;
      if (response.Results.length != 0 && response.Results.length > 0) {
        if (response.Results.length > 1) {
          for (let i = 0; i < 1; i++) {
            this.certificateListingData.push(this.cerData[i]);
            
          }
        } else {
          for (let i = 0; i < response.Results.length; i++) {
            this.certificateListingData.push(this.cerData[i]);
          }
        }

      }
     

    });
  }



}
