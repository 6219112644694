import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class NewPasswordService {

    constructor(private http: BaseService) { }

    createOrUpdatePassword(formData: any, AuthToken: any) {
        
        const httpOptionsAuth = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': AuthToken })
        };

        return this.http
              .post('https://dev-user.pathways.net.in/LighthouseServices/' + this.http.Services.Account.ChangePassword, formData, httpOptionsAuth)
              //   .post('https://user-uat.pathways.net.in/LighthouseServices/' + this.http.Services.Account.ChangePassword, formData, httpOptionsAuth)
            //.post('https://user.pathways.net.in/LighthouseServices/' + this.http.Services.Account.ChangePassword, formData, httpOptionsAuth)
            //.post('https://delhi-user.pathways.net.in/LighthouseServices/' + this.http.Services.Account.ChangePassword, formData, httpOptionsAuth)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    handleError(error: HttpErrorResponse) {
        let errorMessage = "Unknown error!";
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }

}
