import { FuseUtils } from '@fuse/utils';

export class NewPasswordModel {
    UserId: string;
    NewPassword: string;
    ConfirmPassword: string;
    Password: string;

    constructor(accountItem?: any) {
        accountItem = accountItem || {};
        this.UserId = accountItem.UserId || '';
        this.NewPassword = accountItem.NewPassword || '';
        this.ConfirmPassword = accountItem.ConfirmPassword || '';
        this.Password = accountItem.Password || '';
    }
} 