<div class="confirm-dialog">
    <div class="confirm-message">
        <!-- <mat-icon id="close-icon" (click)="closeDialog()">close</mat-icon> -->
        <span class="content-span full-width" style="text-align: center;
        color: blue;
        font-size: 23px; padding: 12px 16px !important">Internship Expected Joining Date</span>

    </div>
    <div class="confirm-message">
        <form class="mat-card mat-elevation-z4 p-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
            name="form" [formGroup]="dateForm" (submit)="onSubmit(dateForm)" autocomplete="off">

            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
                <input matInput formControlName="StartDate" [(ngModel)]="startDate" type="date" id="date" name="date"
                    class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                                           fw-600 cursor-pointer input-top">
            </div>
            


        </form>
    </div>
    <div mat-dialog-actions class="confirm-action">
        <!-- <button mat-flat-button id="no-button" [mat-dialog-close]="false" (click)="onSubmit(dateForm)">NO</button> -->
        <button mat-raised-button id="" class="mat-accent mr-16 " [mat-dialog-close]="true" (click)="onSubmit(dateForm)"
            style="width: 100%;    height: 50px !important;
       
            background-color: #3c4252 !important;
            color: #fff;
            border-radius: 0px !important;">Submit</button>
    </div>
</div>