import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { GetHelpService } from './get-help.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';


import { GetHelpModel } from './get-help.model';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DatePipe } from '@angular/common'
import { ViewportScroller } from "@angular/common";

@Component({
  providers: [AppConstants],
  selector: 'igmite-get-help',
  templateUrl: './get-help.component.html',
  styleUrls: ['./get-help.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class GetHelpComponent extends BaseListComponent<GetHelpModel> implements OnInit {
  public getHelpForm: FormGroup;
  public getHelpModel: GetHelpModel;

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private getHelpService: GetHelpService,
    private dialogService: DialogService,
    public zone: NgZone,
    public datepipe: DatePipe,
    private scroller: ViewportScroller,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);


    this.getHelpModel = new GetHelpModel();
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };
    this.getHelpForm = this.createInternshipOpportunityForm();

  }

  ngOnInit(): void {

    let elementIn1: any = document.querySelectorAll(".fixed-left-navigation");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }

  }

  //Create Internship Opportunity form and returns {FormGroup}
  createInternshipOpportunityForm(): FormGroup {
    return this.formBuilder.group({
      AuthUserId: new FormControl(),
      RequestType: new FormControl(),
      IsReadonly: new FormControl(),
      IsActive: new FormControl(),
      RequestId: new FormControl(),
      RequestFrom: new FormControl(),
      TypeOfRequest: new FormControl(),
      RequestedDate: new FormControl(),
      RequestTitle: new FormControl(), // WorkingType: new FormControl({ value: this.getHelpModel.WorkingType, disabled: this.PageRights.IsReadOnly }),
      RequestDesc: new FormControl({ value: this.getHelpModel.RequestDesc, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsApproved: new FormControl(),
      IsActionTaken: new FormControl(),
      RequestAssignedTo: new FormControl(),
      RequestResolvedDate: new FormControl(),
      RequestContentId: new FormControl(),
    });
  }



  saveOrUpdateInternshipOpportunityDetails() {


    // this.getHelpModel.EmployerId = this.UserModel.UserId
    if (!this.getHelpForm.valid) {
      this.validateAllFormFields(this.getHelpForm);
      return;
    }

    this.setValueFromFormGroup(this.getHelpForm, this.getHelpModel);

    // this.getHelpModel.AuthUserId = this.UserModel.UserId;


    this.getHelpModel.AuthUserId = this.UserModel.UserId;
    this.getHelpModel.RequestType = 0;
    this.getHelpModel.IsReadonly = true;
    this.getHelpModel.IsActive = true;
    this.getHelpModel.RequestId = "00000000-0000-0000-0000-000000000000";
    this.getHelpModel.RequestFrom = this.UserModel.UserTypeId;
    this.getHelpModel.TypeOfRequest = "HiringHelp";
    this.getHelpModel.RequestedDate = new Date();
    this.getHelpModel.RequestTitle = "HiringHelp";
    this.getHelpModel.RequestDesc = this.getHelpForm.value.RequestDesc;
    this.getHelpModel.IsApproved = true;
    this.getHelpModel.IsActionTaken = true;
    this.getHelpModel.RequestAssignedTo = "00000000-0000-0000-0000-000000000000";
    this.getHelpModel.RequestResolvedDate = null;
    this.getHelpModel.RequestContentId = "00000000-0000-0000-0000-000000000000";





    this.getHelpService.createOrUpdateInternship(this.getHelpModel)
      .subscribe((internshipResp: any) => {

        if (internshipResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );
            this.router.navigateByUrl(RouteConstants.HomeEmployer);

          });
        }
        else {
          var errorMessages = this.getHtmlMessage(internshipResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
      });
  }
}
