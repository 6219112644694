<section class="">
    <div class="container">

        <div class="content-section mt-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu></igmite-side-menu>
                </div>

                <div class="col-12 col-lg-10 right-content px-lg-35 py-18 pb-5 bg-white px-10 mb-4">
                    <div class="text-center">
                        <img src="assets/upload/certificate-page-image.png" alt="" srcset="">
                    </div>
                    <h1 class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black  line-h-30 mb-40">
                        Certificate</h1>



                    <div class="row mx-0 px-0" *ngIf="certificateData.length != 0">
                        <div class="mb-35 col-md-6 col-12" *ngFor="let certificateInfo of certificateData;index as i;">

                            <p class="fs-14 text-black fw-400 mb-5px px-1 ">
                                <!-- 15 Jun 2022 -->
                                {{certificateInfo.CertificateIssueDate| date:"dd MMM yyyy"}}
                            </p>

                            <div class="border-theme-2px border-radius-20 px-10 py-20">
                                <p class="fs-16 text-black text-center fw-900 mb-5px">
                                    <!-- Certificate of Completion -->
                                    {{certificateInfo.CertificateTitle}}
                                </p>
                                <p class="fs-13 text-black fw-400 read-more-less-text mb-0 text-center"
                                    data-read-more="{ visible: false, useStyledContainer: true }">
                                    <!-- Internship @ M L E Electronics Services -->
                                    Internship @ {{certificateInfo.OrganizationName}}
                                </p>

                                <div class="text-center mt-15 mb-15" id="certificate-Img">
                                    <p style="z-index: 100;
                                    padding-top: 33%;
                                    font-size: 13px;
                                    font-weight: bolder;
                                    color: #3e67f6;
                                    font-family: sans-serif;">{{certificateInfo.StudentFirstName}}
                                        {{ certificateInfo.StudentFatherName}} {{ certificateInfo.StudentLastName}}</p>
                                    <!-- <img src="assets/upload/MLE-certificate.png" alt="" srcset=""> -->
                                </div>

                                <div class="d-flex justify-content-between align-items-center">
                                    <!-- <a href="{{CertificateImageUrl + certificateInfo.CertificateImage }}"
                                        target="_blank" >
                                        <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="25"
                                            width="25" height="30px" viewBox="0 9 46 27" width="30px">
                                            <path
                                                d="M14 34h20v-3H14Zm9.85-6.3 7.9-7.85-2.1-2.1-4.25 4.2V12h-3v9.95l-4.25-4.2-2.1 2.1ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z" />
                                        </svg>

                                        <span class="fs-14 fw-900 color1">Download Certificate</span>
                                    </a> -->
                                    <a (click)="downloadCertificate(certificateInfo.CertificateId)" target="_blank">
                                        <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="25"
                                            width="25" height="30px" viewBox="0 9 46 27" width="30px">
                                            <path
                                                d="M14 34h20v-3H14Zm9.85-6.3 7.9-7.85-2.1-2.1-4.25 4.2V12h-3v9.95l-4.25-4.2-2.1 2.1ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z" />
                                        </svg>

                                        <span class="fs-14 fw-900 color1">Download Certificate</span>
                                    </a>
                                    <a>

                                        <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="25"
                                            width="25" height="30px" viewBox="0 9 46 27" width="30px">
                                            <path
                                                d="M36.35 44q-2.35 0-4.025-1.675Q30.65 40.65 30.65 38.3q0-.35.075-.825t.225-.875L15.8 27.8q-.75.85-1.85 1.375t-2.25.525q-2.35 0-4.025-1.675Q6 26.35 6 24q0-2.4 1.675-4.05T11.7 18.3q1.15 0 2.2.45 1.05.45 1.9 1.3l15.15-8.7q-.15-.35-.225-.8-.075-.45-.075-.85 0-2.4 1.675-4.05T36.35 4q2.4 0 4.05 1.65t1.65 4.05q0 2.35-1.65 4.025-1.65 1.675-4.05 1.675-1.15 0-2.225-.375Q33.05 14.65 32.3 13.8l-15.15 8.4q.1.4.175.925.075.525.075.875t-.075.75q-.075.4-.175.8l15.15 8.6q.75-.7 1.75-1.125 1-.425 2.3-.425 2.4 0 4.05 1.65t1.65 4.05q0 2.35-1.65 4.025Q38.75 44 36.35 44Z" />
                                        </svg>



                                        <span class="fs-14 fw-900 color1">Share</span>
                                    </a>
                                </div>



                            </div>
                        </div>
                        <!-- <button (click)="downloadAsPDF()">Download Certificate</button> -->
                        <!-- <a target="_blank">
                            <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="25" width="25"
                                height="30px" viewBox="0 9 46 27" width="30px">
                                <path
                                    d="M14 34h20v-3H14Zm9.85-6.3 7.9-7.85-2.1-2.1-4.25 4.2V12h-3v9.95l-4.25-4.2-2.1 2.1ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z" />
                            </svg>

                            <span class="fs-14 fw-900 color1" (click)="downloadAsPDF()">Download Certificate</span>
                        </a>
                        <div id="printableArea" #printableArea>

                            <div class="generate-certificate position-relative max-w-max-content mx-auto">
                                <p class="certificate-holder fs-45 fw-500 text-primary position-absolute translate-middle-x start-50"
                                    style="font-size: 30px;margin-bottom: 0px;">
                                    Pramod Kumar</p>
                                <p
                                    class="Certificate-common-text position-absolute translate-middle-x start-50 fs-20 text-black fw-500">
                                    For creating a positive impact on nation-building</p>
                                <p class="Certificate-type position-absolute translate-middle-x start-50 fw-600 fs-22"
                                    style="font-weight: bolder;">@ MLE Electronic Services,</p>

                                <img src="assets/upload/CERTIFICATE-blank.png"
                                    class="img-fluid d-block mx-auto border-gray-500" alt="">
                                <p class="certificate-issue-date fs-18 fw-500 position-absolute">March 5, 2022
                                </p>
                                <img src="assets/upload/signature.png"
                                    class="certificate-director-name  position-absolute" width="120px">

                            </div>
                        </div> -->

                    </div>

                    <div class="row mx-0 px-0 mb-5" *ngIf="certificateData.length == 0">
                        <p class="fs-16 text-black text-center fw-900 mb-5px">
                            {{certificateListingMsg}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>