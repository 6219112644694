<section class="">
    <div class="container">
        <div class="content-section mt-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu></igmite-side-menu>


                </div>

                <div class="col-12 col-lg-10 right-content px-lg-35 pb-5 bg-white py-10 px-10">

                    <div class="text-center mt-10 mb-40">
                        <img src="assets/upload/trophy-icon.png" class="img-fluid text-center " alt="Trophy icon"
                            srcset="">
                        <p class="text-black fw-900 mb-0 fs-20 mt-15"> Extra Points</p>
                        <p class="fs-16 text-black mb-0 fw-400">Get some extra points by <br>
                            completing Below listed tasks</p>
                    </div>

                    <div class="collect-points-list pb-60">
                        <div
                            class="bg-theme-light-grey border-radius-25 d-flex align-items-center px-10 py-10 cursor-pointer max-w-500 mx-auto mb-30">
                            <div
                                class="d-flex justify-content-center align-content-center bg-white px-15 py-15 border-radius-20 mr-15">
                                <div>
                                    <p class="mb-0 fs-22 line-h-22 text-center color2 fw-900">100</p>
                                    <p class="mb-0 fs-12 text-center color2 fw-600">Points</p>

                                </div>
                            </div>

                            <div class="mr-5">
                                <p class="fs-16 fw-900 mb-5px line-h-20 text-black">Fill Survey Form</p>
                                <p class="fs-13 fw-600 mb-0 text-black">Lorem ipsum dolor sit amet, conse Ctetur amet...
                                </p>
                            </div>

                            <div class="ml-5 mr-l-auto"> <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mr-l-auto"></i>
                            </div>
                        </div>

                        <div
                            class="bg-theme-light-grey border-radius-25 d-flex align-items-center px-10 py-10 cursor-pointer max-w-500 mx-auto mb-30">
                            <div
                                class="d-flex justify-content-center align-content-center bg-white px-15 py-15 border-radius-20 mr-15">
                                <div>
                                    <p class="mb-0 fs-22 line-h-22 text-center color2 fw-900">100</p>
                                    <p class="mb-0 fs-12 text-center color2 fw-600">Points</p>

                                </div>
                            </div>

                            <div class="mr-5">
                                <p class="fs-16 fw-900 mb-5px line-h-20 text-black">Students Rights Quiz</p>
                                <p class="fs-13 fw-600 mb-0 text-black">Lorem ipsum dolor sit amet, conse Ctetur amet...
                                </p>
                            </div>

                            <div class="ml-5 mr-l-auto"> <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mr-l-auto"></i>
                            </div>
                        </div>


                        <div
                            class="bg-theme-light-grey border-radius-25 d-flex align-items-center px-10 py-10 cursor-pointer max-w-500 mx-auto mb-30">
                            <div
                                class="d-flex justify-content-center align-content-center bg-white px-15 py-15 border-radius-20 mr-15">
                                <div>
                                    <p class="mb-0 fs-22 line-h-22 text-center color2 fw-900">100</p>
                                    <p class="mb-0 fs-12 text-center color2 fw-600">Points</p>

                                </div>
                            </div>

                            <div class="mr-5">
                                <p class="fs-16 fw-900 mb-5px line-h-20 text-black">Complete Diary Entry </p>
                                <p class="fs-13 fw-600 mb-0 text-black">Lorem ipsum dolor...</p>
                            </div>

                            <div class="ml-5 mr-l-auto"> <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mr-l-auto"></i>
                            </div>
                        </div>

                        <div
                            class="bg-theme-light-grey border-radius-25 d-flex align-items-center px-10 py-10 cursor-pointer max-w-500 mx-auto mb-30">
                            <div
                                class="d-flex justify-content-center align-content-center bg-white px-15 py-15 border-radius-20 mr-15">
                                <div>
                                    <p class="mb-0 fs-22 line-h-22 text-center color2 fw-900">100</p>
                                    <p class="mb-0 fs-12 text-center color2 fw-600">Points</p>

                                </div>
                            </div>

                            <div class="mr-5">
                                <p class="fs-16 fw-900 mb-5px line-h-20 text-black">Students Feedback</p>
                                <p class="fs-13 fw-600 mb-0 text-black">Lorem ipsum dolor...</p>
                            </div>

                            <div class="ml-5 mr-l-auto"> <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mr-l-auto"></i>
                            </div>
                        </div>


                        <div
                            class="bg-theme-light-grey border-radius-25 d-flex align-items-center px-10 py-10 cursor-pointer max-w-500 mx-auto mb-30">
                            <div
                                class="d-flex justify-content-center align-content-center bg-white px-15 py-15 border-radius-20 mr-15">
                                <div>
                                    <p class="mb-0 fs-22 line-h-22 text-center color2 fw-900">100</p>
                                    <p class="mb-0 fs-12 text-center color2 fw-600">Points</p>

                                </div>
                            </div>

                            <div class="mr-5">
                                <p class="fs-16 fw-900 mb-5px line-h-20 text-black">Share on Social Media</p>
                                <p class="fs-13 fw-600 mb-0 text-black">Lorem ipsum dolor...</p>
                            </div>

                            <div class="ml-5 mr-l-auto"> <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mr-l-auto"></i>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

</section>