import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatSelectFilterModule } from 'mat-select-filter';
import '../common/lighthouse.extension';

import { RouteConstants } from 'app/constants/route.constant';
import { AuthGuardService } from 'app/helpers/auth.guard.service';
import { LoginComponent } from 'app/auth/login/login.component';
import { HomeComponent } from 'app/auth/home/home.component';

import { LoginService } from 'app/auth/login/login.service';
//import { MatTableServerComponent } from './mat-table-server/mat-table-server.component';
//import { MatTableServerService } from './mat-table-server/mat-table-server.service';
import { SummaryDashboardComponent } from 'app/dashboards/summary-dashboard/summary-dashboard.component';
import { SummaryDashboardService } from 'app/dashboards/summary-dashboard/summary-dashboard.service';
import { MaterialElevationDirective } from 'app/dashboards/summary-dashboard/material-elevation.directive';
import { CompareDashboardComponent } from 'app/dashboards/compare-dashboard/compare-dashboard.component';
import { CompareDashboardService } from 'app/dashboards/compare-dashboard/compare-dashboard.service';
import { IssueManagementDashboardComponent } from 'app/dashboards/issue-management-dashboard/issue-management-dashboard.component';
import { IssueManagementDashboardService } from 'app/dashboards/issue-management-dashboard/issue-management-dashboard.service';
import { UrlService } from 'app/common/shared/url.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginOTPComponent } from 'app/auth/login-otp/login-otp.component';
import { LoginOTPService } from 'app/auth/login-otp/login-otp.service';

import { LoginVerifyComponent } from 'app/auth/login-verify/login-verify.component';
import { LoginVerifyService } from 'app/auth/login-verify/login-verify.service';
import { ResetPasswordComponent } from 'app/auth/reset-password/reset-password.component';
import { ResetPasswordService } from 'app/auth/reset-password/reset-password.service';
import { ResetPasswordOTPComponent } from 'app/auth/reset-password-otp/reset-password-otp.component';
import { ResetPasswordOTPService } from 'app/auth/reset-password-otp/reset-password-otp.service';
import { NewPasswordComponent } from 'app/auth/new-password/new-password.component';
import { NewPasswordService } from 'app/auth/new-password/new-password.service';


import { ProfileUploadComponent } from 'app/main/profile-upload/profile-upload.component';
import { ProfileUploadService } from 'app/main/profile-upload/profile-upload.service';
import { HighlightOneComponent } from 'app/main/highlight-one/highlight-one.component';
import { HighlightOneService } from 'app/main/highlight-one/highlight-one.service';
import { HighlightTwoComponent } from 'app/main/highlight-two/highlight-two.component';
import { HighlightTwoService } from 'app/main/highlight-two/highlight-two.service';
import { OpprtunitiesListingComponent } from 'app/main/opprtunities-listing/opprtunities-listing.component';
import { OpprtunitiesListingService } from 'app/main/opprtunities-listing/opprtunities-listing.service';
import { ProfileDetailCorrectionComponent } from 'app/main/profile-detail-correction/profile-detail-correction.component';
import { ProfileDetailCorrectionService } from 'app/main/profile-detail-correction/profile-detail-correction.service';
import { ProfileMobileComponent } from 'app/main/profile-mobile/profile-mobile.component';
import { ProfileMobileService } from 'app/main/profile-mobile/profile-mobile.service';
import { RaiseSafetyConcerComponent } from 'app/main/raise-safety-concer/raise-safety-concer.component';
import { RaiseSafetyConcerService } from 'app/main/raise-safety-concer/raise-safety-concer.service';
import { RightsDetailsComponent } from 'app/main/rights-details/rights-details.component';
import { RightsDetailsService } from 'app/main/rights-details/rights-details.service';
import { StudentsRightComponent } from 'app/main/students-right/students-right.component';
import { StudentsRightService } from 'app/main/students-right/students-right.service';

import { CollectExtraPointsComponent } from 'app/main/collect-extra-points/collect-extra-points.component';
import { CollectExtraPointsService } from 'app/main/collect-extra-points/collect-extra-points.service';
import { CongratulationComponent } from 'app/main/congratulation/congratulation.component';
import { CongratulationService } from 'app/main/congratulation/congratulation.service';
import { ScorecardComponent } from 'app/main/scorecard/scorecard.component';
import { ScorecardService } from 'app/main/scorecard/scorecard.service';
import { StudyMaterialsComponent } from 'app/main/study-materials/study-materials.component';
import { StudyMaterialsService } from 'app/main/study-materials/study-materials.service';
import { DashboardComponent } from 'app/main/dashboard/dashboard.component';
import { DashboardService } from 'app/main/dashboard/dashboard.service';
import { InterestsComponent } from 'app/main/interests/interests.component';
import { InterestsService } from 'app/main/interests/interests.service';
import { DiaryEntryListingComponent } from 'app/main/diary-entry-listing/diary-entry-listing.component';
import { DiaryEntryListingService } from 'app/main/diary-entry-listing/diary-entry-listing.service';

import { ResourcesComponent } from 'app/main/resources/resources.component';
import { ResourcesService } from 'app/main/resources/resources.service';
import { AboutPathwaysComponent } from 'app/main/about-pathways/about-pathways.component';
import { AboutPathwaysService } from 'app/main/about-pathways/about-pathways.service';
import { CertificateComponent } from 'app/main/certificate/certificate.component';
import { CertificateService } from 'app/main/certificate/certificate.service';
import { FinalInternshipReportComponent } from 'app/main/final-internship-report/final-internship-report.component';
import { FinalInternshipReportService } from 'app/main/final-internship-report/final-internship-report.service';
import { Gallery1Component } from 'app/main/gallery-1/gallery-1.component';
import { Gallery1Service } from 'app/main/gallery-1/gallery-1.service';
import { SideMenuComponent } from 'app/main/side-menu/side-menu.component';
import { NotificationService } from 'app/main/notification/notification.service';
import { NotificationComponent } from 'app/main/notification/notification.component';
import { HomeMainComponent } from 'app/main/home-main/home-main.component';
import { HomeMainService } from 'app/main/home-main/home-main.service';

import { WebcamModule } from 'app/main/profile-upload/modules/webcam/webcam.module';

import { ContactUsComponent } from 'app/main/contact-us/contact-us.component';
import { ContactUsService } from 'app/main/contact-us/contact-us.service';

import { HomeEmpComponent } from 'app/main/home-emp/home-emp.component';
import { HomeEmpService } from 'app/main/home-emp/home-emp.service';
import { SideMenuEmpComponent } from 'app/main/side-menu-emp/side-menu-emp.component';
import { UnpublishedIntershipsComponent } from 'app/main/unpublished-interships/unpublished-interships.component';
import { UnpublishedIntershipsService } from 'app/main/unpublished-interships/unpublished-interships.service';
import { ApplicantListingV2Component } from 'app/main/applicant-listing-v2/applicant-listing-v2.component';
import { ApplicantListingV2Service } from 'app/main/applicant-listing-v2/applicant-listing-v2.service';
import { InternshipListingMobileV2Component } from 'app/main/internship-listing-mobile-v2/internship-listing-mobile-v2.component';
import { InternshipListingMobileV2Service } from 'app/main/internship-listing-mobile-v2/internship-listing-mobile-v2.service';

import { AttendanceV2Service } from './attendance-v2/attendance-v2.service';
import { AttendanceV2Component } from './attendance-v2/attendance-v2.component';
import { InternshipDetailComponent } from './internship-detail/internship-detail.component';
import { InternshipDetailService } from './internship-detail/internship-detail.service';
import { PastInternshipComponent } from './past-internship/past-internship.component';
import { InterListingComponent } from './inter-listing/inter-listing.component';
import { InterListingService } from './inter-listing/inter-listing.service';
import { PastInternshipService } from './past-internship/past-internship.service';
import { EmployerProfileComponent } from './employer-profile/employer-profile.component';
import { EmployerProfileService } from './employer-profile/employer-profile.service';

import { GetHelpComponent } from './get-help/get-help.component';
import { GetHelpService } from './get-help/get-help.service';
import { EmployerProfileEditService } from './employer-profile-edit/employer-profile-edit.service';
import { EmployerProfileEditComponent } from './employer-profile-edit/employer-profile-edit.component';
import { GratitudeListingService } from './gratitude-listing/gratitude-listing.service';
import { GratitudeListingComponent } from './gratitude-listing/gratitude-listing.component';
import { GratitudeCornerComponent } from './gratitude-corner/gratitude-corner.component';
import { GratitudeCornerService } from './gratitude-corner/gratitude-corner.service';
import { SurveyListingComponent } from './survey-listing/survey-listing.component';
import { SurveyListingService } from './survey-listing/survey-listing.service';
import { SurveyForm1Component } from './survey-form1/survey-form1.component';
import { SurveyForm1Service } from './survey-form1/survey-form1.service';
import { CertificateListingComponent } from './certificate-listing/certificate-listing.component';
import { CertificateListingService } from './certificate-listing/certificate-listing.service';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ThankYouService } from './thank-you/thank-you.service';
import { SurveyForm2Component } from './survey-form2/survey-form2.component';
import { SurveyForm2Service } from './survey-form2/survey-form2.service';
import { ThankYouResetComponent } from './thank-you-reset/thank-you-reset.component';


import { Internship3Component } from 'app/main/internship-3/internship-3.component';
import { Internship3Service } from 'app/main/internship-3/internship-3.service';
import { VideoMaterialsComponent } from './video-materials/video-materials.component';
import { VideoMaterialsService } from './video-materials/video-materials.service';

const routes: Routes = [
    { path: RouteConstants.Login, component: LoginComponent },
    { path: RouteConstants.LoginOTP, component: LoginOTPComponent },
    { path: RouteConstants.LoginVerify, component: LoginVerifyComponent },
    { path: RouteConstants.ResetPassword, component: ResetPasswordComponent },
    { path: RouteConstants.ResetPasswordOTP, component: ResetPasswordOTPComponent },
    { path: RouteConstants.NewPassword, component: NewPasswordComponent },
    { path: RouteConstants.DashboardNew, component: DashboardComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.CollectExtraPoints, component: CollectExtraPointsComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.Congratulation, component: CongratulationComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.Scorecard, component: ScorecardComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.StudyMaterials, component: StudyMaterialsComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.Interests, component: InterestsComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.ProfileUpload, component: ProfileUploadComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.HighlightOne, component: HighlightOneComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.HighlightTwo, component: HighlightTwoComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.OpprtunitiesListing, component: OpprtunitiesListingComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.ProfileDetailCorrection, component: ProfileDetailCorrectionComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.ProfileMobile, component: ProfileMobileComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.RaiseSafetyConcer, component: RaiseSafetyConcerComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.RightsDetails, component: RightsDetailsComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.StudentsRight, component: StudentsRightComponent, canActivate: [AuthGuardService] },

    { path: RouteConstants.Resources, component: ResourcesComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.AboutPathways, component: AboutPathwaysComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.Certificate, component: CertificateComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.FinalInternshipReport, component: FinalInternshipReportComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.Gallery1, component: Gallery1Component, canActivate: [AuthGuardService] },
    { path: RouteConstants.Notification, component: NotificationComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.DiaryEntryListing, component: DiaryEntryListingComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.HomeMain, component: HomeMainComponent, canActivate: [AuthGuardService] },


    { path: RouteConstants.ContactUs, component: ContactUsComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.ContactUs1, component: ContactUsComponent, },
    { path: RouteConstants.HomeEmployer, component: HomeEmpComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.UnpublishedInterships, component: UnpublishedIntershipsComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.ApplicantListingV2, component: ApplicantListingV2Component, canActivate: [AuthGuardService] },

    { path: RouteConstants.InternshipListingMobileV2, component: InternshipListingMobileV2Component, canActivate: [AuthGuardService] },

    { path: RouteConstants.PastInternship, component: PastInternshipComponent, canActivate: [AuthGuardService] },

    { path: RouteConstants.InterListing, component: InterListingComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.AttendanceV2, component: AttendanceV2Component, canActivate: [AuthGuardService] },
    { path: RouteConstants.InternshipDetail, component: InternshipDetailComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.GetHelp, component: GetHelpComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.EmployerProfile, component: EmployerProfileComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.EmployerProfileEdit, component: EmployerProfileEditComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.GratitudeListing, component: GratitudeListingComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.GratitudeCorner, component: GratitudeCornerComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.SurveyListing, component: SurveyListingComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.SurveyForm1, component: SurveyForm1Component, canActivate: [AuthGuardService] },
    { path: RouteConstants.SurveyForm2, component: SurveyForm2Component, canActivate: [AuthGuardService] },
    //{ path: '', redirectTo: RouteConstants.Login, pathMatch: 'full' },
    //{ path: '**', component: PageNotFoundComponent },
    //{ path: RouteConstants.PageNotFound, component: PageNotFoundComponent },
    //{ path: RouteConstants.MatTableServer.List, component: MatTableServerComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.SummaryDashboard.Dashboard, component: SummaryDashboardComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.CompareDashboard.CompareDashboard, component: CompareDashboardComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.IssueManagementDashboard.IssueManagementDashboard, component: IssueManagementDashboardComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.CertificateListing, component: CertificateListingComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.ThankYou, component: ThankYouComponent, canActivate: [AuthGuardService] },
    { path: RouteConstants.ThankYouReset, component: ThankYouResetComponent },
    { path: RouteConstants.internship3, component: Internship3Component, canActivate: [AuthGuardService] },
    { path: RouteConstants.VideoMaterials, component: VideoMaterialsComponent, canActivate: [AuthGuardService] },


];

@NgModule({
    declarations: [
        LoginComponent,
        LoginOTPComponent,
        LoginVerifyComponent,
        PageNotFoundComponent,
        ResetPasswordComponent,
        ResetPasswordOTPComponent,
        HomeComponent,
        NewPasswordComponent,
        HomeMainComponent,

        //MatTableServerComponent,

        SummaryDashboardComponent,
        CompareDashboardComponent,
        IssueManagementDashboardComponent,
        MaterialElevationDirective,
        DashboardComponent,
        CollectExtraPointsComponent,
        ProfileMobileComponent,
        HighlightOneComponent,
        RaiseSafetyConcerComponent,
        OpprtunitiesListingComponent,
        HighlightTwoComponent,
        ProfileDetailCorrectionComponent,
        InterestsComponent,
        HomeComponent,
        ProfileUploadComponent,
        RightsDetailsComponent,
        StudentsRightComponent,
        CongratulationComponent,
        ScorecardComponent,
        StudyMaterialsComponent,
        ResourcesComponent,
        AboutPathwaysComponent,
        CertificateComponent,
        FinalInternshipReportComponent,
        Gallery1Component,
        NotificationComponent,
        SideMenuComponent,
        DiaryEntryListingComponent,


        ContactUsComponent,
        HomeEmpComponent,
        SideMenuEmpComponent,
        UnpublishedIntershipsComponent,
        ApplicantListingV2Component,
        InternshipListingMobileV2Component,
        PastInternshipComponent,
        InterListingComponent,
        AttendanceV2Component,
        InternshipDetailComponent,
        GetHelpComponent,
        EmployerProfileComponent,
        EmployerProfileEditComponent,
        GratitudeListingComponent,
        GratitudeCornerComponent,
        SurveyListingComponent,
        SurveyForm1Component,
        CertificateListingComponent,
        ThankYouComponent,
        ThankYouResetComponent,
        SurveyForm2Component,
        Internship3Component,
        VideoMaterialsComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseWidgetModule,
        TranslateModule,
        MatAutocompleteModule,

        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatListModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        NgxChartsModule,
        MaterialFileInputModule,
        MatRadioModule,
        MatCardModule,
        MatTooltipModule,
        MatDividerModule,
        MatProgressBarModule,
        BrowserAnimationsModule,
        MatSlideToggleModule,
        MatPasswordStrengthModule.forRoot(),
        MatNativeDateModule,
        MatTableExporterModule,
        MatToolbarModule,
        MatCarouselModule.forRoot(),
        MatSelectFilterModule,
        WebcamModule,

    ],
    exports: [
        LoginComponent,
        LoginOTPComponent,
        LoginVerifyComponent,
        PageNotFoundComponent,
        ResetPasswordComponent,
        HomeComponent,
        ResetPasswordOTPComponent,
        NewPasswordComponent,
        HomeMainComponent,
        SummaryDashboardComponent,
        CompareDashboardComponent,
        IssueManagementDashboardComponent,
        //MatTableServerComponent, 
        DashboardComponent,
        CollectExtraPointsComponent,
        ProfileMobileComponent,
        HighlightOneComponent,
        RaiseSafetyConcerComponent,
        OpprtunitiesListingComponent,
        HighlightTwoComponent,
        ProfileDetailCorrectionComponent,
        InterestsComponent,
        HomeComponent,
        ProfileUploadComponent,
        RightsDetailsComponent,
        StudentsRightComponent,
        CongratulationComponent,
        ScorecardComponent,
        StudyMaterialsComponent,
        MatButtonModule,
        ResourcesComponent,
        AboutPathwaysComponent,
        CertificateComponent,
        FinalInternshipReportComponent,
        Gallery1Component,
        NotificationComponent,
        SideMenuComponent,
        DiaryEntryListingComponent,


        ContactUsComponent,
        HomeEmpComponent,
        SideMenuEmpComponent,
        UnpublishedIntershipsComponent,
        ApplicantListingV2Component,
        InternshipListingMobileV2Component,
        PastInternshipComponent,
        InterListingComponent,
        AttendanceV2Component,
        InternshipDetailComponent,
        GetHelpComponent,
        EmployerProfileComponent,
        EmployerProfileEditComponent,
        GratitudeListingComponent,
        GratitudeCornerComponent,
        SurveyListingComponent,
        SurveyForm1Component,
        CertificateListingComponent,
        ThankYouComponent,
        ThankYouResetComponent,
        SurveyForm2Component,
        Internship3Component,
        VideoMaterialsComponent,
    ],
    providers: [
        LoginService,
        LoginOTPService,
        //MatTableServerService,
        DiaryEntryListingService,
        SummaryDashboardService,
        CompareDashboardService,
        IssueManagementDashboardService,
        LoginVerifyService,
        UrlService,
        ResetPasswordOTPService,
        DashboardService,
        HighlightOneService,
        HighlightTwoService,
        OpprtunitiesListingService,
        ProfileMobileService,
        InterestsService,
        ResetPasswordService,
        NewPasswordService,
        ProfileUploadService,
        UrlService,
        ProfileDetailCorrectionService,
        RaiseSafetyConcerService,
        RightsDetailsService,
        StudentsRightService,
        CollectExtraPointsService,
        CongratulationService,
        ScorecardService,
        StudyMaterialsService,
        ResourcesService,
        AboutPathwaysService,
        CertificateService,
        FinalInternshipReportService,
        Gallery1Service,
        NotificationService,
        HomeMainService,
        ContactUsService,
        HomeEmpService,
        UnpublishedIntershipsService,
        ApplicantListingV2Service,
        InternshipListingMobileV2Service,
        PastInternshipService,
        InterListingService,
        AttendanceV2Service,
        InternshipDetailService,
        GetHelpService,
        EmployerProfileService,
        EmployerProfileEditService,
        GratitudeListingService,
        GratitudeCornerService,
        SurveyListingService,
        SurveyForm1Service,
        CertificateListingService,
        ThankYouService,
        SurveyForm2Service,
        Internship3Service,
        VideoMaterialsService,
    ],
})

export class IgmiteModule { }
