<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu-emp></igmite-side-menu-emp>


                </div>
                <div class="col-12 col-lg-10 right-content px-lg-35 px-0 pb-5 bg-white py-18">
                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">

                        <div class="col-12 px-0 text-center">

                            <div class="user-profile-dash mt-lg-0">
                                <img [src]="profileDetails.StudentProfilePhoto" alt="" srcset="">
                                <!-- <img src="#" alt="" srcset=""> -->
                            </div>
                        </div>
                    </div>
                    <div class="user-name text-center mt-15 max-w-800 mx-auto">
                        <h2 class="color2 fw-900 fs-25 mb-0">Hi <span class="text-black fs-26 font-pacific fw-400">
                                {{profileDetails.StudentName}}</span> </h2>
                        <p class="text-center fs-16 color1 fw-600 mb-0 mt-10">{{profileDetails.StudentMobile}}</p>
                    </div>
                    <!-- </div> -->

                    <section class="mt-20 position-relative mb-0">
                        <div id="tabs-design-2" class="filter-tabs">
                            <ul
                                class="mx-auto p-0 py-0 row mb-0 d-flex filter-tabs-key justify-content-between align-items-center">
                                <li
                                    class="col-6 p-0 h-100 d-flex border-grey-light-right justify-content-center align-items-center">
                                    <a class="fs-14 tabs-caller tabulour-links py-15 fw-700 color1 h-100 d-flex justify-content-center align-items-center"
                                        (click)="toggle('AllApplications')"
                                        [ngClass]="current === 'AllApplications' ? 'tabulous_active' : ''" title=""
                                        (click)="toggle('AllApplications');">

                                        <svg class="mr-10 fill-color-1" version="1.1" id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px"
                                            height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"
                                            xml:space="preserve">
                                            <g>
                                                <rect fill="none" height="24" width="24"></rect>
                                            </g>
                                            <g>
                                                <path
                                                    d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z" />
                                            </g>

                                        </svg>
                                        Attendance
                                    </a>
                                </li>
                                <li class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"><a
                                        class="fs-14 tabs-caller tabulour-links py-15  fw-700 color1 h-100 d-flex justify-content-center align-items-center"
                                        [ngClass]="current === 'Shortlisted' ? 'tabulous_active' : ''" title=""
                                        (click)="toggle('Shortlisted');">

                                        <svg class="mr-10 fill-color-1" version="1.1" id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px"
                                            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
                                            xml:space="preserve">
                                            <g>
                                                <rect fill="none" height="24" width="24"></rect>
                                            </g>
                                            <g>
                                                <path
                                                    d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
                                            </g>

                                        </svg>
                                       
                                        Diary Entry
                                    </a>
                                </li>

                            </ul>
                            <div id="tabs_container" class="filter-tabs-pannel px-0 mt-30 ">
                                <div id="tabs-1" class="filter-tabs-slide max-w-420 mx-auto"
                                    *ngIf="current == 'AllApplications'">
                                    <p class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-10 line-h-30 mt-50">Attendance</p>
                                    <div
                                        class="calendar-container max-w-420 mx-auto overflow-hidden border-theme-2px mb-40">
                                        <mat-card class="demo-inline-calendar-card">
                                            <mat-calendar #calendar [dateClass]="dateClass" [selected]="selectedDate">
                                            </mat-calendar>
                                        </mat-card>
                                        <!-- <mat-datepicker #releasedAtPicker [dateClass]="dateClass">
                                            <input matInput [matDatepicker]="picker">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
                                        </mat-datepicker> -->

                                    </div>

                                    <div class="row mx-0 px-0">
                                        <div class="col-6 px-2">
                                            <div class="border-theme-2px border-radius-25 px-10 py-10 bg-light-theme">
                                                <div
                                                    class="px-4 py-30 w-100 text-center bg-white border-radius-25 mx-auto">
                                                    <p class="fs-40 fw-900 text-black mb-0 line-h-40">{{totalDays}}</p>
                                                </div>
                                                <p class="text-center fs-16 text-black line-h-20 mt-10 mb-0 fw-600">
                                                    Total<br />
                                                    Present Days</p>
                                            </div>
                                        </div>

                                        <div class="col-6 px-2">
                                            <div class="border-theme-2px border-radius-25 px-10 py-10 bg-light-theme">
                                                <div
                                                    class="px-4 py-30 w-100 text-center bg-white border-radius-25 mx-auto">
                                                    <p class="fs-40 fw-900 text-black mb-0 line-h-40">{{hoursSpent |slice:0:2}}</p>
                                                </div>
                                                <p class="text-center fs-16 text-black line-h-20 mt-10 mb-0 fw-600">
                                                    Total<br />
                                                    Hours spend</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div class=" max-w-800 mx-auto ">
                                        <div class="py-20 px-15 border-theme-2px border-radius-25 mt-40">
                                            <form [formGroup]="attendanceV2Form" name="attendanceV2Form">
                                                <div class="mb-20">
                                                    <p class="fs-16 text-black mb-10 px-1 fw-900">Share your
                                                        comments with trainer </p>
                                                    <div class="input-with-icon position-relative">
                                                        <textarea name id cols="30" rows="5" formControlName="Comments"
                                                            class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea>
                                                    </div>

                                                </div>
                                                <button
                                                    class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-20 text-uppercase box-shadow-hard mb-10"
                                                    (click)="CreateAttendanceV2()">
                                                    Comment
                                                </button>
                                            </form>
                                        </div>
                                        <a [routerLink]="['/inter-listing']"
                                            class="fs-16 fw-900 color1 text-center mt-30 d-block">Go
                                            Back</a>
                                    </div>
                                </div>
                                <!-- tab 1 end -->
                                <!-- tab 2 -->
                                <div id="tabs-2" class="filter-tabs-slide max-w-420 mx-auto"
                                    *ngIf="current == 'Shortlisted'">
                                    <p class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-10 line-h-30 mt-50">Diary Entry</p>
                                    <div
                                        class="calendar-container max-w-420 mx-auto overflow-hidden border-theme-2px mb-40">
                                        <mat-card class="demo-inline-calendar-card">
                                            <mat-calendar #calendar (change)="GetDailyDiaryEntries()"
                                                [selected]="selectedDate" [dateClass]="dateClass" (selectedChange)="onSelect($event)">
                                            </mat-calendar>
                                        </mat-card>

                                    </div>
                                    <div *ngFor="let list of listDiaryEntry;">
                                        <p
                                            class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-15 line-h-30 mt-50">
                                            {{selectedDate | date:'mediumDate'}}</p>
                                        <div class="py-20 px-15 border-theme-2px border-radius-25 mt-0">
                                            <div *ngFor="let list of list.listEntries;">
                                                <p class="fs-16 text-black fw-900 mb-5px"
                                                    *ngIf="list.QuestionAnswerType === 'TextArea'">{{list.QuestionText}}
                                                </p>
                                                <p class="text-black fw-600 fs-16 mb-0"
                                                    *ngIf="list.QuestionAnswerType === 'TextArea'">
                                                    {{list.AnswerText}}
                                                </p>

                                                <p class="fs-16 text-black fw-900 mb-5px mt-20"
                                                    *ngIf="list.QuestionAnswerType === 'Dropdown'">{{list.QuestionText}}
                                                    <span class="fw-600" *ngIf="list.QuestionAnswerType === 'Dropdown'">
                                                        {{list.AnswerText}}</span>
                                                </p>
                                            </div>
                                            <div *ngIf="flag.length !== 0">
                                                <p class="fs-16 text-black fw-900 mb-5px mt-20">Media</p>
                                                <div class="d-flex flex-wrap align-items-center">
                                                    <div class=" mr-10 w-80"
                                                        *ngFor="let attItem of list.listAttachments;">
                                                        <a class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0 vbox-item"
                                                            data-gall="myGallery" data-vbtype="inline"
                                                            *ngIf="attItem.AttachmentPath != ''">
                                                            <div class="position-relative">
                                                                <!-- <span
                                                    class="fs-13 text-white mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600"><i
                                                        class="fa-solid fa-circle-play"></i> <span
                                                        class="video-timing">02:00</span> </span> -->
                                                                <!-- <img class="w-100 object-fit-cover h-80"
                                                    [src]="listAttachment.AttachmentPath" alt="" srcset=""> -->
                                                                <span
                                                                    *ngIf="(attItem.AttachmentPath!=null)&& (attItem.AttachmentPath.split('.')[1]=='jpg' || attItem.AttachmentPath.split('.')[1]=='jpeg' || attItem.AttachmentPath.split('.')[1]=='iso' 
                                                    ||attItem.AttachmentPath.split('.')[1]=='png' ||attItem.AttachmentPath.split('.')[1]=='gif'
                                                    ||attItem.AttachmentPath.split('.')[1]=='docx'||attItem.AttachmentPath.split('.')[1]=='doc'||attItem.AttachmentPath.split('.')[1]=='pdf'
                                                    ||attItem.AttachmentPath.split('.')[1]=='mp3' ||attItem.AttachmentPath.split('.')[1]=='mp4'||attItem.AttachmentPath.split('.')[1]=='mpeg'||attItem.AttachmentPath.split('.')[1]=='pptx')">
                                                                    <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                                                        href="{{ imageBasePath + attItem.AttachmentPath }}"
                                                                        target="_blank">
                                                                        <!-- <mat-icon *ngIf="attItem.AttachmentType=='PDF||pdf'">picture_as_pdf</mat-icon> -->
                                                                        <i class="fa fa-file-pdf-o" aria-hidden="true"
                                                                            *ngIf="attItem.AttachmentType=='PDF||pdf'"></i>
                                                                        <i class="fa fa-file" aria-hidden="true"
                                                                            *ngIf="attItem.AttachmentType=='DOC||doc'"></i>

                                                                        <img class="object-fit-cover h-80"
                                                                            *ngIf="attItem.AttachmentType=='Image'||'image/png'"
                                                                            [src]="imageBasePath + attItem.AttachmentPath"
                                                                            alt="" srcset="">
                                                                        <!-- <i class="fa fa-picture-o" aria-hidden="true" *ngIf="attItem.AttachmentType=='Image'||'image/png'"></i> -->
                                                                        <!-- <mat-icon *ngIf="attItem.AttachmentType=='Image'||'image/png'">image</mat-icon> -->
                                                                    </a>
                                                                </span>

                                                                <span
                                                                    *ngIf="attItem.AttachmentPath !=null && attItem.AttachmentPath.includes('https://')">
                                                                    <a href="{{attItem.AttachmentPath }}"
                                                                        target="_blank">
                                                                        <!-- <mat-icon *ngIf="attItem.AttachmentType=='PDF||pdf'">picture_as_pdf</mat-icon> -->
                                                                        <i class="fa fa-file-pdf-o" aria-hidden="true"
                                                                            *ngIf="attItem.AttachmentType=='PDF||pdf'"></i>
                                                                        <i class="fa fa-file" aria-hidden="true"
                                                                            *ngIf="attItem.AttachmentType=='DOC||doc'"></i>

                                                                        <!-- <i class="fa fa-picture-o" aria-hidden="true" *ngIf="attItem.AttachmentType=='Image'||'image/png'"></i> -->
                                                                        <img class="object-fit-cover h-80"
                                                                            *ngIf="attItem.AttachmentType=='Image'||'image/png'"
                                                                            [src]="attItem.AttachmentPath" alt=""
                                                                            srcset="">
                                                                        <!-- <mat-icon *ngIf="attItem.AttachmentType=='Image'||'image/png'">image</mat-icon> -->
                                                                    </a>
                                                                </span>
                                                            </div>


                                                        </a>
                                                    </div>
                                                </div>
                                            </div><!--img end-->
                                        </div>
                                    </div>
                                    <a [routerLink]="['/inter-listing']"
                                        class="fs-16 fw-900 color1 text-center mt-30 d-block">Go
                                        Back</a>

                                </div>
                            </div><!--End tabs container-->
                        </div><!--End tabs-->
                    </section>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</section>