<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0">
            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <div class="fixed-left-navigation">
                        <ul>
                            <!-- <li>
                                <a class="active fs-16" (click)="scrollPoint1(point_3)" style="cursor: pointer;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="fill-color-1"
                                        height="48" width="48">
                                        <path
                                            d="M25.2 16.7V6.2Q25.2 5.25 25.85 4.6Q26.5 3.95 27.45 3.95H41.8Q42.75 3.95 43.4 4.6Q44.05 5.25 44.05 6.2V16.7Q44.05 17.7 43.4 18.35Q42.75 19 41.8 19H27.45Q26.5 19 25.85 18.35Q25.2 17.7 25.2 16.7ZM3.9 24.05V6.2Q3.9 5.25 4.575 4.6Q5.25 3.95 6.2 3.95H20.4Q21.35 3.95 22 4.6Q22.65 5.25 22.65 6.2V24.05Q22.65 25 22 25.65Q21.35 26.3 20.4 26.3H6.2Q5.25 26.3 4.575 25.65Q3.9 25 3.9 24.05ZM25.2 41.65V23.85Q25.2 22.85 25.85 22.2Q26.5 21.55 27.45 21.55H41.8Q42.75 21.55 43.4 22.2Q44.05 22.85 44.05 23.85V41.65Q44.05 42.65 43.4 43.325Q42.75 44 41.8 44H27.45Q26.5 44 25.85 43.325Q25.2 42.65 25.2 41.65ZM3.9 41.65V31.15Q3.9 30.2 4.575 29.55Q5.25 28.9 6.2 28.9H20.4Q21.35 28.9 22 29.55Q22.65 30.2 22.65 31.15V41.65Q22.65 42.65 22 43.325Q21.35 44 20.4 44H6.2Q5.25 44 4.575 43.325Q3.9 42.65 3.9 41.65Z" />
                                    </svg>
                                    Home
                                </a>
                            </li>

                            <li>
                                <a class="fs-16" href="/login" style="cursor: pointer;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
                                        class="fill-color-1 h-30" height="48" width="48">
                                        <path
                                            d="M7 42Q5.8 42 4.9 41.1Q4 40.2 4 39V14.35Q4 13.15 4.9 12.25Q5.8 11.35 7 11.35H16.5V7Q16.5 5.8 17.4 4.9Q18.3 4 19.5 4H28.5Q29.7 4 30.6 4.9Q31.5 5.8 31.5 7V11.35H41Q42.2 11.35 43.1 12.25Q44 13.15 44 14.35V39Q44 40.2 43.1 41.1Q42.2 42 41 42ZM19.5 11.35H28.5V7Q28.5 7 28.5 7Q28.5 7 28.5 7H19.5Q19.5 7 19.5 7Q19.5 7 19.5 7Z" />
                                    </svg>
                                    Internships
                                </a>
                            </li>

                            <li>
                                <a class="fs-16" href="/login" style="cursor: pointer;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                        class="fill-color-1 h-30" height="48" width="48" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM8 17.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zM9.5 8c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5S9.5 9.38 9.5 8zm6.5 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
                                    </svg>
                                    Resources
                                </a>
                            </li> -->

                            <li>
                                <a href="/login" class="fs-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                        viewBox="0 0 24 24" class="fill-color-1 h-30" height="48" width="48"
                                        fill="#000000">
                                        <g>
                                            <rect fill="none" height="24" width="24" />
                                            <rect fill="none" height="24" width="24" />
                                        </g>
                                        <g>
                                            <path
                                                d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,6c1.93,0,3.5,1.57,3.5,3.5S13.93,13,12,13 s-3.5-1.57-3.5-3.5S10.07,6,12,6z M12,20c-2.03,0-4.43-0.82-6.14-2.88C7.55,15.8,9.68,15,12,15s4.45,0.8,6.14,2.12 C16.43,19.18,14.03,20,12,20z" />
                                        </g>
                                    </svg>
                                    Login
                                </a>
                            </li>
                        </ul>
                        <div class="border-theme-2px mt-30 border-bottom-0 border-end-0 border-start-0 py-10 pl-20">
                            <!-- <a class="d-block col-6  text-black fw-600 fs-15  py-2 w-100"> AboutUs</a> -->
                            <a class="d-block col-6  text-black fw-600 fs-15 py-2 w-100" (click)="redirct()"> Contact
                                Us</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-9 col-lg-10 right-content px-lg-1 overflow-hidden px-5px pb-5 bg-white py-5px">
                    <!-- <mat-carousel class="mat-carousel-style owl-carousel owl-theme home-video-single cstm-owl-dots" timings="250ms ease-in" [autoplay]="true" interval="3000"
                        color="accent" maxWidth="auto" proportion="25" slides="4" [loop]="true" [hideArrows]="false"
                        [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr"
                        slideHeight="100%" style="display: block;">

                        <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index"
                            overlayColor="#00000040" [hideOverlay]="true">
                            <div class="item">
                                <div class="text-center">
                                    <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                        data-vbtype="video" [href]="slide.SlideContentUrl">

                                        <img [src]="slide.YoutubeIcon" height="45"
                                            class="img-fluid position-absolute center-icon" alt="" srcset="">
                                        <img class="w-100" [src]="slide.SlidePreviewUrl" alt="" srcset="">
                                    </a>
                                </div>
                            </div>
                        </mat-carousel-slide>
                    </mat-carousel> -->

                    <div class="owl-carousel owl-theme home-video-single cstm-owl-dots" #point_3>
                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="assets/upload/Video-1.png" alt="" srcset="">
                                </a>
                            </div>
                        </div>

                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="assets/upload/Video-2.png" alt="" srcset="">
                                </a>
                            </div>
                        </div>

                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="assets/upload/Video-3.png" alt="" srcset="">
                                </a>
                            </div>
                        </div>

                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="assets/upload/Video-4.png" alt="" srcset="">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div #point_1 class="internship-section border-theme-2px border-radius-40 mt-50 max-w-800 mx-auto">
                        <a href="/login"
                            class="bg-color2 px-20 py-15 position-relative d-flex align-items-center border-radius-30 box-shadow-light justify-content-between">
                            <img src="assets/upload/find-opportunities-icon.png" alt="Find opportunities" srcset=""
                                class="mt--50">

                            <h3 class="fs-20 line-h-25 fw-900 mb-0 text-black mr-auto">
                                Find Internship Opportunities
                            </h3>

                            <div class="ml-auto">
                                <i
                                    class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35"></i>
                            </div>
                        </a>

                        <div class="row px-15 mx-0 h-100 mb-30 mt-20">
                            <div class="col-4 h-100 ">

                                <a href="#"
                                    class="bg-light-theme d-block text-center align-items-start border-radius-10 py-lg-3">
                                    <div class="h-70 d-flex justify-content-center align-items-center px-2 py-1">
                                        <img class="mb-0" src="../assets/images/opportunity-Mechanical.png"
                                            alt="Internship" srcset="" height="45px">
                                    </div>
                                </a>
                                <a href="#"
                                    class="fs-lg-15 fs-13 color1 text-center mb-0 line-h-15 mt-10 fw-600 d-block">
                                    Electronics
                                    Mechanic
                                </a>
                            </div>
                            <div class="col-4 ">
                                <a href="#"
                                    class="bg-light-theme text-center  align-items-start border-radius-10 d-block  py-lg-3">
                                    <div class="h-70 d-flex justify-content-center align-items-center px-2 py-12">

                                        <img class="mb-0" src="../assets/images/opportunity-Website-coding.png"
                                            alt="Internship" srcset="" height="36px">
                                    </div>
                                </a>
                                <a href="#"
                                    class="fs-lg-15 fs-13 color1 text-center mb-0 line-h-15 mt-10 fw-600 d-block">
                                    Website
                                    Developer
                                </a>
                            </div>
                            <div class="col-4 ">
                                <a href="#"
                                    class="bg-light-theme text-center align-items-start border-radius-10 d-block  py-lg-3">
                                    <div class="h-70 d-flex justify-content-center align-items-center px-2 py-1">
                                        <img class="mb-0" src="../assets/images/opportunity-Mobile-Repairing.png"
                                            alt="Internship" srcset="" height="45px">
                                    </div>
                                </a>

                                <a href="#"
                                    class="fs-lg-15 fs-13 color1 text-center mb-0 line-h-15 mt-10 fw-600 d-block">
                                    Mobile
                                    Repairing
                                </a>
                            </div>
                        </div>
                    </div>

                    <section>
                        <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-lg-60 mt-50 mb-0 text-center">
                            Student Success Stories
                        </h2>

                        <div class="row mx-0 p-0 mt-lg-30">
                            <div class="col-md-6 mb-lg-0 mb-30 px-vs-0">
                                <div class="owl-carousel owl-theme student-testimonial h-100">
                                    <div class="item h-100">

                                        <div
                                            class="student-text-testimonial px-3 py-25 border-grey-light border-radius-10 h-100">

                                            <img src="assets/upload/testimonial-example.png" alt="" class="mb-10"
                                                srcset="">
                                            <p class="fs-17 text-black mb-5px fw-600 text-center mb-10">
                                                Rashmi
                                                Thakur
                                            </p>
                                            <p class="fs-13 fw-600 mb-10 text-center">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit. Sed vitae ipsum vitae felis uet dictum
                                                diam eget sagittis.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="item h-100">

                                        <div
                                            class="student-text-testimonial px-3 py-10 pb-0 py-lg-30 border-grey-light border-radius-10 h-100">

                                            <img src="assets/upload/testimonial-example.png" alt="" class="mb-10"
                                                srcset="">
                                            <p class="fs-17 text-black mb-5px fw-600 text-center mb-10">
                                                Rashmi
                                                Thakur
                                            </p>
                                            <p class="fs-13 mb-10 text-center">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit. Sed vitae ipsum vitae felis uet dictum
                                                diam eget sagittis.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 px-vs-0 cstm-dots-mob">
                                <div class="owl-carousel owl-theme student-testimonial h-100">
                                    <div class="item h-100">
                                        <div
                                            class="student-video-testimonial border-theme-2px border-radius-30  overflow-hidden h-100">

                                            <iframe class="fade-overlay border-radius-0" width="100%" height="220"
                                                src="https://www.youtube.com/embed/6ehIYXsNMXY?rel=0"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen>
                                                <p>jfbdjfdjfnfdb</p>
                                            </iframe>
                                            <p class="Video-heading px-15 py-15 mt-0 fs-17 fw-600 text-center">
                                                Multi Skill Vocational Education in Schools
                                            </p>
                                        </div>
                                    </div>

                                    <div class="item h-100">
                                        <div
                                            class="student-video-testimonial border-theme-2px border-radius-30  overflow-hidden h-100 ">

                                            <iframe class="fade-overlay border-radius-0" width="100%" height="220"
                                                src="https://www.youtube.com/embed/oi3QcHUeQqw?rel=0"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                            <p class="Video-heading px-15 py-15 mt-0 fs-17 fw-600 text-center">
                                                Skills on Wheels - in Himachal Pradesh
                                            </p>
                                        </div>
                                    </div>

                                    <div class="item h-100">
                                        <div
                                            class="student-video-testimonial border-theme-2px border-radius-30  overflow-hidden h-100">

                                            <iframe class="fade-overlay border-radius-0" width="100%" height="220"
                                                src="https://www.youtube.com/embed/6ehIYXsNMXY?rel=0"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen>
                                                <p>jfbdjfdjfnfdb</p>
                                            </iframe>
                                            <p class="Video-heading px-15 py-15 mt-0 fs-17 fw-600 text-center">
                                                Multi Skill Vocational Education in Schools
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section #point_2>
                        <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-lg-60 mt-70 mb-20 text-center">
                            Resources
                        </h2>
                        <div class="resources-section">

                            <div class="row mx-0 p-0 max-w-800 mx-auto">
                                <div class="col-md-6 col-6 px-1">
                                    <a href="/login">
                                        <div class=" px-10 py-20 study-material-block bg-light-theme h-100 border-radius-30"
                                            style=" background-image: url(assets/upload/Download-Study-Material.png);">
                                            <p class="fs-17 fw-600 mb-10 max-w-180" style="color: #212529;">
                                                Download Study Material
                                            </p>
                                            <div>
                                                <i class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mb-30"
                                                    href="/login"></i>
                                            </div>
                                        </div>
                                    </a>

                                </div>

                                <div class="col-md-6 col-6 px-1">
                                    <a href="/login">
                                        <div class=" px-10 py-20 video-tutorial-block bg-light-theme h-100 border-radius-30"
                                            style=" background-image: url(assets/upload/Watch-Video-Tutorials.png);">
                                            <p class="fs-17 fw-600 mb-10 max-w-180" style="color: #212529;">
                                                Watch Video
                                                Tutorials
                                            </p>
                                            <div>
                                                <i class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mb-30"
                                                    href="/login"></i>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-md-12 col-12  mb-lg-0 mt-20 px-1">
                                    <a href="/contact-us">

                                        <div class=" px-10 py-20 student-support-block bg-light-theme h-100 border-radius-30"
                                            style=" background-image: url(assets/upload/Student-Support.png);">
                                            <p class="fs-17 fw-600 mb-10 max-w-180" style="color: #212529;">
                                                Student Support
                                            </p>
                                            <div>
                                                <i class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35 mb-30"
                                                    href="/login"></i>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>