<section class="">
    <div class="container">

        <div class="content-section mt-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu></igmite-side-menu>


                </div>



                <div class="col-12 col-lg-10 right-content px-lg-35 py-18 pb-5 bg-white px-10">
                    <div class="max-w-800 mx-auto">
                        <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-40 mb-0 text-center">
                            Basic Rights
                        </h2>

                        <div *ngFor="let studentsRightItems of studentsRightModel; let i=index">
                            <div
                                *ngFor="let studentsRightViewItems of studentsRightItems.ResourcesViewModel; let i=index">
                                <div *ngIf="studentsRightItems.Category=='BasicRights' && studentsRightItems.ResourcesViewModel !=[]"
                                    class="py-15 px-15 border-radius-30 mt-20 border-theme-2px d-flex align-items-center max-w-420 mx-auto">

                                    <img src="assets/upload/Student-basic-rights.png" class="img-fluid mr-25" alt=""
                                        srcset="">
                                    <div>
                                        <p class="fs-17 text-black fw-900 mb-0 mb-0">
                                            {{studentsRightViewItems.ResourceTitle}}
                                        </p>
                                        <p class="fs-13 text-black mb-0 fw-600"
                                            [innerHTML]="studentsRightViewItems.ResourceDescription">
                                        </p>
                                        <a (click)="getStudentRightsDesc(studentsRightViewItems.ResourceId)" class="fs-14 color6 fw-600">Know More
                                            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-color-6"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="#000000" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round" height="22" width="18">

                                                <polyline points="9 6 15 12 9 18"></polyline>
                                            </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="py-15 px-10 border-radius-10 bg-light-theme border-theme-2px d-flex align-items-center mt-20 mb-30 max-w-420 mx-auto">
                            <p class="bg-color6 px-10 py-1 fs-17 text-white mb-0 fw-600 border-radius-2 mr-15">
                                Quiz?
                            </p>


                            <div class="d-flex justify-content-between align-items-center w-100">
                                <div>
                                    <p class="fs-17 text-black fw-600 mb-0 line-h-20">Students Rights Quiz</p>
                                    <p class="color2 fw-900 fs-13 mb-0">Get 25 Points </p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-color-6" viewBox="0 0 24 24"
                                    stroke-width="2" stroke="#000000" fill="none" stroke-linecap="round"
                                    stroke-linejoin="round" height="30" width="30">

                                    <polyline points="9 6 15 12 9 18"></polyline>
                                </svg>
                            </div>

                        </div>



                    </div>



                    <div class="max-w-800 mx-auto">

                        <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-40 mb-0 text-center">
                            Harassment
                        </h2>

                        <div *ngFor="let studentsRightItems of studentsRightModel; let i=index">
                            <div
                                *ngFor="let studentsRightViewItems of studentsRightItems.ResourcesViewModel; let i=index">
                                <div *ngIf="studentsRightItems.Category=='HarassmentRights' && studentsRightItems.ResourcesViewModel !=[]"
                                    class="py-15 px-15 border-radius-30 mt-20 border-theme-2px d-flex align-items-center max-w-420 mx-auto">

                                    <img src="assets/upload/safety-and-security-rights.png" class="img-fluid mr-25"
                                        alt="" srcset="">
                                    <div>
                                        <p class="fs-17 text-black fw-900 mb-0 mb-0">
                                            {{studentsRightViewItems.ResourceTitle}}
                                        </p>
                                        <p class="fs-13 text-black mb-0 fw-600"
                                            [innerHTML]="studentsRightViewItems.ResourceDescription">
                                        </p>
                                        <a (click)="getStudentRightsDesc(studentsRightViewItems.ResourceId)" class="fs-14 color6 fw-600">Know More
                                            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-color-6"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="#000000" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round" height="22" width="18">

                                                <polyline points="9 6 15 12 9 18"></polyline>
                                            </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="py-15 px-10 border-radius-10 bg-light-theme border-theme-2px d-flex align-items-center mt-20 mb-30 max-w-420 mx-auto">
                            <p class="bg-color6 px-10 py-1 fs-17 text-white mb-0 fw-600 border-radius-2 mr-15">
                                Quiz?
                            </p>


                            <div class="d-flex justify-content-between align-items-center w-100">
                                <div>
                                    <p class="fs-17 text-black fw-600 mb-0 line-h-20">Safety & Security Quiz</p>
                                    <p class="color2 fw-900 fs-13 mb-0">Get 100 Points </p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-color-6" viewBox="0 0 24 24"
                                    stroke-width="2" stroke="#000000" fill="none" stroke-linecap="round"
                                    stroke-linejoin="round" height="30" width="30">

                                    <polyline points="9 6 15 12 9 18"></polyline>
                                </svg>
                            </div>

                        </div>


                    </div>



                    <div class="max-w-800 mx-auto mb-5 pb-5">

                        <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-40 mb-0 text-center">
                            Workplace
                        </h2>

                        <div *ngFor="let studentsRightItems of studentsRightModel; let i=index">
                            <div
                                *ngFor="let studentsRightViewItems of studentsRightItems.ResourcesViewModel; let i=index">
                                <div *ngIf="studentsRightItems.Category=='Workplace' && studentsRightItems.ResourcesViewModel !=[]"
                                    class="py-15 px-15 border-radius-30 mt-20 border-theme-2px d-flex align-items-center max-w-420 mx-auto">

                                    <img src="assets/upload/Dos-Donts-rights.png" class="img-fluid mr-25" alt=""
                                        srcset="">
                                    <div>
                                        <p class="fs-17 text-black fw-900 mb-0 mb-0">
                                            {{studentsRightViewItems.ResourceTitle}}
                                        </p>
                                        <p class="fs-13 text-black mb-0 fw-600"
                                            innerHTML="{{studentsRightViewItems.ResourceDescription}}">
                                        </p>
                                        <a (click)="getStudentRightsDesc(studentsRightViewItems.ResourceId)" class="fs-14 color6 fw-600">Know More
                                            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-color-6"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="#000000" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round" height="22" width="18">

                                                <polyline points="9 6 15 12 9 18"></polyline>
                                            </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>




                    <div>





                    </div>
                </div>






            </div>
        </div>
    </div>

</section>