import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, forkJoin, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class ResourcesService {


    constructor(private http: BaseService) { }


    GetAllResources(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Resources.GetAll, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }


    GetResources(): Observable<any> {
        let videoItems = {
            "Name": "Videos",
            "PageIndex": 0,
            "PageSize": 100
        }
        let videos = this.http
            .HttpPost(this.http.Services.Resources.GetAll, videoItems)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );


        let requestParams = {
            "Name": "StudyMaterial",
            "PageIndex": 0,
            "PageSize": 100
        }
        let studyMaterial = this.http
            .HttpPost(this.http.Services.Resources.GetAll, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
        // let studyMaterial = this.http.GetMasterDataByType({ DataType: 'Divisions', SelectTitle: 'Division' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([videos, studyMaterial]);
    }

}