import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { AppConstants } from 'app/app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';

import * as $ from 'jquery';
import { timeout } from 'rxjs/operators';
import { OpprtunitiesListingService } from '../opprtunities-listing/opprtunities-listing.service';
import { RouteConstants } from 'app/constants/route.constant';
import { DashboardService } from '../dashboard/dashboard.service';


@Component({
  providers: [AppConstants],
  selector: 'igmite-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SideMenuComponent extends BaseComponent<LoginModel> implements OnInit, AfterViewInit {
  public appInfo = environment;

  allowedData = false
  IsDirectMappingEnabled: any
  statusData: any
  dashboardData: any
  InternshipById: any
idT:any
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private opprtunitiesListingService: OpprtunitiesListingService,
    private dashboardService: DashboardService,

    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  
  }

  ngOnInit(): void {
    this.IsDirectMappingEnabled = this.UserModel.IsDirectMappingEnabled

    var data = {
      "DataId": this.UserModel.UserTypeId,
    }
    this.dashboardService.GetStudentDashboard(data).subscribe((dashboardResp: any) => {
      
      if (dashboardResp.Success == true) {

        if (dashboardResp.Results[0].StudentInternshipGuid) {
          var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
          currentUser.InternshipId = dashboardResp.Results[0].StudentInternshipGuid
          sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
          this.allowedData = true;
          var int = {
            DataId: this.UserModel.UserTypeId,
            DataId1 :  dashboardResp.Results[0].StudentInternshipGuid
          }
          this.new( int);
        } else {
          this.allowedData = false

        }

        this.IsLoading = false;



      }
      else {
        // this.showErrorMessage(
        //   'Invalid GetStudentDashboard ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });
    this.opprtunitiesListingService.GetStudentsInternshipApprovalStatus(data).subscribe((response: any) => {

    }, error => {
    });
    // this.allowedData = sessionStorage.getItem("allow");
    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });
    var proId = { DataId: this.UserModel.UserTypeId }

    this.dashboardService.GetStudentDashboard(proId).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.dashboardData = dashboardResp.Results[0]
        console.log(this.dashboardData)

        this.IsLoading = false;
      }
    }, error => {
    });
    var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    console.log(currentUser)
   
   
  
  }

new(int){
 
  if(int){
    this.dashboardService.GetStudentInternshipsById(int).subscribe((StutusResp: any) => {
      if (StutusResp.Success == true) {
        this.statusData = StutusResp.Result
        console.log(this.statusData)
        this.IsLoading = false;


      }

    }, error => {
    });
  }
}

  ngAfterViewInit() {


  }

  redirct() {
    this.router.navigateByUrl(RouteConstants.ContactUs1);
  }
}
