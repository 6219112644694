<section class="mb-lg-0 mb-10">
    <div class="container">
        <div class="content-section mt-lg-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu></igmite-side-menu>

                </div>

                <div class="col-md-9 col-lg-10 right-content px-lg-35 px-0 pb-5 bg-white py-18">


                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">

                        <div class="col-12 px-0 text-center">

                            <div class="user-profile-dash mt-lg-0">
                                <a href="/profile-upload">
                                    <img [src]="profileAll.PhotoUrl" alt="" srcset=""
                                        onError="assets/upload/insta-icon.png">
                                </a>

                            </div>
                        </div>

                    </div>


                    <div class="user-name text-center mt-10 max-w-800 mx-auto">
                        <h2 class="color2 fw-900 fs-28">{{profileAll.FullName}} </h2>
                    </div>


                    <div class="px-10 mt-20 max-w-800 mx-auto">
                        <div class="bg-light-theme px-20 py-25 border-radius-20">

                            <div class="d-flex row mx-0 px-0">
                                <div class="col-2 col-md-1 px-0">

                                    <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="30"
                                        viewBox="0 0 24 24" width="30" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path
                                            d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm1 17H7V4h10v14zm-3 3h-4v-1h4v1z" />
                                    </svg>
                                </div>
                                <div class="col-10 px-0">
                                    <p class="mb-0 line-h-10 fs-14 text-black fw-400">
                                        Mobile Number
                                    </p>

                                    <p class="mb-0 fs-18 text-black fw-900">
                                        {{profileAll.Mobile}}
                                    </p>
                                </div>
                            </div>


                            <div class="d-flex row mt-20 mx-0 px-0">
                                <div class="col-2 col-md-1 px-0">

                                    <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="30"
                                        viewBox="0 0 24 24" width="30" fill="#000000">
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path
                                            d="M21.99 8c0-.72-.37-1.35-.94-1.7L12 1 2.95 6.3C2.38 6.65 2 7.28 2 8v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2l-.01-10zM12 13L3.74 7.84 12 3l8.26 4.84L12 13z" />
                                    </svg>
                                </div>
                                <div class="col-10 px-0">
                                    <p class="mb-0 line-h-10 fs-14 text-black fw-400">
                                        Email ID
                                    </p>

                                    <p class="mb-0 fs-18 text-black fw-900">
                                        {{profileAll.EmailId}}

                                    </p>
                                </div>
                            </div>


                            <div class="d-flex row mt-20 mx-0 px-0">
                                <div class="col-2 col-md-1 px-0">
                                    <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                        enable-background="new 0 0 24 24" height="32px" viewBox="0 0 24 24" width="32px"
                                        fill="#000000">
                                        <g>
                                            <path d="M0,0h24v24H0V0z" fill="none" />
                                        </g>
                                        <g>
                                            <path
                                                d="M12,2c-4.2,0-8,3.22-8,8.2c0,3.32,2.67,7.25,8,11.8c5.33-4.55,8-8.48,8-11.8C20,5.22,16.2,2,12,2z M12,12c-1.1,0-2-0.9-2-2 c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C14,11.1,13.1,12,12,12z" />
                                        </g>
                                    </svg>

                                </div>
                                <div class="col-10 px-0">
                                    <p class="mb-0 line-h-10 fs-14 text-black fw-400">
                                        Home Address
                                    </p>

                                    <p class="mb-0 fs-18 text-black fw-900">
                                        {{profileAll.Address1}}

                                    </p>
                                </div>
                            </div>

                        </div>



                    </div>

                    <div class="px-10 mt-30 max-w-800 mx-auto">
                        <div
                            class="d-flex align-items-center border-theme-2px border-start-0 border-top-0 border-end-0 py-10 mb-15">

                            <svg class="fill-color-2 mr-10" xmlns="http://www.w3.org/2000/svg" height="24px"
                                viewBox="0 0 24 24" width="24px" fill="#000000">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                    d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z">
                                </path>
                            </svg>

                            <p class="mb-0 fs-18 color2 fw-900">
                                Internship Work
                            </p>


                        </div>

                        <div class="row mx-0 p-0  border-theme-2px border-radius-25 overflow-hidden px-15 py-15 mt-30"
                            *ngIf="intershipActive.length !=0">
                            <div class=" mb-0 d-flex justify-content-between align-items-center mt-0  pb-10"
                                style="padding-top: 15px;">
                                <div *ngFor="let inter of intershipActive">
                                    <p class="fs-16 text-black fw-900 mb-0"> {{inter.EmployerName}}</p>
                                    <p class="fs-12"> {{inter.SectorName}} </p>
                                </div>

                                <!-- <span
                                    class="border-grey-light border-radius-5  h-50px w-50 d-flex justify-content-center align-items-center"><img
                                        src="assets/upload/MLE-l0go.png" alt="MLE LOGO" class="img-fluid"
                                        srcset=""></span> -->
                            </div>


                            <!-- <span style="padding-bottom: 15px;">
                                <a href="../student/login.html"
                                    class="d-block px-3 color1 text-center max-w-400 border-radius-15 mx-auto mt-10 w-100 py-2 bg-light-theme fw-500 theme-btn line-h-30 fs-12 text-uppercase max-w-300">Download
                                    Project report</a>
                            </span> -->

                        </div>
                    </div>
                    <div class="px-0 mt-30 max-w-800 mx-auto">
                        <div
                            class="d-flex align-items-center border-theme-2px border-start-0 border-top-0 border-end-0 py-10 mb-15">

                            <svg class="fill-color-2 mr-10" xmlns="http://www.w3.org/2000/svg" height="24px"
                                viewBox="0 0 24 24" width="24px" fill="#000000">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                    d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z">
                                </path>
                            </svg>

                            <p class="mb-0 fs-18 color2 fw-900">
                                Gallery
                            </p>


                        </div>

                        <div class="row px-0 mx-0 mt-20 max-w-500">
                            <div class="col-4 px-1 mb-5px position-relative" *ngFor="let photo of studentPhoto">

                                <div class="selector-by-input">
                                    <div class="select-the-div position-relative">
                                        <label class="cstm-checkbox show-hide d-none  ">
                                            <input class="inp-cbx position-absolute" id="morning" type="checkbox">
                                            <label class="cbx" for="morning">

                                                <span>
                                                    <i class="fa-solid fa-check "></i></span>

                                            </label>
                                        </label>

                                        <div
                                            *ngIf="photo.AttachmentType =='Image' ">

                                            <a *ngIf="photo.SectionType !='DailyDiary'"
                                            class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0 vbox-item"
                                            href="{{FinalInternshipImageBaseURL+photo.AttachmentPath}}"
                                            data-gall="myGallery">


                                            <img class="w-100 h-80 object-fit-cover"
                                                [src]="FinalInternshipImageBaseURL+photo.AttachmentPath" alt=""
                                                srcset="">
                                            <svg class="fill-color-2 mr-10 position-absolute top-0 start-0 m-1 "
                                                xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                                height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                                                <g>
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
                                                </g>
                                            </svg>

                                        </a>

                                            <!-- <a *ngIf="photo.SectionType=='IRBestPicture'"
                                                class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0 vbox-item"
                                                href="{{FinalInternshipImageBaseURL+photo.AttachmentPath}}"
                                                data-gall="myGallery">


                                                <img class="w-100 h-80 object-fit-cover"
                                                    [src]="FinalInternshipImageBaseURL+photo.AttachmentPath" alt=""
                                                    srcset="">
                                                <svg class="fill-color-2 mr-10 position-absolute top-0 start-0 m-1 "
                                                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                                    height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                                                    <g>
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
                                                    </g>
                                                </svg>

                                            </a>
                                            <a *ngIf="photo.SectionType=='IRCertificate'"
                                                class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0 vbox-item"
                                                href="{{FinalInternshipImageBaseURL+photo.AttachmentPath}}"
                                                data-gall="myGallery">


                                                <img class="w-100 h-80 object-fit-cover"
                                                    [src]="FinalInternshipImageBaseURL+photo.AttachmentPath" alt=""
                                                    srcset="">
                                                <svg class="fill-color-2 mr-10 position-absolute top-0 start-0 m-1 "
                                                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                                    height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                                                    <g>
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
                                                    </g>
                                                </svg>

                                            </a>
                                            <a *ngIf="photo.SectionType=='IRRportPhotos'"
                                                class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0 vbox-item"
                                                href="{{FinalInternshipImageBaseURL+photo.AttachmentPath}}"
                                                data-gall="myGallery">


                                                <img class="w-100 h-80 object-fit-cover"
                                                    [src]="FinalInternshipImageBaseURL+photo.AttachmentPath" alt=""
                                                    srcset="">
                                                <svg class="fill-color-2 mr-10 position-absolute top-0 start-0 m-1 "
                                                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                                    height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                                                    <g>
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
                                                    </g>
                                                </svg>

                                            </a> -->
                                            <a *ngIf="photo.SectionType=='DailyDiary'"
                                                class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0 vbox-item"
                                                href="{{imageAttachments+photo.AttachmentPath}}" data-gall="myGallery">


                                                <img class="w-100 h-80 object-fit-cover"
                                                    [src]="imageAttachments+photo.AttachmentPath" alt="" srcset="">
                                                <svg class="fill-color-2 mr-10 position-absolute top-0 start-0 m-1 "
                                                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                                    height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                                                    <g>
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
                                                    </g>
                                                </svg>

                                            </a>



                                        </div>
                                        <div *ngIf="photo.AttachmentType =='Video'">



                                            <a class="venobox mx-auto d-block w-100 border-radius-0 vbox-item"
                                                data-autoplay="true" data-vbtype="inline" href="#gallery-video-1"
                                                type="video/mp4">

                                                <div class="position-relative">
                                                    <span
                                                        class="fs-13 text-white mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600"><i
                                                            class="fa-solid fa-circle-play"></i> <span
                                                            class="video-timing">02:00</span> </span>
                                                    <img class="w-100 h-80 object-fit-cover"
                                                        src="assets/upload/gallery-video-1.png" alt="" srcset="">
                                                </div>
                                                <svg class="fill-color-2 mr-10 position-absolute top-0 start-0 m-1 "
                                                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                                    height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                                                    <g>
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
                                                    </g>
                                                </svg>


                                            </a>


                                            <div class="video-caller" id="gallery-video-1" hidden="">
                                                <video class="w-100 h-100" controls="" width="100%" height="100%">

                                                    <source src="assets/upload/sample-video.mp4" type="video/mp4">
                                                    <source src="assets/upload/sample-video.mp4" type="video/ogg">

                                                </video>

                                            </div>



                                        </div>
                                        <div *ngIf="photo.AttachmentType =='Document'">



                                            <a class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0 vbox-item"
                                                href="{photo.AttachmentPath}" data-gall="myGallery">


                                                <img class="w-100 h-80 object-fit-cover" [src]="photo.AttachmentPath"
                                                    alt="" srcset="">
                                                <svg class="fill-color-2 mr-10 position-absolute top-0 start-0 m-1 "
                                                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                                    height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                                                    <g>
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
                                                    </g>
                                                </svg>

                                            </a>



                                        </div>
                                        <div *ngIf="photo.AttachmentType =='PDF'">



                                            <a class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0 vbox-item"
                                                href="{photo.AttachmentPath}" data-gall="myGallery">


                                                <img class="w-100 h-80 object-fit-cover" [src]="photo.AttachmentPath"
                                                    alt="" srcset="">
                                                <svg class="fill-color-2 mr-10 position-absolute top-0 start-0 m-1 "
                                                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                                    height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                                                    <g>
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
                                                    </g>
                                                </svg>

                                            </a>



                                        </div>

                                    </div>
                                </div>



                            </div>

                            <!-- <div class="col-4 px-1 mb-5px">
                                <div class="selector-by-input">
                                    <div class="select-the-div position-relative">
                                        <label class="cstm-checkbox show-hide d-none  ">
                                            <input class="inp-cbx position-absolute" id="gallery-2" type="checkbox">
                                            <label class="cbx" for="gallery-2">

                                                <span>
                                                    <i class="fa-solid fa-check "></i></span>

                                            </label></label>

                                        <div>



                                            <a class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0 vbox-item"
                                                href="assets/upload/gallery-image-2.png" data-gall="myGallery">


                                                <img class="w-100 h-80 object-fit-cover"
                                                    src="assets/upload/gallery-image-2.png" alt="" srcset="">
                                                <svg class="fill-color-2 mr-10 position-absolute top-0 start-0 m-1 "
                                                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                                    height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                                                    <g>
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
                                                    </g>
                                                </svg>


                                            </a>



                                        </div>



                                    </div>
                                </div>


                            </div> -->




                            <!-- <div class="col-4 px-1 mb-5px">



                                <div class="selector-by-input">
                                    <div class="select-the-div position-relative">
                                        <label class="cstm-checkbox show-hide d-none  ">
                                            <input class="inp-cbx position-absolute" id="gallery2" type="checkbox">
                                            <label class="cbx" for="gallery2">

                                                <span>
                                                    <i class="fa-solid fa-check "></i></span>

                                            </label></label>

                                        <div>



                                            <a class="venobox mx-auto d-block w-100 border-radius-0 vbox-item"
                                                data-autoplay="true" data-vbtype="inline" href="#gallery-video-1"
                                                type="video/mp4">

                                                <div class="position-relative">
                                                    <span
                                                        class="fs-13 text-white mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600"><i
                                                            class="fa-solid fa-circle-play"></i> <span
                                                            class="video-timing">02:00</span> </span>
                                                    <img class="w-100 h-80 object-fit-cover"
                                                        src="assets/upload/gallery-video-1.png" alt="" srcset="">
                                                </div>
                                                <svg class="fill-color-2 mr-10 position-absolute top-0 start-0 m-1 "
                                                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                                    height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
                                                    <g>
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
                                                    </g>
                                                </svg>


                                            </a>

                                            
                                            <div class="video-caller" id="gallery-video-1" hidden="">
                                                <video class="w-100 h-100" controls="" width="100%" height="100%">

                                                    <source src="assets/upload/sample-video.mp4" type="video/mp4">
                                                    <source src="assets/upload/sample-video.mp4" type="video/ogg">

                                                </video>

                                            </div>
                                          


                                        </div>



                                    </div>
                                </div>


                            </div>  -->

















                        </div>

                    </div>

                    <div class="px-10 mt-30 max-w-800 mx-auto">
                        <div
                            class="d-flex align-items-center border-theme-2px border-start-0 border-top-0 border-end-0 py-10 mb-15">
                            <svg class="fill-color-2 mr-10" xmlns="http://www.w3.org/2000/svg"
                                enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px"
                                fill="#000000">
                                <g>
                                    <rect fill="none" height="27" width="27" />
                                    <path
                                        d="M16,4c0-1.11,0.89-2,2-2s2,0.89,2,2s-0.89,2-2,2S16,5.11,16,4z M20,22v-6h2.5l-2.54-7.63C19.68,7.55,18.92,7,18.06,7h-0.12 c-0.86,0-1.63,0.55-1.9,1.37l-0.86,2.58C16.26,11.55,17,12.68,17,14v8H20z M12.5,11.5c0.83,0,1.5-0.67,1.5-1.5s-0.67-1.5-1.5-1.5 S11,9.17,11,10S11.67,11.5,12.5,11.5z M5.5,6c1.11,0,2-0.89,2-2s-0.89-2-2-2s-2,0.89-2,2S4.39,6,5.5,6z M7.5,22v-7H9V9 c0-1.1-0.9-2-2-2H4C2.9,7,2,7.9,2,9v6h1.5v7H7.5z M14,22v-4h1v-4c0-0.82-0.68-1.5-1.5-1.5h-2c-0.82,0-1.5,0.68-1.5,1.5v4h1v4H14z" />
                                </g>
                            </svg>

                            <p class="mb-0 fs-18 color2 fw-900">
                                Parents Details
                            </p>


                        </div>

                        <div>
                            <div class="row mx-0 p-0">
                                <div class="col-6 px-0 mb-15">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        Father’s Name
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{profileAll.FatherName}}

                                    </p>
                                </div>

                                <div class="col-6 px-0 mb-15">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        Mobile Number
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{profileAll.FatherMobile}}

                                    </p>
                                </div>

                                <div class="col-6 px-0 mb-15">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        Mother’s Name
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{profileAll.MotherName}}

                                    </p>
                                </div>

                                <div class="col-6 px-0 mb-15">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        Mobile Number
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{profileAll.MotherMobile}}

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="px-10 mt-30 max-w-800 mx-auto" *ngIf="IsDirectMappingEnabled == false ">
                        <div *ngIf="interestsByStudent.length !=0 "
                            class="d-flex align-items-center border-theme-2px border-start-0 border-top-0 border-end-0 py-10 mb-15">
                            <svg class="fill-color-2 mr-10" xmlns="http://www.w3.org/2000/svg"
                                enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px"
                                fill="#000000">
                                <g>
                                    <rect fill="none" height="27" width="27" />
                                    <path
                                        d="M16,4c0-1.11,0.89-2,2-2s2,0.89,2,2s-0.89,2-2,2S16,5.11,16,4z M20,22v-6h2.5l-2.54-7.63C19.68,7.55,18.92,7,18.06,7h-0.12 c-0.86,0-1.63,0.55-1.9,1.37l-0.86,2.58C16.26,11.55,17,12.68,17,14v8H20z M12.5,11.5c0.83,0,1.5-0.67,1.5-1.5s-0.67-1.5-1.5-1.5 S11,9.17,11,10S11.67,11.5,12.5,11.5z M5.5,6c1.11,0,2-0.89,2-2s-0.89-2-2-2s-2,0.89-2,2S4.39,6,5.5,6z M7.5,22v-7H9V9 c0-1.1-0.9-2-2-2H4C2.9,7,2,7.9,2,9v6h1.5v7H7.5z M14,22v-4h1v-4c0-0.82-0.68-1.5-1.5-1.5h-2c-0.82,0-1.5,0.68-1.5,1.5v4h1v4H14z" />
                                </g>
                            </svg>

                            <p class="mb-0 fs-18 color2 fw-900">
                                Selected Interests
                            </p>


                        </div>

                        <div>
                            <div class="row mx-0 p-0">
                                <div class="col-12 px-0 mb-15">
                                    <!-- <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        Interests Name
                                    </p> -->

                                    <span class="mb-0 fs-16 text-black fw-900"
                                        *ngFor="let interests of interestsByStudent;">
                                        <p class=""> &#x2022; {{interests.InterestName}}</p>
                                    </span>
                                    <!-- <ul class="mb-0 fs-16 text-black fw-900" *ngFor="let interests of interestsByStudent;">
                                        <li>{{interests.InterestName}}</li>
                                       
                                      </ul> -->
                                </div>

                                <div class="col-12 px-0 mb-15" *ngIf="interestsByStudent.length !=0 ">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        <a style="cursor:pointer ;" href="/interests"
                                            class="d-block px-3 text-center text-white max-w-420 border-radius-15 mx-auto mt-5px  w-100 py-10 fs-18 upto-down-gradient-clr-1 text-uppercase theme-btn box-shadow-light">Edit
                                            Interest</a>
                                    </p>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="px-10 mt-30 max-w-800 mx-auto">
                        <div
                            class="d-flex align-items-center border-theme-2px border-start-0 border-top-0 border-end-0 py-10 mb-15">
                            <svg class="fill-color-2 mr-10" xmlns="http://www.w3.org/2000/svg"
                                enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px"
                                fill="#000000">
                                <g>
                                    <rect fill="none" height="27" width="27"></rect>
                                    <path
                                        d="M16,4c0-1.11,0.89-2,2-2s2,0.89,2,2s-0.89,2-2,2S16,5.11,16,4z M20,22v-6h2.5l-2.54-7.63C19.68,7.55,18.92,7,18.06,7h-0.12 c-0.86,0-1.63,0.55-1.9,1.37l-0.86,2.58C16.26,11.55,17,12.68,17,14v8H20z M12.5,11.5c0.83,0,1.5-0.67,1.5-1.5s-0.67-1.5-1.5-1.5 S11,9.17,11,10S11.67,11.5,12.5,11.5z M5.5,6c1.11,0,2-0.89,2-2s-0.89-2-2-2s-2,0.89-2,2S4.39,6,5.5,6z M7.5,22v-7H9V9 c0-1.1-0.9-2-2-2H4C2.9,7,2,7.9,2,9v6h1.5v7H7.5z M14,22v-4h1v-4c0-0.82-0.68-1.5-1.5-1.5h-2c-0.82,0-1.5,0.68-1.5,1.5v4h1v4H14z">
                                    </path>
                                </g>
                            </svg>

                            <p class="mb-0 fs-18 color2 fw-900">
                                Academic Details
                            </p>


                        </div>

                        <div>
                            <div class="row mx-0 p-0">

                                <div class="col-12 px-0 mb-15">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        School Name
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{profileAll.schoolName}}

                                    </p>
                                </div>




                                <div class="col-6 px-0 mb-15">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        Class
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{profileAll.ClassName}}
                                    </p>
                                </div>

                                <div class="col-6 px-0 mb-15">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        Section
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{profileAll.SectionName}}
                                    </p>
                                </div>
                                <div class="col-12 px-0 mb-15">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        Vocational Subject
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{profileAll.VocationalSubject}}
                                    </p>
                                </div>
                                <div class="col-6 px-0 mb-15" *ngIf="VTDetails">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        Trainer Name
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{VTDetails.VTName}}

                                    </p>
                                </div>

                                <div class="col-6 px-0 mb-15" *ngIf="VTDetails">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        Trainer Number
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{VTDetails.VTMobile}}

                                    </p>
                                </div>
                                <div class="col-12 px-0 mb-15">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        School Contact Number
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{profileAll.SchoolPhoneNo}}
                                    </p>
                                </div>

                                <div class="col-12 px-0 mb-15">
                                    <p class="mb-0 line-h-20 fs-13 text-black fw-600">
                                        School Address
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        {{profileAll.SchoolAdress}}

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <p class="text-center fs-28 mt-20 color2 fw-900 max-w-800 mx-auto" *ngIf="!StudentID">All Good!</p>
                    <div class="px-10 max-w-800 mx-auto" *ngIf="!StudentID">
                        <a style="cursor:pointer ;"
                            class="d-block px-3 text-center text-white max-w-420 border-radius-15 mx-auto mt-5px  w-100 py-10 fs-18 upto-down-gradient-clr-1 text-uppercase theme-btn box-shadow-light"
                            (click)="NextPage()">continue</a>

                        <div
                            class="d-block px-3 text-center text-black max-w-420 mt-30 border-radius-15 mx-auto w-100 py-10 fs-14 fw-900 bg-light-theme">
                            Need Some Corrections <a href="/profile-detail-correction"
                                class="text-decoration-underline">Contact VT</a></div>
                    </div>
                    <div class="px-10 max-w-800 mx-auto" *ngIf="StudentID">
                        <button
                            class="max-w-400 w-100 mt-20 py-10 px-10 text-white fs-17 fw-500 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-15 box-shadow-hard mb-10 max-w-320">Download
                            Resume</button>


                        <!-- this below link will go back to the previous page -->

                        <a href="/home-employer" class="fs-15 mt-30 mb-10 color1 fw-900 d-block text-center">GO BACK</a>
                        <!-- this above link will go back to the previous page -->
                    </div>


                    <div>





                    </div>
                </div>
            </div>
        </div>
    </div>
</section>