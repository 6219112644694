

import { FuseUtils } from '@fuse/utils';

export class ProfileDetailCorrectionModel{
  
    RequestType: any;
    AuthUserId: string;
    IsActive: boolean;
    IsApproved: boolean;
    IsActionTaken: boolean;
    RequestFrom: string;
    TypeOfRequest: string;
    RequestTitle: string;
    RequestDesc: string;
   


    constructor(ProfileItem?: any) {
        ProfileItem = ProfileItem || {};
        this.AuthUserId = ProfileItem.AuthUserId    || '';
        this.IsActive =  true;
        this.IsApproved =  false;
        this.IsActionTaken =  false;
        this.RequestFrom =  ProfileItem.RequestFrom ||'';
        this.TypeOfRequest = ProfileItem.TypeOfRequest || 'Profile Correction Details';
        this.RequestTitle = ProfileItem.RequestTitle || 'Profile Correction Details';
        this.RequestDesc =  ProfileItem.RequestDesc ||'';
        this.RequestType = 0; // New
    }
}
