import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { InternshipListingMobileV2Service } from './internship-listing-mobile-v2.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';


import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { InternshipListingMobileV2Model } from './internship-listing-mobile-v2.model';
import { debug } from 'console';


@Component({
  providers: [AppConstants],
  selector: 'igmite-internship-listing-mobile-v2',
  templateUrl: './internship-listing-mobile-v2.component.html',
  styleUrls: ['./internship-listing-mobile-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class InternshipListingMobileV2Component extends BaseListComponent<InternshipListingMobileV2Model> implements OnInit {
  public interestsForm: FormGroup;
  public loginModel: LoginModel;
  public internshipListingMobileV2Model: InternshipListingMobileV2Model;

  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  selectedName: any = []
  InterestsData: any
  selectedId: any = [];
  selectedData: any = [];
  toggle = true;
  toggleLog = false;
  checked: boolean = false
  interestsByStudent: any
  currentOpeningData: any;
  ongoingInternshipData: any;
  //ongoingInternshipData = [] as any;
  ApiBaseUrlImage: any;
  InternshipStartDate: any;
  workingInterns: number;
  viewMoreIndex = 0;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private internshipListingMobileV2Service: InternshipListingMobileV2Service,
    private dialogService: DialogService,
    public zone: NgZone,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);


    this.internshipListingMobileV2Model = new InternshipListingMobileV2Model();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {
this.ApiBaseUrlImage=this.Constants.CommonImageUrl.Account
    let elementIn: any = document.querySelectorAll(".withinlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    let elementIn1: any = document.querySelectorAll(".withoutlogin");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    // let elementIn1: any = document.querySelectorAll(".withinlogin");
    // elementIn1.forEach(elementIn1 => {
    //   elementIn1.setAttribute('style', 'display: none !important');

    // });

    // let elementIn2: any = document.querySelectorAll(".withinlogin");
    // elementIn2.forEach(elementIn2 => {
    //   elementIn2.setAttribute('style', 'display: none !important');

    // });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    this.getCurrentInternshipDetailsForEmp();
    this.ongoingInternships();
  }

  getCurrentInternshipDetailsForEmp() {
    this.internshipListingMobileV2Service.getCurrentInternshipDetailsForEmp(this.UserModel.LoginId, 0, 1000).subscribe(response => {
      this.currentOpeningData = response.Results;
    });
  }

  viewMoreInternship() {
    this.viewMoreIndex++;
    this.ongoingInternships();
  }
  ongoingInternships() {
    // this.viewMoreIndex = (this.viewMoreIndex == undefined ? 0 : this.viewMoreIndex);
    let pageSize = (this.viewMoreIndex == 0 ? 10 : (this.viewMoreIndex + 1) * 10);
    this.internshipListingMobileV2Service.getOngoingInternshipsForEmployer(this.UserModel.LoginId, 0, pageSize).subscribe(response => {
      this.workingInterns = response.TotalResults;
      this.ongoingInternshipData = response.Results;

    });
  }
  GetInternshipId(internshipId) {
    sessionStorage.setItem('InternshipId', internshipId);
    if (internshipId) {
      this.router.navigateByUrl(RouteConstants.ApplicantListingV2);
    }
  }

  callStudentId(item) {
    sessionStorage.setItem('StudentID', item);
    if (item) {
      this.router.navigateByUrl(RouteConstants.ProfileMobile);
    }
  }
  redirectOnDiaryEntryPage(studentId, internshipId) {
    sessionStorage.setItem('StudentID', studentId);
    sessionStorage.setItem('InternshipId', internshipId)
    sessionStorage.setItem('EmployerRedirected', 'EmployerRedirected')
    if (studentId) {
      this.router.navigateByUrl(RouteConstants.DiaryEntryListing);
    }
  }
 



  onForInterestId(item) {

    if (item.isChecked == false) {
      if (this.selectedId.length == 3) {
        this.showActionMessage(
          'Interests -Select up to 3 ',
          this.Constants.Html.SuccessSnackbar
        );
      } else {
        item.isChecked = true
        this.selectedId.push({ InterestGuidList: item.InterestId })

      }

    }
    else if (item.isChecked == true) {

      item.isChecked = false
      let ind = this.selectedId.indexOf(item)
      this.selectedId.splice(ind, 1);
    }


  }









}
