
export class LoginVerifyModel {
    EmailID: string;
    EnteredOtp:string;
    RoleCode:string;
  

    constructor() {
        this.EmailID = '';
        this.EnteredOtp = '';
        this.RoleCode = '';
        
    }
}
