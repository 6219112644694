
<div class="container">

    <div class="content-section mt-0">

        <div class="px-lg-35 px-10 border-radius-30  pb-3 bg-white py-18 pb-5 overflow-hidden max-w-800 mx-auto">

            <section class="mt-20 position-relative mb-0">


                <div>
                    <img src="assets/upload/reset-circle.png" class="img-fluid d-block text-center mx-auto"
                        alt="Reset Password" srcset="">
                    <p class="fs-22 text-black fw-800 text-center  line-h-22 mt-15 mb-15"> Reset Password </p>
                </div>


                <div class="px-20 py-20 mt-40 text-black bg-light-theme border-radius-20">
                    <p class="fs-14 fw-500 text-black mb-0">We have sent one time password (OTP) on your
                        registered email ID and mobile number.</p>




                </div>

                <div class="border-theme-2px px-15 border-radius-20 py-40 mt-30 mb-5">

                    <div class="mb-15">
                        <p class="fs-16 text-black mb-0 px-1">Enter OTP</p>
                        <form name="resetotpForm" novalidate [formGroup]="resetotpForm">
                            <div class="input-with-icon position-relative">

                                <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                    <!-- <mat-label >User Id</mat-label> -->
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="fill-color-2 ml-10 start-0 start-0 position-absolute translate-middle-y top-50"
                                        height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                        <path
                                            d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z">
                                        </path>
                                    </svg>
                                    <input matInput name="enteredOtp" formControlName="EnteredOtp"
                                        class="border-radius-10 border-theme-2px fs-18 py-2 w-100 px-5" required>
                                    <mat-error *ngIf="resetotpForm.controls.EnteredOtp.invalid">
                                        {{getErrorMessage(resetotpForm, 'EnteredOtp')}}
                                    </mat-error>
                                </mat-form-field>


                            </div>
                            <div class="d-flex justify-content-end">
                                <p class="text-black fw-800 fs-15 mb-0 mr-5">{{display}} -</p>
                                <p  *ngIf= "display != '00:00' " class="text-end fs-14 fw-800 mb-0 text-decoration-underline color1"  > Resend OTP</p>
                                <p *ngIf= "display == '00:00'" class="text-end fs-14 fw-800 mb-0 text-decoration-underline color1" style="cursor: pointer;"  (click)="SendOTPEmail()"> Resend OTP</p>
                            </div>


                        </form>

                        <button
                            class="d-block px-3 text-white max-w-180 border-radius-15 mx-auto mt-30 w-100 py-2 upto-down-gradient-clr-1 theme-btn"
                            [disabled]="resetotpForm.invalid" (click)="validateUserAuth()">
                            Verify
                        </button>
                    </div>


                </div>




            </section>



        </div>

    </div>
</div>