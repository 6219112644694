<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu></igmite-side-menu>


                </div>

                <div class="col-12 col-lg-10 right-content px-lg-35 px-0 pb-5 bg-white py-18">
                    <div class="content-section mt-0">

                        <div
                            class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 overflow-hidden  max-w-800 mx-auto">

                            <section class="mt-20 position-relative mb-0">
                                <div id="tabs-design-2" class="filter-tabs px-10">
                                    <ul
                                        class="mx-auto p-0 py-0 row mb-0 d-flex filter-tabs-key justify-content-between align-items-center">
                                        <li
                                            class="col-6 p-0 h-100 d-flex border-grey-light-right justify-content-center align-items-center">
                                            <a class="fs-15 tabs-caller tabulour-links py-15 fw-700 color1 h-100 d-flex justify-content-center align-items-center"
                                                (click)="toggle('AllApplications')"
                                                [ngClass]="current === 'AllApplications' ? 'tabulous_active' : ''"
                                                title="" (click)="toggle('AllApplications');">



                                                <svg class="mr-10 fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                                    height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                    <path
                                                        d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
                                                </svg>

                                                Surveys</a>
                                        </li>
                                        <li class="col-6 p-0 h-100 d-flex justify-content-center align-items-center">
                                            <a class="fs-15 tabs-caller tabulour-links py-15  fw-700 color1 h-100 d-flex justify-content-center align-items-center"
                                                [ngClass]="current === 'Shortlisted' ? 'tabulous_active' : ''" title=""
                                                (click)="toggle('Shortlisted');">

                                                <svg class="mr-10 fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                                    height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path
                                                        d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9.29 16.29L6.7 13.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l5.88-5.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-6.59 6.59c-.38.39-1.02.39-1.41 0z" />
                                                </svg>





                                                Completed</a>
                                        </li>

                                    </ul>
                                </div>
                                <div id="tabs_container" class="filter-tabs-pannel px-0 mt-30 ">
                                    <div id="tabs-1" class="filter-tabs-slide max-w-420 mx-auto"
                                        *ngIf="current == 'AllApplications'">
                                        <div>


                                            <div class="mt-50">
                                                <img src="assets/upload/survey-image.png" alt="Survey"
                                                    class="img-fluid d-block text-center mx-auto" srcset="">

                                                <p
                                                    class="fs-18 fw-900 text-center px-10 text-black mb-20 line-h-25 mt-10">
                                                    Complete Surveys <br>
                                                    & Earn Points
                                                </p>
                                            </div>



                                        </div>
                                        <div>
                                            <p class="fs-14 text-black fw-400 mb-5px px-1 ">
                                                15 Jun 2022
                                            </p>
                                            <div
                                                class="border-radius-25 d-flex align-items-center px-10 py-10 cursor-pointer max-w-500 mx-auto mb-25 border-theme-2px">
                                                <div
                                                    class="d-flex justify-content-center align-content-center px-15 py-15 border-radius-20 mr-15 bg-theme-light-grey">
                                                    <div>
                                                        <p class="mb-0 fs-22 line-h-22 text-center color2 fw-900">
                                                            100</p>
                                                        <p class="mb-0 fs-12 text-center color2 fw-600">Points
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="mr-5">
                                                    <p class="fs-16 fw-900 mb-5px line-h-20 text-black">
                                                        Mid-Internship Survey</p>
                                                    <p class="fs-14 fw-900 mb-0 color1">Complete Survey</p>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <p class="fs-14 text-black fw-400 mb-5px px-1 ">
                                                05 Jun 2022
                                            </p>
                                            <div
                                                class="border-radius-25 d-flex align-items-center px-10 py-10 cursor-pointer max-w-500 mx-auto mb-25 border-theme-2px">
                                                <div
                                                    class="d-flex justify-content-center align-content-center px-15 py-15 border-radius-20 mr-15 bg-theme-light-grey">
                                                    <div>
                                                        <p class="mb-0 fs-22 line-h-22 text-center color2 fw-900">
                                                            100</p>
                                                        <p class="mb-0 fs-12 text-center color2 fw-600">Points
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="mr-5">
                                                    <p class="fs-16 fw-900 mb-5px line-h-20 text-black">Post
                                                        Internship Survey </p>
                                                    <p class="fs-14 fw-900 mb-0 color1">Complete Survey</p>
                                                </div>


                                            </div>
                                        </div>


                                        <div>
                                            <p class="fs-14 text-black fw-400 mb-5px px-1 ">
                                                20 Mar 2022
                                            </p>
                                            <div
                                                class="border-radius-25 d-flex align-items-center px-10 py-10 cursor-pointer max-w-500 mx-auto mb-25 border-theme-2px">
                                                <div
                                                    class="d-flex justify-content-center align-content-center px-15 py-15 border-radius-20 mr-15 bg-theme-light-grey">
                                                    <div>
                                                        <p class="mb-0 fs-22 line-h-22 text-center color2 fw-900">
                                                            100</p>
                                                        <p class="mb-0 fs-12 text-center color2 fw-600">Points
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="mr-5">
                                                    <p class="fs-16 fw-900 mb-5px line-h-20 text-black">Students
                                                        Feedback
                                                        Survey Form </p>
                                                    <p class="fs-14 fw-900 mb-0 color1">Complete Survey</p>
                                                </div>


                                            </div>
                                        </div>



                                        <div>
                                            <p class="fs-14 text-black fw-400 mb-5px px-1 ">
                                                02 Mar 2022
                                            </p>
                                            <div
                                                class="border-radius-25 d-flex align-items-center px-10 py-10 cursor-pointer max-w-500 mx-auto mb-25 border-theme-2px">
                                                <div
                                                    class="d-flex justify-content-center align-content-center px-15 py-15 border-radius-20 mr-15 bg-theme-light-grey">
                                                    <div>
                                                        <p class="mb-0 fs-22 line-h-22 text-center color2 fw-900">
                                                            100</p>
                                                        <p class="mb-0 fs-12 text-center color2 fw-600">Points
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="mr-5">
                                                    <p class="fs-16 fw-900 mb-5px line-h-20 text-black">Employer
                                                        Feedback
                                                        Survey </p>
                                                    <p class="fs-14 fw-900 mb-0 color1">Complete Survey</p>
                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                    <!--End tabs container-->
                                </div><!--End tabs-->


                            </section>



                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>