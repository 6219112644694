import { Injectable } from "@angular/core";
import { environment } from 'environments/environment';

@Injectable()
export class ServiceConstants {
    public BaseUrl: string = environment.ApiBaseUrl;
    public RetryServieNo: number = 0;

    public CommonService = {
        SaveLighthouseSettings: "Lighthouse/SaveLighthouseSettings"
    }

    public Interests = {
        GetInterestsByCriteria: "Interest/GetInterestsByCriteria",
        CreateStudentInterests: "StudentInterests/CreateStudentInterests",
        GetStudentsInternshipByFilter: "Internship/GetStudentsInternshipByFilter",
        GetStudentsInternshipByCriteria: "Internship/GetStudentsInternshipByCriteria",
        GetInternshipById: "Internship/GetInternshipById",
        GetInternship: "Internship/GetInternship",
        GetStudentInternshipsByCriteria: "StudentInternships/GetStudentInternshipsByCriteria",
        UpdateStudentInternshipsStatus: "StudentInternships/UpdateStudentInternshipsStatus",
        CreateStudentInternships: "StudentInternships/CreateStudentInternships",
        GetInterestsByStudentId: "StudentInterests/GetInterestsByStudentId",

    };

    public Home = {
        GetAllByCriteria: "Resources/GetResources",

    };
    public HomeEmp = {
        GetOngoingInternshipsForEmployer: "Employers/GetOngoingInternshipsForEmployer",
        GetCurrentInternshipDetailsForEmp: "Employers/GetCurrentInternshipDetailsForEmp",

    };

    public ApplicantListingV2 = {
        GetAllApplicantsForEmployer: "Employers/GetAllApplicantsForEmployer",
        GetShortlistedApplicantsForEmployer: "Employers/GetShortlistedApplicantsForEmployer",
        ShortlistApplicantByEmployer: "StudentInternships/ShortlistApplicantByEmployer",
        HireStudent: "StudentInternships/HireStudent",
        RejectApplicantByEmployer: "StudentInternships/RejectApplicantByEmployer",
    };

    public InterListing = {
        GetOngoingInternshipsForEmployer: "Employers/GetOngoingInternshipsForEmployer",
    };

    public PastInternship = {
        GetPastInternshipsForEmployer: "Employers/GetPastInternshipsForEmployer",
    };

    public InternshipDetail = {
        GetInternshipById: "Internship/GetInternshipById",
        GetWorkingInternDashboardByInternshipId: "Employers/GetWorkingInternDashboardByInternshipId",
    };
    public GetHelp = {
        CreateHiringHelpRequest: "Requests/CreateHiringHelpRequests",
    }

    public GratitudeListing = {
        GetVTApprovedEmployerGratitudeByCriteria: "Gratitude/GetVTApprovedEmployerGratitudeByCriteria",
    }

    public GratitudeCorner = {
        CreateOrUpdateGratitude: "Gratitude/CreateOrUpdateGratitude",
    }

    public Dashboard = {
        GetTopPerformerStudentList: "QuestionResponse/GetTopPerformerStudentList",
        GetStudentDashboard: "QuestionResponse/GetStudentDashboard",
        GetHighlightOne: "Question/GetQuestionsBySectionName?questionSectionName=Highlight",
        GetDailyDiary: "Question/GetQuestionsBySectionName?questionSectionName=DailyDiary",
        CreateQuestionResponse: "QuestionResponse/CreateQuestionResponse",
        CreateDailyDiaryEntry: "QuestionResponse/CreateDailyDiaryEntry",
        GetDailyDiaryEntries: "QuestionResponse/GetDailyDiaryEntries",
        CreateAttachments: "Attachments/CreateAttachments",
        GetVTByStudentId: "Students/GetVTByStudentId",
        CreateFinalInternshipReport: "Attachments/CreateFinalInternshipReport",
        GetFinalInternshipReport: "Attachments/GetFinalInternshipReport",
        GetStudentInternshipsById: "StudentInternships/GetStudentInternshipsById",
        GetStudentAttandanceDetails: "QuestionResponse/GetStudentAttandanceDetails",
        GetProfileAttachmentsByAccountId: "Attachments/GetProfileAttachmentsByAccountId",

    };

    public EmpCommentsForInternships = {
        CreateOrUpdateEmpCommentsForInternshipsDetails: "EmpCommentsForInternships/CreateOrUpdateEmpCommentsForInternshipsDetails",
    }

    public EmployerProfileInfo = {
        CreateOrUpdateEmployerProfileInfo: "Employers/UpdateEmployersBranchesDetails",
        CompanyLogoImg: 'Employers/UploadPhotoForemployer',
        RemoveCompanyLogoImg: 'Employers/RemovePhotoForemployer'
    }

    public MasterData = {
        GetAll: "Lighthouse/GetMasterDataByType",
        GetClassesByVTId: "Lighthouse/GetClassesByVTId",
        GetSectionsByVTClassId: "Lighthouse/GetSectionsByVTClassId",
        GetUnitsByClassAndModuleId: "Lighthouse/GetUnitsByClassAndModuleId",
        GetSessionsByUnitId: "Lighthouse/GetSessionsByUnitId",
        GetStudentsByClassIdForVT: "Lighthouse/GetStudentsByClassIdForVT",
        GetSchoolsByVCId: "Lighthouse/GetSchoolsByVCId",
        GetSchoolsByDRPId: "Lighthouse/GetSchoolsByDRPId",
        GetCourseModuleUnitSessions: "Lighthouse/GetCourseModuleUnitSessions",
        GetClassSectionsByVTId: "Lighthouse/GetClassSectionsByVTId",
        GetStudentsByVTId: "Lighthouse/GetStudentsByVTId",
        UploadExcelData: "DataUpload/UploadExcelData",
        GetDashboardData: "Lighthouse/GetDashboardData",
        GetDashboardCardData: "Lighthouse/GetDashboardCardData",
        GetSchoolVTPSectorsByUserId: "Lighthouse/GetSchoolVTPSectorsByUserId"
    };

    public DashboardGraphData = {
        GetDashboardSchoolChartData: "Dashboard/GetDashboardSchoolChartData",
        GetDashboardVocationalTrainersCardData: "Dashboard/GetDashboardVocationalTrainersCardData",
        GetDashboardJobRoleUnitsCardData: "Lighthouse/GetDashboardJobRoleUnitsCardData",
        GetDashboardStudentsCardData: "Dashboard/GetDashboardStudentsCardData",
        GetDashboardClassesCardData: "Dashboard/GetDashboardClassesCardData",
        GetDashboardGuestLectureChartData: "Dashboard/GetDashboardGuestLectureChartData",
        GetDashboardCourseMaterialChartData: "Lighthouse/GetDashboardCourseMaterialChartData",
        GetDashboardToolsAndEquipmentChartData: "Lighthouse/GetDashboardToolsAndEquipmentChartData",
        GetDashboardFieldVisitChartData: "Dashboard/GetDashboardFieldVisitChartData",
        GetDashboardVTAttendanceChartData: "Dashboard/GetDashboardVTAttendanceChartData",
        GetDashboardVCAttendanceChartData: "Lighthouse/GetDashboardVCAttendanceChartData",
        GetDashboardStudentAttendanceChartData: "Dashboard/GetDashboardStudentAttendanceChartData",
        GetDashboardSchoolVisitStatusChartData: "Lighthouse/GetDashboardSchoolVisitStatusChartData",
        GetDashboardIssueManagementStatusChartData: "Lighthouse/GetDashboardIssueManagementStatusChartData",
        GetDashboardIssueManagementChartData: "Lighthouse/GetDashboardIssueManagementChartData",
        GetDashboardSchoolVisitsByMonth: "Lighthouse/GetDashboardSchoolVisitsByMonth",
        GetDashboardSchoolVisitsByVTP: "Lighthouse/GetDashboardSchoolVisitsByVTP",
        GetDashboardCourseMaterialsDrilldownData: "Lighthouse/GetDashboardCourseMaterialsDrilldownData"
    }

    public CompareDashboardData = {
        GetCompareSchoolsData: "Dashboard/GetCompareSchoolsData",
        GetCompareCourseMaterialsData: "Dashboard/GetCompareCourseMaterialsData",
        GetCompareToolsAndEquipmentsData: "Dashboard/GetCompareToolsAndEquipmentsData",
        GetCompareStudentsData: "Dashboard/GetCompareStudentsData",
        GetCompareNewEnrolmentAndDropoutStudentsData: "Dashboard/GetCompareNewEnrolmentAndDropoutStudentsData",
        GetCompareGuestLecturesData: "/Dashboard/GetCompareGuestLecturesData",
        GetCompareFieldVisitsData: "/Dashboard/GetCompareFieldVisitsData",
        GetCompareTrainersData: "/Dashboard/GetCompareTrainersData",
        GetCompareCoordinatorsData: "/Dashboard/GetCompareCoordinatorsData",
        GetCompareVTVCReportingData: "/Dashboard/GetCompareVTVCReportingData"
    }

    public AcademicYear = {
        GetAll: "AcademicYear/GetAcademicYears",
        GetAllByCriteria: "AcademicYear/GetAcademicYearsByCriteria",
        GetById: "AcademicYear/GetAcademicYearById",
        CreateOrUpdate: "AcademicYear/CreateOrUpdateAcademicYearDetails",
        Delete: "AcademicYear/DeleteAcademicYearById"
    };

    public Account = {
        GetAll: "Account/GetAccounts",
        GetAllByCriteria: "Account/GetAccountsByCriteria",
        GetById: "Account/GetAccountById",
        CreateOrUpdate: "Account/CreateOrUpdateAccountDetails",
        SetFirstLogin: "Account/SetFirstLogin",
        UploadPhotoByAccountId: "Account/UploadPhotoByAccountId",
        GetStudentProfileConfirmationById: "Students/GetStudentProfileConfirmationById",
        CreateOrUpdateRequests: "Requests/CreateOrUpdateRequests",
        GetUserTransactionsByOtp: "OtpDetails/GetUserTransactionsByOtp",
        Delete: "Account/DeleteAccountById",
        ChangePassword: "Account/ChangePassword",
        ForgotPassword: "Lighthouse/ForgotPassword",
        ResetPassword: "Lighthouse/ResetPassword",
        ChangeLogin: "Account/ChangeUserLoginId",
        Allowed: "QuestionResponse/IsStudentPointsCollectionAllowed",
        GetInterestsByStudentId: "StudentInterests/GetInterestsByStudentId",
        GetStudentsInternshipApprovalStatus: "StudentInternships/GetStudentsInternshipApprovalStatus",
        GetEmployerProfileConfirmationById: "Employers/GetEmployerBranchDetails"
    };

    public Certificate = {
        GetCertificateByEmployerId: "Certificate/GetCertificateByEmployerId",
        GetCertificateByStudentId: "Certificate/GetCertificateByStudentId",
        GetCertificatePdf: "PDF/GetCertificatePdf",
    };

    public SurveyListing = {
        GetAllApplicantsForEmployer: "Employers/GetAllApplicantsForEmployer",
        GetShortlistedApplicantsForEmployer: "Employers/GetShortlistedApplicantsForEmployer",
        ShortlistApplicantByEmployer: "StudentInternships/ShortlistApplicantByEmployer",
        HireStudent: "StudentInternships/HireStudent",
        RejectApplicantByEmployer: "StudentInternships/RejectApplicantByEmployer",
    };

    public SurveyForm1 = {
        GetAllApplicantsForEmployer: "Employers/GetAllApplicantsForEmployer",
        GetShortlistedApplicantsForEmployer: "Employers/GetShortlistedApplicantsForEmployer",
        ShortlistApplicantByEmployer: "StudentInternships/ShortlistApplicantByEmployer",
        HireStudent: "StudentInternships/HireStudent",
        RejectApplicantByEmployer: "StudentInternships/RejectApplicantByEmployer",
    };
    public SurveyForm2 = {
        GetAllApplicantsForEmployer: "Employers/GetAllApplicantsForEmployer",
        GetShortlistedApplicantsForEmployer: "Employers/GetShortlistedApplicantsForEmployer",
        ShortlistApplicantByEmployer: "StudentInternships/ShortlistApplicantByEmployer",
        HireStudent: "StudentInternships/HireStudent",
        RejectApplicantByEmployer: "StudentInternships/RejectApplicantByEmployer",
    };

    public ContactUs = {
        GetPathwayOfficeDetails: "ContactUs/GetPathwayOfficeDetails",
        GetContactUs: "ContactUs/GetContactUs",
        CreateContactUs: "ContactUs/CreateContactUs",
        SetIsActionTaken: "ContactUs/SetIsActionTaken",

    };

    public Gallery = {
        GetAttachmentsByAccountId: "Attachments/GetAttachmentsByAccountId",
        GetGalleryAttachments: "Attachments/GetGalleryAttachments",
        SetIsAddedInProfile: "Attachments/SetIsAddedInProfile",
        RemoveIsAddedInProfile: "Attachments/RemoveIsAddedInProfile",
        GetBase64: "Attachments/GetBase64",
        GetProfileAttachmentsByAccountId: "Attachments/GetProfileAttachmentsByAccountId"
    };

    public InternshipOpportunity = {
        GetAll: "Internship/GetInternship",
        GetAllByCriteria: "Internship/GetInternshipByCriteria",
        GetById: "Internship/GetInternshipById",
        CreateOrUpdate: "Internship/CreateOrUpdateInternship",
        Delete: "Internship/DeleteInternshipById",
        IsCompleted: "Internship/SetIsCompleted",
        PublishInternship: "/Internship/PublishInternship",
        GetUnPublishedInternshipsForEmployer: "Employers/GetUnPublishedInternshipsForEmployer",

    };

    public Notices = {
        GetNoticesByType: "Notices/GetNoticesByVT",
    };

    public Internship3 = {
        GetStudentDashboardInternshipData: "QuestionResponse/GetStudentDashboardInternshipData",
        GetDailyDiaryEntryByStudentId: "QuestionResponse/GetDailyDiaryEntryByStudentId",
        GetEmployerBranchDetails: "Students/GetVTByStudentId",
        GetStudentProfileConfirmationById: "Students/GetStudentProfileConfirmationById",
        GetTopPerformerStudentList: "QuestionResponse/GetTopPerformerStudentList",
        GetLatestHighlight: "QuestionResponse/GetLatestHighlight",

    };

    public Resources = {
        GetAll: "Resources/GetResourcesByType",
        GetAllByCriteria: "Resources/GetResourcesByTypeAndCategory",
        GetResourcesById: "Resources/GetResourcesById",
    };






};
