import { FuseUtils } from '@fuse/utils';

export class GratitudeCornerModel {
    AuthUserId: string;
    RequestType: any;

    IsActive: boolean;
    GratitudeId: string;
    StudentId: string;
    GratitudeVTId: string;
    GratitudeEmployerBranchId: string;
    GratitudeDate: Date;
    GratitudeDesc: string;
    IsGratitudeForVT: boolean;
    IsAccepted: boolean
    IsRejected: boolean;

    constructor(getHelpItem?: any) {
        getHelpItem = getHelpItem || {};
        this.AuthUserId = getHelpItem.AuthUserId || '';
        this.RequestType = 0;
        this.IsActive = getHelpItem.IsActive || true;
        this.GratitudeId = getHelpItem.GratitudeId || FuseUtils.NewGuid();
        this.StudentId = getHelpItem.StudentId || '';
        this.GratitudeVTId = getHelpItem.GratitudeVTId || '';
        this.GratitudeEmployerBranchId = getHelpItem.GratitudeEmployerBranchId || '';
        this.GratitudeDate = getHelpItem.GratitudeDate || '';
        this.GratitudeDesc = getHelpItem.GratitudeDesc || '';
        this.IsGratitudeForVT = getHelpItem.IsGratitudeForVT || false;
        this.IsAccepted = getHelpItem.IsAccepted || false;
        this.IsRejected = getHelpItem.IsRejected || '';
    }
}
