import { FuseUtils } from '@fuse/utils';

export class GetHelpModel {
    AuthUserId: string;
    RequestType: any;
    IsReadonly: boolean;
    IsActive: boolean;
    RequestId: string;
    RequestFrom: string;
    TypeOfRequest: string;
    RequestedDate: Date;
    RequestTitle: string;
    RequestDesc: string;
    IsApproved: boolean;
    IsActionTaken: boolean
    RequestAssignedTo: string;
    RequestResolvedDate: Date;
    RequestContentId: string;


    constructor(getHelpItem?: any) {
        getHelpItem = getHelpItem || {};
        this.AuthUserId = getHelpItem.AuthUserId || '';
        this.RequestType = 0;
        this.IsReadonly = getHelpItem.IsReadonly || false;
        this.IsActive = getHelpItem.IsActive || true;
        this.RequestId = getHelpItem.RequestId || FuseUtils.NewGuid();
        this.RequestFrom = getHelpItem.RequestFrom || '';
        this.TypeOfRequest = getHelpItem.TypeOfRequest || '';
        this.RequestedDate = getHelpItem.RequestedDate || '';
        this.RequestTitle = getHelpItem.RequestTitle || '';
        this.RequestDesc = getHelpItem.RequestDesc || '';
        this.IsApproved = getHelpItem.IsApproved || false;
        this.IsActionTaken = getHelpItem.IsActionTaken || false;
        this.RequestAssignedTo = getHelpItem.RequestAssignedTo || '';
        this.RequestResolvedDate = getHelpItem.RequestResolvedDate || '';
        this.RequestContentId = getHelpItem.RequestContentId || '';
    }
}
