import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class InterListingService {


    constructor(private http: BaseService) { }

    getOngoingInternshipsForEmployer(userId: string, pageIndex: number, pageSize: number): Observable<any> {
        let requestParams = {
            UserId: userId,
            pageIndex: pageIndex,
            pageSize: pageSize
        };
        return this.http
            .HttpPost(this.http.Services.InterListing.GetOngoingInternshipsForEmployer, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

}
