import { Component, OnInit, ViewEncapsulation, NgZone, Input, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';

import { BaseListComponent } from 'app/common/base-list/base.list.component';


import * as $ from 'jquery';

import { DialogService } from 'app/common/confirm-dialog/dialog.service';
//import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
//import { AttendanceV2Model } from './attendance-v2.model';
import { MatCalendar, MatCalendarCellCssClasses, MatDatepicker, MatDatepickerModule } from "@angular/material/datepicker";
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { CertificateListingModel } from './certificate-listing.model';
import { CertificateListingService } from './certificate-listing.service';
import { CertificateService } from '../certificate/certificate.service';




@Component({
  providers: [AppConstants],
  selector: 'igmite-certificate-listing',
  templateUrl: './certificate-listing.component.html',
  styleUrls: ['./certificate-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CertificateListingComponent extends BaseComponent<LoginModel> implements OnInit {
  public attendanceV2Form: FormGroup;
  public loginModel: LoginModel;
  //public attendanceV2Model: AttendanceV2Model;
  certificateListingModel: CertificateListingModel;

  selectedId: any = [];
  selectedData: any = [];
  // toggle = true;
  toggleLog = false;
  checked: boolean = false;
  interestsByStudent: any;
  current = 'AllApplications';

  allApplicationData: any;
  shartlistedStudentData: any;
  totalHired: number;
  profileDetails: any
  ApiBaseUrlImage: string = this.Constants.CommonImageUrl.Account;
  InternshipById = this.UserModel.InternshipId
  InternshipId: string;
  internshipDetails: any = {};
  selected: Date | null;
  selectedDate = new Date();
  todayDate: Date;
  DailyDiary: string;
  ActiveTab = 'Close'
  ActiveTabCancle = 'open'
  flag: any;
  listDiaryEntry: any;

  endDate: any;
  startDate: any;
  selecteAttendanceDate: any;
  endAttendanceDate: string;
  startAttendanceDate: string;
  listStudentAttandance: any;
  totalDays: any;
  public studAttandanceDates: any = [];
  hoursSpent: any;
  filesArray: any[];
  studentId: any;
  public studAttandanceDates1: any[];
  @ViewChild("calendar", { static: false }) calendar: MatCalendar<Date>;
  getInternshipId: string;
  getStudentId: string;
  ApiBaseUrlCertificateImage: string = this.Constants.CommonImageUrl.CertificateImage;

  IsDiaryEntry: string;
  certificateListingData = [] as any;
  certificateListingMsg: string;
  DownloadCertificatePath: string = this.Constants.CommonImageUrl.CertificatePDF;
  certificatePdf: string;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private certificateService: CertificateService,
    private certificateListingService: CertificateListingService,
    private dialogService: DialogService,
    private elRef: ElementRef,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private renderer: Renderer2) {
    super(commonService, router, routeParams, snackBar);
    this.certificateListingModel = new CertificateListingModel();

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    let certificateParams = {
      EmployerGuid: this.UserModel.EmployerId,//"900f5c8c-cbc0-4962-a3f2-d3b2d682aa9b",//
      PageIndex: 0,
      PageSize: 1000
    }


    this.certificateListingService.GetCertificateByEmployerId(certificateParams).subscribe((profileResp: any) => {
      if (profileResp.Success == true) {
        this.zone.run(() => {
          if (profileResp.Results.length != 0 && profileResp.Results.length > 0) {
            this.certificateListingData = profileResp.Results;

          }
          else {
            this.certificateListingMsg = "No certificate List available for now! ";

          }

          //this.empProfileDetails.PhotoUrl = this.Constants.CommonImageUrl.Employer + this.empProfileDetails.PhotoUrl;

        });

      }



    }, error => {
    });




  }

  downloadCertificate(certificateId: string) {
    // "08db2492-05fa-f93b-a5e6-c54daa9fa925"
    this.certificateService.GetCertificatePdf(certificateId).subscribe(response => {
      this.certificatePdf = response.Result;
      this.DownloadCertificatePath + this.certificatePdf;
      let xxx = document.createElement("a");
      xxx.download = this.DownloadCertificatePath + this.certificatePdf;
      xxx.href = this.DownloadCertificatePath + this.certificatePdf;
      xxx.target = "_blank";
      xxx.click();
    });
  }




}
