import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { UnpublishedIntershipsService } from './unpublished-interships.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';


import { UnpublishedIntershipsModel } from './unpublished-interships.model';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DatePipe } from '@angular/common'
import { ViewportScroller } from "@angular/common";

@Component({
  providers: [AppConstants],
  selector: 'igmite-unpublished-interships',
  templateUrl: './unpublished-interships.component.html',
  styleUrls: ['./unpublished-interships.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class UnpublishedIntershipsComponent extends BaseListComponent<UnpublishedIntershipsModel> implements OnInit {
  public internshipOpportunityForm: FormGroup;
  public loginModel: LoginModel;
  public unpublishedIntershipsModel: UnpublishedIntershipsModel;
  isPublished: boolean = false;
  public internshipOpportunityFormEdit: FormGroup;

  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  selectedName: any = []
  InterestsData: any
  selectedId: any = [];
  selectedData: any = [];
  toggle = true;
  toggleLog = false;
  checked: boolean = false
  interestsByStudent: any
  sectorList: any;
  employerList: any;
  filteredEmployerItems: any;
  distList: any;
  filteredDistrictItems: any;
  academicYearList: any;
  branchList: any;
  interestList: any;
  currentAcademicYearId: any;
  UnPublishedInternshipsForEmployer: any
  tabView: boolean = false
  tabActive: any
  tabInter = 'Add'
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private unpublishedIntershipsService: UnpublishedIntershipsService,
    private dialogService: DialogService,
    public zone: NgZone,
    public datepipe: DatePipe,
    private scroller: ViewportScroller,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);


    this.unpublishedIntershipsModel = new UnpublishedIntershipsModel();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };
    this.internshipOpportunityForm = this.createInternshipOpportunityForm();
    this.internshipOpportunityFormEdit = this.editInternshipOpportunityForm();


  }

  ngOnInit(): void {
    // let elementIn: any = document.querySelectorAll(".withoutlogin");
    // elementIn.forEach(elementIn => {
    //   elementIn.setAttribute('style', 'display: none !important');

    // });

    let elementIn1: any = document.querySelectorAll(".withoutlogin");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    let elementIn2: any = document.querySelectorAll(".withinlogin");
    elementIn2.forEach(elementIn2 => {
      elementIn2.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    this.unpublishedIntershipsService.getDropdownforInternshipOpportunity(this.UserModel).subscribe((results) => {
      if (results[0].Success) {
        this.sectorList = results[0].Results;
      }

      if (results[1].Success) {
        this.employerList = results[1].Results;
        this.filteredEmployerItems = this.employerList.slice();
      }

      if (results[2].Success) {
        this.distList = results[2].Results;
        this.filteredDistrictItems = this.distList.slice();
      }

      // if (results[3].Success) {
      //   this.stateList = results[3].Results;
      // }
      if (results[3].Success) {
        this.academicYearList = results[3].Results;
      }

      if (results[4].Success) {
        this.branchList = results[4].Results;
      }

      if (results[5].Success) {
        this.interestList = results[5].Results;
      }

      let currentYearItem = this.academicYearList.find(ay => ay.IsSelected == true)
      if (currentYearItem != null) {
        this.currentAcademicYearId = currentYearItem.Id;
      }
      this.internshipOpportunityForm = this.createInternshipOpportunityForm();

      // this.route.paramMap.subscribe(params => {

      //   if (params.keys.length > 0) {
      //     this.PageRights.ActionType = params.get('actionType');

      //     if (this.PageRights.ActionType == this.Constants.Actions.New) {
      //       this.unpublishedIntershipsModel = new UnpublishedIntershipsModel();



      //     } else {
      //       var InternshipId: string = params.get('InternshipId');

      //       this.unpublishedIntershipsService.getInternshipById(InternshipId)
      //         .subscribe((response: any) => {
      //           this.unpublishedIntershipsModel = response.Result;

      //           if (this.PageRights.ActionType == this.Constants.Actions.Edit)
      //             this.unpublishedIntershipsModel.RequestType = this.Constants.PageType.Edit;
      //           else if (this.PageRights.ActionType == this.Constants.Actions.View) {
      //             this.unpublishedIntershipsModel.RequestType = this.Constants.PageType.View;
      //             this.PageRights.IsReadOnly = true;
      //           }
      //           this.onChangeEmployeer(this.unpublishedIntershipsModel.EmployerId);
      //           this.internshipOpportunityForm = this.createInternshipOpportunityForm();
      //         });
      //     }
      //   }
      // });
    });

    this.GetUnPublishedInternshipsForEmployer()


  }

  onChangeEmployeer(EmployeerId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'EmpBranch', ParentId: EmployeerId, SelectTitle: 'Employer Branch' }).subscribe((response: any) => {

      this.branchList = response.Results;
    });
  }

  GetUnPublishedInternshipsForEmployer() {
    var data = {
      UserId: this.UserModel.LoginId,
      // UserId:'5196872d-e13a-42bc-b797-79b285df69fc',
      PageIndex: 0,
      PageSize: 1000
    }

    this.unpublishedIntershipsService.GetUnPublishedInternshipsForEmployer(data).subscribe((response: any) => {
      this.UnPublishedInternshipsForEmployer = response.Results
      this.UnPublishedInternshipsForEmployer.map(item => {
        item.isChecked = false
        item.isToggle = false


      })
      this.IsLoading = false;
    }, error => {
    });
  }
  //Create Internship Opportunity form and returns {FormGroup}
  createInternshipOpportunityForm(): FormGroup {
    return this.formBuilder.group({
      InternshipId: new FormControl(this.unpublishedIntershipsModel.InternshipId),
      IsActive: new FormControl({ value: this.unpublishedIntershipsModel.IsActive, disabled: this.PageRights.IsReadOnly }, Validators.required),
      WorkingType: new FormControl({ value: this.unpublishedIntershipsModel.WorkingType, disabled: this.PageRights.IsReadOnly }),
      Title: new FormControl({ value: this.unpublishedIntershipsModel.Title, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      EmployerId: new FormControl({ value: this.UserModel.EmployerId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      BranchId: new FormControl({ value: this.unpublishedIntershipsModel.BranchId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      BranchName: new FormControl({ value: this.unpublishedIntershipsModel.BranchName, disabled: this.PageRights.IsReadOnly }),
      InternshipType: new FormControl({ value: this.unpublishedIntershipsModel.InternshipType, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SectorId: new FormControl({ value: this.unpublishedIntershipsModel.SectorId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      StartDate: new FormControl({ value: this.getDateValue(this.unpublishedIntershipsModel.StartDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      Duration: new FormControl({ value: this.unpublishedIntershipsModel.Duration, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Stipend: new FormControl({ value: this.unpublishedIntershipsModel.Stipend, disabled: this.PageRights.IsReadOnly }, Validators.required),
      NoOfOpenings: new FormControl({ value: this.unpublishedIntershipsModel.NoOfOpenings, disabled: this.PageRights.IsReadOnly }, Validators.required),
      JobDescription: new FormControl({ value: this.unpublishedIntershipsModel.JobDescription, disabled: this.PageRights.IsReadOnly }),
      Perks: new FormControl({ value: this.unpublishedIntershipsModel.Perks, disabled: this.PageRights.IsReadOnly }),
      RequiredSkills: new FormControl({ value: this.unpublishedIntershipsModel.RequiredSkills, disabled: this.PageRights.IsReadOnly }),
      DistrictCode: new FormControl({ value: this.unpublishedIntershipsModel.DistrictCode, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
      DistrictName: new FormControl({ value: this.unpublishedIntershipsModel.DistrictName, disabled: this.PageRights.IsReadOnly }),
      Pincode: new FormControl({ value: this.unpublishedIntershipsModel.Pincode, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(6), Validators.minLength(6), Validators.pattern(this.Constants.Regex.Number)]),
      IsCompleted: new FormControl({ value: this.unpublishedIntershipsModel.IsCompleted, disabled: this.PageRights.IsReadOnly }),
      IsPublished: new FormControl({ value: this.unpublishedIntershipsModel.IsPublished, disabled: this.PageRights.IsReadOnly }),
      PublishDate: new FormControl({ value: this.getDateValue(this.unpublishedIntershipsModel.PublishDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      AcademicYearId: new FormControl({ value: this.unpublishedIntershipsModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }),
      POCForInternship: new FormControl({ value: this.unpublishedIntershipsModel.POCForInternship, disabled: this.PageRights.IsReadOnly }),
      POCEmail: new FormControl({ value: this.unpublishedIntershipsModel.POCEmail, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50), Validators.pattern(this.Constants.Regex.Email)]),
      POCMobile: new FormControl({ value: this.unpublishedIntershipsModel.POCMobile, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      InterestId: new FormControl({ value: this.unpublishedIntershipsModel.InterestId, disabled: this.PageRights.IsReadOnly }, Validators.required),
    });
  }

  editInternshipOpportunityForm(): FormGroup {
    return this.formBuilder.group({
      InternshipId: new FormControl(this.unpublishedIntershipsModel.InternshipId),
      IsActive: new FormControl({ value: this.unpublishedIntershipsModel.IsActive, disabled: this.PageRights.IsReadOnly }, Validators.required),
      WorkingType: new FormControl({ value: this.unpublishedIntershipsModel.WorkingType, disabled: this.PageRights.IsReadOnly }),
      Title: new FormControl({ value: this.unpublishedIntershipsModel.Title, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      EmployerId: new FormControl({ value: this.UserModel.EmployerId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      BranchId: new FormControl({ value: this.unpublishedIntershipsModel.BranchId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      BranchName: new FormControl({ value: this.unpublishedIntershipsModel.BranchName, disabled: this.PageRights.IsReadOnly }),
      InternshipType: new FormControl({ value: this.unpublishedIntershipsModel.InternshipType, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SectorId: new FormControl({ value: this.unpublishedIntershipsModel.SectorId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      StartDate: new FormControl({ value: this.getDateValue(this.unpublishedIntershipsModel.StartDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      Duration: new FormControl({ value: this.unpublishedIntershipsModel.Duration, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Stipend: new FormControl({ value: this.unpublishedIntershipsModel.Stipend, disabled: this.PageRights.IsReadOnly }, Validators.required),
      NoOfOpenings: new FormControl({ value: this.unpublishedIntershipsModel.NoOfOpenings, disabled: this.PageRights.IsReadOnly }, Validators.required),
      JobDescription: new FormControl({ value: this.unpublishedIntershipsModel.JobDescription, disabled: this.PageRights.IsReadOnly }),
      Perks: new FormControl({ value: this.unpublishedIntershipsModel.Perks, disabled: this.PageRights.IsReadOnly }),
      RequiredSkills: new FormControl({ value: this.unpublishedIntershipsModel.RequiredSkills, disabled: this.PageRights.IsReadOnly }),
      DistrictCode: new FormControl({ value: this.unpublishedIntershipsModel.DistrictCode, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
      DistrictName: new FormControl({ value: this.unpublishedIntershipsModel.DistrictName, disabled: this.PageRights.IsReadOnly }),
      Pincode: new FormControl({ value: this.unpublishedIntershipsModel.Pincode, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(6), Validators.minLength(6), Validators.pattern(this.Constants.Regex.Number)]),
      IsCompleted: new FormControl({ value: this.unpublishedIntershipsModel.IsCompleted, disabled: this.PageRights.IsReadOnly }),
      IsPublished: new FormControl({ value: this.unpublishedIntershipsModel.IsPublished, disabled: this.PageRights.IsReadOnly }),
      PublishDate: new FormControl({ value: this.getDateValue(this.unpublishedIntershipsModel.PublishDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      AcademicYearId: new FormControl({ value: this.unpublishedIntershipsModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }),
      POCForInternship: new FormControl({ value: this.unpublishedIntershipsModel.POCForInternship, disabled: this.PageRights.IsReadOnly }),
      POCEmail: new FormControl({ value: this.unpublishedIntershipsModel.POCEmail, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50), Validators.pattern(this.Constants.Regex.Email)]),
      POCMobile: new FormControl({ value: this.unpublishedIntershipsModel.POCMobile, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      InterestId: new FormControl({ value: this.unpublishedIntershipsModel.InterestId, disabled: this.PageRights.IsReadOnly }, Validators.required),
    });
  }

  saveOrUpdateInternshipOpportunityDetails(type: any) {


    if (type == 'draft') {
      this.isPublished = false;
    } else {
      this.isPublished = true;
    }
    this.unpublishedIntershipsModel.EmployerId = this.UserModel.UserId
    if (!this.internshipOpportunityForm.valid) {
      this.validateAllFormFields(this.internshipOpportunityForm);
      return;
    }

    this.setValueFromFormGroup(this.internshipOpportunityForm, this.unpublishedIntershipsModel);

    this.unpublishedIntershipsModel.AuthUserId = this.UserModel.UserId;
    if (this.isPublished) {
      this.unpublishedIntershipsModel.IsPublished = true;
    } else {
      this.unpublishedIntershipsModel.IsPublished = false;
    }

    //  let xxx=this.branchList.filter(x=>x.Id==this.internshipOpportunityForm.value.BranchId);

    this.unpublishedIntershipsModel.BranchName = this.branchList.filter(x => x.Id == this.internshipOpportunityForm.value.BranchId)[0].Name;
    this.unpublishedIntershipsModel.DistrictName = this.distList.filter(x => x.Id == this.internshipOpportunityForm.value.DistrictCode)[0].Name;
    this.unpublishedIntershipsModel.AcademicYearId = this.currentAcademicYearId;
    // this.unpublishedIntershipsModel.DateOfEnrollment = this.CurrentDate

    this.unpublishedIntershipsService.createOrUpdateInternship(this.unpublishedIntershipsModel)
      .subscribe((internshipResp: any) => {

        if (internshipResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );
            this.GetUnPublishedInternshipsForEmployer()
            //window.location.reload();
            this.router.navigate([RouteConstants.InternshipListingMobileV2]);

          });
        }
        else {
          var errorMessages = this.getHtmlMessage(internshipResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
      });
  }


  viewBox(item) {
    this.tabView = !this.tabView
    if (item.isChecked == false) {
      item.isChecked = true
    }
    else if (item.isChecked == true) {
      item.isChecked = false
    }

  }

  toggleTab(item) {
  
    if (item.isToggle == false) {
      item.isToggle = true
    }
    else if (item.isToggle == true) {
      item.isToggle = false
    }


  }

  toggleInter(type, item) {
    this.tabInter = type
    this.unpublishedIntershipsModel = item
   
    var dateS = this.datepipe.transform(item.StartDate, 'yyyy-MM-dd');
    var dateP = this.datepipe.transform(item.PublishDate, 'yyyy-MM-dd');

    this.internshipOpportunityFormEdit.patchValue({
      InternshipId: item.InternshipId,
      // IsActive: item.IsActive,
      WorkingType: item.WorkingType,
      Title: item.Title,
      EmployerId: item.EmployerId,
      BranchId: item.BranchId,
      BranchName: item.BranchName,
      InternshipType: item.InternshipType,
      SectorId: item.SectorId,
      StartDate: dateS,
      Duration: item.Duration,
      Stipend: item.Stipend,
      NoOfOpenings: item.NoOfOpenings,
      JobDescription: item.JobDescription,
      Perks: item.Perks,
      RequiredSkills: item.RequiredSkills,
      DistrictCode: item.DistrictCode,
      DistrictName: item.DistrictName,
      Pincode: item.Pincode,
      IsCompleted: item.IsCompleted,
      IsPublished: item.IsPublished,
      PublishDate: dateP,
      AcademicYearId: item.AcademicYearId,
      POCForInternship: item.POCForInternship,
      POCEmail: item.POCEmail,
      POCMobile: item.POCMobile,
      InterestId: item.InterestId,
    });
  }

  scroll(element) {
    window.scrollTo(element.yPosition)
  }
  UpdateInternshipOpportunityDetails() {

    if (!this.internshipOpportunityFormEdit.valid) {
      this.validateAllFormFields(this.internshipOpportunityFormEdit);
      return;
    }

    this.setValueFromFormGroup(this.internshipOpportunityFormEdit, this.unpublishedIntershipsModel);
    this.unpublishedIntershipsModel.RequestType = this.Constants.PageType.Edit;
    this.unpublishedIntershipsModel.IsPublished = true;


    this.unpublishedIntershipsModel.BranchName = this.branchList.filter(x => x.Id == this.internshipOpportunityFormEdit.value.BranchId)[0].Name;
    this.unpublishedIntershipsModel.DistrictName = this.distList.filter(x => x.Id == this.internshipOpportunityFormEdit.value.DistrictCode)[0].Name;
    this.unpublishedIntershipsModel.AcademicYearId = this.currentAcademicYearId;

    this.unpublishedIntershipsService.createOrUpdateInternship(this.unpublishedIntershipsModel)
      .subscribe((internshipResp: any) => {

        if (internshipResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );
            this.GetUnPublishedInternshipsForEmployer()
            this.tabInter = 'Add'


          });
        }
        else {
          var errorMessages = this.getHtmlMessage(internshipResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
      });
  }
  goDown2() {
    //this.scroller.scrollToAnchor("targetGreen");
    document.getElementById("targetGreen").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
