<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu-emp></igmite-side-menu-emp>


                </div>
                <div
                    class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 overflow-hidden max-w-800 mx-auto mt-20">


                    <img src="assets/upload/get-help.png" class="text-center mx-auto d-block mt-20" alt="">

                    <p class="fs-22 text-black fw-800 text-center line-h-30 mt-25 mb-15 mb-0 text-center">Get Help <br>
                        For Hiring</p>


                    <div class="mb-0 mt-20 border-theme-2px py-20 border-radius-20 w-100 px-15">

                        <form action="" class="row mx-0 px-0" name="getHelpForm" novalidate [formGroup]="getHelpForm">

                            <div class="input-with-icon position-relative">
                                <p class="fs-16 text-black mb-1 px-2 fw-900">Share details with us</p>
                                <!-- <textarea name="" id="" cols="30" rows="6"
                                class="border-radius-10 text-black border-theme-2px fw-400 fs-16 py-10 w-100 px-15"></textarea> -->

                                <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                    <mat-label></mat-label>
                                    <textarea matInput type="text" id="RequestDesc" formControlName="RequestDesc"
                                        required name="name"
                                        class="border-radius-10 border-theme-2px fs-18 py-2 w-100"
                                        required=""></textarea>
                                    <mat-error *ngIf="getHelpForm.controls.RequestDesc.invalid"
                                        class="error text-danger mt-1 px-1 d-block ">
                                        Enter Query details
                                    </mat-error>
                                </mat-form-field>


                                <button (click)="saveOrUpdateInternshipOpportunityDetails()"
                                    class="d-block px-3 text-white max-w-180 border-radius-15 mx-auto mt-30 w-100 py-2 upto-down-gradient-clr-1 theme-btn">SHARE
                                    NOW</button>

                            </div>
                        </form>
                        <a href="/home-employer" class="fs-15 mt-30 mb-10 color1 fw-900 d-block text-center">GO BACK</a>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>