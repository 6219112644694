import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { StudyMaterialsService } from './study-materials.service';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { ImageService } from '../gallery-1/image.service';
import { Gallery1Service } from '../gallery-1/gallery-1.service';

@Component({
  providers: [AppConstants],
  selector: 'igmite-study-materials',
  templateUrl: './study-materials.component.html',
  styleUrls: ['./study-materials.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class StudyMaterialsComponent extends BaseComponent<LoginModel> implements OnInit {
  public loginForm: FormGroup;
  public loginModel: LoginModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  studyMaterialData: any;
  panelOpenState = false;
  studyMterialDocPath=this.Constants.CommonImageUrl.AttachmentsSR;
  imageExtension: string;
  imgPath: any;
  imgData: any;

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private studyMaterialsService: StudyMaterialsService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private imageService: ImageService,
    private gallery1Service: Gallery1Service,

    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

    ngOnInit(): void {

    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');
    });

    let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
    employeeMenuItems.forEach(employeeMenuItems => {
      employeeMenuItems.setAttribute('style', 'display: none !important');
    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }

    $('.accordion > li .active').next().slideDown();

    $('.accordion .accodian-opner').click(function (j) {
      var dropDown = $(this).closest('li').find('.hide-content');

      $(this).closest('.accordion').find('.hide-content').not(dropDown).slideUp();

      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
      } else {
        $(this).closest('.accordion').find('.accodian-opner.active').removeClass('active');
        $(this).addClass('active');
      }

      dropDown.stop(false, true).slideToggle();

      j.preventDefault();
    });
    // $("#accordian123").click(function () {
    //   alert('hello!');
    // });

    // API call

    let requestParams = {
      "UserId": this.UserModel.LoginId, //loginId
      "UserTypeId": this.UserModel.UserTypeId, //UserTypeId
      "PageIndex": 0,
      "PageSize": 1000,
      "ResourceType": "StudyMaterial"
    }

    this.studyMaterialsService.GetResourcesByTypeAndCategory(requestParams)
      .subscribe((response: any) => {
        this.studyMaterialData = response.Results.filter(sR => sR.ResourceType == 'StudyMaterial');

        $('.accordion > li .active').next().slideDown();

        $('.accordion-li').on("click", function (j) {
          var dropDown = $(this).closest('li').find('.hide-content');

          $(this).closest('.accordion').find('.hide-content').not(dropDown).slideUp();

          if ($(this).hasClass('active')) {
            $(this).removeClass('active');
          } else {
            $(this).closest('.accordion').find('.accodian-opner.active').removeClass('active');
            $(this).addClass('active');
          }

          dropDown.stop(false, true).slideToggle();

          j.preventDefault();
        });
      });

  }

  onClickLink(){
    this.dialogService.openShowDialog("Coming soon....");
  }

  downloadImage(url) {
    this.imageExtension = this.imageService.getImageExtension(url);
    this.gallery1Service.GetBase64(url).subscribe(response => {
      let xx = 'data:image/jpeg;base64,' + response.Result;
        this.imgData = xx;
        this.imageService.download(this.imgData);
     
    });
  }

}
