import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { BaseListComponent } from 'app/common/base-list/base.list.component';


import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { InternshipDetailModel } from './internship-detail.model';
import { debug } from 'console';
import { InternshipListingMobileV2Model } from '../internship-listing-mobile-v2/internship-listing-mobile-v2.model';
import { InternshipDetailService } from './internship-detail.service';


@Component({
  providers: [AppConstants],
  selector: 'igmite-internship-detail',
  templateUrl: './internship-detail.component.html',
  styleUrls: ['./internship-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class InternshipDetailComponent extends BaseListComponent<InternshipDetailModel> implements OnInit {
  public interestsForm: FormGroup;
  public loginModel: LoginModel;
  public InternshipDetailModel: InternshipDetailModel;

  public appInfo = environment;
  interestsDetails: any;
  workingInterns: any;
  InternshipId: any;

  //ongoingInternshipData = [] as any;
  ApiBaseUrlImage: string = this.Constants.CommonImageUrl.Account//environment.ApiBaseUrlImage;
  ongoingInternshipData: any;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private InternshipDetailService: InternshipDetailService,
    private dialogService: DialogService,
    public zone: NgZone,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);


    this.InternshipDetailModel = new InternshipDetailModel();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {

    let elementIn1: any = document.querySelectorAll(".withoutlogin");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    let elementIn2: any = document.querySelectorAll(".withinlogin");
    elementIn2.forEach(elementIn2 => {
      elementIn2.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.ApiBaseUrlImage + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }

    this.InternshipId = sessionStorage.getItem("InternshipId");
    if (this.InternshipId) {
      this.getInternshipById(this.InternshipId);

      this.getWorkingInternDashboardByInternshipId(this.InternshipId);//"774ca254-206b-4a70-81fc-8cac9b4129f4"
      // this.getWorkingInternDashboardByInternshipId("774ca254-206b-4a70-81fc-8cac9b4129f4");
    }

  }

  getWorkingInternDashboardByInternshipId(internshipId: any) {
    this.InternshipDetailService.getWorkingInternDashboardByInternshipId(internshipId, 0, 10000).subscribe(response => {
      this.workingInterns = response.TotalResults;
      this.ongoingInternshipData = response.Results;
    });
  }

  getInternshipById(id: any) {
    var data = { DataId: id }
    this.InternshipDetailService.getInternshipById(data).subscribe((response: any) => {
      this.interestsDetails = response.Result
      this.IsLoading = false;
    }, error => {
    });
  }

  callStudentId(item) {
    sessionStorage.setItem('StudentID', item);
    if (item) {
      this.router.navigateByUrl(RouteConstants.ProfileMobile);
    }
  }
}
