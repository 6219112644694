<div class="confirm-dialog">
    <div class="confirm-message">
        <mat-icon id="close-icon" (click)="closeDialog()">close</mat-icon>
        <span class="content-span full-width" style="text-align: center;
        color: blue;
        font-size: 16px; padding: 12px 16px !important">Password has expired! Please change your password.</span>

    </div>
    <div class="confirm-message">
        <form class="mat-card mat-elevation-z4 p-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
            name="form" name="ChangePasswordForm" novalidate [formGroup]="ChangePasswordForm" autocomplete="off" style="width: 90%;">

            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
               
              
                    <mat-form-field appearance="outline" class="input-with-icon position-relative" style="padding-bottom: 5px">
                        <mat-label >User Id</mat-label>
                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="fill-color-2 ml-10 start-0 start-0 position-absolute translate-middle-y top-50"
                            height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                            <path d="M0 0h24v24H0z" fill="none"></path>
                            <path
                                d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z">
                            </path>
                        </svg>
                        <input matInput name="userId" formControlName="UserId"
                        class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                        fw-600 cursor-pointer input-top" required style="padding-left: 40px">

                    </mat-form-field>
              
               
                    <!-- <mat-form-field appearance="outline" class="input-with-icon position-relative" style="padding-bottom: 5px">

                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="fill-color-2 ml-10 start-0 start-0 position-absolute translate-middle-y top-50"
                            height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
                        </svg>
                        <input matInput type="password" name="password" formControlName="Password"
                        class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                        fw-600 cursor-pointer input-top" required style="padding-left: 40px">

                    </mat-form-field> -->
                    <mat-form-field appearance="outline" class="input-with-icon position-relative" style="padding-bottom: 5px">
                        <!-- <mat-label>Old Password</mat-label> -->

                        <svg xmlns="http://www.w3.org/2000/svg"
                        class="fill-color-2 ml-10 start-0 start-0 position-absolute translate-middle-y top-50"
                        height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                            d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z">
                        </path>
                    </svg>
                        <input matInput #password type="password" name="Password"
                            formControlName="Password" required placeholder="Old Password *"
                            title="1) Your password must be between 8 and 50 characters.
                            2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                            3) Your password must contain at least one lowercase letter.
                            4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                            5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                            matTooltipPosition='above' matTooltipClass="allow-cr"  class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                            fw-600 cursor-pointer input-top" style="padding-left: 40px">
                       
                        <span *ngIf="ChangePasswordForm.controls.Password.invalid">
                            {{getErrorMessage(ChangePasswordForm, 'Password')}}

                        </span>

                    </mat-form-field>

                    <mat-form-field appearance="outline" class="input-with-icon position-relative" style="padding-bottom: 5px">
                        <!-- <mat-label>New Password</mat-label> -->

                        <svg xmlns="http://www.w3.org/2000/svg"
                        class="fill-color-2 ml-10 start-0 start-0 position-absolute translate-middle-y top-50"
                        height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                            d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z">
                        </path>
                    </svg>
                        <input matInput #password type="password" name="newPassword"
                            formControlName="NewPassword" required placeholder="New Password *"
                            title="1) Your password must be between 8 and 50 characters.
                            2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                            3) Your password must contain at least one lowercase letter.
                            4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                            5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                            matTooltipPosition='above' matTooltipClass="allow-cr"  class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                            fw-600 cursor-pointer input-top" style="padding-left: 40px">
                       
                        <mat-error *ngIf="ChangePasswordForm.controls.NewPassword.invalid">
                            {{getErrorMessage(ChangePasswordForm, 'NewPassword')}}

                        </mat-error>

                    </mat-form-field>

                    <mat-form-field appearance="outline" class="input-with-icon position-relative" style="padding-bottom: 5px">
                        <!-- <mat-label>Confirm Password</mat-label> -->

                        <svg xmlns="http://www.w3.org/2000/svg"
                        class="fill-color-2 ml-10 start-0 start-0 position-absolute translate-middle-y top-50"
                        height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                            d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z">
                        </path>
                    </svg>
                        <input matInput #password [type]="hideConfirmPassword ? 'password' : 'text'" name="confirmPassword"
                        formControlName="ConfirmPassword" required  placeholder="Confirm Password *"
                        title="1) Your password must be between 8 and 50 characters.
                            2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                            3) Your password must contain at least one lowercase letter.
                            4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                            5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                            matTooltipPosition='above' matTooltipClass="allow-cr" style="padding-left: 40px"  class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                            fw-600 cursor-pointer input-top">
                            <mat-icon matSuffix (click)="setVisibleConfirmPassword()">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}
                            </mat-icon>

                            <mat-error *ngIf="ChangePasswordForm.controls.ConfirmPassword.invalid">
                                {{getErrorMessage(ChangePasswordForm, 'ConfirmPassword')}}
                            </mat-error>
                            <span *ngIf="ChangePasswordForm.controls.ConfirmPassword.errors" style="color:red;">Password Not
                                Matched</span>

                    </mat-form-field>
             
            </div>


        </form>
    </div>
    <div mat-dialog-actions class="confirm-action" *ngIf=" ChangePasswordForm.controls.ConfirmPassword.value == '' && ChangePasswordForm.controls.ConfirmPassword.value == '' ">
        <!-- <button mat-flat-button id="no-button" [mat-dialog-close]="false" (click)="onSubmit(dateForm)">NO</button> -->
        <button mat-raised-button id="" class="mat-accent mr-16 "  
           style="width: 100%;height: 50px !important;background-color: #3c4252 !important;color: #fff;border-radius: 0px !important;cursor: not-allowed;">Change Password</button>
    </div>
    <div mat-dialog-actions class="confirm-action" *ngIf="ChangePasswordForm.controls.ConfirmPassword.value != '' && ChangePasswordForm.controls.ConfirmPassword.value != ''">
        <!-- <button mat-flat-button id="no-button" [mat-dialog-close]="false" (click)="onSubmit(dateForm)">NO</button> -->
        <button mat-raised-button id="" class="mat-accent mr-16 "  
            (click)="onSubmit(ChangePasswordForm)" style="width: 100%;height: 50px !important;background-color: #3c4252 !important;color: #fff;border-radius: 0px !important;">Change Password</button>
    </div>
</div>