import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { ProfileMobileService } from './profile-mobile.service';
import { OpprtunitiesListingService } from '../opprtunities-listing/opprtunities-listing.service';


@Component({
  providers: [AppConstants],
  selector: 'igmite-profile-mobile',
  templateUrl: './profile-mobile.component.html',
  styleUrls: ['./profile-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ProfileMobileComponent extends BaseComponent<LoginModel> implements OnInit {
  public loginForm: FormGroup;
  public loginModel: LoginModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  profileAll: any
  image: any
  interestsByStudent: any
  interests: any
  VTDetails: any;
  StudentID: any;
  stuId: any
  stId: any
  studentPhoto: any;
  FinalInternshipImageBaseURL: any
  imageAttachments: any

  IsDirectMappingEnabled: any
  intershipActive: any
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private profileMobileService: ProfileMobileService,
    public zone: NgZone,
    private opprtunitiesListingService: OpprtunitiesListingService,

    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };
    const firstTime = localStorage.getItem('key')
    if (!firstTime) {
      localStorage.setItem('key', 'loaded')
      window.location.reload();

    } else {
      localStorage.removeItem('key')
    }

  }

  ngOnInit(): void {
    this.IsDirectMappingEnabled = this.UserModel.IsDirectMappingEnabled
    this.FinalInternshipImageBaseURL = this.Constants.CommonImageUrl.FinalInternshipReports;
    this.imageAttachments = this.Constants.CommonImageUrl.Attachments;

    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.RoleCode == 'ST') {
      let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
      employeeMenuItems.forEach(employeeMenuItems => {
        employeeMenuItems.setAttribute('style', 'display: none !important');
      });
    }

    this.interests = sessionStorage.getItem("interests");
    this.StudentID = sessionStorage.getItem("StudentID");
    if (this.StudentID) {
      this.stuId = { DataId: this.StudentID }

    } else {
      this.stuId = { DataId: this.UserModel.UserTypeId }
    }


    this.profileMobileService.GetVTByStudentId(this.stuId).subscribe((vtResp: any) => {
      if (vtResp.Success == true) {
        this.zone.run(() => {
          this.VTDetails = vtResp.Results[0]
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid UpdateStudentInternshipsStatus ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

    if (this.StudentID) {
      this.stId = {
        UserTypeId: this.StudentID,
        PageIndex: 0,
        PageSize: 1000
      }

    } else {
      this.stId = {
        UserTypeId: this.UserModel.UserTypeId,
        PageIndex: 0,
        PageSize: 1000
      }
    }
    this.profileMobileService.GetProfileAttachmentsByAccountId(this.stId).subscribe((vtResp: any) => {
      if (vtResp.Success == true) {
        this.zone.run(() => {
          this.studentPhoto = vtResp.Results
          // this.studentPhoto.map(item => {
          //   item.AttachmentPath = this.FinalInternshipImageBaseURL+item.AttachmentPath ;


          // })
          this.IsLoading = false;

        });

      }
      else {

      }


    }, error => {
    });
    if (this.StudentID) {
      this.stuId = { DataId: this.StudentID }

    } else {
      this.stuId = { DataId: this.UserModel.UserTypeId }
    }
    this.profileMobileService.GetStudentProfileConfirmationById(this.stuId).subscribe((profileResp: any) => {
      if (profileResp.Success == true) {
        this.zone.run(() => {
          this.profileAll = profileResp.Results[0]
          this.profileAll.PhotoUrl = this.Constants.CommonImageUrl.Account + this.profileAll.PhotoUrl;
          if (this.UserModel.RoleCode == 'ST') {
            let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
            userProfileImage[0].setAttribute('src', this.profileAll.PhotoUrl);
          } else if (this.UserModel.RoleCode == "EMP") {
            let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
            userProfileImage[0].setAttribute('src', "");  //commnet line bocz its setting Login user profile image as student Img coreection requied to set actual login user image to  this.profileAll.PhotoUrl
          } else {
            let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
            userProfileImage[0].setAttribute('src', this.profileAll.PhotoUrl);
          }

          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordMessage,

          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid UpdateStudentInternshipsStatus ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });


    //  var  stuId={ DataId:this.UserModel.UserTypeId }
    if (this.StudentID) {
      this.stuId = { DataId: this.StudentID }

    } else {
      this.stuId = { DataId: this.UserModel.UserTypeId }
    }
    this.profileMobileService.GetInterestsByStudentId(this.stuId).subscribe((response: any) => {
      this.interestsByStudent = response.Results

      this.IsLoading = false;
    }, error => {
    });

    this.opprtunitiesListingService.GetStudentsInternshipApprovalStatus(this.stuId).subscribe((response: any) => {
      if(response.Success == true){
        this.intershipActive = response.Results

      }
    }, error => {
    });

  }



  NextPage() {
    if (this.IsDirectMappingEnabled == false) {
      if (this.interestsByStudent.length != 0) {
        this.router.navigateByUrl(RouteConstants.OpprtunitiesListing);

      } else {
        this.router.navigateByUrl(RouteConstants.Interests);

      }
    } else {
      if (this.UserModel.InternshipId != null) {
        this.router.navigateByUrl(RouteConstants.DashboardNew);


      } else {
        this.router.navigateByUrl(RouteConstants.HomeMain);


      }
    }


  }


}
