
export class LoginOTPModel {
    EmailID: string;
    RoleCode: string;

    

    constructor() {
        this.EmailID = '';
        this.RoleCode = '';
        
      
    }
}