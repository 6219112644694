<section class="">
    <div class="container">

        <div class="content-section mt-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side" *ngIf="roleCode == 'EMP'">


                    <igmite-side-menu-emp ></igmite-side-menu-emp>


                </div>

                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side"  *ngIf="roleCode == 'ST'">


                    <igmite-side-menu></igmite-side-menu>


                </div>



                <div class="col-12 col-lg-10 right-content px-lg-35 py-18 pb-5 bg-white px-10 mb-4">
                    <h1
                        class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-15  line-h-30 mb-30">
                        Notification</h1>


                    <p
                        class="fs-16 text-black fw-900 border-theme-2px border-start-0 border-end-0 border-top-0 pb-10 max-w-800 mx-auto">
                        New Notice
                    </p>

                    <!-- <div class="mb-35 max-w-800 mx-auto">

                        <p class="fs-14 text-black fw-400 mb-0 px-1 ">
                            15 July 2022
                        </p>

                        <div class="border-theme-2px border-radius-20 px-20 py-20">
                            <p class="fs-16 text-black fw-900 mb-5px">
                                Survey Form Notice
                            </p>
                            <p class="fs-13 text-black fw-400 read-more-less-text mb-0"
                                data-read-more="{ visible: false, useStyledContainer: true }">
                                Lorem ipsum dolor sit amet, conse Ctetur amet, conse
                            </p>
                            <a href="#" class="d-block text-end color1 fw-900 fs-14 mt-10">
                                Complete survey
                            </a>
                        </div>

                    </div> -->


                    <div class="mb-35 max-w-800 mx-auto" *ngFor="let notification of notifications;let index = index">

                        <p class="fs-14 text-black fw-400 mb-0 px-1 ">
                            {{notification.PublishDate  | date:"dd MMM
                            yyyy" }}
                        </p>

                        <div class="border-theme-2px border-radius-20 px-20 py-20">
                            <p class="fs-16 text-black fw-900 mb-5px">
                                {{notification.NoticeTitle}}
                            </p>
                            <p class="fs-13 text-black fw-400 read-more-less-text mb-0"
                                data-read-more="{ visible: false, useStyledContainer: true }">
                                <!-- Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum
                                dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit
                                amet, conse Ctetur amet, conse adipiscing elit. -->
                                {{notification.NoticeDescription}}
                            </p>

                        </div>

                    </div>


                    <!-- <div class="mb-35 max-w-800 mx-auto">

                        <p class="fs-14 text-black fw-400 mb-0 px-1 ">
                            8 May 2022
                        </p>

                        <div class="border-theme-2px border-radius-20 px-20 py-20">
                            <p class="fs-16 text-black fw-900 mb-5px">
                                Feedback Form
                            </p>
                            <p class="fs-13 text-black fw-400 read-more-less-text mb-0"
                                data-read-more="{ visible: false, useStyledContainer: true }">
                                Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum
                                dolor sit amet, conse Ctetur amet, conse adipiscing elit. Lorem ipsum dolor sit
                                amet, conse Ctetur amet, conse adipiscing elit.
                            </p>

                        </div>

                    </div>





                    <p
                        class="fs-16 text-black fw-900 border-theme-2px border-start-0 border-end-0 border-top-0 pb-10 max-w-800 mx-auto">
                        Earlier Notice
                    </p>

                    <div class="mb-35 max-w-800 mx-auto">

                        <p class="fs-14 text-black fw-400 mb-0 px-1 ">
                            22 Apr 2022
                        </p>

                        <div class="border-theme-2px border-radius-20 px-20 py-20">
                            <p class="fs-16 text-black fw-900 mb-5px">
                                Survey Form
                            </p>
                            <p class="fs-13 text-black fw-400 read-more-less-text mb-0"
                                data-read-more="{ visible: false, useStyledContainer: true }">
                                Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit.
                            </p>
                            <a href="#" class="d-block text-end color1 fw-900 fs-14 mt-10">
                                Complete Survey Form
                            </a>

                        </div>

                    </div>







                    <div class="mb-35 max-w-800 mx-auto">

                        <p class="fs-14 text-black fw-400 mb-0 px-1 ">
                            11 Feb 2022
                        </p>

                        <div class="border-theme-2px border-radius-20 px-20 py-20">
                            <p class="fs-16 text-black fw-900 mb-5px">
                                Survey Form Notice
                            </p>
                            <p class="fs-13 text-black fw-400 read-more-less-text mb-0"
                                data-read-more="{ visible: false, useStyledContainer: true }">
                                Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit.
                            </p>


                        </div>

                    </div>


                    <div class="mb-35 max-w-800 mx-auto">

                        <p class="fs-14 text-black fw-400 mb-0 px-1 ">
                            19 Jan 2022
                        </p>

                        <div class="border-theme-2px border-radius-20 px-20 py-20">
                            <p class="fs-16 text-black fw-900 mb-5px">
                                Job Fair
                            </p>
                            <p class="fs-13 text-black fw-400 read-more-less-text mb-0"
                                data-read-more="{ visible: false, useStyledContainer: true }">
                                Lorem ipsum dolor sit amet, conse Ctetur amet, conse adipiscing elit.
                            </p>


                        </div>

                    </div> -->



                </div>






            </div>
        </div>
    </div>

</section>