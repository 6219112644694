<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu-emp></igmite-side-menu-emp>


                </div>
                <div class="col-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
                    <div class="row px-0 mx-0 align-items-lg-center max-w-800 mx-auto">



                        <div class="col-12 px-0 text-center">

                            <div
                                class="bg-theme-light-grey top-circle overflow-hidden mt-lg-0 mx-auto d-flex justify-content-center align-items-center">




                                <svg version="1.1" class="fill-color-1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="60px"
                                    height="60px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
                                    xml:space="preserve">
                                    <path fill="#0063FA" d="M20,6h-4V4c0-1.11-0.891-2-2-2h-4C8.89,2,8,2.89,8,4v2H4C2.89,6,2.01,6.89,2.01,8L2,19c0,1.109,0.89,2,2,2
h16c1.109,0,2-0.891,2-2V8C22,6.89,21.109,6,20,6z M14,6h-4V4h4V6z" />
                                    <circle fill="#0063FA" stroke="#FFFFFF" stroke-miterlimit="10" cx="18.908"
                                        cy="18.095" r="4.083" />
                                    <g>
                                        <path fill="#FFFFFF" d="M20.741,17.817c0.079,0.077,0.119,0.175,0.119,0.292s-0.04,0.216-0.119,0.296
    c-0.08,0.08-0.179,0.12-0.297,0.12h-1.119v1.128c0,0.117-0.04,0.215-0.12,0.292s-0.179,0.116-0.296,0.116
    c-0.118,0-0.217-0.039-0.297-0.116c-0.079-0.077-0.119-0.175-0.119-0.292v-1.128h-1.12c-0.117,0-0.216-0.04-0.296-0.12
    s-0.12-0.179-0.12-0.296s0.04-0.215,0.12-0.292s0.179-0.116,0.296-0.116h1.12v-1.128c0-0.117,0.04-0.216,0.119-0.296
    c0.08-0.08,0.179-0.12,0.297-0.12c0.117,0,0.216,0.04,0.296,0.12s0.12,0.179,0.12,0.296v1.128h1.119
    C20.563,17.701,20.661,17.74,20.741,17.817z" />
                                    </g>
                                </svg>










                            </div>

                        </div>


                    </div>


                    <p class="fs-22 text-black fw-900  line-h-22 mt-25 mb-0 px-10 text-center"> Unpublished
                        Internship </p>

                    <div *ngFor="let UnPublishedInternships of UnPublishedInternshipsForEmployer;index as i;">
                        <div class="border-theme-2px px-10 py-15 mb-0 d-block border-radius-30 overflow-hidden bg-light-theme opportunities-block max-w-800 mx-auto mt-20"
                            [ngClass]="UnPublishedInternships.isChecked === true ? 'show' : ''">

                            <div class="d-flex justify-content-between align-items-center mt-10">
                                <div>
                                    <p class="fs-16 text-black fw-900 mb-0 "> {{UnPublishedInternships.Title }} </p>

                                    <p class="fs-14 fw-600 text-black fw-600 mb-0 "> Posted on -
                                        {{UnPublishedInternships.PublishDate | date:"dd MMM yyyy"}}</p>
                                </div>
                                <div class="dropdown text-end">
                                    <a class="btn btn-dark-100 btn-icon btn-sm rounded-circle shadow-none px-0"
                                        role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false" (click)="toggleTab(UnPublishedInternships);"
                                        [ngClass]="UnPublishedInternships.isToggle === true ? 'show' : ''">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                            class="fill-color-1 cursor-pointer drop-box" viewBox="0 0 24 24"
                                            width="24px" fill="#000000">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path
                                                d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                        </svg>

                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end shadow-sm"
                                        [ngClass]="UnPublishedInternships.isToggle === true ? 'show classView' : ''">
                                        <a href="" class="dropdown-item">
                                            Action
                                        </a>
                                        <a href="" class="dropdown-item">
                                            Another action
                                        </a>
                                        <a href="" class="dropdown-item">
                                            Something else here
                                        </a>
                                    </div>
                                </div>


                            </div>


                            <div class="row mx-0 p-0">


                                <div class="col-6 pl-0 mt-20 pr-5px">
                                    <div class="d-flex align-items-center">

                                        <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                                            width="24px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24" />
                                            </g>
                                            <g>
                                                <path
                                                    d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z" />
                                            </g>
                                        </svg>
                                        <div>
                                            <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Start Date</p>
                                            <p class="fs-14 text-black fw-600 mb-0">{{UnPublishedInternships.StartDate |
                                                date:"dd MMM yyyy"}}</p>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-6 pl-0 mt-20 pr-5px">
                                    <div class="d-flex align-items-center">

                                        <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                                            width="24px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24" />
                                            </g>
                                            <g>
                                                <path
                                                    d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z" />
                                            </g>
                                        </svg>
                                        <div>
                                            <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Duration
                                            </p>
                                            <p class="fs-14 text-black fw-600 mb-0">{{UnPublishedInternships.Duration}}
                                            </p>
                                        </div>
                                    </div>



                                </div>


                                <div class="col-6 pl-0 mt-20 pr-5px">
                                    <div class="d-flex align-items-center">





                                        <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg" height="24px"
                                            viewBox="0 0 24 24" width="24px" fill="#000000">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path
                                                d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z" />
                                        </svg>



                                        <div>
                                            <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Work Type
                                            </p>
                                            <p class="fs-14 text-black fw-600 mb-0">{{UnPublishedInternships.WorkingType
                                                }}
                                            </p>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-6 pl-0 mt-20 pr-5px">
                                    <div class="d-flex align-items-center">



                                        <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                                            width="24px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24" />
                                            </g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path
                                                            d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>


                                        <div>
                                            <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Internship Type
                                            </p>
                                            <p class="fs-14 text-black fw-600 mb-0">
                                                {{UnPublishedInternships.InternshipType
                                                }}</p>
                                        </div>
                                    </div>



                                </div>



                            </div>

                            <div class="internship-full-details">
                                <div class="row mx-0 px-0">


                                    <!-- <div class="col-6 pl-0 mt-20 pr-5px">
                                        <div class="d-flex align-items-center">


                                            <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                                height="28px" viewBox="0 0 24 24" width="28px" fill="#000000">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path
                                                    d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                                            </svg>


                                            <div>
                                                <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Open Positions

                                                </p>
                                                <p class="fs-14 text-black fw-600 mb-0">5 Interns</p>
                                            </div>
                                        </div>



                                    </div>



                                    <div class="col-6 pl-0 mt-20 pr-5px">
                                        <div class="d-flex align-items-center">

                                            <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                                enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                                                width="24px" fill="#000000">
                                                <g>
                                                    <rect fill="none" height="24" width="24" y="0" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="M20,7h-5V4c0-1.1-0.9-2-2-2h-2C9.9,2,9,2.9,9,4v3H4C2.9,7,2,7.9,2,9v11c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V9 C22,7.9,21.1,7,20,7z M9,12c0.83,0,1.5,0.67,1.5,1.5c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5C7.5,12.67,8.17,12,9,12z M12,18H6 v-0.43c0-0.6,0.36-1.15,0.92-1.39C7.56,15.9,8.26,15.75,9,15.75s1.44,0.15,2.08,0.43c0.55,0.24,0.92,0.78,0.92,1.39V18z M13,9h-2V4 h2V9z M17.25,16.5h-2.5c-0.41,0-0.75-0.34-0.75-0.75v0c0-0.41,0.34-0.75,0.75-0.75h2.5c0.41,0,0.75,0.34,0.75,0.75v0 C18,16.16,17.66,16.5,17.25,16.5z M17.25,13.5h-2.5c-0.41,0-0.75-0.34-0.75-0.75v0c0-0.41,0.34-0.75,0.75-0.75h2.5 c0.41,0,0.75,0.34,0.75,0.75v0C18,13.16,17.66,13.5,17.25,13.5z" />
                                                </g>
                                            </svg>





                                            <div>
                                                <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Onboarded

                                                </p>
                                                <p class="fs-14 text-black fw-600 mb-0">20 Interns</p>
                                            </div>
                                        </div>



                                    </div> -->

                                </div>

                                <p class="fs-17 text-black fw-600 mb-0 mt-30">Internship Details</p>
                                <p class="fs-14 text-black fw-600 mb-0 px-10 mt-5px">
                                    {{UnPublishedInternships.JobDescription}}</p>


                                <p class="fs-17 text-black fw-600 mb-0 mt-30">Required Skills</p>
                                <ul class="list-none cstm-list-style pl-10 mt-5px">
                                    <li class="pl-10 fs-15 fw-400 text-black mb-1">
                                        <span
                                            *ngIf="UnPublishedInternships.RequiredSkills !=''">{{UnPublishedInternships.RequiredSkills}}</span>
                                        <span *ngIf="UnPublishedInternships.RequiredSkills ==''">NA</span>
                                    </li>


                                </ul>

                                <p class="fs-17 text-black fw-600 mb-0 mt-30">Additional Benefits</p>
                                <ul class="list-none cstm-list-style pl-10 mt-5px">
                                    <li class="pl-10 fs-15 fw-400 text-black mb-1">
                                        <span
                                            *ngIf="UnPublishedInternships.Perks !=''">{{UnPublishedInternships.Perks}}</span>
                                        <span *ngIf="UnPublishedInternships.Perks ==''">NA</span>
                                    </li>


                                </ul>




                                <button
                                    class="bg-transparent fs-17 fw-900 color1 internship-full-details-opener text-center d-block mt-20 w-100 border-0"
                                    (click)="viewBox(UnPublishedInternships)">Close</button>
                            </div>





                            <button (click)="viewBox(UnPublishedInternships)"
                                class="bg-white w-100 max-w-300 py-10 fs-15 fw-600 text-center border-0 border-radius-15 mt-30 color1 mx-auto d-block internship-full-details-opener hide-on-click">
                                <svg class="mr-5 fill-color-1" xmlns="http://www.w3.org/2000/svg" height="24px"
                                    viewBox="0 0 24 24" width="24px" fill="#000000">
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path
                                        d="M12 4C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                </svg>
                                View Details
                            </button>


                            <a (click)="toggleInter('Edit',UnPublishedInternships)" style="cursor: pointer;"
                                class="max-w-400 w-100 mt-15 py-10 px-10 text-white fs-17 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-15 box-shadow-hard mb-10 max-w-320 text-uppercase col-12">Edit
                                & Publish</a>







                        </div>
                    </div>






                    <!--Add tabs container-->

                    <section *ngIf="tabInter == 'Add'">
                        <p class="fs-22 text-black fw-900  line-h-22 mt-50 mb-20  px-10 text-center">Add New
                            Internship </p>

                        <div class="py-20 px-5px border-theme-2px border-radius-25">

                            <form action="" class="row mx-0 px-0" name="internshipOpportunityForm" novalidate
                                [formGroup]="internshipOpportunityForm">


                                <div class="col-12 px-5px">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Title*</p>

                                        <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                            <input matInput type="text" id="Title" formControlName="Title" name="name"
                                                class="border-radius-10 border-theme-2px fs-18 py-2 w-100"
                                                required="">
                                            <mat-error *ngIf="internshipOpportunityForm.controls.Title.invalid"
                                                class="error text-danger mt-1 px-1 d-block ">
                                                Enter Title in Proper manner
                                            </mat-error>
                                        </mat-form-field>
                                    </div>



                                </div>

                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Date of Publishing*</p>

                                    <div class="input-with-icon position-relative dynamic-text-input-date">
                                        <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                            <input matInput type="date" id="PublishDate" formControlName="PublishDate"
                                                name="PublishDate" class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100  
                                            fw-600 cursor-pointer input-top">
                                            <mat-error *ngIf="internshipOpportunityForm.controls.PublishDate.invalid">
                                                Date of Publishing is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                </div>

                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Internship Type*</p>

                                    <div class="input-with-icon position-relative">
                                        <mat-form-field appearance="outline"
                                            class="input-with-icon position-relative hp">
                                            <select matNativeControl name="" matInput id="InternshipType"
                                                formControlName="InternshipType" name="InternshipType"
                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                <option value="">Select Internship Type</option>
                                                <option value="Online">Online</option>
                                                <option value="Offline">Offline</option>
                                                <option value="Hybrid">Hybrid</option>


                                            </select>
                                            <label for="InternshipType" class="right-icon-input z-index-div-bottm">
                                            </label>
                                            <mat-error
                                                *ngIf="internshipOpportunityForm.controls.InternshipType.invalid">
                                                Internship Type is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Sector*</p>

                                    <div class="input-with-icon position-relative">
                                        <mat-form-field appearance="outline"
                                            class="input-with-icon position-relative hp">
                                            <select matNativeControl name="" matInput id="SectorId"
                                                formControlName="SectorId" name="SectorId"
                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                <option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                                    {{ sectorItem.Name }}</option>

                                            </select>
                                            <label for="SectorId" class="right-icon-input z-index-div-bottm">
                                            </label>
                                            <mat-error *ngIf="internshipOpportunityForm.controls.SectorId.invalid">
                                                Sector is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Interest*</p>

                                    <div class="input-with-icon position-relative">
                                        <mat-form-field appearance="outline"
                                            class="input-with-icon position-relative hp">
                                            <select matNativeControl name="" matInput id="InterestId"
                                                formControlName="InterestId" name="InterestId"
                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                <option [value]="interestItem.Id"
                                                    *ngFor="let interestItem of interestList">
                                                    {{ interestItem.Name }}</option>

                                            </select>
                                            <label for="InterestId" class="right-icon-input z-index-div-bottm">
                                            </label>
                                            <mat-error *ngIf="internshipOpportunityForm.controls.InterestId.invalid">
                                                Interest is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>


                                <div class="mb-20  px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Start Date*</p>

                                    <div class="input-with-icon position-relative dynamic-text-input-date">
                                        <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                            <input matInput type="date" id="StartDate" formControlName="StartDate"
                                                name="StartDate" class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100  
                                            fw-600 cursor-pointer input-top">
                                            <mat-error *ngIf="internshipOpportunityForm.controls.StartDate.invalid">
                                                Start Date is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="mb-20  px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Duration*</p>

                                    <div class="input-with-icon position-relative ">
                                        <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                            <input matInput type="text" id="Duration" formControlName="Duration"
                                                name="name"
                                                class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "
                                                required="">
                                            <mat-error *ngIf="internshipOpportunityForm.controls.Duration.invalid"
                                                class="error text-danger mt-1 px-1 d-block ">
                                                Duration is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Stipend(₹)*
                                            <!-- <span class="color5 fw-400 fs-12">(Optional)</span> -->
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="Stipend" formControlName="Stipend"
                                                    name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "
                                                    required="">
                                                <mat-error *ngIf="internshipOpportunityForm.controls.Stipend.invalid"
                                                    class="error text-danger mt-1 px-1 d-block ">
                                                    Stipend is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>
                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">No. of Openings*
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="NoOfOpenings"
                                                    formControlName="NoOfOpenings" name="name" digitOnly
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "
                                                    required="">
                                                <mat-error
                                                    *ngIf="internshipOpportunityForm.controls.NoOfOpenings.invalid"
                                                    class="error text-danger mt-1 px-1 d-block ">
                                                    No Of Openings is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Perks<span
                                                class="color-55">(Optional)</span>
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <!-- <input matInput type="text" id="Perks" formControlName="Perks" name="name"
                                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "> -->
                                                <textarea matInput id="Perks" formControlName="Perks" cols="30" rows="2"
                                                    class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea>

                                            </mat-form-field>

                                        </div>
                                    </div>



                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Job Description <span
                                                class="color-55">(Optional)</span>
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <!-- <input matInput type="text" id="JobDescription"
                                                formControlName="JobDescription" name="name"
                                                class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "> -->
                                                <textarea matInput id="JobDescription" formControlName="JobDescription"
                                                    cols="30" rows="2"
                                                    class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>





                                <div class="col-12 px-5px">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Skills Required <span
                                                class="color-55">(Optional)</span></p>
                                        <div class="input-with-icon position-relative">


                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">

                                                <textarea matInput id="RequiredSkills" formControlName="RequiredSkills"
                                                    cols="30" rows="2"
                                                    class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea>

                                            </mat-form-field>

                                        </div>
                                    </div>



                                </div>

                                <div class="mb-20  px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">District Name*</p>

                                    <div class="input-with-icon position-relative">
                                        <mat-form-field appearance="outline"
                                            class="input-with-icon position-relative hp">
                                            <select matNativeControl name="" matInput id="DistrictCode"
                                                formControlName="DistrictCode" name="DistrictCode"
                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                <option [value]="distItem.Id"
                                                    *ngFor="let distItem of filteredDistrictItems">
                                                    {{ distItem.Name }}</option>

                                            </select>
                                            <label for="DistrictCode" class="right-icon-input z-index-div-bottm">
                                            </label>
                                            <mat-error *ngIf="internshipOpportunityForm.controls.DistrictCode.invalid">
                                                District Code is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Pincode*
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="Pincode" formControlName="Pincode"
                                                    name="name" digitOnly minlength="6" maxlength="6"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "
                                                    required="">
                                                <mat-error *ngIf="internshipOpportunityForm.controls.Pincode.invalid"
                                                    class="error text-danger mt-1 px-1 d-block ">
                                                    Pincode is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Branch Name*
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">

                                                <select matNativeControl name="" matInput id="BranchId"
                                                    formControlName="BranchId" name="BranchId" required
                                                    class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                    <option [value]="branch.Id" *ngFor="let branch of branchList">
                                                        {{ branch.Name }}</option>

                                                </select>
                                                <label for="BranchId" class="right-icon-input z-index-div-bottm">
                                                </label>
                                                <mat-error *ngIf="internshipOpportunityForm.controls.BranchId.invalid">
                                                    Branch Name is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>
                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Person of Contact for Internship
                                            <span class="color-55">(Optional)</span>
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="POCForInternship"
                                                    formControlName="POCForInternship" name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 ">

                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Email ID of Person of Contact <span
                                                class="color-55">(Optional)</span>
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="POCEmail" formControlName="POCEmail"
                                                    name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 ">

                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Mobile No. of Person of Contact
                                            <span class="color-55">(Optional)</span>
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="POCMobile" formControlName="POCMobile"
                                                    name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 ">

                                            </mat-form-field>
                                            <mat-error *ngIf="internshipOpportunityForm.controls.POCMobile.invalid">
                                                Please enter a valid phone number
                                            </mat-error>
                                        </div>
                                    </div>



                                </div>
                                <div class="col-12 px-5px">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900"> Working Type
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-radio-group aria-labelledby="Select WorkingType" name="workingType"
                                                formControlName="WorkingType">
                                                <mat-radio-button value="FullTime" style="padding-right: 10px;">Full
                                                    Time</mat-radio-button>
                                                <mat-radio-button value="PartTime">Part Time</mat-radio-button>
                                            </mat-radio-group>
                                            <mat-error *ngIf="internshipOpportunityForm.controls.WorkingType.invalid">
                                                Working Type is required
                                            </mat-error>
                                        </div>

                                    </div>

                                </div>

                                <!-- <div class="col-12 px-5px">
                                    <div class="mb-20">


                                        <div class="input-with-icon position-relative">

                                            <span class="checkbox-space fs-14 text-black mb-1 px-1 fw-900">Is
                                                Active?</span>
                                            <mat-checkbox matInput formControlName="IsActive">
                                            </mat-checkbox>
                                        </div>

                                    </div>



                                </div> -->







                                <button (click)="saveOrUpdateInternshipOpportunityDetails('Publish')"
                                    class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-20 text-uppercase box-shadow-hard mb-10">Post
                                    INternship</button>

                                <button (click)="saveOrUpdateInternshipOpportunityDetails('draft')"
                                    class="bg-transparent fs-15 mt-10 mb-5 color1 fw-900 text-uppercase border-0">Save
                                    DRaft</button>


                            </form>
                        </div>


                    </section>



                    <!--Edit tabs container-->

                    <section *ngIf="tabInter == 'Edit'" id="targetGreen">
                        <p class="fs-22 text-black fw-900  line-h-22 mt-50 mb-20  px-10 text-center">Edit
                            Internship </p>

                        <div class="py-20 px-5px border-theme-2px border-radius-25">

                            <form action="" class="row mx-0 px-0" name="internshipOpportunityFormEdit" novalidate
                                [formGroup]="internshipOpportunityFormEdit">


                                <div class="col-12 px-5px">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Title*</p>

                                        <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                            <input matInput type="text" id="Title" formControlName="Title" name="name"
                                                class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "
                                                required="">
                                            <mat-error *ngIf="internshipOpportunityFormEdit.controls.Title.invalid"
                                                class="error text-danger mt-1 px-1 d-block ">
                                                Title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>



                                </div>

                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Date of Publishing*</p>

                                    <div class="input-with-icon position-relative dynamic-text-input-date">
                                        <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                            <input matInput type="date" id="PublishDate" formControlName="PublishDate"
                                                name="PublishDate" class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100  
                                            fw-600 cursor-pointer input-top">
                                            <mat-error
                                                *ngIf="internshipOpportunityFormEdit.controls.PublishDate.invalid">
                                                Date of Publishing is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                </div>

                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Internship Type*</p>

                                    <div class="input-with-icon position-relative">
                                        <mat-form-field appearance="outline"
                                            class="input-with-icon position-relative hp">
                                            <select matNativeControl name="" matInput id="InternshipType"
                                                formControlName="InternshipType" name="InternshipType"
                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                <option value="">Select Internship Type</option>
                                                <option value="Online">Online</option>
                                                <option value="Offline">Offline</option>
                                                <option value="Hybrid">Hybrid</option>


                                            </select>
                                            <label for="InternshipType" class="right-icon-input z-index-div-bottm">
                                            </label>
                                            <mat-error
                                                *ngIf="internshipOpportunityFormEdit.controls.InternshipType.invalid">
                                                Internship Type is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Sector*</p>

                                    <div class="input-with-icon position-relative">
                                        <mat-form-field appearance="outline"
                                            class="input-with-icon position-relative hp">
                                            <select matNativeControl name="" matInput id="SectorId"
                                                formControlName="SectorId" name="SectorId"
                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                <option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                                    {{ sectorItem.Name }}</option>

                                            </select>
                                            <label for="SectorId" class="right-icon-input z-index-div-bottm">
                                            </label>
                                            <mat-error *ngIf="internshipOpportunityFormEdit.controls.SectorId.invalid">
                                                Sector is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Interest*</p>

                                    <div class="input-with-icon position-relative">
                                        <mat-form-field appearance="outline"
                                            class="input-with-icon position-relative hp">
                                            <select matNativeControl name="" matInput id="InterestId"
                                                formControlName="InterestId" name="InterestId"
                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                <option [value]="interestItem.Id"
                                                    *ngFor="let interestItem of interestList">
                                                    {{ interestItem.Name }}</option>

                                            </select>
                                            <label for="InterestId" class="right-icon-input z-index-div-bottm">
                                            </label>
                                            <mat-error
                                                *ngIf="internshipOpportunityFormEdit.controls.InterestId.invalid">
                                                Interest is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>


                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Start Date*</p>

                                    <div class="input-with-icon position-relative dynamic-text-input-date">
                                        <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                            <input matInput type="date" id="StartDate" formControlName="StartDate"
                                                name="StartDate" class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100  
                                            fw-600 cursor-pointer input-top">
                                            <mat-error *ngIf="internshipOpportunityFormEdit.controls.StartDate.invalid">
                                                Start Date is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">Duration*</p>

                                    <div class="input-with-icon position-relative ">
                                        <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                            <input matInput type="text" id="Duration" formControlName="Duration"
                                                name="name"
                                                class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "
                                                required="">
                                            <mat-error *ngIf="internshipOpportunityFormEdit.controls.Duration.invalid"
                                                class="error text-danger mt-1 px-1 d-block ">
                                                Duration is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Stipend(₹)*
                                            <!-- <span class="color5 fw-400 fs-12">(Optional)</span> -->
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="Stipend" formControlName="Stipend"
                                                    name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "
                                                    required="">
                                                <mat-error
                                                    *ngIf="internshipOpportunityFormEdit.controls.Stipend.invalid"
                                                    class="error text-danger mt-1 px-1 d-block ">
                                                    Stipend is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>
                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">No. of Openings*
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="NoOfOpenings"
                                                    formControlName="NoOfOpenings" name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "
                                                    required="">
                                                <mat-error
                                                    *ngIf="internshipOpportunityFormEdit.controls.NoOfOpenings.invalid"
                                                    class="error text-danger mt-1 px-1 d-block ">
                                                    NoOfOpenings is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Perks<span
                                                class="color-55">(Optional)</span>
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="Perks" formControlName="Perks"
                                                    name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 ">

                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Job Description <span
                                                class="color-55">(Optional)</span>
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="JobDescription"
                                                    formControlName="JobDescription" name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 ">

                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>





                                <div class="col-12 px-5px">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Skills Required <span
                                                class="color-55">(Optional)</span></p>
                                        <div class="input-with-icon position-relative">


                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">

                                                <textarea matInput id="RequiredSkills" formControlName="RequiredSkills"
                                                    cols="30" rows="2"
                                                    class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea>

                                            </mat-form-field>

                                        </div>
                                    </div>



                                </div>

                                <div class="mb-20 px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <p class="fs-14 text-black mb-1 px-1 fw-900">District Name*</p>

                                    <div class="input-with-icon position-relative">
                                        <mat-form-field appearance="outline"
                                            class="input-with-icon position-relative hp">
                                            <select matNativeControl name="" matInput id="DistrictCode"
                                                formControlName="DistrictCode" name="DistrictCode"
                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                <option [value]="distItem.Id"
                                                    *ngFor="let distItem of filteredDistrictItems">
                                                    {{ distItem.Name }}</option>

                                            </select>
                                            <label for="DistrictCode" class="right-icon-input z-index-div-bottm">
                                            </label>
                                            <mat-error
                                                *ngIf="internshipOpportunityFormEdit.controls.DistrictCode.invalid">
                                                District Code is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Pincode*
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="Pincode" formControlName="Pincode"
                                                    name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "
                                                    required="">
                                                <mat-error
                                                    *ngIf="internshipOpportunityFormEdit.controls.Pincode.invalid"
                                                    class="error text-danger mt-1 px-1 d-block ">
                                                    Pincode is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Branch Name*
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">

                                                <select matNativeControl name="" matInput id="BranchId"
                                                    formControlName="BranchId" name="BranchId" required
                                                    class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                    <option [value]="branch.Id" *ngFor="let branch of branchList">
                                                        {{ branch.Name }}</option>

                                                </select>
                                                <label for="BranchId" class="right-icon-input z-index-div-bottm">
                                                </label>
                                                <mat-error
                                                    *ngIf="internshipOpportunityFormEdit.controls.BranchId.invalid">
                                                    Branch Name is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>
                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Person of Contact for Internship
                                            <span class="color-55">(Optional)</span>
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="POCForInternship"
                                                    formControlName="POCForInternship" name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 ">

                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Email ID of Person of Contact <span
                                                class="color-55">(Optional)</span>
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="POCEmail" formControlName="POCEmail"
                                                    name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 ">

                                            </mat-form-field>
                                        </div>
                                    </div>



                                </div>

                                <div class="px-5px" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900">Mobile No. of Person of Contact
                                            <span class="color-55">(Optional)</span>
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input matInput type="text" id="POCMobile" formControlName="POCMobile"
                                                    name="name"
                                                    class="border-radius-10 border-theme-2px fs-18 py-2 w-100 ">

                                            </mat-form-field>
                                            <mat-error *ngIf="internshipOpportunityFormEdit.controls.POCMobile.invalid">
                                                Please enter a valid phone number
                                            </mat-error>
                                        </div>
                                    </div>



                                </div>
                                <div class="col-12 px-5px">
                                    <div class="mb-20">
                                        <p class="fs-14 text-black mb-1 px-1 fw-900"> Working Type
                                        </p>

                                        <div class="input-with-icon position-relative">
                                            <mat-radio-group aria-labelledby="Select WorkingType" name="workingType"
                                                formControlName="WorkingType">
                                                <mat-radio-button value="FullTime">Full Time</mat-radio-button>
                                                <mat-radio-button value="PartTime">Part Time</mat-radio-button>
                                            </mat-radio-group>
                                            <mat-error
                                                *ngIf="internshipOpportunityFormEdit.controls.WorkingType.invalid">
                                                Working Type is required
                                            </mat-error>
                                        </div>

                                    </div>

                                </div>

                                <!-- <div class="col-12 px-5px">
                                    <div class="mb-20">


                                        <div class="input-with-icon position-relative">

                                            <span class="checkbox-space fs-14 text-black mb-1 px-1 fw-900">Is
                                                Active?</span>
                                            <mat-checkbox matInput formControlName="IsActive">
                                            </mat-checkbox>
                                        </div>

                                    </div>



                                </div> -->







                                <button (click)="UpdateInternshipOpportunityDetails()"
                                    class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-20 text-uppercase box-shadow-hard mb-10">Post
                                    INternship</button>




                            </form>
                        </div>


                    </section>







                </div>
            </div>
        </div>
    </div>
</section>