import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { RaiseSafetyConcerService } from './raise-safety-concer.service';
import { RaiseSafetyConcerModel } from './raise-safety-concer.model';


@Component({
  providers: [AppConstants],
  selector: 'igmite-raise-safety-concer',
  templateUrl: './raise-safety-concer.component.html',
  styleUrls: ['./raise-safety-concer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class RaiseSafetyConcerComponent extends BaseComponent<RaiseSafetyConcerModel> implements OnInit {
  public RaiseSafetyConcerForm: FormGroup;
  public loginModel: LoginModel;
  public raiseSafetyConcerModel: RaiseSafetyConcerModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  current = 'Report'
  type: any
  title: any

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private zone: NgZone,
    private deviceService: DeviceDetectorService,
    private raiseSafetyConcerService: RaiseSafetyConcerService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };
    this.raiseSafetyConcerModel = new RaiseSafetyConcerModel();

    this.RaiseSafetyConcerForm = this.createRaiseSafetyConcerForm();

  }

  ngOnInit(): void {
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });
    let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
    employeeMenuItems.forEach(employeeMenuItems => {
      employeeMenuItems.setAttribute('style', 'display: none !important');
    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }

    const firstTime = localStorage.getItem('key')
    if (!firstTime) {
      localStorage.setItem('key', 'loaded')
      window.location.reload();

    } else {
      localStorage.removeItem('key')
    }

  }



  //Create RaiseSafetyConcerForm and returns {FormGroup}
  createRaiseSafetyConcerForm(): FormGroup {
    return this.formBuilder.group({
      TypeOfRequest: new FormControl(this.raiseSafetyConcerModel.TypeOfRequest, [Validators.required]),
      RequestDesc: new FormControl(''),
    });
  }

  RaiseSafetyConcer() {

    // if(this.current === 'Call'){
    //   this.raiseSafetyConcerModel.TypeOfRequest = this.type + ': MobileNo :' + this.RaiseSafetyConcerForm.value.TypeOfRequest;
    //   this.raiseSafetyConcerModel.RequestTitle = 'Raise risk CallBack';


    // }else{
    //   this.raiseSafetyConcerModel.TypeOfRequest = this.RaiseSafetyConcerForm.value.TypeOfRequest;
    //   this.raiseSafetyConcerModel.RequestTitle = 'Raise Risk Report';

    // }
    this.raiseSafetyConcerModel.TypeOfRequest = this.RaiseSafetyConcerForm.value.TypeOfRequest;
    this.raiseSafetyConcerModel.RequestTitle = 'Raise Risk Report';
    this.raiseSafetyConcerModel.RequestDesc = this.RaiseSafetyConcerForm.value.RequestDesc;
    this.raiseSafetyConcerModel.AuthUserId = this.UserModel.UserTypeId;
    this.raiseSafetyConcerModel.RequestFrom = this.UserModel.UserTypeId;
    this.raiseSafetyConcerService.CreateOrUpdateRequests(this.raiseSafetyConcerModel)
      .subscribe((logResp: any) => {
        if (logResp.Success == true) {
          let RaiseSafetyConcer = 'RaiseSafetyConcer'
          sessionStorage.setItem('ThankYou', RaiseSafetyConcer);
          this.router.navigateByUrl(RouteConstants.ThankYou);
          //this.RaiseSafetyConcerForm.reset();


          // this.zone.run(() => {
          //   this.showActionMessage(
          //     this.Constants.Messages.RecordSavedMessage,
          //     this.Constants.Html.SuccessSnackbar
          //   );
          // })
        }
        else {
          this.showErrorMessage(
            'Invalid  Raise Safety Conce',
            'info-snackbar'
          );
        }
      });
  }

  toggle(tab) {
    this.RaiseSafetyConcerForm.reset();

    this.current = tab;
    if (tab === 'Report') {
      this.title = 'Raise Risk Report';
      this.type = 'Raise risk Report'
    } else {
      this.title = 'Raise Risk CallBack';
      this.type = 'Raise risk CallBack'

    }
  }

  NextPage() {
      if (this.UserModel.InternshipId != null) {
        this.router.navigateByUrl(RouteConstants.DashboardNew);
      } else {
        this.router.navigateByUrl(RouteConstants.HomeMain);
      }
  }


}
