<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu-emp></igmite-side-menu-emp>
                </div>
                <div class="col-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
                    <!-- <div
                        class=" px-10  pb-3 bg-white py-18 pb-0 overflow-hidden max-w-800 mx-auto ">

                        <h1
                            class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-15  line-h-30 mb-30">
                            Attendance Received</h1>

                        <div class="col-12 col-lg-10 right-content px-lg-35 px-0 pb-5 bg-white py-18"> -->

                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">

                        <div class="col-12 px-0 text-center">

                            <div class="user-profile-dash position-relative max-w-max-cont mx-auto mt-lg-0">
                                <img [src]="PhotoUrl" *ngIf="FileName != null;else other_content"
                                    class="theme-bx-shadow" alt="Profile Image" srcset>
                                <ng-template #other_content><img src="assets/upload/student.jpg" class="theme-bx-shadow"
                                        alt="Profile Image" srcset></ng-template>
                                <div
                                    class="remove-profile-pic position-absolute cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-30 w-30">
                                    <i class="fa-solid fa-camera"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 class="text-black fw-900 fs-22 text-center mt-20"> Profile Photo </h2>
                    <div class="py-20 px-15 border-theme-2px border-radius-25 max-w-600 mx-auto mt-30">
                        <form [formGroup]="empProfileDetailsForm">
                            <div class="mb-15">
                                <p class="fs-14 text-black mb-0 px-1 fw-900">Name</p>
                                <div class=" position-relative">
                                    <mat-form-field class="border-radius-10 border-theme-2px fs-16 py-2 w-100 px-3">
                                        <mat-label></mat-label>
                                        <input matInput name="POCName" formControlName="POCName">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="mb-15">
                                <p class="fs-14 text-black mb-0 px-1 fw-900">Mobile</p>
                                <div class=" position-relative">
                                    <mat-form-field fxFlex.lt-xs="20"
                                        class="border-radius-10 border-theme-2px fs-16 py-2 w-100 px-3">

                                        <input matInput name="POCMobile" formControlName="POCMobile">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="mb-15">
                                <p class="fs-14 text-black mb-0 px-1 fw-900">Email</p>
                                <div class=" position-relative">
                                    <mat-form-field fxFlex.lt-xs="20"
                                        class="border-radius-10 border-theme-2px fs-16 py-2 w-100 px-3">

                                        <input matInput name="LoginId" disabled formControlName="LoginId">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="mb-15">
                                <p class="fs-14 text-black mb-0 px-1 fw-900">Company Name</p>
                                <div class=" position-relative">
                                    <mat-form-field class="border-radius-10 border-theme-2px fs-16 py-2 w-100 px-3">

                                        <input matInput name="OrganizationName" [disabled]="disabledField"
                                            formControlName="OrganizationName">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="mb-15">
                                <p class="fs-14 text-black mb-0 px-1 fw-900">Company Logo</p>
                                <div class="d-flex">
                                    <div
                                        class="h-70 w-70 w-50 p-1 d-flex justify-content-center align-items-center border-theme-2px border-radius-15 mr-20">
                                        <img [src]="PhotoUrl" class="border-theme-2px img-fluid" for="uploadlogo" alt
                                            srcset>
                                    </div>
                                    <div class="">
                                        <label class="fs-16 color1 fw-900 mb-0 mt-5px" for="uploadlogo">Upload
                                            Logo</label>
                                        <input type="file" id="uploadlogo" (change)="onSelectFile($event)"
                                            class="visually-hidden" />
                                        <p><a (click)="removeImg()"
                                                class="text-danger fs-16 fw-900 mb-0 mt-10">Remove</a></p>
                                    </div>

                                </div>
                            </div>
                            <div class="mb-20">
                                <p class="fs-16 text-black mb-0 px-1 fw-900">Company address</p>
                                <div class="input-with-icon position-relative">
                                    <textarea cols="30" rows="5" formControlName="BranchAddress"
                                        class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea>
                                </div>
                            </div>

                            <!-- <div class="mb-15">
                                <p class="fs-16 text-black mb-0 px-1 fw-900">Add Google Map Location</p>
                                <div class=" position-relative">
                                    <mat-form-field class="border-radius-10 border-theme-2px fs-16 py-2 w-100 px-3">

                                        <input matInput name="Map" formControlName="Map">
                                    </mat-form-field>
                                </div>
                            </div> -->
                            <button (click)="saveOrUpdateEmployerProfileInfo()"
                                class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-25 text-uppercase box-shadow-hard mb-10">Save</button>
                            <a [routerLink]="['/employer-profile']" href="/"
                                class="text-decoration-none text-center d-block fs-18 fw-900 mt-20">Back</a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>