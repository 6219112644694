import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { DiaryEntryListingService } from './diary-entry-listing.service';
import { FileUploadModel } from 'app/models/file.upload.model';
import { DatePipe } from '@angular/common'
import { DialogService } from 'app/common/confirm-dialog/dialog.service';


import * as $ from 'jquery';


@Component({
  providers: [AppConstants],
  selector: 'igmite-diary-entry-listing',
  templateUrl: './diary-entry-listing.component.html',
  styleUrls: ['./diary-entry-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class DiaryEntryListingComponent extends BaseComponent<LoginModel> implements OnInit {
  public diaryEntryForm: FormGroup;
  public dashboardForm: FormGroup;
  public fileUploadModel: FileUploadModel;

  public loginModel: LoginModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  dashboardData: any
  dashboardTopData: any
  profileDetails: any
  color = 'primary';
  mode = 'buffer';
  value = 50;
  bufferValue = 100;
  DailyDiary: any;
  myFiles: string[] = [];
  listQuestionResponse: any = []
  listDiaryEntry: any = []
  ActiveTab = 'Close'
  ActiveTabCancle = 'open'
  InternshipById: any
  todayDate: any
  editItem: any
  FileName: any
  editItem1: any
  flag: any
  maxDate: any
  minDate: any
  urls = [];
  filesArray: any = [];
  imageBasePath: any
  StudentID: string;
  InternshipId: any;
  EmployerRedirected: boolean = false;
  fileType: any
  studentLogin: any;
  roleCode: any;
  valueT='ABC'
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private diaryEntryListingService: DiaryEntryListingService,
    public zone: NgZone,
    public datepipe: DatePipe,
    private dialogService: DialogService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);
    this.fileUploadModel = new FileUploadModel();

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };

    this.diaryEntryForm = this.createDiaryEntryForm();
    this.dashboardForm = this.createdashboardForm();



  }

  createDiaryEntryForm(): FormGroup {
    return this.formBuilder.group({
      StartDateTime: new FormControl('', [Validators.required]),
      // EndDateTime: new FormControl('',[Validators.required]),
    });

  }

  createdashboardForm(): FormGroup {
    return this.formBuilder.group({
      Date: new FormControl('', [Validators.required]),
      HoursSpent: new FormControl('', Validators.required),
      TodayLearning: new FormControl('', [Validators.required]),
    });

  }

  ngOnInit(): void {
    this.imageBasePath = this.Constants.CommonImageUrl.Attachments
    this.roleCode = this.UserModel.RoleCode
    this.studentLogin = JSON.parse(localStorage.getItem('currentTab'));
    if (this.UserModel.RoleCode == 'ST') {
      let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
      employeeMenuItems.forEach(employeeMenuItems => {
        employeeMenuItems.setAttribute('style', 'display: none !important');
      });
     // this.GetDailyDiaryEntries();
    }

    // let elementIn: any = document.querySelectorAll(".withoutlogin");
    // elementIn.forEach(elementIn => {
    //   elementIn.setAttribute('style', 'display: none !important');

    // });

    // let gratitudeListing: any = document.querySelectorAll(".within-employee-login");
    // gratitudeListing.forEach(gratitudeListing => {
    //   gratitudeListing.setAttribute('style', 'display: none !important');

    // });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }



    var date = new Date();
    this.maxDate = this.datepipe.transform(date, 'yyyy-MM-dd');

    var date1 = new Date(); // Now
   
    this.minDate = this.datepipe.transform(this.UserModel.InternshipActualStartDate, 'yyyy-MM-dd');
    this.diaryEntryForm.get('StartDateTime').setValue(this.maxDate);
    var Inter = JSON.parse(sessionStorage.getItem('item'));
    if (Inter !== null) {
      this.InternshipById = Inter

    } else {
      this.InternshipById = this.UserModel.InternshipId
    }

    var proId = { DataId: this.UserModel.UserTypeId }
    this.diaryEntryListingService.GetStudentProfileConfirmationById(proId).subscribe((profileResp: any) => {
      if (profileResp.Success == true) {
        this.zone.run(() => {
          this.profileDetails = profileResp.Results[0]
          this.profileDetails.PhotoUrl = this.Constants.CommonImageUrl.Account + this.profileDetails.PhotoUrl;
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid UpdateStudentInternshipsStatus ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

    this.diaryEntryListingService.GetTopPerformerStudentList(proId).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.zone.run(() => {
          this.dashboardTopData = dashboardResp.Results
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid GetTopPerformerStudentList ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

    this.diaryEntryListingService.GetStudentDashboard(proId).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.zone.run(() => {
          this.dashboardData = dashboardResp.Results[0]
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid GetStudentDashboard ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

    // code used for Employer redirection from Ongoing internship 

    if ('EmployerRedirected' == sessionStorage.getItem('EmployerRedirected')) {
      this.StudentID = sessionStorage.getItem("StudentID");
      this.InternshipId = sessionStorage.getItem("InternshipId");
      this.EmployerRedirected = true;

      this.employeeeRedirection(this.StudentID);
    }
    //end code Employer redirection

  }
  // method used when employer redirected from on click of Ongoing internship
  employeeeRedirection(studetId: string) {
    let hideIgmiteSidemenu: any = document.querySelectorAll("igmite-side-menu");
    hideIgmiteSidemenu.forEach(hideIgmiteSidemenu => {
      hideIgmiteSidemenu.setAttribute('style', 'display: none !important');

    });

    let gratitudeListing: any = document.querySelectorAll(".within-employee-login");
    gratitudeListing.forEach(gratitudeListing => {
      gratitudeListing.setAttribute('style', 'display: inline-block !important');

    });

    let elementIn: any = document.querySelectorAll(".withinlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    let elementIn1: any = document.querySelectorAll(".withoutlogin");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    var proId = { DataId: studetId }
    this.diaryEntryListingService.GetStudentProfileConfirmationById(proId).subscribe((profileResp: any) => {
      if (profileResp.Success == true) {
        this.zone.run(() => {
          this.profileDetails = profileResp.Results[0]
          this.profileDetails.PhotoUrl = this.Constants.CommonImageUrl.Account + this.profileDetails.PhotoUrl;
          this.IsLoading = false;
        });

      }
      else {
      }


    }, error => {
    });

    this.diaryEntryListingService.GetTopPerformerStudentList(proId).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.zone.run(() => {
          this.dashboardTopData = dashboardResp.Results
          this.IsLoading = false;

        });
      }
      else {
      }


    }, error => {
    });

    this.diaryEntryListingService.GetStudentDashboard(proId).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.zone.run(() => {
          this.dashboardData = dashboardResp.Results[0]
          this.IsLoading = false;

        });
      }
      else {

      }


    }, error => {
    });
  }
  // end method used after employer redirection from Ongoing internship

  Edit(list) {
    this.ActiveTab = 'Close';
    this.ActiveTabCancle = 'edit'
    this.todayDate = list[0].dailyDiaryDateTime
    this.editItem = list[0].listEntries
    this.editItem1 = list[0].listAttachments
  }
  Cancle() {
    this.ActiveTabCancle = 'open'
    this.GetDailyDiaryEntries()
  }

  GetDailyDiaryEntries() {

    if (this.EmployerRedirected == true) {
      var dayEntriy = {
        "StudentID": this.StudentID,
        "InternshipId": this.InternshipId,
        "StartDateTime": this.diaryEntryForm.value.StartDateTime,
        "EndDateTime": this.diaryEntryForm.value.StartDateTime
      }
    } else {
      var dayEntriy = {
        "StudentID": this.UserModel.UserTypeId,
        "InternshipId": this.InternshipById,
        "StartDateTime": this.diaryEntryForm.value.StartDateTime,
        "EndDateTime": this.diaryEntryForm.value.StartDateTime
      }
    }
    this.DailyDiary = ''
    this.todayDate = this.diaryEntryForm.value.StartDateTime
    this.diaryEntryListingService.GetDailyDiaryEntries(dayEntriy).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.ActiveTab = 'edit'
        this.ActiveTabCancle = 'open'


        this.zone.run(() => {
          this.flag = dashboardResp.Results[0].listEntries
          // let imgList: any = dashboardResp.Results[0].listAttachments
          // dashboardResp.Results[0].listAttachments.map(item => {  
          //   item.AttachmentPath= item.AttachmentPath== null ? '' : (item.AttachmentPath.includes('https') ? item.AttachmentPath : environment.ApiBaseUrlImageAttachments + item.AttachmentPath);
          //  return item.AttachmentPath;

          // })
          this.listDiaryEntry = dashboardResp.Results;


          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid GetDailyDiaryEntries ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

  }


  CreateQuestionResponse(item, value) {
   
    let Question = {
      QuestionId: item.QuestionId,
      StudentID: this.UserModel.UserTypeId,
      UserId: this.UserModel.UserTypeId,
      UserRole: this.UserModel.RoleCode,
      UserRoleId: this.UserModel.UserRoleId,
      QuestionResponseId: item.QuestionResponseId,
      AuthUserId: this.UserModel.UserTypeId,
      QuestionAnswerOptionId: '00000000-0000-0000-0000-000000000000',
      Remarks: 'Question Answer',
      InternshipId: this.InternshipById,
      RequestType: 4,
      IsActive: true,
      AnswerText: item.AnswerText,
      EntryDate: item.EntryDate
    }
    if(item.AnswerText != ''){
      
      this.dialogService
      .openConfirmDialog('Are you sure if you want to edit the diary entry?')
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.diaryEntryListingService.CreateQuestionResponse(Question).subscribe((highlightOneResp: any) => {
            if (highlightOneResp.Success == true) {
              sessionStorage.setItem('EntryDate', JSON.stringify(item.EntryDate));
              this.valueT='ABC'
              
              this.zone.run(() => {
                this.IsLoading = false;
                // this.showActionMessage(
                //   this.Constants.Messages.RecordSavedMessage,
                //   this.Constants.Html.SuccessSnackbar
                // );
              });
            }
            else {
              this.showErrorMessage(
                // 'Invalid CreateQuestion ',
                highlightOneResp.Errors[0],

                'info-snackbar'
              );
            }


          }, error => {
          });

        }
      });
    }else{
      this.valueT=''
    }
   
  }
  onSelectFile(item, event) {
    console.log(event.target.files);

    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.urls.push(event.target.result);

        }

        reader.readAsDataURL(event.target.files[i]);
        this.getFileDetailsAdd(item, event)
      }
    } 
  }

  removeSelectedFileEdit(index) {

    // Delete the item from fileNames list
    this.urls.splice(index, 1);
    this.filesArray.splice(index, 1);
    // delete file from FileList
  }

  onSelectFileEdit(event) {
    console.log(event.target.files);

    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.urls.push(event.target.result);

        }

        reader.readAsDataURL(event.target.files[i]);
        this.getFileDetails(event)
      }
    } ``
  }

  removeSelectedFile(index) {

    // Delete the item from fileNames list
    this.urls.splice(index, 1);
    this.filesArray.splice(index, 1);
    // delete file from FileList
  }
  getFileDetails(e) {
    this.getUploadedFileData(e, this.Constants.DocumentType.Attachments).then((response: FileUploadModel) => {
      this.fileUploadModel = response;
      console.log(this.fileUploadModel.FileType)
      if (this.fileUploadModel) {
        if (this.fileUploadModel.FileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          this.fileType = 'Document'
        } else if (this.fileUploadModel.FileType == 'video/mp4') {
          this.fileType = 'Video'
        } else if (this.fileUploadModel.FileType == 'application/pdf') {
          this.fileType = 'PDF'
        } else if (this.fileUploadModel.FileType == 'image/png') {
          this.fileType = 'Image'
        }else if (this.fileUploadModel.FileType == 'image/jpeg') {
          this.fileType = 'Image'
        }else if(this.fileUploadModel.FileType == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'){
          this.fileType = 'PPT'
        }
      }

      if (this.fileType) {
        this.filesArray.push({
          AuthUserId: this.UserModel.UserTypeId,
          RequestType: 0,
          IsActive: true,

          AttatchmentId: "00000000-0000-0000-0000-000000000000",
          SectionType: "DailyDiary",
          SectionId: "12345678-9012-3456-7890-123456789012",
          AttachmentType: this.fileType,
          AttachmentPath: this.fileUploadModel.FileName,
          AttachmentDesc: this.fileUploadModel.FileName,
          Sequence: 1,
          AttachmentDate: this.todayDate,
          AccountId: this.UserModel.UserTypeId,
          AttachmentsFile: this.fileUploadModel
        })
      }

      this.FileName = response.FileName

      // this.CreateAttachments()
    });
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
  }

  removeImg() {
    this.FileName = "";
  }

  CreateAttachments() {
    for (const i of this.filesArray) {
      let file = {
        AuthUserId: this.UserModel.UserTypeId,
        RequestType: 0,
        IsActive: true,

        AttatchmentId: "00000000-0000-0000-0000-000000000000",
        SectionType: "DailyDiary",
        SectionId: "12345678-9012-3456-7890-123456789012",
        AttachmentType: i.AttachmentType,
        AttachmentPath: i.AttachmentPath,
        AttachmentDesc: i.AttachmentDesc,
        Sequence: 1,
        AttachmentDate: this.todayDate,
        AccountId: this.UserModel.UserTypeId,
        AttachmentsFile: i.AttachmentsFile

      }
      this.diaryEntryListingService.CreateAttachments(file).subscribe((highlightOneResp: any) => {

        if (highlightOneResp.Success == true) {
          this.zone.run(() => {
            this.IsLoading = false;
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );
          });
        }
        else {
          this.showErrorMessage(
            // 'Invalid Create Attachments ',
            highlightOneResp.Errors[0],

            'info-snackbar'
          );
        }


      }, error => {
      });
    }
  }

  Add() {
    this.diaryEntryListingService.GetDailyDiary().subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        var Inter = JSON.parse(sessionStorage.getItem('item'));
        if (Inter !== null) {
          this.InternshipById = Inter

        } else {
          this.InternshipById = this.UserModel.InternshipId
        }
        this.zone.run(() => {
          this.DailyDiary = dashboardResp.Results
          this.DailyDiary.map(item => {
            if (item.QuestionAnswerType === 'Date') {
              item.AnswerText = this.todayDate
              item.EntryDate = this.todayDate

            } else if (item.QuestionAnswerType === 'File') {
              item.AnswerText = 'No Image'
              item.QuestionResponseId = "00000000-0000-0000-0000-000000000000",
                item.QuestionAnswerOptionId = "00000000-0000-0000-0000-000000000000",
                item.EntryDate = this.todayDate,
                item.AttachmentsFile = this.fileUploadModel
            } else {
              item.AnswerText = ''

            }
            item.UserId = this.UserModel.UserTypeId
            item.UserRoleId = this.UserModel.UserRoleId
            item.AuthUserId = this.UserModel.UserTypeId
            item.InternshipId = this.InternshipById
            item.UserRole = this.UserModel.RoleCode

          })
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        this.showErrorMessage(
          // 'Invalid GetDailyDiary ',
          dashboardResp.Errors[0],
          'info-snackbar'
        );
      }


    }, error => {
    });
  }

  CreateDailyDiaryEntry(items) {


    let Question = {
      QuestionId: '00000000-0000-0000-0000-000000000000',
      StudentID: this.UserModel.UserTypeId,
      UserId: this.UserModel.UserTypeId,
      UserRole: this.UserModel.RoleCode,
      QuestionResponseId: '00000000-0000-0000-0000-000000000000',
      AuthUserId: this.UserModel.UserTypeId,
      QuestionAnswerOptionId: '00000000-0000-0000-0000-000000000000',
      Remarks: 'Question Answer',
      InternshipId: this.InternshipById,
      RequestType: 0,
      IsActive: true,
      listQuestionResponse: items


    }
    this.CreateAttachmentsAdd();
    this.diaryEntryListingService.CreateDailyDiaryEntry(Question).subscribe((highlightOneResp: any) => {
      if (highlightOneResp.Success == true) {
        this.zone.run(() => {
          sessionStorage.setItem('EntryDate', JSON.stringify(this.todayDate));

          this.router.navigateByUrl(RouteConstants.HighlightOne);

          this.filesArray = [];
          this.IsLoading = false;
          this.showActionMessage(
            this.Constants.Messages.RecordSavedMessage,
            this.Constants.Html.SuccessSnackbar
          );
        });
      }
      else {
        this.showErrorMessage(
          // 'Invalid CreateQuestion ',
          highlightOneResp.Errors[0],

          'info-snackbar'
        );
      }


    }, error => {
    });
  }

  onChangeAdd(item, value) {


    item.AnswerText = value
    item.QuestionResponseId = "00000000-0000-0000-0000-000000000000",
      item.QuestionAnswerOptionId = "00000000-0000-0000-0000-000000000000",
      item.EntryDate = this.todayDate




  }

  getFileDetailsAdd(item, e) {
    this.getUploadedFileData(e, this.Constants.DocumentType.Attachments).then((response: FileUploadModel) => {
      this.fileUploadModel = response;
      if (this.fileUploadModel) {
        if (this.fileUploadModel.FileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          this.fileType = 'Document'
        } else if (this.fileUploadModel.FileType == 'video/mp4') {
          this.fileType = 'Video'
        } else if (this.fileUploadModel.FileType == 'application/pdf') {
          this.fileType = 'PDF'
        } else if (this.fileUploadModel.FileType == 'image/png' ) {
          this.fileType = 'Image'
        }else if (this.fileUploadModel.FileType == 'image/jpeg') {
          this.fileType = 'Image'
        }else if(this.fileUploadModel.FileType == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'){
          this.fileType = 'PPT'
        }
   
      }

      if (this.fileType) {
        this.filesArray.push({
          AuthUserId: this.UserModel.UserTypeId,
          RequestType: 0,
          IsActive: true,

          AttatchmentId: "00000000-0000-0000-0000-000000000000",
          SectionType: "DailyDiary",
          SectionId: "12345678-9012-3456-7890-123456789012",
          AttachmentType: this.fileType,
          AttachmentPath: this.fileUploadModel.FileName,
          AttachmentDesc: this.fileUploadModel.FileName,
          Sequence: 1,
          AttachmentDate: this.todayDate,
          AccountId: this.UserModel.UserTypeId,
          AttachmentsFile: this.fileUploadModel
        })
      }

      this.FileName = response.FileName
      if (response) {
        item.AnswerText = this.FileName
        item.QuestionResponseId = "00000000-0000-0000-0000-000000000000",
          item.QuestionAnswerOptionId = "00000000-0000-0000-0000-000000000000",
          item.EntryDate = this.todayDate,
          item.AttachmentsFile = this.fileUploadModel

      }

    });
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
  }

  CreateAttachmentsAdd() {
    if (this.filesArray.length != 0) {
      for (const i of this.filesArray) {
        let file = {
          AuthUserId: this.UserModel.UserTypeId,
          RequestType: 0,
          IsActive: true,

          AttatchmentId: "00000000-0000-0000-0000-000000000000",
          SectionType: "DailyDiary",
          SectionId: "12345678-9012-3456-7890-123456789012",
          AttachmentType: i.AttachmentType,
          AttachmentPath: i.AttachmentPath,
          AttachmentDesc: i.AttachmentDesc,
          Sequence: 1,
          AttachmentDate: this.todayDate,
          AccountId: this.UserModel.UserTypeId,
          AttachmentsFile: i.AttachmentsFile

        }

        this.diaryEntryListingService.CreateAttachments(file).subscribe((highlightOneResp: any) => {
          if (highlightOneResp.Success == true) {
            this.zone.run(() => {
              this.IsLoading = false;
              this.showActionMessage(
                this.Constants.Messages.RecordSavedMessage,
                this.Constants.Html.SuccessSnackbar
              );
            });
          }
          else {
            this.showErrorMessage(
              // 'Invalid CreateQuestion ',
              highlightOneResp.Errors[0],

              'info-snackbar'
            );
          }


        }, error => {
        });
      }
    }

    // let file = {
    //   AuthUserId: this.UserModel.UserTypeId,
    //   RequestType: 0,
    //   IsActive: true,

    //   AttatchmentId: "00000000-0000-0000-0000-000000000000",
    //   SectionType: "DailyDiary",
    //   SectionId: "12345678-9012-3456-7890-123456789012",
    //   AttachmentType: "Image",
    //   AttachmentPath: " this.FileName",
    //   AttachmentDesc: "Testing Image Attachments",
    //   Sequence: 1,
    //   AttachmentDate: this.todayDate,
    //   AccountId: this.UserModel.UserTypeId,
    //   AttachmentsFile: this.fileUploadModel

    // }
  }

  NextPage() {
    this.CreateAttachments();
    this.filesArray = [];
    sessionStorage.setItem('EntryDate', JSON.stringify(this.todayDate));

    this.router.navigateByUrl(RouteConstants.DashboardNew);

  }

}
