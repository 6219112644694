import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError, forkJoin } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from 'app/models/user.model';


@Injectable({
    providedIn: "root"
})
export class UnpublishedIntershipsService {


    constructor(private http: BaseService) { }


    getInternshipById(internshipId: string) {
        let requestParams = {
          DataId: internshipId
        };
    
        return this.http.HttpPost(this.http.Services.InternshipOpportunity.GetUnPublishedInternshipsForEmployer, requestParams)
          .pipe(
            retry(this.http.Services.RetryServieNo),
            catchError(this.http.HandleError),
            tap(function (response: any) {
              return response.Results;
            })
          );
      }

      GetUnPublishedInternshipsForEmployer(requestParams: any) {
    
    
        return this.http.HttpPost(this.http.Services.InternshipOpportunity.GetUnPublishedInternshipsForEmployer, requestParams)
          .pipe(
            retry(this.http.Services.RetryServieNo),
            catchError(this.http.HandleError),
            tap(function (response: any) {
              return response.Results;
            })
          );
      }
      
      createOrUpdateInternship(formData: any) {
        return this.http
          .HttpPost(this.http.Services.InternshipOpportunity.CreateOrUpdate, formData)
          .pipe(
            retry(this.http.Services.RetryServieNo),
            catchError(this.http.HandleError),
            tap(response => {
              return response;
            })
          );
      }

    getDropdownforInternshipOpportunity(userModel: UserModel): Observable<any[]> {
        let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' }, false);
        let employerRequest = this.http.GetMasterDataByType({ DataType: 'Employers', SelectTitle: 'Employer' });
        let districtRequest = this.http.GetMasterDataByType({ DataType: 'Districts', SelectTitle: 'District' });
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId: userModel.UserTypeId, SelectTitle: 'Academic Year' });
        let branchRequest = this.http.GetMasterDataByType({ DataType: 'EmpBranch', ParentId: userModel.EmployerId, SelectTitle: 'Branch Name' })
        let interestRequest = this.http.GetMasterDataByType({ DataType: 'Interests', SelectTitle: 'Interests' }, false);

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([sectorRequest, employerRequest, districtRequest, academicYearRequest, branchRequest, interestRequest]);
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = "Unknown error!";
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }
}
