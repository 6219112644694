import { Component, OnInit, ViewEncapsulation, NgZone, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { CertificateService } from './certificate.service';

declare var require: any;

// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
// const htmlToPdfmake = require("html-to-pdfmake");
// (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// import { jsPDF } from 'jspdf';
// import { debug } from 'console';
@Component({
  providers: [AppConstants],
  selector: 'igmite-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CertificateComponent extends BaseComponent<LoginModel> implements OnInit {
  public loginForm: FormGroup;
  public loginModel: LoginModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  certificateData = [] as any;
  CertificateImageUrl:any;
  DownloadCertificatePath: any;
  @ViewChild('printableArea', { static: false }) printableArea: ElementRef;
  pdfOptions: any;
  xxx: boolean = false;
  certificatePdf: string;
  certificateListingMsg: string;
  IsCertificateAvailbles: boolean = false;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private certificateService: CertificateService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }




  ngOnInit(): void {
this.CertificateImageUrl=this.Constants.CommonImageUrl.CertificateImage;
this.DownloadCertificatePath=this.Constants.CommonImageUrl.CertificatePDF;

    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    let gratitudeListing: any = document.querySelectorAll(".within-employee-login");
    gratitudeListing.forEach(gratitudeListing => {
      gratitudeListing.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    this.getCertificateByStudentId();

  }

  getCertificateByStudentId() {
    // "28591546-59ac-4b64-8cfd-82c93b8d2331"
    this.certificateService.getCertificateByStudentId(this.UserModel.UserTypeId, 0, 1000).subscribe(response => {

      if (response.Results.length != 0 && response.Results.length > 0) {
        this.certificateData = response.Results;
        this.IsCertificateAvailbles = true;
      }
      else {
        this.IsCertificateAvailbles = false;
        this.certificateListingMsg = "No certificate List available for now! ";

      }
    });
  }

  downloadCertificate(certificateId: string) {
    // "08db2492-05fa-f93b-a5e6-c54daa9fa925"
    this.certificateService.GetCertificatePdf(certificateId).subscribe(response => {
      this.certificatePdf = response.Result;
      this.DownloadCertificatePath + this.certificatePdf;
      let xxx = document.createElement("a");
      xxx.download = this.DownloadCertificatePath + this.certificatePdf;
      xxx.href = this.DownloadCertificatePath + this.certificatePdf;
      xxx.target = "_blank";
      xxx.click();
    });
  }


}
