import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { GratitudeCornerService } from './gratitude-corner.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { GratitudeCornerModel } from './gratitude-corner.model';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DatePipe } from '@angular/common'
import { ViewportScroller } from "@angular/common";

@Component({
  providers: [AppConstants],
  selector: 'igmite-gratitude-corner',
  templateUrl: './gratitude-corner.component.html',
  styleUrls: ['./gratitude-corner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class GratitudeCornerComponent extends BaseListComponent<GratitudeCornerModel> implements OnInit {
  public gratitudeCornerForm: FormGroup;
  public gratitudeCornerModel: GratitudeCornerModel;
  current = 'Employer'
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private gratitudeCornerService: GratitudeCornerService,
    private dialogService: DialogService,
    public zone: NgZone,
    public datepipe: DatePipe,
    private scroller: ViewportScroller,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);


    this.gratitudeCornerModel = new GratitudeCornerModel();
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };
    this.gratitudeCornerForm = this.createInternshipOpportunityForm();

  }

  toggle(tab) {
    
    this.current = tab;
    this.gratitudeCornerForm.reset();

  }

  ngOnInit(): void {

    let elementIn1: any = document.querySelectorAll(".fixed-left-navigation");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    let gratitudeListing: any = document.querySelectorAll(".within-employee-login");
    gratitudeListing.forEach(gratitudeListing => {
      gratitudeListing.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }

  }

  //Create Internship Opportunity form and returns {FormGroup}
  createInternshipOpportunityForm(): FormGroup {
    return this.formBuilder.group({
      AuthUserId: new FormControl(),
      IsActive: new FormControl(),
      GratitudeId: new FormControl(),
      StudentId: new FormControl(),
      GratitudeVTId: new FormControl(),
      GratitudeEmployerBranchId: new FormControl(),
      GratitudeDesc: new FormControl({ value: this.gratitudeCornerModel.GratitudeDesc, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsGratitudeForVT: new FormControl(),
      IsAccepted: new FormControl(),
      IsRejected: new FormControl(),
    });
  }



  saveOrUpdateGratitude() {


    // this.gratitudeCornerModel.EmployerId = this.UserModel.UserId
    if (!this.gratitudeCornerForm.valid) {
      this.validateAllFormFields(this.gratitudeCornerForm);
      return;
    }

    this.setValueFromFormGroup(this.gratitudeCornerForm, this.gratitudeCornerModel);

    // this.gratitudeCornerModel.AuthUserId = this.UserModel.UserId;

    this.gratitudeCornerModel.AuthUserId = this.UserModel.UserId;
    this.gratitudeCornerModel.IsActive = true;
    this.gratitudeCornerModel.GratitudeId = "00000000-0000-0000-0000-000000000000";
    this.gratitudeCornerModel.StudentId = this.UserModel.UserTypeId;
    this.gratitudeCornerModel.GratitudeVTId = "00000000-0000-0000-0000-000000000000";
    this.gratitudeCornerModel.GratitudeEmployerBranchId = "00000000-0000-0000-0000-000000000000";
    this.gratitudeCornerModel.GratitudeDate = new Date();
    this.gratitudeCornerModel.GratitudeDesc = this.gratitudeCornerForm.value.GratitudeDesc;
    this.gratitudeCornerModel.IsGratitudeForVT = this.current == 'Employer' ? false : true;
    this.gratitudeCornerModel.IsAccepted = false;
    this.gratitudeCornerModel.IsRejected = false;

    this.gratitudeCornerService.CreateOrUpdateGratitude(this.gratitudeCornerModel)
      .subscribe((internshipResp: any) => {

        if (internshipResp.Success) {
          this.zone.run(() => {
            // this.showActionMessage(
            //   this.Constants.Messages.RecordSavedMessage,
            //   this.Constants.Html.SuccessSnackbar
            // );
            // this.router.navigateByUrl(RouteConstants.HomeEmployer);
            this.gratitudeCornerForm.reset();
            this.gratitudeCornerForm.controls['GratitudeDesc'].clearValidators;
            this.gratitudeCornerForm.controls['GratitudeDesc'].clearAsyncValidators;
            //this.ngOnInit();
            let GratitudeCorner = 'GratitudeCorner'
            sessionStorage.setItem('ThankYou', GratitudeCorner);
            this.router.navigateByUrl(RouteConstants.ThankYou);
            //this.router.navigateByUrl(RouteConstants.DashboardNew);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(internshipResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
      });
  }
}
