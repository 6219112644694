<section class="mb-lg-0 mb-20" *ngIf="profileAll">
    <div class="container" *ngIf="item">
        <div class="content-section mt-0">
    
            <div class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-10 pb-0 overflow-hidden max-w-800 mx-auto mt-20">
    
    
    
                <div class="text-center position-relative congratulation-cstm-section" >
                    <div class="student-top-section">
                        <p class="color9 fw-900 fs-24 text-center congratulation-heading mb-0">Congratulations</p>
                        <p class="text-black fw-900 student-name fs-18 text-black line-h-20">{{profileAll.FullName}}</p>
    
    
                    </div>
    
                    <div class="congrats-student-profile-pic ">
                        <img [src]="profileAll.PhotoUrl" class="user-profile" alt="" >
                    </div>
    
                    <img src="assets/upload/congratulations-bg.png" class="img-fluid conH" alt="Congratulations Student" srcset="">
    
    
                    <div class="congratulation-course mt-10" >
                        <p class="bl">{{item.Title}}</p>
                        <p class="text-black fw-900 student-name fs-17 text-black mb-0">{{item.OrganizationName}}</p>
                        <!-- <p class="text-black fw-900 student-name fs-17 text-black mb-0">{{item.BranchName}}</p> -->
                        <p class="text-black fw-400 student-name fs-15 text-black line-h-20">{{item.BranchName}}</p>

    
                    </div>
                </div>
    
    
                <p class="text-black fs-16 fw-900 mb-0 text-center mt-40">Share With Friends</p>
                <div class="d-flex justify-content-center">
                    <a class="mx-1" href="#">
                        <img src="assets/upload/whatsapp-icon.png" alt="" srcset="" class="mt-1" width="40px" height="40px">
                    </a>
                    <a class="mx-1" href="#">
                        <img src="assets/upload/insta-icon.png" alt="" srcset="" style="margin-top: 4px;" width="40px"
                            height="40px">
                    </a>
                    <a class="mx-1" href="#">
                        <img src="assets/upload/facebook-icon.png" alt="" srcset="" class="mt-3px" width="40px" height="40px">
                    </a>
    
                    <a class="mx-1" href="#">
                        <img src="assets/upload/linkedin-icon.png" alt="" srcset="" style="margin-top: 4px;" width="40px"
                            height="40px">
                    </a>
                </div>
    
    
    
    
                <a href="/dashboard" class="fs-15 mt-70 mb-10 color1 fw-900 d-block text-center">GO TO
                    HOME</a>
            </div>
    
        </div>
    </div>
</section>



