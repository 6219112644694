<section class="mb-lg-0 mb-20">
    <div class="container">



        <div class="text-center mt-30">
            <img src="assets/upload/100point.png" alt="Get 100 Points" srcset="">
            <div class="text-center d-flex justify-content-center mt-30">
                <svg class="mr-5" xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 24 24" width="22px"
                    fill="#fff">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                </svg>
    
                <p class="mb-0 fs-15 text-white fw-600">
                    Diary Entry Completed <br>
                    {{EntryDate | date :'mediumDate'}}
                </p>
            </div>
        </div>
    
    
        <div class="content-section mt-0" *ngIf="HighlightOne">
    
            <div
                class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 overflow-hidden max-w-800 mx-auto mt-20">
    
                <p class="fs-22 text-black fw-900 text-center line-h-22 mt-25 mb-15 mb-0 text-center">{{HighlightOne.QuestionText}}</p>
                <p class="fw-400 fs-13 text-black mb-0 text-center mt-10">Share your unique achievement for today.
                </p>
                <form name="highlightOneForm" novalidate [formGroup]="highlightOneForm">
                    <div class="mb-0 mt-20">
                        <div class="input-with-icon position-relative">
                            <div *ngIf="HighlightOne.QuestionAnswerType === 'TextArea'">
                                <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                    <textarea matInput name="AnswerText" formControlName="AnswerText" cols="30" rows="6" class="border-radius-10 text-black border-theme-2px fw-400 fs-16 py-10 w-100 px-15" required>Lorem ipsum dolor sit amet, conse Ctetur amet, conse adip iscing elit. The psum dolor sit amet. Sconse adi piscing elit. Apsum dolor sit amet. Sconse adi</textarea>
                                    <mat-error *ngIf="highlightOneForm.controls.AnswerText.invalid" >
                                        {{getErrorMessage(highlightOneForm, 'AnswerText')}}
                                    </mat-error>
                                </mat-form-field>
                                <p class="fw-400 fs-13 text-black mb-0 text-end mt-5px px-1">Character Limit - 150</p>

                            </div>
                            
                            <button class="d-block px-3 text-white max-w-180 border-radius-15 mx-auto mt-30 w-100 py-2 upto-down-gradient-clr-1 theme-btn" [disabled]="highlightOneForm.invalid" (click)="CreateQuestionResponse()">SUBMIT</button>
                            <a href="/dashboard" class="fs-15 mt-20 color1 fw-900 d-block text-center mb-0">SKIP</a>
                        </div>
                    </div>

                </form>
               
    
    
            </div>
    
        </div>
    </div>
</section>
  
