<section class="mb-lg-0 mb-60">
    <div class="container">






        <div class="content-section mt-0">
    
            <div class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-10 pb-0 overflow-hidden max-w-800 mx-auto mt-20">
    
    
    
                <div class="text-center position-relative congratulation-cstm-section border-theme-2px border-radius-25">
                    <div class="student-top-section">
                        <p class="color9 fw-900 fs-24 text-center congratulation-heading mb-0">Scoreboard</p>
                        <p class="text-black fw-900 student-name fs-18 text-black line-h-20">Suraj Kumar</p>
    
    
                    </div>
    
                    <div class="congrats-student-profile-pic">
                        <img src="assets/upload/Suraj-Kumar-150.png" class="border-5px-light-musturd" alt="">
                    </div>
    
                    <img src="assets/upload/scorecard-bg.png" class="img-fluid" alt="Congratulations Student" srcset="">
    
    
    
                </div>
    
                <div
                    class="row mx-0 px-0 dash-scorecard border-theme-2px border-radius-25 mt--70 bg-white position-relative">
    
                    <div class="col-4 text-center py-10">
    
                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10" enable-background="new 0 0 24 24"
                            fill="#000000" height="35" width="35" viewBox="0 0 22 22">
                            <rect fill="none" height="24" width="24"></rect>
                            <path
                                d="M19,5h-2V4c0-0.55-0.45-1-1-1H8C7.45,3,7,3.45,7,4v1H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94 c0.63,1.5,1.98,2.63,3.61,2.96V19H8c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-3v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z">
                            </path>
                        </svg>
    
                        <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Points</p>
    
                        <strong class="fs-20 line-h-17 fw-900 text-center text-black ">600</strong>
    
                    </div>
    
    
                    <div class="col-4 text-center py-10">
    
    
    
    
    
                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10" enable-background="new 0 0 24 24"
                            fill="#000000" height="35" width="35" viewBox="1 0 22 22">
                            <g>
                                <rect fill="none" height="24" width="24"></rect>
                                <rect fill="none" height="24" width="24"></rect>
                            </g>
                            <g>
                                <path
                                    d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M9.38,16.01L7,13.61c-0.39-0.39-0.39-1.02,0-1.41 l0.07-0.07c0.39-0.39,1.03-0.39,1.42,0l1.61,1.62l5.15-5.16c0.39-0.39,1.03-0.39,1.42,0l0.07,0.07c0.39,0.39,0.39,1.02,0,1.41 l-5.92,5.94C10.41,16.4,9.78,16.4,9.38,16.01z">
                                </path>
                            </g>
                        </svg>
    
    
                        <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Attendance</p>
    
                        <strong class="fs-20 line-h-17 fw-900 text-center text-black ">10 D</strong>
    
                    </div>
    
    
                    <div class="col-4 text-center py-10">
    
    
                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10" height="35" width="35"
                            viewBox="0 0 22 22">
                            <path d="M0 0h24v24H0V0z" fill="none"></path>
                            <path
                                d="M16.24 7.76C15.07 6.59 13.54 6 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z">
                            </path>
                        </svg>
    
    
                        <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Internship</p>
    
                        <strong class="fs-20 line-h-17 fw-900 text-center text-black ">60 H</strong>
    
                    </div>
    
                </div>
    
    
                <p class="text-black fs-16 fw-900 mb-0 text-center mt-40">Share With Friends</p>
    
    
                <div class="d-flex justify-content-center">
                    <a class="mx-1" href="#">
                        <img src="assets/upload/whatsapp-icon.png" alt="" srcset="" class="mt-1" width="40px" height="40px">
                    </a>
                    <a class="mx-1" href="#">
                        <img src="assets/upload/insta-icon.png" alt="" srcset="" style="margin-top: 4px;" width="40px"
                            height="40px">
                    </a>
                    <a class="mx-1" href="#">
                        <img src="assets/upload/facebook-icon.png" alt="" srcset="" class="mt-3px" width="40px" height="40px">
                    </a>
    
                    <a class="mx-1" href="#">
                        <img src="assets/upload/linkedin-icon.png" alt="" srcset="" style="margin-top: 4px;" width="40px"
                            height="40px">
                    </a>
                </div>
    
    
    
    
                <a href="../Student/dashboard.html" class="fs-15 mt-70 mb-10 color1 fw-900 d-block text-center">GO TO
                    HOME</a>
            </div>
    
        </div>
    </div>
</section>
