<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<button mat-icon-button class="warn mat-elevation-z2 theme-options-button"
    (click)="toggleSidebarOpen('themeOptionsPanel')" fxHide>
    <mat-icon>settings</mat-icon>
</button>

<fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <fuse-theme-options></fuse-theme-options>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->

<igmite-loader></igmite-loader>