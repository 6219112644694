import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class NotificationService {
   

    constructor(private http: BaseService) { }
    GetNoticesByType(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Notices.GetNoticesByType, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }
   
}
