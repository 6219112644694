import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { AppConstants } from 'app/app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { HomeEmpService } from './home-emp.service';


import * as $ from 'jquery';
import { timeout } from 'rxjs/operators';
import { RouteConstants } from 'app/constants/route.constant';
import { GratitudeListingService } from '../gratitude-listing/gratitude-listing.service';
import { CertificateListingService } from '../certificate-listing/certificate-listing.service';

@Component({
  providers: [AppConstants],
  selector: 'igmite-home-emp',
  templateUrl: './home-emp.component.html',
  styleUrls: ['./home-emp.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class HomeEmpComponent extends BaseComponent<LoginModel> implements OnInit, AfterViewInit {
  public appInfo = environment;
  OngoingInternshipsForEmployer: any
  CurrentInternshipDetailsForEmp: any
  tabActive: any
  public slides = [
    { 'SlidePreviewUrl': 'assets/upload/Video-1.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" },
    { 'SlidePreviewUrl': 'assets/upload/Video-2.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" },
    { 'SlidePreviewUrl': 'assets/upload/Video-3.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" },
    { 'SlidePreviewUrl': 'assets/upload/Video-4.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" }
  ];
  name: any;
  workingInterns: number;
  data: any;
  studentGratitudeCornerData = [] as any;
  ApiBaseUrlImage: any;
  ApiBaseUrlCertificateImage: any;
  certificateListingData = [] as any;
  cerData: any;
  
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private homeEmpService: HomeEmpService,
    private gratitudeListingService: GratitudeListingService,
    private certificateListingService: CertificateListingService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
    const firstTime = localStorage.getItem('key')
    if (!firstTime) {
      localStorage.setItem('key', 'loaded')
      window.location.reload();

    } else {
      localStorage.removeItem('key')
    }

  }

  ngOnInit(): void {
    this.ApiBaseUrlImage=this.Constants.CommonImageUrl.Account;
    this.ApiBaseUrlCertificateImage=this.Constants.CommonImageUrl.CertificateImage
    // let elementIn: any = document.querySelectorAll(".withoutlogin");
    // elementIn.forEach(elementIn => {
    //   elementIn.setAttribute('style', 'display: none !important');
    // });

    let elementIn1: any = document.querySelectorAll(".withoutlogin");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    let elementIn2: any = document.querySelectorAll(".withinlogin");
    elementIn2.forEach(elementIn2 => {
      elementIn2.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Employer + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    this.name = this.UserModel.UserName
    this.GetOngoingInternshipsForEmployer();
    this.GetCurrentInternshipDetailsForEmp();  
    this.gratitudeCorner();
    this.certificates();
  }

  GetOngoingInternshipsForEmployer() {
    var data = {
      UserId: this.UserModel.LoginId,
      PageIndex: 0,
      PageSize: 1000
    }

    this.homeEmpService.GetOngoingInternshipsForEmployer(data).subscribe((response: any) => {
      this.workingInterns = response.TotalResults;
      this.OngoingInternshipsForEmployer = response.Results
      this.IsLoading = false;
    }, error => {
    });
  }

  GetCurrentInternshipDetailsForEmp() {
    var data = {
      UserId: this.UserModel.LoginId,
      PageIndex: 0,
      PageSize: 1000
    }

    this.homeEmpService.GetCurrentInternshipDetailsForEmp(data).subscribe((response: any) => {
      this.CurrentInternshipDetailsForEmp = response.Results
      this.CurrentInternshipDetailsForEmp.map(item => {
        item.isToggle = false


      })
      this.IsLoading = false;
    }, error => {
    });
  }
  toggle(tab) {
    if (tab.isToggle == false) {
      tab.isToggle = true
    }
    else if (tab.isToggle == true) {
      tab.isToggle = false
    }

  }

  callStudentId(item) {
    sessionStorage.setItem('StudentID', item);
    if (item) {
      this.router.navigateByUrl(RouteConstants.ProfileMobile);

    }

  }
  ngAfterViewInit() {


  }
  // gratitudeCorner() {
  //   // "903a3712-4f5a-4f61-80c1-3259afae263a" this.UserModel.UserTypeId
  //   this.gratitudeListingService.getVTApprovedEmployerGratitudeByCriteria(this.UserModel.EmployerId, 0, 10000).subscribe(response => {
  //     this.data = response.Results;
  //     if (response.Results.length != 0) {
  //       for (let i = 0; i < 2; i++) {
  //         // this.gratituteListArray.push(this.gratituteListingData[0]);
  //         this.studentGratitudeCornerData.push(this.data[i]);
  //       }
  //     }
  //   });
  // }

  gratitudeCorner() {
    this.gratitudeListingService.getVTApprovedEmployerGratitudeByCriteria(this.UserModel.EmployerId, 0, 10000).subscribe(response => {
      this.data = response.Results;
      if (response.Results.length != 0 && response.Results.length > 0) {
        if (response.Results.length >= 1) {
          for (let i = 0; i < 2; i++) {
            this.studentGratitudeCornerData.push(this.data[i]);
          }
         
        } else {
          for (let i = 0; i < response.Results.length; i++) {
            this.studentGratitudeCornerData.push(this.data[i]);
          }
          
        }

      }

    });
  }

  certificates() {
    let certificateParams = {
      EmployerGuid: this.UserModel.EmployerId,
      PageIndex: 0,
      PageSize: 1000
    }
    this.certificateListingService.GetCertificateByEmployerId(certificateParams).subscribe((response:any) => {
      this.cerData = response.Results;
      if (response.Results.length != 0 && response.Results.length > 0) {
        if (response.Results.length > 1) {
          for (let i = 0; i < 2; i++) {
            this.certificateListingData.push(this.cerData[i]);
          
          }
        } else {
          for (let i = 0; i < response.Results.length; i++) {
            this.certificateListingData.push(this.cerData[i]);
          }
        }

      }

    });
  }


  GetInternshipId(internshipId) {
    sessionStorage.setItem('InternshipId', internshipId);
    if (internshipId) {
      this.router.navigateByUrl(RouteConstants.ApplicantListingV2);
    }
  }

  redirectOnDiaryEntryPage(studentId) {
    sessionStorage.setItem('StudentID', studentId);
    sessionStorage.setItem('EmployerRedirected', 'EmployerRedirected')
    if (studentId) {
      this.router.navigateByUrl(RouteConstants.DiaryEntryListing);
    }
  }
  // GetInternshipId(internshipId) {
  //   sessionStorage.setItem('InternshipId', internshipId);
  //   if (internshipId) {
  //     this.router.navigateByUrl(RouteConstants.AttendanceV2);
  //   }
  // }

  // GetInternshipId(internshipId) {
  //   sessionStorage.setItem('InternshipId', internshipId);
  //   if (internshipId) {
  //     this.router.navigateByUrl(RouteConstants.EmployerProfile);
  //   }
  // }
}
