<section class="mb-lg-0 mb-10">
    <div class="container">
        <div class="content-section mt-0">
            <img src="assets/upload/Thumbs-up-vector.png" alt="Thank You Vector" class="img-fluid mx-auto d-block my-3"
                srcset="">
            <div class="px-lg-35 px-10 border-radius-30 bg-white py-5 overflow-hidden max-w-800 mx-auto mt-40">

                <p class="text-center color2 fw-900 mb-0 fs-lg-40 fs-35">
                    Thank You!
                </p>
                <p class="text-center text-black fw-900 fs-19" >
                    Your password is <br>
                    successfully Changed.
                </p>

                <a class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-20 text-uppercase box-shadow-hard mb-10"
                     href="/login">go to Login</a>


            </div>
        </div>

    </div>
</section>