import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-date-dialog',
  templateUrl: './date-dialog.component.html',
  styleUrls: ['./date-dialog.component.scss']
})
export class DateDialogComponent implements OnInit {
  public dateForm: FormGroup;
  startDate: any
  constructor(public datepipe: DatePipe,public formBuilder: FormBuilder, public dialogRef: MatDialogRef<DateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.dateForm = this.createdateForm();
    var date = new Date(this.data.message.InternshipStartDate);
    this.startDate = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.dateForm.controls['StartDate'].patchValue(this.startDate);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onSubmit(form) {
    // if (!form.valid) {
    //   this.validateDynamicFormArrayFields(form);
    //   this.validateAllFormFields(form);
    //   return;
    // }
    this.actualStartDateSubmitedBystudent(form);
  
  }

  actualStartDateSubmitedBystudent(date: any) {
    this.dialogRef.close({ startDate: date });
  }

  createdateForm(): FormGroup {
    return this.formBuilder.group({
      StartDate: new FormControl({ value: '', disabled: false }),
    });
  }
}
