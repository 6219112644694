<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu></igmite-side-menu>


                </div>
                <div class="col-12 col-lg-10 px-0">

                    <div class="">

                        <section class="mt-0 position-relative mb-0">
                            <div id="tabs-design-2" class="active-white-filter  mt-lg-0 mt-15">
                                <ul
                                    class="mx-auto p-0 py-0 row mb-0 d-flex filter-tabs-key justify-content-between align-items-center border-radius-25 inset-shadow-dark">
                                    <li
                                        class="col-6 p-0 h-100 d-flex border-grey-light-right justify-content-center align-items-center  ">
                                        <a class="fs-15 tabs-caller py-15 fw-700 text-white h-100 d-flex justify-content-center align-items-center"
                                            (click)="toggle('Internships')"
                                            [ngClass]="tabActive === 'Internships' ? 'tabulous_active' : ''" title="">

                                            <svg class="mr-10 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                                height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path
                                                    d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z" />
                                            </svg>




                                            Internships</a>
                                    </li>
                                    <li class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"><a
                                            class="fs-15 tabs-caller py-15  fw-700 text-white h-100 d-flex justify-content-center align-items-center"
                                            (click)="toggle('Applied');"
                                            [ngClass]="tabActive === 'Applied' ? 'tabulous_active' : ''" title="">


                                            <svg class="mr-10 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                                height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path
                                                    d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                                            </svg>

                                            Applied</a></li>

                                </ul>
                                <div id="tabs_container" class="filter-tabs-pannel px-0 mt-30 ">



                                    <div *ngIf="tabActive == 'Internships'" id="tabs-1"
                                        class="filter-tabs-slide  mx-auto px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 overflow-hidden mx-auto right-content short">

                                        <p
                                            class="fs-22 text-black max-w-800 mx-auto fw-800 text-center line-h-22 mt-25 mb-15 te">
                                            Internship Opportunities</p>



                                        <div class="row mx-0 align-items-center mb-0 max-w-800 mx-auto">
                                            <div class="col-6 px-0">
                                                <div (click)="dropDown()"
                                                    [ngClass]="filterOpen == true ? 'filter-opne' : ''"
                                                    class="d-flex border-theme-2px px-10 py-2 border-radius-10 justify-content-between align-items-center filter-opner cursor-pointer">
                                                    <div> <svg class="fill-color-1 mr-3 fill-color-1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            enable-background="new 0 0 24 24" height="24px"
                                                            viewBox="0 0 24 24" width="24px" fill="#000000">
                                                            <g>
                                                                <path d="M0,0h24 M24,24H0" fill="none" />
                                                                <path
                                                                    d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
                                                                <path d="M0,0h24v24H0V0z" fill="none" />
                                                            </g>
                                                        </svg> <span class="fs-16 color1 mb-0 fw-600">filters</span>
                                                    </div>

                                                    <span class="fs-13 fw-600 color5 text-center">(0)</span>

                                                </div>

                                            </div>
                                            <div class="col-6 pr-0 pl-10" *ngIf="InterestsData">

                                                <p class="fs-15 fw-600 text-black mb-0 text-center">
                                                    {{InterestsData.length}} New
                                                    Positions</p>
                                            </div>
                                        </div>


                                        <div [ngClass]="filterOpen == true ? '' : ' d-none'"
                                            class="row mx-0 mb-25 opertunites-filter border-theme-2px px-10 py-20  max-w-800 mx-auto">
                                            <form class="" [formGroup]="interestsForm">
                                                <div class="col-12 px-0 mb-0 ">
                                                    <div class="mb-20">
                                                        <p class="fs-16 text-black mb-1 px-1 fw-600">Interest </p>
                                                        <div class="input-with-icon position-relative">
                                                            <select name="" id="timing" formControlName="InterestId"
                                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                                <option value="" disabled> Select Interest</option>
                                                                <option *ngFor="let int of filterDetails"
                                                                    [value]="int.InterestId"> {{int.InterestName}}
                                                                </option>

                                                            </select>

                                                            <label for="timing"
                                                                class="right-icon-input z-index-div-bottm">

                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    class="fill-color-1" height="30px" width="30px"
                                                                    fill="#000000" viewBox="2 8 15 7">
                                                                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                                    <path
                                                                        d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z">
                                                                    </path>
                                                                </svg>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="col-12 pl-0 pr-5">
                                                    <div class="mb-20">
                                                        <p class="fs-16 text-black mb-1 px-1 fw-600">Work Type </p>
                                                        <div class="input-with-icon position-relative">
                                                            <select name="" id="timing" (change)="interestsFilter()"
                                                                formControlName="InternShipType"
                                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                                <option value=""> select</option>
                                                                <option value="Online"> Online</option>
                                                                <option value="Onsite">Onsite</option>
                                                                <option value="Hybrid">Hybrid</option>
                                                            </select>

                                                            <label for="timing"
                                                                class="right-icon-input z-index-div-bottm">

                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    class="fill-color-1" height="30px" width="30px"
                                                                    fill="#000000" viewBox="2 8 15 7">
                                                                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                                    <path
                                                                        d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z">
                                                                    </path>
                                                                </svg></label>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 pr-0 pl-5">
                                                    <div class="mb-20">
                                                        <p class="fs-16 text-black mb-1 px-1 fw-600">Timing </p>
                                                        <div class="input-with-icon position-relative">
                                                            <select name="" id="timing"
                                                                formControlName="InternshipTiming"
                                                                (change)="interestsFilter()"
                                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                                <option value="">Select Timing</option>
                                                                <!-- <option value="10:30">10:30</option>
                                                                <option value="1:30">1:30</option>
                                                                <option value="3:30">3:30</option>
                                                                <option value="6:30">6:30</option> -->
                                                                <option value="Morning"> Morning</option>
                                                                <option value="Evening">Evening</option>
                                                                <option value="Afternoon">Afternoon</option>
                                                                <option value="Night">Night</option>

                                                            </select>

                                                            <label for="timing"
                                                                class="right-icon-input z-index-div-bottm">

                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    class="fill-color-1" height="30px" width="30px"
                                                                    fill="#000000" viewBox="2 8 15 7">
                                                                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                                    <path
                                                                        d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z">
                                                                    </path>
                                                                </svg></label>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                        <div *ngIf="InterestsData">
                                            <div [ngClass]="interests.isChecked === true ? 'show' : ''"
                                                *ngFor="let interests of InterestsData;let i = index"
                                                class="border-theme-2px px-10 py-15 mb-30 d-block border-radius-30 overflow-hidden bg-light-theme opportunities-block max-w-800 mx-auto mt-30">

                                                <div class="d-flex justify-content-between align-items-center mt-10">
                                                    <div>
                                                        <!-- <p class="fs-16  fw-900 mb-0 bl">
                                                            {{interests.EmployerName}}
                                                        </p>
                                                        <p class="fs-16 text-black fw-900 mb-0 ">
                                                            {{interests.InterestName}}
                                                        </p>

                                                        <p class="fs-14 fw-600 text-black fw-600 mb-0 ">
                                                            {{interests.InternshipTitle}}</p> -->

                                                            <p class="fs-16 text-yellow  fw-900 mb-0 "> {{interests.InterestName}}
                                                            </p>
                                                            <p class="fs-14 fw-900 text-black  mb-0 ">
                                                                {{interests.InternshipTitle}}</p>
                                                            <p class="fs-16  fw-400 mb-0 "> {{interests.EmployerName}}
                                                            </p>
                                                    </div>
                                                    <span
                                                        class="border-grey-light py-1 border-radius-2 px-1 bg-white d-flex align-items-center w-40 h-40"
                                                        *ngIf="interests.EmpPhotoUrl == ''">
                                                        <img [src]="DefaultImageUrl" alt="" srcset="" class="img-fluid">
                                                    </span>
                                                    <span
                                                        class="border-grey-light py-1 border-radius-2 px-1 bg-white d-flex align-items-center w-40 h-40"
                                                        *ngIf="interests.EmpPhotoUrl != ''">
                                                        <img [src]="Constants.CommonImageUrl.Employer+interests.EmpPhotoUrl"
                                                            alt="" srcset="" class="img-fluid">
                                                    </span>

                                                </div>


                                                <div class="row mx-0 p-0">
                                                    <div class="col-12 pl-0 mt-20 pr-5px">
                                                        <div class="d-flex align-items-center">

                                                            <svg class="mr-5 fill-color-2"
                                                                xmlns="http://www.w3.org/2000/svg" height="30px"
                                                                viewBox="3 0 24 24" width="30px" fill="#000000">
                                                                <path d="M0 0h24v24H0z" fill="none" />
                                                                <path
                                                                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
                                                            </svg>
                                                            <div>
                                                                <p
                                                                    class="fs-14 fw-600 text-black fw-600 mb-0 line-h-10 mt-1">
                                                                    {{interests.InternshipBranchName}} - </p>
                                                                <a class="fs-14 fw-600 color6 fw-600">View on
                                                                    Map -coming soon</a>
                                                                <!-- <a  class="fs-14 fw-600 color6 fw-600">coming soon
                                                                         </a> -->
                                                            </div>
                                                        </div>



                                                    </div>

                                                    <div class="col-6 pl-0 mt-20 pr-5px">
                                                        <div class="d-flex align-items-center">

                                                            <svg class="mr-5 fill-color-2"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                enable-background="new 0 0 24 24" height="24px"
                                                                viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                <g>
                                                                    <rect fill="none" height="24" width="24" />
                                                                </g>
                                                                <g>
                                                                    <path
                                                                        d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z" />
                                                                </g>
                                                            </svg>
                                                            <div>
                                                                <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">
                                                                    Start Date</p>
                                                                <p class="fs-14 text-black fw-600 mb-0">
                                                                    {{interests.InternshipStartDate | date}}</p>
                                                            </div>
                                                        </div>



                                                    </div>

                                                    <div class="col-6 pl-0 mt-20 pr-5px">
                                                        <div class="d-flex align-items-center">

                                                            <svg class="mr-5 fill-color-2"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                enable-background="new 0 0 24 24" height="24px"
                                                                viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                <g>
                                                                    <rect fill="none" height="24" width="24" />
                                                                </g>
                                                                <g>
                                                                    <path
                                                                        d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z" />
                                                                </g>
                                                            </svg>
                                                            <div>
                                                                <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">
                                                                    Duration
                                                                </p>
                                                                <p class="fs-14 text-black fw-600 mb-0">
                                                                    {{interests.InternshipDuration}}
                                                                </p>
                                                            </div>
                                                        </div>



                                                    </div>






                                                </div>

                                                <div class="internship-full-details" *ngIf="InterestsDetails">
                                                    <div class="row mx-0 px-0">
                                                        <div class="col-6 pl-0 mt-20 pr-5px">
                                                            <div class="d-flex align-items-center">





                                                                <svg class="mr-5 fill-color-2"
                                                                    xmlns="http://www.w3.org/2000/svg" height="24px"
                                                                    viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                                    <path
                                                                        d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z" />
                                                                </svg>



                                                                <div>
                                                                    <p
                                                                        class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">
                                                                        Work Type
                                                                    </p>
                                                                    <p class="fs-14 text-black fw-600 mb-0">
                                                                        {{InterestsDetails.InternshipType}}</p>
                                                                </div>
                                                            </div>



                                                        </div>

                                                        <div class="col-6 pl-0 mt-20 pr-5px">
                                                            <div class="d-flex align-items-center">



                                                                <svg class="mr-5 fill-color-2"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    enable-background="new 0 0 24 24" height="24px"
                                                                    viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                    <g>
                                                                        <rect fill="none" height="24" width="24" />
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <path
                                                                                    d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>


                                                                <div>
                                                                    <p
                                                                        class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">
                                                                        Timing
                                                                    </p>
                                                                    <p class="fs-14 text-black fw-600 mb-0">Morning</p>
                                                                </div>
                                                            </div>



                                                        </div>

                                                        <div class="col-6 pl-0 mt-20 pr-5px">
                                                            <div class="d-flex align-items-center">


                                                                <svg class="mr-5 fill-color-2"
                                                                    xmlns="http://www.w3.org/2000/svg" height="28px"
                                                                    viewBox="0 0 24 24" width="28px" fill="#000000">
                                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                                    <path
                                                                        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                                                                </svg>


                                                                <div>
                                                                    <p
                                                                        class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">
                                                                        Open Positions

                                                                    </p>
                                                                    <p class="fs-14 text-black fw-600 mb-0">
                                                                        {{InterestsDetails.NoOfOpenings}}
                                                                    </p>
                                                                </div>
                                                            </div>



                                                        </div>



                                                        <div class="col-6 pl-0 mt-20 pr-5px">
                                                            <div class="d-flex align-items-center">

                                                                <svg class="mr-5 fill-color-2"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    enable-background="new 0 0 24 24" height="24px"
                                                                    viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                    <g>
                                                                        <rect fill="none" height="24" width="24"
                                                                            y="0" />
                                                                    </g>
                                                                    <g>
                                                                        <path
                                                                            d="M20,7h-5V4c0-1.1-0.9-2-2-2h-2C9.9,2,9,2.9,9,4v3H4C2.9,7,2,7.9,2,9v11c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V9 C22,7.9,21.1,7,20,7z M9,12c0.83,0,1.5,0.67,1.5,1.5c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5C7.5,12.67,8.17,12,9,12z M12,18H6 v-0.43c0-0.6,0.36-1.15,0.92-1.39C7.56,15.9,8.26,15.75,9,15.75s1.44,0.15,2.08,0.43c0.55,0.24,0.92,0.78,0.92,1.39V18z M13,9h-2V4 h2V9z M17.25,16.5h-2.5c-0.41,0-0.75-0.34-0.75-0.75v0c0-0.41,0.34-0.75,0.75-0.75h2.5c0.41,0,0.75,0.34,0.75,0.75v0 C18,16.16,17.66,16.5,17.25,16.5z M17.25,13.5h-2.5c-0.41,0-0.75-0.34-0.75-0.75v0c0-0.41,0.34-0.75,0.75-0.75h2.5 c0.41,0,0.75,0.34,0.75,0.75v0C18,13.16,17.66,13.5,17.25,13.5z" />
                                                                    </g>
                                                                </svg>





                                                                <div>
                                                                    <p
                                                                        class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">
                                                                        Stipend

                                                                    </p>
                                                                    <p class="fs-14 text-black fw-600 mb-0">
                                                                        {{InterestsDetails.Stipend}}
                                                                    </p>
                                                                </div>
                                                            </div>



                                                        </div>

                                                    </div>

                                                    <p class="fs-17 text-black fw-600 mb-0 mt-30">Internship Details</p>
                                                    <p class="fs-14 text-black fw-600 mb-0 px-10 mt-5px">
                                                        {{InterestsDetails.JobDescription}}</p>
                                                    <!-- <p class="fs-17 text-black fw-600 mb-0 mt-30">Required Skills</p>
                                                    <ul class="list-none cstm-list-style pl-10 mt-5px">
                                                        <li class="pl-10 fs-15 fw-400 text-black mb-1">
                                                            {{InterestsDetails.RequiredSkills}}
                                                        </li>


                                                    </ul> -->

                                                    <!-- <p class="fs-17 text-black fw-600 mb-0 mt-30">Additional Benefits
                                                    </p>
                                                    <ul class="list-none cstm-list-style pl-10 mt-5px">
                                                        <li class="pl-10 fs-15 fw-400 text-black mb-1">
                                                            {{InterestsDetails.Perks}}
                                                        </li>
                                                    </ul> -->


                                                    <button
                                                        class="max-w-400 w-100 mt-30 py-10 px-10 text-white fs-17 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-15 box-shadow-hard mb-10 max-w-320"
                                                       
                                                        (click)="CreateStudentInternships(InterestsDetails)">APPLY</button>

                                                    <button
                                                        class="bg-transparent fs-17 fw-900 color1 internship-full-details-opener text-center d-block mt-20 w-100 border-0"
                                                        (click)="viewBox(interests)">Close</button>
                                                </div>





                                                <button
                                                    (click)="viewBox(interests);GetInternshipById(interests.InternshipId)"
                                                    class="bg-white w-100 max-w-300 py-12 fs-15 fw-600 text-center border-0 border-radius-15 mt-30 color1 mx-auto d-block internship-full-details-opener hide-on-click">
                                                    <svg class="mr-5 fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                                        height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path
                                                            d="M12 4C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                                    </svg> View Details
                                                </button>








                                            </div>
                                        </div>
                                        <div *ngIf="InterestsData ==''" style="text-align: center;">
                                            <span>No data found</span>
                                        </div>


                                    </div>

                                    <div *ngIf="tabActive == 'Applied'" id="tabs-2"
                                        class="filter-tabs-slide  mx-auto px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 overflow-hidden mx-auto right-content short">
                                        <p
                                            class="fs-22 text-black fw-800 text-center line-h-22 mt-25 max-w-800 mx-auto mb-15 te">
                                            My Applications</p>




                                        <div *ngFor="let Applied of SortedData;let i = index"
                                            class="border-theme-2px px-10 py-15 mb-30 d-block border-radius-25 bg-light-theme max-w-800 mx-auto">

                                            <div class="d-flex justify-content-between align-items-center mt-10">
                                                <div>
                                                    <p class="fs-16 text-yellow  fw-900 mb-0 "> {{Applied.InterestName}}
                                                    </p>
                                                    <p class="fs-14 fw-900 text-black  mb-0 ">
                                                        {{Applied.InternshipTitle}}</p>
                                                    <p class="fs-16  fw-400 mb-0 "> {{Applied.EmployerName}}
                                                    </p>



                                                </div>
                                                <span
                                                    class="border-grey-light py-1 border-radius-2 px-1 bg-white d-flex align-items-center w-40 h-40 bg-white"
                                                    *ngIf="Applied.EmpPhotoUrl != ''"><img
                                                        [src]="Constants.CommonImageUrl.Employer+Applied.EmpPhotoUrl"
                                                        alt="" srcset="" class="img-fluid">
                                                </span>
                                                <span
                                                    class="border-grey-light py-1 border-radius-2 px-1 bg-white d-flex align-items-center w-40 h-40 bg-white"
                                                    *ngIf="Applied.EmpPhotoUrl == ''">
                                                    <img [src]="DefaultImageUrl" alt="" srcset="" class="img-fluid">
                                                </span>


                                            </div>


                                            <div class="row mx-0 p-0">
                                                <div class="col-12 pl-0 mt-20 pr-5px">
                                                    <div class="d-flex align-items-center">

                                                        <svg xmlns="http://www.w3.org/2000/svg" class="mr-5" width="30"
                                                            height="30" viewBox="0 0 24 24" stroke-width="1.5"
                                                            stroke="#33d561" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <circle cx="12" cy="11" r="3"></circle>
                                                            <path
                                                                d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z">
                                                            </path>
                                                        </svg>
                                                        <div>
                                                            <p
                                                                class="fs-14 fw-600 text-black fw-300 mb-0 line-h-10 mt-1">
                                                                {{Applied.InternshipCityName}} </p>
                                                            <a href="" class="fs-14 fw-600 color6 fw-300">View on
                                                                Map - coming soon</a>

                                                        </div>
                                                    </div>



                                                </div>


                                            </div>






                                            <div class="border-bottom-grey-light my-3">

                                            </div>

                                            <div class="d-flex justify-content-between align-items-center">
                                                <p class="fs-16 text-black fw-900 mb-0 "> Application Status </p>





                                            </div>
                                            <div class="wrapper mt-10">
                                                <ul class="StepProgress py-0 mb-0">
                                                    <li class="StepProgress-item  fs-14 fw-600  pb-1"
                                                        [ngClass]="Applied.IsApprovedByVT === true ? 'is-done' : 'is-pending color4 '">
                                                        Trainer Approval -
                                                        <span class=" color3" *ngIf="Applied.IsApprovedByVT    == true">
                                                            Approved</span>
                                                        <span class=" color4"
                                                            *ngIf="Applied.IsApprovedByVT   == false">Pending </span>
                                                    </li>
                                                    <li class="StepProgress-item  fs-14 fw-600  pb-1"
                                                        [ngClass]="Applied.IsApprovedByEmployer === true ? 'is-done' : 'is-pending color4 '">
                                                        Employer Approval -
                                                        <span class=" color3 "
                                                            *ngIf="Applied.IsApprovedByEmployer  == true ">Approved
                                                        </span>
                                                        <span class=" color4"
                                                            *ngIf="Applied.IsApprovedByEmployer   == false">Pending
                                                        </span>
                                                    </li>
                                                    <li class="StepProgress-item  fs-14 fw-600  pb-1"
                                                        [ngClass]="Applied.TypeStatus !== 'Pending' ? 'is-done' : 'is-pending color4 '">
                                                        Status -
                                                        <span class=" color3 "
                                                            *ngIf="Applied.TypeStatus   != 'Pending'">{{Applied.TypeStatus}}
                                                        </span>
                                                        <span class=" color4"
                                                            *ngIf="Applied.TypeStatus   == 'Pending'">Pending
                                                        </span>

                                                    </li>

                                                </ul>

                                            </div>


                                            <span *ngIf="Applied.IsApprovedByEmployer ==true && Applied.IsApprovedByVT ==true  && Applied.IsPlaced != true && Applied.IsCmpleted != true && Applied.IsIRReviewed != true && Applied.DateOfDropOut == NULL && allStatus == false
                                            ">
                                                <button
                                                    class="max-w-400 w-100 mt-30 py-10 px-10 text-white fs-17 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-15 box-shadow-hard mb-10 max-w-320"
                                                    (click)="onClickAddNewCollege(Applied)">I am happy to accept
                                                </button>

                                                <button
                                                    class="max-w-400 w-100 mt-30 py-10 px-10 color1 fs-17 fw-600 bg-white theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-15 mb-10 max-w-320">I
                                                    am sorry, I can't join
                                                </button>
                                            </span>
                                            <span *ngIf="Applied.IsApprovedByEmployer == false ||  Applied.IsApprovedByVT ==false  || Applied.IsPlaced == true || Applied.IsCmpleted == true || Applied.IsIRReviewed == true || Applied.DateOfDropOut != NULL || allStatus == true
                                            ">
                                                <button style="background-color: #c0c0c0 !important;
                                                background-image: linear-gradient(0deg, silver, silver) !important;"
                                                    class="max-w-400 w-100 mt-30 py-10 px-10 text-white fs-17 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-15 box-shadow-hard mb-10 max-w-320">I
                                                    am happy to accept
                                                </button>

                                                <!-- <button style="background-color: #c0c0c0 !important;
                                                background-image: linear-gradient(0deg, silver, silver) !important;"
                                                    class="max-w-400 w-100 mt-30 py-10 px-10 color1 fs-17 fw-600 bg-white theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-15 mb-10 max-w-320">I
                                                    am sorry, I can't join
                                                </button> -->
                                            </span>


                                        </div>








                                    </div>



                                </div>


                            </div>
                            <!--End tabs container-->
                        </section>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>