<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-lg-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu></igmite-side-menu>

                </div>


                <div class="col-md-9 col-lg-10 right-content px-lg-35 px-0 pb-5 bg-white py-18"
                    *ngIf="StudentProfileConfirmationById">


                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">

                        <div class="col-3 cursor-pointer" [routerLink]="['/raise-safety-concer']"
                            style="cursor:pointer;"><img src="assets/Content/images/Alert-icon.png" class="" alt="Alert"
                                height="25" srcset="">
                        </div>


                        <div class="col-6 px-0 text-center">

                            <div class="user-profile-dash mt-lg-0"> <img [src]="StudentProfileConfirmationById.PhotoUrl"
                                    alt="" srcset=""> </div>
                        </div>

                        <div class="col-3 text-end cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1 h-30" viewBox="0 0 24 24"
                                width="28px" fill="#000000">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path
                                    d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92-1.31-2.92-2.92-2.92z" />
                            </svg>


                        </div>
                    </div>
                    <div class="user-name text-center mt-15 max-w-800 mx-auto">
                        <h2 class="color2 fw-900 fs-25">Hi <span
                                class="text-black fs-26 font-pacific fw-400">{{StudentProfileConfirmationById.FullName}}</span>
                        </h2>
                    </div>


                    <div class="px-10 mt-20 max-w-800 mx-auto" *ngIf="StudentDashboardInternshipData">
                        <div class="row mx-0 px-0 dash-scorecard border-theme-2px border-radius-40">

                            <div class="col-4 text-center py-10 ">

                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10"
                                    enable-background="new 0 0 24 24" fill="#000000" height="35" width="35"
                                    viewBox="0 0 22 22">
                                    <rect fill="none" height="24" width="24"></rect>
                                    <path
                                        d="M19,5h-2V4c0-0.55-0.45-1-1-1H8C7.45,3,7,3.45,7,4v1H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94 c0.63,1.5,1.98,2.63,3.61,2.96V19H8c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-3v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z">
                                    </path>
                                </svg>
                                <a href="/dashboard">
                                    <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Points</p>

                                    <strong
                                        class="fs-20 line-h-17 fw-900 text-center text-black ">{{StudentDashboardInternshipData.StudentPoints}}</strong>
                                </a>
                            </div>


                            <div class="col-4 text-center py-10">





                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10"
                                    enable-background="new 0 0 24 24" fill="#000000" height="35" width="35"
                                    viewBox="1 0 22 22">
                                    <g>
                                        <rect fill="none" height="24" width="24" />
                                        <rect fill="none" height="24" width="24" />
                                    </g>
                                    <g>
                                        <path
                                            d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M9.38,16.01L7,13.61c-0.39-0.39-0.39-1.02,0-1.41 l0.07-0.07c0.39-0.39,1.03-0.39,1.42,0l1.61,1.62l5.15-5.16c0.39-0.39,1.03-0.39,1.42,0l0.07,0.07c0.39,0.39,0.39,1.02,0,1.41 l-5.92,5.94C10.41,16.4,9.78,16.4,9.38,16.01z" />
                                    </g>
                                </svg>

                                <a href="/diary-entry-listing">
                                    <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Attendance</p>

                                    <strong
                                        class="fs-20 line-h-17 fw-900 text-center text-black ">{{StudentDashboardInternshipData.Attendance}}
                                        D</strong>
                                </a>
                            </div>


                            <div class="col-4 text-center py-10 active">


                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10" height="35"
                                    width="35" viewBox="0 0 22 22">
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path
                                        d="M16.24 7.76C15.07 6.59 13.54 6 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
                                </svg>


                                <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Internship</p>

                                <strong
                                    class="fs-20 line-h-17 fw-900 text-center text-black ">{{StudentDashboardInternshipData.Hours|
                                    slice:0:5}} H</strong>

                            </div>

                        </div>
                    </div>

                    <div class="px-10 mt-50 max-w-800 mx-auto">
                        <p class="fs-19 line-h-17 fw-900 text-center text-black  px-10 text-black mb-10 line-h-30 mt-0">
                            Your internship Progress</p>
                    </div>

                    <div class="px-15" *ngIf="StudentDashboardInternshipData">

                        <div class="progress-outer max-w-600 mx-auto mt-90 px-15 bold-bar">
                            <div class="progress">
                                <div class="progress-bar position-relative" role="progressbar" aria-valuenow="70"
                                    aria-valuemin="0" aria-valuemax="100" [style.width]="widthVal + '%'">

                                    <div class="position-absolute end-0 top-0 progress-value">
                                        <div class="position-relative d-flex justify-content-center ">
                                            <img src="assets/upload/location-marker-icon.png" alt="" srcset="">
                                            <p
                                                class="fw-900 text-black fs-10 mb-0 position-absolute  start-50 translate-middle mt-30 ">
                                                {{StudentDashboardInternshipData.Hours|
                                                slice:0:5}} H
                                            </p>
                                        </div>
   
                                    </div>

                                </div>
                            </div>

                            <div class="win-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px"
                                    viewBox="0 0 24 24" width="24px" fill="#f9a705">
                                    <rect fill="none" height="24" width="24" />
                                    <path
                                        d="M19,5h-2V3H7v2H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94c0.63,1.5,1.98,2.63,3.61,2.96V19H7v2h10v-2h-4v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z" />
                                </svg>
                            </div>

                        </div>

                    </div>


                    <div *ngIf="StudentDashboardInternshipData">
                        <div class="row mx-0 px-15 mt-40">
                            <div class="col-6 pl-0 pr-5px">
                                <div
                                    class="bg-theme-light-grey intereset-options border-radius-25 d-flex flex-column  py-20 align-items-center cursor-pointer px-20">

                                    <p
                                        class="bg-white w-100 border-radius-25 fs-40 fw-900 color2 text-center py-20 mb-10 ">
                                        {{StudentDashboardInternshipData.Attendance}}</p>
                                    <p class="text-black fw-500 mb-0 fs-18 text-center px-20  line-h-20">Days
                                        Completed</p>
                                </div>

                            </div>

                            <div class="col-6 pr-0 pl-5px">
                                <div
                                    class="bg-theme-light-grey intereset-options border-radius-25 d-flex flex-column  py-20 align-items-center cursor-pointer px-20">

                                    <p
                                        class="bg-white w-100 border-radius-25 fs-40 fw-900 color2 text-center py-20 mb-10">
                                        {{StudentDashboardInternshipData.Hours|
                                        slice:0:5}}</p>
                                    <p class="text-black fw-500 mb-0 fs-18 text-center px-20 line-h-20">Hours
                                        Completed</p>
                                </div>

                            </div>
                        </div>
                    </div>





                    <div class="owl-carousel cstm-owl-dots px-15 mt-40 home-video-single owl-theme"
                        *ngIf="StudentProfileConfirmationById.length != 0">

                        <div class="item" *ngFor="let toplist of DailyDiaryEntryByStudentId;let i = index">
                            <div>
                                <div class="highlight-section px-20 py-10 border-radius-25 max-w-420 mx-auto">
                                    <p
                                        class="fs-22 text-white fw-800 text-center line-h-35 mt-10 mb-15 mb-0 text-center">
                                        Highlights</p>

                                    <p class="fs-15 text-white fw-800 text-center mb-0">{{toplist.EntryDate | date
                                        :'mediumDate'}}</p>
                                    <div class="px-15 border-radius-20 py-15 mt-15 bg-white pb-5">
                                        <p class="mb-0 fs-14 fw-600 text-black mb-5">{{toplist.HighLightText}}</p>


                                    </div>
                                    <div class="ribbon-section d-flex align-items-center">
                                        <img [src]="StudentProfileConfirmationById.PhotoUrl"
                                            class="student-picture mr-10 ml-10" alt="" srcset="">
                                        <div>
                                            <p class="fs-15 text-black fw-900 mb-0 line-h-15">
                                                {{toplist.StudentName}}</p>
                                            <p class="fw-400 mb-0 text-black fs-12">{{toplist.OrganizationName}}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>


                    <div class="px-15 mt-40"  *ngIf="high == false ">
                        <div class="item">

                            <div>
                                <div class="highlight-section px-20 py-10 border-radius-25 max-w-420 mx-auto">
                                    <p
                                        class="fs-22 text-white fw-800 text-center line-h-35 mt-10 mb-15 mb-0 text-center">
                                        Highlights</p>

                                    <div class="px-15 border-radius-20 py-15 mt-15 bg-white pb-5">
                                        <p class="mb-0 fs-14 fw-600 text-black mb-5">No Highlight</p>


                                    </div>


                                </div>
                            </div>


                        </div>






                    </div>






                    <div class="px-10 mt-50 max-w-800 mx-auto" *ngIf="EmployerBranchDetails">
                        <p class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-10 line-h-30 mt-0">
                            Company Details</p>

                        <div class="border-theme-2px border-radius-25 overflow-hidden px-15 py-15">



                            <p
                                class="fs-16 fw-600 text-black mb-0 d-flex justify-content-between align-items-center mt-0 border-bottom-grey-light pb-10">
                                {{EmployerBranchDetails.VTName}} <span
                                    class="border-grey-light border-radius-5  h-50px w-50 d-flex justify-content-center align-items-center"><img
                                        src="assets/upload/MLE-l0go.png" alt="MLE LOGO" class="img-fluid"
                                        srcset=""></span>
                            </p>


                            <div class="d-flex mt-15">
                                <svg class="mr-10 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                    enable-background="new 0 0 24 24" height="42px" viewBox="2 0 20 27" width="51px"
                                    fill="#000000">
                                    <g>
                                        <rect fill="none" height="24" width="24"></rect>
                                    </g>
                                    <g>
                                        <path
                                            d="M12,2c-4.2,0-8,3.22-8,8.2c0,3.18,2.45,6.92,7.34,11.23c0.38,0.33,0.95,0.33,1.33,0C17.55,17.12,20,13.38,20,10.2 C20,5.22,16.2,2,12,2z M12,12c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C14,11.1,13.1,12,12,12z">
                                        </path>
                                    </g>
                                </svg>
                                <p class="fs-14 text-black fw-600 mb-0">Shop No. 101, Gali No. - 12, Aligarh
                                    Uttar Pradesh <a href="#" class="color6 ml-10" target="_blank"
                                        rel="noopener noreferrer">View on Map</a></p>

                            </div>


                            <div class="mt-10 d-flex align-items-center mb-15">
                                <svg class="fill-color-2 mr-10 width-lg-51" xmlns="http://www.w3.org/2000/svg"
                                    height="30px" viewBox="0 0 24 24" width="30px" fill="#000000">
                                    <path
                                        d="M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z" />
                                </svg>
                                <p class="fs-18 text-black fw-600 mb-0"><a href="#" class="color6 " target="_blank"
                                        rel="noopener noreferrer">{{EmployerBranchDetails.VTMobile}}</a></p>

                            </div>

                        </div>

                    </div>





                </div>
            </div>
        </div>
    </div>
</section>