<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0">
            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu-emp></igmite-side-menu-emp>
                </div>

                <div class="col-md-9 col-lg-10 right-content px-lg-1 overflow-hidden px-5px pb-5 bg-white py-5px">


                    <div class="owl-carousel owl-theme home-video-single cstm-owl-dots">
                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="assets/upload/Helping-idia-banner-slider.png" alt=""
                                        srcset="">


                                </a>


                            </div>
                        </div>
                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="assets/upload/Video-2.png" alt="" srcset="">


                                </a>


                            </div>


                        </div>

                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="assets/upload/Video-3.png" alt="" srcset="">


                                </a>


                            </div>


                        </div>

                        <div class="item">
                            <div class="text-center">

                                <a class="venobox auto-play just-open fade-overlay mx-auto d-block max-w-500 w-100 youtube-icon vbox-item border-radius-25"
                                    data-vbtype="video" href="https://www.youtube.com/watch?v=Onz7k0G19Gk">

                                    <img src="assets/upload/you-tube-icon.png" height="45"
                                        class="img-fluid position-absolute center-icon" alt="" srcset="">
                                    <img class="w-100" src="assets/upload/Video-4.png" alt="" srcset="">


                                </a>


                            </div>


                        </div>




                    </div>



                    <div class="user-name text-center mt-15 max-w-800 mx-auto">
                        <h2 class="color2 fw-900 fs-25">Hi <span
                                class="text-black fs-26 font-pacific fw-400">{{name}}</span> </h2>
                    </div>



                    <div class="px-15 max-w-800 w-100 mx-auto" *ngIf="CurrentInternshipDetailsForEmp">
                        <p
                            class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-20 line-h-30 mt-40 ">
                            Current Openings
                        </p>


                        <div style="max-height: 373px;overflow: auto;">
                            <div class=" py-15 w-100 bg-light-theme2 border-radius-25 mx-auto pb-0 px-0 mb-30"
                                *ngFor="let current of CurrentInternshipDetailsForEmp;index as i;">


                                <div class="d-flex justify-content-between align-items-center px-15">
                                    <div>
                                        <p class="fs-16 text-black fw-600 mb-0 fw-900">
                                            {{current.Title}}</p>
                                        <p class="fs-13 fw-400 text-black mb-0">Posted on {{current.PublishDate |
                                            date:"dd
                                            MMM yyyy" }}</p>
                                    </div>


                                    <!-- <div class="ml-auto w-100 d-flex justify-content-end px-10 "> -->
                                    <a (click)="GetInternshipId(current.InternshipId)">

                                        <i
                                            class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35">

                                        </i>
                                    </a>

                                    <!-- </div> -->







                                </div>


                                <div class="border-radius-25 bg-color1 px-15 py-15 mt-20 box-shadow-light">
                                    <div class="align-items-center row">

                                        <div
                                            class="col-6 d-flex align-items-center border-light border-2 border-start-0 border-top-0 border-bottom-0 border-end pr-5">

                                            <svg class="mr-10" version="1.1" id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="34px"
                                                height="34px" viewBox="0 0 44 44" enable-background="new 0 0 44 44"
                                                xml:space="preserve">
                                                <path fill="none" stroke="#fff" stroke-width="2.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    d="M23.834,9.167H38.5" />
                                                <path fill="none" stroke="#fff" stroke-width="2.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    d="M23.834,16.5H33" />
                                                <path fill="none" stroke="#fff" stroke-width="2.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    d="M23.834,27.5H38.5" />
                                                <path fill="none" stroke="#fff" stroke-width="2.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    d="M23.834,34.834H33" />
                                                <path fill="none" stroke="#fff" stroke-width="2.5"
                                                    stroke-linecap="round" stroke-linejoin="round" d="M7.333,7.333h7.333
c1.013,0,1.833,0.821,1.833,1.833V16.5c0,1.013-0.821,1.833-1.833,1.833H7.333c-1.013,0-1.833-0.821-1.833-1.833V9.167
C5.5,8.154,6.321,7.333,7.333,7.333z" />
                                                <path fill="none" stroke="#fff" stroke-width="2.5"
                                                    stroke-linecap="round" stroke-linejoin="round" d="M7.333,25.666h7.333
c1.013,0,1.833,0.821,1.833,1.834v7.334c0,1.012-0.821,1.832-1.833,1.832H7.333c-1.013,0-1.833-0.82-1.833-1.832V27.5
C5.5,26.487,6.321,25.666,7.333,25.666z" />
                                            </svg>

                                            <div>
                                                <p class="fs-13 text-white fw-600 mb-0 line-h-17">Applications</p>
                                                <p class="fs-20 text-white fw-900 mb-0">{{current.TotalApplications}}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-6 d-flex align-items-center">




                                            <svg class="mr-10" version="1.1" id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px"
                                                height="40px" viewBox="0 0 44 44" enable-background="new 0 0 44 44"
                                                xml:space="preserve">
                                                <path fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" d="M10.083,9.167h7.333
c0.506,0,0.917,0.411,0.917,0.917v7.333c0,0.506-0.411,0.917-0.917,0.917h-7.333c-0.506,0-0.917-0.411-0.917-0.917v-7.333
C9.167,9.577,9.577,9.167,10.083,9.167z" />
                                                <path fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" d="M10.083,25.666h7.333
c0.506,0,0.917,0.411,0.917,0.918v7.332c0,0.507-0.411,0.918-0.917,0.918h-7.333c-0.506,0-0.917-0.411-0.917-0.918v-7.332
C9.167,26.077,9.577,25.666,10.083,25.666z" />
                                                <path fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" d="M25.666,27.5l5.5,5.5
l5.5-5.5" />
                                                <path fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" d="M31.166,33V11" />
                                            </svg>


                                            <div>
                                                <p class="fs-13 text-white fw-600 mb-0 line-h-17">Shortlisted</p>
                                                <p class="fs-20 text-white fw-900 mb-0">{{current.ShortlistedByEmp}}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>



                    <div class="px-15 max-w-800 w-100 mx-auto" *ngIf="OngoingInternshipsForEmployer">
                        <p
                            class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-20 line-h-30 mt-40 ">
                            Ongoing Internships</p>


                        <div class="border-theme-2px border-radius-30 overflow-hidden">
                            <div class="px-15 py-15 bg-light-theme2 border-radius-30">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p class="fs-16 text-black fw-600 mb-0 fw-900">Working Interns
                                            ({{workingInterns}})</p>
                                        <!-- <p class="fs-13 fw-400 text-black mb-0">05 Aug 2022 - 10 Sep 2022</p> -->
                                    </div>

                                    <a class="ml-auto d-block">
                                        <i
                                            class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35"></i>
                                    </a>
                                </div>
                            </div>


                            <div class="px-15 py-15" *ngFor="let ongoingInterns of OngoingInternshipsForEmployer;">
                                <div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div
                                            class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                            <span *ngIf="ongoingInterns.ProfilePhoto != null"><img [src]="ApiBaseUrlImage + ongoingInterns.ProfilePhoto"
                                                class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                width="40" height="40" /></span>
                                            <div>
                                                <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                    {{ongoingInterns.StudentName}}</p>
                                                <p class="fs-14 fw-600 color1 mb-0 mt-0">
                                                    {{ongoingInterns.StudentMobile}}</p>
                                            </div>

                                        </div>
                                        <a (click)="callStudentId(ongoingInterns.StudentId)" class="ml-auto d-block">
                                            <i
                                                class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                        </a>

                                    </div>

                                    <div>
                                        <p class="mb-0 line-h-25 mt-0" style="color: #f99e06; margin-right: 5%;">
                                            <span>{{ongoingInterns.Title}}</span>
                                            <span class="fs-14 fw-600 color1 mb-0 mt-0">
                                                - {{ongoingInterns.StartDate| date:"dd MMM yyyy"}}
                                            </span>
                                        </p>
                                        <!-- <p class="fs-14 fw-600 color1 mb-0 mt-0">
                                            {{ongoingInterns.StartDate| date:"dd MMM yyyy"}}</p> -->
                                    </div>

                                    <div class="row mx-0 px-0">

                                        <a class="col-4 py-10 px-10">


                                            <p class="fs-13 fw-400 mt-1 text-black mb-0">Attendance</p>

                                            <strong
                                                class="fs-16 line-h-15 fw-900 text-black ">{{ongoingInterns.TotalAttendance}}
                                                D</strong>

                                        </a>

                                        <a (click)="redirectOnDiaryEntryPage(ongoingInterns.StudentId)"
                                            class="col-4 py-10 px-10 ">

                                            <p class="fs-13 fw-400 mt-1 text-black mb-0">Internship</p>

                                            <strong
                                                class="fs-16 line-h-15 fw-900 text-black ">{{ongoingInterns.Totalhours}}
                                                H</strong>

                                        </a>

                                        <a class="col-4 py-10 px-10 ">

                                            <p class="fs-13 fw-400 mt-1 text-black mb-0">Diary Entry</p>

                                            <strong
                                                class="fs-16 line-h-15 fw-900 text-black ">{{ongoingInterns.Totalhours /
                                                80 *100 | number}}%</strong>

                                        </a>

                                    </div>
                                </div>
                                <div class="border-end-0 border-top-0 border-start-0 border-theme-2px mb-25 mt-20">
                                </div>



                            </div>


                        </div>





                    </div>






                    <div class="mt-70 max-w-600 mx-auto px-15">
                        <div class="bg-light-theme2 px-15 py-15 row mx-0 border-radius-40 align-items-center">
                            <div class="col-6 px-0">
                                <p class="fs-18 fw-400 text-black mb-0 line-h-25">
                                    Start posting your
                                    requirement for
                                    hiring interns.
                                </p>
                            </div>

                            <div class="col-6 mt--50 text-end">
                                <img src="assets/upload/Start-posting.png" alt="Start posting your 
requirement for 
hiring interns" class="img-fluid" srcset="">
                            </div>

                            <a href="/unpublished-interships"
                                class="max-w-400 w-100 mt-30 py-10 px-10 text-white fs-17 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-15 box-shadow-hard mb-10 max-w-320 text-uppercase col-12">Start
                                Hiring</a>
                        </div>


                    </div>

                    <section>
                        <div class="row mx-0 px-0">
                            <div class="col-md-6">
                                <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-lg-60 mt-50 mb-0 text-center">
                                    Gratitude Corner
                                </h2>


                                <div class="owl-carousel owl-theme home-video-single cstm-owl-dots mt-20"
                                    *ngIf="studentGratitudeCornerData.length != 0">
                                    <div class="item" *ngFor="let gratitute of studentGratitudeCornerData; let i=index">

                                        <div class="border-theme-2px border-radius-30 overflow-hidden px-15 py-20">
                                            <p
                                                class="fs-16 fw-400 mb-10 text-center border-theme-2px border-start-0 border-end-0 border-top-0 pb-20 mb-10">
                                                {{gratitute?.GratitudeDesc}}.</p>


                                            <div class="ranker-name-profile d-flex align-items-center">
                                                <img [src]="ApiBaseUrlImage + gratitute?.StudentPhoto"
                                                    class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                    width="40" height="40" style="height: 40px;
                                                width: 40px !important;">
                                                <div>
                                                    <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                        {{gratitute?.StudentName}}</p>
                                                    <p class="fs-14 fw-400 text-black mb-0 mt-0">
                                                        {{gratitute?.StudentAddress1}}...</p>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div class="owl-carousel  home-video-single  mt-20"
                                    *ngIf="studentGratitudeCornerData.length == 0">
                                    <div class="item" style="text-align: center;">
                                        <div class="border-theme-2px border-radius-30 overflow-hidden px-15 py-20">
                                            <span>No Gratitude messages from students are available for now! </span>

                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div class="col-md-6">
                                <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-lg-60 mt-30 mb-0 text-center">
                                    Certificates
                                </h2>


                                <div class="owl-carousel owl-theme home-video-single cstm-owl-dots mt-20"
                                    *ngIf="certificateListingData.length != 0">


                                    <div class="item"
                                        *ngFor="let certificateList of certificateListingData; let i=index">

                                        <div class="border-theme-2px border-radius-20 px-10 py-20">
                                            <p class="fs-16 text-black text-center fw-900 mb-5px">
                                                {{certificateList.CertificateType}}
                                            </p>
                                            <p class="fs-13 text-black fw-400 mb-0 text-center">
                                                {{certificateList.OrganizationName}}
                                            </p>

                                            <div class="text-center mt-15 mb-15 w-100"><img
                                                    [src]="ApiBaseUrlCertificateImage+certificateList.CertificateImage"
                                                    alt="" srcset="" class="img-fluid mx-auto text-center"></div>

                                            <div class="d-flex justify-content-between align-items-center">
                                                <a
                                                    href="{{ApiBaseUrlCertificateImage + certificateList.CertificateImage }}">
                                                    <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                                        height="25" width="25" viewBox="0 9 46 27">
                                                        <path
                                                            d="M14 34h20v-3H14Zm9.85-6.3 7.9-7.85-2.1-2.1-4.25 4.2V12h-3v9.95l-4.25-4.2-2.1 2.1ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z">
                                                        </path>
                                                    </svg>

                                                    <span class="fs-14 fw-900 color1">Download Certificate</span>
                                                </a>
                                                <a>

                                                    <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                                        height="25" width="25" viewBox="0 9 46 27">
                                                        <path
                                                            d="M36.35 44q-2.35 0-4.025-1.675Q30.65 40.65 30.65 38.3q0-.35.075-.825t.225-.875L15.8 27.8q-.75.85-1.85 1.375t-2.25.525q-2.35 0-4.025-1.675Q6 26.35 6 24q0-2.4 1.675-4.05T11.7 18.3q1.15 0 2.2.45 1.05.45 1.9 1.3l15.15-8.7q-.15-.35-.225-.8-.075-.45-.075-.85 0-2.4 1.675-4.05T36.35 4q2.4 0 4.05 1.65t1.65 4.05q0 2.35-1.65 4.025-1.65 1.675-4.05 1.675-1.15 0-2.225-.375Q33.05 14.65 32.3 13.8l-15.15 8.4q.1.4.175.925.075.525.075.875t-.075.75q-.075.4-.175.8l15.15 8.6q.75-.7 1.75-1.125 1-.425 2.3-.425 2.4 0 4.05 1.65t1.65 4.05q0 2.35-1.65 4.025Q38.75 44 36.35 44Z">
                                                        </path>
                                                    </svg>



                                                    <span class="fs-14 fw-900 color1">Share</span>
                                                </a>
                                            </div>



                                        </div>

                                    </div>




                                </div>

                                <div class="owl-carousel  home-video-single  mt-20"
                                    *ngIf="certificateListingData.length == 0">
                                    <div class="item" style="text-align: center;">
                                        <div class="border-theme-2px border-radius-30 overflow-hidden px-15 py-20">
                                            <span>No certificate List available for now! </span>

                                        </div>


                                    </div>
                                </div>


                            </div>

                        </div>


                    </section>



                    <section>
                        <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-lg-60 mt-50 mb-0 text-center">
                            Student Success Stories
                        </h2>
                        <div class="row mx-0 px-0 mt-20">
                            <div class="col-md-6 px-vs-0 cstm-dots-mob">
                                <div class="owl-carousel owl-theme student-testimonial h-100">
                                    <div class="item h-100">
                                        <div
                                            class="student-video-testimonial border-theme-2px border-radius-30  overflow-hidden h-100">

                                            <iframe class="fade-overlay border-radius-0" width="100%" height="220"
                                                src="https://www.youtube.com/embed/6ehIYXsNMXY?rel=0"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen>
                                                <p>jfbdjfdjfnfdb</p>
                                            </iframe>
                                            <p class="Video-heading px-15 py-15 mt-0 fs-17 fw-600 text-center">
                                                Multi Skill Vocational Education in Schools

                                            </p>

                                        </div>


                                    </div>

                                    <div class="item h-100">
                                        <div
                                            class="student-video-testimonial border-theme-2px border-radius-30  overflow-hidden h-100 ">

                                            <iframe class="fade-overlay border-radius-0" width="100%" height="220"
                                                src="https://www.youtube.com/embed/oi3QcHUeQqw?rel=0"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                            <p class="Video-heading px-15 py-15 mt-0 fs-17 fw-600 text-center">
                                                Skills on Wheels - in Himachal Pradesh

                                            </p>

                                        </div>


                                    </div>

                                    <div class="item h-100">
                                        <div
                                            class="student-video-testimonial border-theme-2px border-radius-30  overflow-hidden h-100">

                                            <iframe class="fade-overlay border-radius-0" width="100%" height="220"
                                                src="https://www.youtube.com/embed/6ehIYXsNMXY?rel=0"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen>
                                                <p>jfbdjfdjfnfdb</p>
                                            </iframe>
                                            <p class="Video-heading px-15 py-15 mt-0 fs-17 fw-600 text-center">
                                                Multi Skill Vocational Education in Schools

                                            </p>

                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>




                    <section>
                        <h2 class="fw-900 fs-22 text-black fs-lg-30 mt-lg-60 mt-70 mb-0 text-center">
                            Support
                        </h2>


                        <div class="px-15">
                            <div
                                class="bg-light-theme px-15 py-15 border-radius-30 mt-10 d-flex align-items-center justify-content-between">
                                <img src="assets/upload/Help-for-hiring.png" class="img-fluid mr-15" alt="" srcset="">
                                <h5 class="fs-18 line-h-25 fw-900 mb-0 text-black">Get Help For
                                    Hiring</h5>
                                <a class=" d-block" [routerLink]="['/get-help']">
                                    <i
                                        class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35"></i>
                                </a>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>