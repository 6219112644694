<section class="">
    <div class="container">

        <div class="content-section">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu></igmite-side-menu>


                </div>



                <div class="col-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">



                        <div class="col-12 px-0 text-center">

                            <div
                                class="bg-theme-light-grey top-circle overflow-hidden mt-lg-0 mx-auto d-flex justify-content-center align-items-center">

                                <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                    enable-background="new 0 0 24 24" height="70px" viewBox="0 0 24 24" width="70px"
                                    fill="#000000">
                                    <g>
                                        <rect fill="none" height="24" width="24" />
                                        <rect fill="none" height="24" width="24" />
                                    </g>
                                    <g>
                                        <path
                                            d="M17.5,4.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5c-1.45,0-2.99,0.22-4.28,0.79C1.49,5.62,1,6.33,1,7.14 l0,11.28c0,1.3,1.22,2.26,2.48,1.94C4.46,20.11,5.5,20,6.5,20c1.56,0,3.22,0.26,4.56,0.92c0.6,0.3,1.28,0.3,1.87,0 c1.34-0.67,3-0.92,4.56-0.92c1,0,2.04,0.11,3.02,0.36c1.26,0.33,2.48-0.63,2.48-1.94l0-11.28c0-0.81-0.49-1.52-1.22-1.85 C20.49,4.72,18.95,4.5,17.5,4.5z M21,17.23c0,0.63-0.58,1.09-1.2,0.98c-0.75-0.14-1.53-0.2-2.3-0.2c-1.7,0-4.15,0.65-5.5,1.5V8 c1.35-0.85,3.8-1.5,5.5-1.5c0.92,0,1.83,0.09,2.7,0.28c0.46,0.1,0.8,0.51,0.8,0.98V17.23z" />
                                        <g />
                                        <path
                                            d="M13.98,11.01c-0.32,0-0.61-0.2-0.71-0.52c-0.13-0.39,0.09-0.82,0.48-0.94c1.54-0.5,3.53-0.66,5.36-0.45 c0.41,0.05,0.71,0.42,0.66,0.83c-0.05,0.41-0.42,0.71-0.83,0.66c-1.62-0.19-3.39-0.04-4.73,0.39 C14.13,10.99,14.05,11.01,13.98,11.01z" />
                                        <path
                                            d="M13.98,13.67c-0.32,0-0.61-0.2-0.71-0.52c-0.13-0.39,0.09-0.82,0.48-0.94c1.53-0.5,3.53-0.66,5.36-0.45 c0.41,0.05,0.71,0.42,0.66,0.83c-0.05,0.41-0.42,0.71-0.83,0.66c-1.62-0.19-3.39-0.04-4.73,0.39 C14.13,13.66,14.05,13.67,13.98,13.67z" />
                                        <path
                                            d="M13.98,16.33c-0.32,0-0.61-0.2-0.71-0.52c-0.13-0.39,0.09-0.82,0.48-0.94c1.53-0.5,3.53-0.66,5.36-0.45 c0.41,0.05,0.71,0.42,0.66,0.83c-0.05,0.41-0.42,0.7-0.83,0.66c-1.62-0.19-3.39-0.04-4.73,0.39 C14.13,16.32,14.05,16.33,13.98,16.33z" />
                                    </g>
                                </svg>




                            </div>
                            <p class="fs-22 text-black fw-900  line-h-22 mt-25 mb-20 px-10 text-center">Final
                                Internship
                                Report </p>




                        </div>






                    </div>

                    <div class="px-20 max-w-800 py-20 mt-10 text-black bg-light-theme border-radius-20 mx-auto">
                        <p class="fs-14 fw-500 text-black mb-0">Upload your final internship report using the form
                            below, and your teacher will review these files.</p>




                    </div>
                    <form name="finalInternshipReporForm" novalidate [formGroup]="finalInternshipReporForm">
                        <div class="border-theme-2px px-15 border-radius-20 py-20 mt-30 mb-5 max-w-800 mx-auto">

                           
                            <div class="mb-25">
                                <p class="fs-16 text-black mb-1 px-1 fw-900">Internship Completion Date <span style="color: red;">*</span></p>
                                <div class="input-with-icon position-relative">

                                    <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                        <input  matInput type="date"  formControlName="ActualEndDate" name="ActualEndDate" class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                                        fw-600 cursor-pointer input-top">
                                        <mat-error *ngIf="finalInternshipReporForm.controls.ActualEndDate.invalid" >
                                            {{getErrorMessage(finalInternshipReporForm, 'ActualEndDate')}}
                                        </mat-error>
                                    </mat-form-field>

                                   



                                </div>
                               
                            </div>

                            <div class="mb-25">
                                <p class="fs-16 text-black mb-1 px-1 fw-900">Upload the best picture of your
                                    internship <span style="color: red;">*</span></p>
                                <div class="input-with-icon position-relative">



                                    <label
                                        class="cstm-file-upload border-theme-2px border-radius-10 px-10 py-2 d-flex align-items-center w-100">
                                        <input type="file"  aria-label="File browser example"
                                            [disabled]=" BestPicture.length != 0" accept="image/jpeg, image/jpg, image/png" (change)="getFileBestPicture($event)">
                                        <span class="file-custom"></span>
                                        <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24"
                                            width="30px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24"></rect>
                                            </g>
                                            <g>
                                                <path
                                                    d="M20,6h-8l-1.41-1.41C10.21,4.21,9.7,4,9.17,4H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M13,13v3c0,0.55-0.45,1-1,1h0c-0.55,0-1-0.45-1-1v-3H9.21c-0.45,0-0.67-0.54-0.35-0.85l2.8-2.79 c0.2-0.2,0.51-0.19,0.71,0l2.79,2.79C15.46,12.46,15.24,13,14.8,13H13z">
                                                </path>
                                            </g>
                                        </svg>


                                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="stroke-color-6" width="30" height="30" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
                    <polyline points="9 15 12 12 15 15"></polyline>
                    <line x1="12" y1="12" x2="12" y2="21"></line>
                  </svg> -->
                                    </label>



                                </div>
                                <p class="fs-16 text-black fw-600 d-flex justify-content-between align-items-center mb-10"
                                    *ngFor='let url of BestPicture; let index = index'>
                                    <a (click)="removeSelectedFile(index,'IRBestPicture',url.name)">

                                        <img [src]="url.url" height="50">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </p>
                            </div>

                            <div class="mb-25">
                                <p class="fs-16 text-black mb-1 px-1 fw-900">Upload the picture of the Certificate
                                    given by Employer </p>
                                <div class="input-with-icon position-relative">



                                    <label
                                        class="cstm-file-upload border-theme-2px border-radius-10 px-10 py-2 d-flex align-items-center w-100">
                                        <input type="file" id="file" accept="image/jpeg, image/jpg, image/png" aria-label="File browser example"
                                            [disabled]=" Cetificate.length != 0" (change)="getFileCetificate($event)">
                                        <span class="file-custom"></span>
                                        <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24"
                                            width="30px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24"></rect>
                                            </g>
                                            <g>
                                                <path
                                                    d="M20,6h-8l-1.41-1.41C10.21,4.21,9.7,4,9.17,4H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M13,13v3c0,0.55-0.45,1-1,1h0c-0.55,0-1-0.45-1-1v-3H9.21c-0.45,0-0.67-0.54-0.35-0.85l2.8-2.79 c0.2-0.2,0.51-0.19,0.71,0l2.79,2.79C15.46,12.46,15.24,13,14.8,13H13z">
                                                </path>
                                            </g>
                                        </svg>


                                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="stroke-color-6" width="30" height="30" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
                    <polyline points="9 15 12 12 15 15"></polyline>
                    <line x1="12" y1="12" x2="12" y2="21"></line>
                  </svg> -->
                                    </label>



                                </div>
                                <p class="fs-16 text-black fw-600 d-flex justify-content-between align-items-center mb-10"
                                    *ngFor='let url of Cetificate; let index = index'>
                                    <a (click)="removeSelectedFile(index,'IRCetificate',url.name)">

                                        <img [src]="url.url" height="50">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </p>
                            </div>

                            <div class="mb-25">
                                <p class="fs-16 text-black mb-1 px-1 fw-900">Upload the Project Photos (upto 5 photos) <span style="color: red;">*</span></p>
                                <div class="input-with-icon position-relative">



                                    <label
                                        class="cstm-file-upload border-theme-2px border-radius-10 px-10 py-2 d-flex align-items-center w-100">
                                        <input type="file" id="file" accept="image/jpeg, image/jpg, image/png" aria-label="File browser example" [ngClass]=""
                                            (change)="getFileRportPhotos($event)" [disabled]=" RportPhotos.length >= 5">
                                        <span class="file-custom"></span>
                                        <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24"
                                            width="30px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24"></rect>
                                            </g>
                                            <g>
                                                <path
                                                    d="M20,6h-8l-1.41-1.41C10.21,4.21,9.7,4,9.17,4H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M13,13v3c0,0.55-0.45,1-1,1h0c-0.55,0-1-0.45-1-1v-3H9.21c-0.45,0-0.67-0.54-0.35-0.85l2.8-2.79 c0.2-0.2,0.51-0.19,0.71,0l2.79,2.79C15.46,12.46,15.24,13,14.8,13H13z">
                                                </path>
                                            </g>
                                        </svg>


                                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="stroke-color-6" width="30" height="30" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
                    <polyline points="9 15 12 12 15 15"></polyline>
                    <line x1="12" y1="12" x2="12" y2="21"></line>
                  </svg> -->
                                    </label>



                                </div>
                                <p class="fs-16 text-black fw-600 d-flex justify-content-between align-items-center mb-10"
                                    *ngFor='let url of RportPhotos; let index = index'>
                                    <a (click)="removeSelectedFile(index,'IRRportPhotos',url.name)">

                                        <img [src]="url.url" height="50">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </p>
                            </div>
                            <div class="mb-25">
                                <p class="fs-16 text-black mb-1 px-1 fw-900">Details <span
                                        class="fs-12 color5">(Optional)</span> </p>
                                <div class="input-with-icon position-relative">
                                    <!-- <textarea name="" id="" cols="20" rows="5"
                                        class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea> -->
                                    <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                        <textarea matInput name="" id="" cols="20" rows="5"
                                            formControlName="ReportDetail" name="reportDetail"
                                            class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15">
                                               </textarea>
                                        <mat-error *ngIf="finalInternshipReporForm.controls.ReportDetail.invalid">
                                            {{getErrorMessage(finalInternshipReporForm, 'ReportDetail')}}
                                        </mat-error>
                                    </mat-form-field>
                                    <button
                                        [disabled]="(finalInternshipReporForm.invalid || RportPhotos.length == 0  || BestPicture.length == 0)"
                                        (click)="FinalInternshipReport()"
                                        class="d-block px-3 text-white max-w-180 text-uppercase border-radius-15 mx-auto mt-50 w-100 py-2 upto-down-gradient-clr-1 theme-btn">SUBMIT</button>




                                </div>
                            </div>

                        </div>
                    </form>






                </div>

            </div>
        </div>
    </div>

</section>