import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { CongratulationService } from './congratulation.service';

@Component({
  providers: [AppConstants],
  selector: 'igmite-congratulation',
  templateUrl: './congratulation.component.html',
  styleUrls: ['./congratulation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CongratulationComponent extends BaseComponent<LoginModel> implements OnInit {
  public loginModel: LoginModel;
  public returnUrl: string;
  private deviceInfo: any;
  profileAll:any
  item:any
  InternshipById:any
  allowedData: any

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private congratulationService: CongratulationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    public zone: NgZone,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
      
        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
    elementIn.setAttribute('style', 'display: none !important');

    });
    if(this.UserModel.PhotoUrl != null){
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src',  this.Constants.CommonImageUrl.Account+this.UserModel.PhotoUrl);
    }else{
      this.UserModel.PhotoUrl=''
    }
 

      var proId={
        DataId:this.UserModel.UserTypeId
      }
      this.congratulationService.GetStudentProfileConfirmationById(proId).subscribe((profileResp: any) => {
          if (profileResp.Success == true) {
            this.zone.run(() => {
              this.profileAll=profileResp.Results[0]
              this.profileAll.PhotoUrl = this.Constants.CommonImageUrl.Account+this.profileAll.PhotoUrl ;
              this.IsLoading = false;
              // this.showActionMessage(
              //   this.Constants.Messages.RecordSavedMessage,
              //   this.Constants.Html.SuccessSnackbar
              // );
            });
            
          }
          else {
            // this.showErrorMessage(
            //   'Invalid UpdateStudentInternshipsStatus ',
            //   'info-snackbar'
            // );
          }

        
        }, error => {
      });
    //   this.item=JSON.parse(sessionStorage.getItem('item'));
      var Inter=JSON.parse(sessionStorage.getItem('item'));
      if( Inter !== null ){
         this.InternshipById={ DataId:Inter}
         
      }else{
         this.InternshipById={ DataId:this.UserModel.InternshipId }
      }
      var data1 = {
        "DataId": this.UserModel.UserTypeId,
        "DataId1": this.InternshipById.DataId,

      }

      this.congratulationService.allowDashboard(data1).subscribe((response: any) => {
        this.allowedData = response.Result
        sessionStorage.setItem('allow', JSON.stringify(this.allowedData));
  
      }, error => {
      });
      this.congratulationService.GetInternshipById(this.InternshipById).subscribe((profileResp: any) => {
          if (profileResp.Success == true) {
            this.zone.run(() => {
              this.item=profileResp.Result
              this.IsLoading = false;
              // this.showActionMessage(
              //   this.Constants.Messages.RecordSavedMessage,
              //   this.Constants.Html.SuccessSnackbar
              // );
            });
            
          }
          else {
            // this.showErrorMessage(
            //   'Invalid UpdateStudentInternshipsStatus ',
            //   'info-snackbar'
            // );
          }

        
        }, error => {
      });

      
  }
  showErrorMessage(messageText: string, messageType: string) {
    this.snackBar.open(messageText, "Dismiss", {
      duration: 2000,
      verticalPosition: "bottom",
      horizontalPosition: "center",
      panelClass: [messageType]
    });
  }

}


