import { FuseUtils } from '@fuse/utils';

export class AttendanceV2Model {
    InterestGuidList: string;
    AuthUserId: string;
    Remarks: string;
    IsActive:boolean;
    StudentInterestId:string;
    StudentId:string;
    RequestType:any;
    InterestId:string
    constructor(InterestsItem?: any) {
        InterestsItem = InterestsItem || {};

        this.InterestGuidList = InterestsItem.InterestGuidList || '';
        this.AuthUserId = InterestsItem.AuthUserId || '';
        this.StudentId = InterestsItem.StudentId || '';
        this.StudentInterestId = '00000000-0000-0000-0000-000000000000';
        this.InterestId = '00000000-0000-0000-0000-000000000000';
        this.Remarks = 'Remarks for interest list';
        this.IsActive = true;
        this.RequestType = 0; // New

    }
} 