<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu-emp></igmite-side-menu-emp>

                </div>

                <div class="col-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
                    <div class="row px-0 mx-0 align-items-lg-center max-w-800 mx-auto mb-4">



                        <div class="col-12 px-0 text-center">

                            <div
                                class="bg-theme-light-grey top-circle overflow-hidden mt-lg-0 mx-auto d-flex justify-content-center align-items-center">
                                <img src="assets/upload/Skill-shine-icon.png" class="img-fluid object-fit-contain" alt=""
                                    srcset="" height="">
                            </div>

                        </div>

                    </div>

                    <p class="fs-22 text-black fw-900  line-h-22 mt-15 mb-30 px-10 text-center"> Gratitude Listing
                    </p>

                    <div class="row mx-0 px-0 mb-5">
                        <div class="col-md-6 mb-30 px-10" *ngFor="let gratitute of gratituteListingData;index as i;">
                            <div class="border-theme-2px border-radius-30 overflow-hidden px-15 py-20">
                                <p
                                    class="fs-16 fw-400 mb-10 border-theme-2px border-start-0 border-end-0 border-top-0 pb-20 mb-10">
                                    {{gratitute.GratitudeDesc}}</p>


                                <div class="ranker-name-profile d-flex align-items-center">
                                    <img [src]="ApiBaseUrlImage + gratitute.StudentPhoto"
                                        class="rounded-circle border-theme-2px mr-10" alt="" srcset="" width="40"
                                        height="40">
                                    <div>
                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">{{gratitute.StudentName}}
                                        </p>
                                        <p class="fs-12 fw-400 text-black mb-0 mt-0">{{gratitute.StudentAddress1}}...
                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <!-- <div class="col-md-6 mb-30 px-10">
                            <div class="border-theme-2px border-radius-30 overflow-hidden px-15 py-20">
                                <p
                                    class="fs-16 fw-400 mb-10 border-theme-2px border-start-0 border-end-0 border-top-0 pb-20 mb-10">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae ipsum vitae
                                    felis uet dictum diam eget sagittis.</p>


                                <div class="ranker-name-profile d-flex align-items-center">
                                    <img src="../Upload/Manisha-Mishra.png"
                                        class="rounded-circle border-theme-2px mr-10" alt="" srcset="" width="40"
                                        height="40">
                                    <div>
                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">Akshra Sighania</p>
                                        <p class="fs-12 fw-400 text-black mb-0 mt-0">Govt Senior Secondary Gambhir
                                            Pura,
                                            Mahendra Nagar...</p>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="col-md-6 mb-30 px-10">
                            <div class="border-theme-2px border-radius-30 overflow-hidden px-15 py-20">
                                <p
                                    class="fs-16 fw-400 mb-10 border-theme-2px border-start-0 border-end-0 border-top-0 pb-20 mb-10">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae ipsum vitae
                                    felis uet dictum diam eget sagittis.</p>


                                <div class="ranker-name-profile d-flex align-items-center">
                                    <img src="../Upload/Mohan-Singh.png" class="rounded-circle border-theme-2px mr-10"
                                        alt="" srcset="" width="40" height="40">
                                    <div>
                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">Mohan Singh</p>
                                        <p class="fs-12 fw-400 text-black mb-0 mt-0">Govt Senior Secondary Gambhir
                                            Pura,
                                            Mahendra Nagar...</p>
                                    </div>

                                </div>

                            </div>
                        </div>


                        <div class="col-md-6 mb-30 px-10">
                            <div class="border-theme-2px border-radius-30 overflow-hidden px-15 py-20">
                                <p
                                    class="fs-16 fw-400 mb-10 border-theme-2px border-start-0 border-end-0 border-top-0 pb-20 mb-10">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae ipsum vitae
                                    felis uet dictum diam eget sagittis.</p>


                                <div class="ranker-name-profile d-flex align-items-center">
                                    <img src="../Upload/Roshni-Jha.png" class="rounded-circle border-theme-2px mr-10"
                                        alt="" srcset="" width="40" height="40">
                                    <div>
                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">Roshni Jha</p>
                                        <p class="fs-12 fw-400 text-black mb-0 mt-0">Govt Senior Secondary Gambhir
                                            Pura,
                                            Mahendra Nagar...</p>
                                    </div>

                                </div>

                            </div>
                        </div> -->

                    </div>



                </div>

            </div>
        </div>
    </div>
</section>