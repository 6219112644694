<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-lg-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu></igmite-side-menu>

                </div>


                <div class="col-md-9 col-lg-10 right-content px-lg-35 px-0 pb-5 bg-white py-18" >


                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto" *ngIf="profileDetails">

                        <!-- <div class="col-3 cursor-pointer" [routerLink]="['/raise-safety-concer']" style="cursor:pointer;">
                            <img src="assets/Content/images/Alert-icon.png" class=""
                                alt="Alert" height="25" srcset="">
                        </div> -->
                        <div class="col-3 cursor-pointer" >
                           
                        </div>
                     


                        <div class="col-6 px-0 text-center">

                            <div class="user-profile-dash mt-lg-0"> <img [src]="profileDetails.PhotoUrl" alt=""
                                    srcset=""> </div>
                        </div>

                        <!-- <div class="col-1 text-end cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1 h-30" viewBox="0 0 24 24"
                                width="28px" fill="#000000">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path
                                    d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92-1.31-2.92-2.92-2.92z" />
                            </svg>


                        </div> -->
                        <!-- <div class="col-2 text-end cursor-pointer " *ngIf="statusData && dashboardData">

                            <a *ngIf="(dashboardData.Hours != 0 && dashboardData.Hours != null) && (statusData.IsIRReviewed == false || statusData.IsIRReviewed == null)"
                                class="fs-16" [routerLink]="['/final-internship-report']"
                                title="Final Internship Report">

                                <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                    enable-background="new 0 0 24 24" height="40px" viewBox="0 0 24 24" width="40px"
                                    fill="#000000">
                                    <g>
                                        <rect fill="none" height="24" width="24" />
                                        <rect fill="none" height="24" width="24" />
                                    </g>
                                    <g>
                                        <path
                                            d="M17.5,4.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5c-1.45,0-2.99,0.22-4.28,0.79C1.49,5.62,1,6.33,1,7.14 l0,11.28c0,1.3,1.22,2.26,2.48,1.94C4.46,20.11,5.5,20,6.5,20c1.56,0,3.22,0.26,4.56,0.92c0.6,0.3,1.28,0.3,1.87,0 c1.34-0.67,3-0.92,4.56-0.92c1,0,2.04,0.11,3.02,0.36c1.26,0.33,2.48-0.63,2.48-1.94l0-11.28c0-0.81-0.49-1.52-1.22-1.85 C20.49,4.72,18.95,4.5,17.5,4.5z M21,17.23c0,0.63-0.58,1.09-1.2,0.98c-0.75-0.14-1.53-0.2-2.3-0.2c-1.7,0-4.15,0.65-5.5,1.5V8 c1.35-0.85,3.8-1.5,5.5-1.5c0.92,0,1.83,0.09,2.7,0.28c0.46,0.1,0.8,0.51,0.8,0.98V17.23z" />
                                        <g />
                                        <path
                                            d="M13.98,11.01c-0.32,0-0.61-0.2-0.71-0.52c-0.13-0.39,0.09-0.82,0.48-0.94c1.54-0.5,3.53-0.66,5.36-0.45 c0.41,0.05,0.71,0.42,0.66,0.83c-0.05,0.41-0.42,0.71-0.83,0.66c-1.62-0.19-3.39-0.04-4.73,0.39 C14.13,10.99,14.05,11.01,13.98,11.01z" />
                                        <path
                                            d="M13.98,13.67c-0.32,0-0.61-0.2-0.71-0.52c-0.13-0.39,0.09-0.82,0.48-0.94c1.53-0.5,3.53-0.66,5.36-0.45 c0.41,0.05,0.71,0.42,0.66,0.83c-0.05,0.41-0.42,0.71-0.83,0.66c-1.62-0.19-3.39-0.04-4.73,0.39 C14.13,13.66,14.05,13.67,13.98,13.67z" />
                                        <path
                                            d="M13.98,16.33c-0.32,0-0.61-0.2-0.71-0.52c-0.13-0.39,0.09-0.82,0.48-0.94c1.53-0.5,3.53-0.66,5.36-0.45 c0.41,0.05,0.71,0.42,0.66,0.83c-0.05,0.41-0.42,0.7-0.83,0.66c-1.62-0.19-3.39-0.04-4.73,0.39 C14.13,16.32,14.05,16.33,13.98,16.33z" />
                                    </g>
                                </svg>
                            </a>



                        </div> -->
                    </div>
                    <div class="user-name text-center mt-15 max-w-800 mx-auto" *ngIf="profileDetails">
                        <h2 class="color2 fw-900 fs-25"><span class="text-black fs-26 font-pacific fw-400">
                                {{profileDetails.FullName}}</span> </h2>
                    </div>


                    <div class="px-10 mt-20 max-w-800 mx-auto" *ngIf="dashboardData">
                        <div class="row mx-0 px-0 dash-scorecard border-theme-2px border-radius-40">
                            <!-- <mat-progress-bar mode="determinate" value="{{dashboardData.StudentPoints}}">
                            </mat-progress-bar> -->

                            <div class="col-4 text-center py-10 active">

                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10"
                                    enable-background="new 0 0 24 24" fill="#000000" height="35" width="35"
                                    viewBox="0 0 22 22">
                                    <rect fill="none" height="24" width="24"></rect>
                                    <path
                                        d="M19,5h-2V4c0-0.55-0.45-1-1-1H8C7.45,3,7,3.45,7,4v1H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94 c0.63,1.5,1.98,2.63,3.61,2.96V19H8c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-3v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z">
                                    </path>
                                </svg>

                                <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Points</p>

                                <strong
                                    class="fs-20 line-h-17 fw-900 text-center text-black ">{{dashboardData.StudentPoints}}</strong>

                            </div>


                            <div class="col-4 text-center py-10">





                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10"
                                    enable-background="new 0 0 24 24" fill="#000000" height="35" width="35"
                                    viewBox="1 0 22 22">
                                    <g>
                                        <rect fill="none" height="24" width="24" />
                                        <rect fill="none" height="24" width="24" />
                                    </g>
                                    <g>
                                        <path
                                            d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M9.38,16.01L7,13.61c-0.39-0.39-0.39-1.02,0-1.41 l0.07-0.07c0.39-0.39,1.03-0.39,1.42,0l1.61,1.62l5.15-5.16c0.39-0.39,1.03-0.39,1.42,0l0.07,0.07c0.39,0.39,0.39,1.02,0,1.41 l-5.92,5.94C10.41,16.4,9.78,16.4,9.38,16.01z" />
                                    </g>
                                </svg>

                                <a href="/diary-entry-listing">
                                    <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Attendance</p>

                                    <strong
                                        class="fs-20 line-h-17 fw-900 text-center text-black ">{{dashboardData.Attendance}}
                                        D</strong>
                                </a>


                            </div>


                            <div class="col-4 text-center py-10">


                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10" height="35"
                                    width="35" viewBox="0 0 22 22">
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path
                                        d="M16.24 7.76C15.07 6.59 13.54 6 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
                                </svg>

                                <a href="/internship-3">
                                <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Total Duration</p>

                                <strong class="fs-20 line-h-17 fw-900 text-center text-black ">{{dashboardData.Hours|
                                    slice:0:5}}
                                    H</strong>
                                </a>

                            </div>

                        </div>
                    </div>
                    <div class="px-10 mt-20 max-w-800 mx-auto" *ngIf="!dashboardData">
                        <div class="row mx-0 px-0 dash-scorecard border-theme-2px border-radius-40">

                            <div class="col-4 text-center py-10 active">

                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10"
                                    enable-background="new 0 0 24 24" fill="#000000" height="35" width="35"
                                    viewBox="0 0 22 22">
                                    <rect fill="none" height="24" width="24"></rect>
                                    <path
                                        d="M19,5h-2V4c0-0.55-0.45-1-1-1H8C7.45,3,7,3.45,7,4v1H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94 c0.63,1.5,1.98,2.63,3.61,2.96V19H8c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-3v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z">
                                    </path>
                                </svg>

                                <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Points</p>

                                <strong class="fs-20 line-h-17 fw-900 text-center text-black ">0</strong>

                            </div>


                            <div class="col-4 text-center py-10">





                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10"
                                    enable-background="new 0 0 24 24" fill="#000000" height="35" width="35"
                                    viewBox="1 0 22 22">
                                    <g>
                                        <rect fill="none" height="24" width="24" />
                                        <rect fill="none" height="24" width="24" />
                                    </g>
                                    <g>
                                        <path
                                            d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M9.38,16.01L7,13.61c-0.39-0.39-0.39-1.02,0-1.41 l0.07-0.07c0.39-0.39,1.03-0.39,1.42,0l1.61,1.62l5.15-5.16c0.39-0.39,1.03-0.39,1.42,0l0.07,0.07c0.39,0.39,0.39,1.02,0,1.41 l-5.92,5.94C10.41,16.4,9.78,16.4,9.38,16.01z" />
                                    </g>
                                </svg>

                                <a>
                                    <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Attendance</p>

                                    <strong class="fs-20 line-h-17 fw-900 text-center text-black ">0
                                        D</strong>
                                </a>


                            </div>


                            <div class="col-4 text-center py-10">


                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10" height="35"
                                    width="35" viewBox="0 0 22 22">
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path
                                        d="M16.24 7.76C15.07 6.59 13.54 6 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
                                </svg>

                                <a href="/internship-3">
                                <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Internship</p>

                                <strong class="fs-20 line-h-17 fw-900 text-center text-black ">0
                                    H</strong>
                                    </a>

                            </div>

                        </div>
                    </div>

                    <div>


                        <div class="d-flex justify-content-center align-items-center mt-30">
                            <img src="assets/upload/collect-points-left.png" alt="collect points" srcset="">
                            <div class="text-center">
                                <p class="fs-40 color2 mb-0 line-h-30 fw-900">100</p>
                                <p class="fs-12 fw-900 mb-0 color2">Points</p>
                            </div>

                            <img src="assets/upload/collect-points-right.png" alt="collect points" srcset="">

                        </div>

                        <p class="fs-18 fw-900 text-center px-10 text-black mb-20 line-h-30 mt-10">
                            What did you Learn Today?
                        </p>
                    </div>




                    <div class="px-10 max-w-800 mx-auto " *ngIf="DailyDiary">


                        <div class="py-20 px-15 border-theme-2px border-radius-25">

                            <form name="dashboardForm" novalidate [formGroup]="dashboardForm">
                                <div *ngFor="let Daily of DailyDiary;">
                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'Date'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}}</p>
                                        <div class="input-with-icon position-relative dynamic-text-input-date">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input [(ngModel)]="todayDate" [min]="maxDate" [max]="maxDate"
                                                    (change)="onChange(Daily,$event.target.value)" matInput type="date"
                                                    id="date" formControlName="Date" name="date" class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                                                fw-600 cursor-pointer input-top">
                                                <mat-error *ngIf="dashboardForm.controls.Date.invalid">
                                                    {{getErrorMessage(dashboardForm, 'Date')}}
                                                </mat-error>
                                            </mat-form-field>

                                        </div>
                                    </div>


                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'Dropdown'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}} </p>
                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative hp">
                                                <select matNativeControl name=""
                                                    (change)="onChange(Daily,$event.target.value)" matInput id="timing"
                                                    formControlName="HoursSpent" name="hoursSpent"
                                                    class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                    <option value="">Select ( Hours : Min. ) </option>
                                                    <option value="00:30">00:30</option>
                                                    <option value="1:00">1:00</option>
                                                    <option value="1:30">1:30</option>
                                                    <option value="2:00">2:00</option>
                                                    <option value="2:30">2:30</option>
                                                    <option value="3:00">3:00</option>
                                                    <option value="3:30">3:30</option>
                                                    <option value="4:00">4:00</option>
                                                    <option value="4:30">4:30</option>
                                                    <option value="5:00">5:00</option>
                                                    <option value="5:30">5:30</option>
                                                    <option value="6:00">6:00</option>
                                                    <option value="6:30">6:30</option>
                                                    <option value="7:00">7:00</option>
                                                    <option value="7:30">7:30</option>
                                                    <option value="8:00">8:00</option>

                                                </select>
                                                <label for="timing" class="right-icon-input z-index-div-bottm">
                                                </label>
                                                <mat-error *ngIf="dashboardForm.controls.HoursSpent.invalid">
                                                    <!-- {{getErrorMessage(dashboardForm, 'HoursSpent')}} -->
                                                    Hours Spent is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>



                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'TextArea'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}} </p>
                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <textarea matInput name="" id=""
                                                    (change)="onChange(Daily,$event.target.value)" cols="30" rows="10"
                                                    formControlName="TodayLearning" name="todayLearning"
                                                    class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15">
                                                Lorem ipsum dolor sit amet, conse Ctetur amet, conse adip iscing elit. 
                                                The psum dolor sit amet. Sconse adi piscing elit. Apsum dolor sit amet. Sconse adi piscing elit. 
                                                Apsum dolor sit amet. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adip iscing elit.</textarea>
                                                <mat-error *ngIf="dashboardForm.controls.TodayLearning.invalid">
                                                    <!-- {{getErrorMessage(dashboardForm, 'TodayLearning')}} -->
                                                    Today Learning is required
                                                </mat-error>
                                            </mat-form-field>






                                        </div>
                                    </div>


                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'File'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}}
                                            <span class="fs-12">(Photos, Videos, Documents etc.)</span>
                                        </p>
                                        <div class="input-with-icon position-relative">



                                            <label
                                                class="cstm-file-upload border-theme-2px border-radius-10 px-10 py-10 d-flex align-items-center w-100">
                                                <!-- <input type="file" id="file" multiple aria-label="File browser example"
                                                    (change)="getFileDetails(Daily,$event)"> -->
                                                <input type='file' id="file" multiple aria-label="File browser example"
                                                    (change)="onSelectFile(Daily,$event)" multiple>
                                                <span class="file-custom"></span>
                                                <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                                    enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24"
                                                    width="30px" fill="#000000">
                                                    <g>
                                                        <rect fill="none" height="24" width="24" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M20,6h-8l-1.41-1.41C10.21,4.21,9.7,4,9.17,4H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M13,13v3c0,0.55-0.45,1-1,1h0c-0.55,0-1-0.45-1-1v-3H9.21c-0.45,0-0.67-0.54-0.35-0.85l2.8-2.79 c0.2-0.2,0.51-0.19,0.71,0l2.79,2.79C15.46,12.46,15.24,13,14.8,13H13z" />
                                                    </g>
                                                </svg>

                                            </label>



                                        </div>
                                    </div>
                                </div>
                                <!-- <p class="fs-16 text-black fw-600 d-flex justify-content-between align-items-center mb-10"
                                    *ngIf="FileName">
                                    {{FileName}}<a (click)="removeImg()">
                                        <i class="fa fa-trash"></i>
                                    </a>

                                </p> -->
                                <p class="fs-16 text-black fw-600 d-flex justify-content-between align-items-center mb-10"
                                    *ngFor='let url of urls; let index = index'>
                                    <a (click)="removeSelectedFile(index)">

                                        <img [src]="url" height="50">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </p>



                                <button
                                    class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-25 text-uppercase box-shadow-hard mb-10"
                                    [disabled]="dashboardForm.invalid "
                                    (click)="CreateDailyDiaryEntry(DailyDiary)">Submit</button>



                            </form>
                        </div>
                    </div>


                    <div class="px-10 mt-50 max-w-800 mx-auto" *ngIf="profileDetails">
                        <p class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-10 line-h-30 mt-0">
                            Top Performers</p>

                        <div class="border-theme-2px border-radius-25 overflow-hidden" *ngIf="!dashboardTopData">
                            Top Performers Not Found
                        </div>
                        <div class="border-theme-2px border-radius-25 overflow-hidden" *ngIf="dashboardTopData">
                            <div *ngFor="let toplist of dashboardTopData;let i = index">
                                <div
                                    [ngClass]="toplist.StudentId === profileDetails.StudentId ? 'py-20 d-flex px-10 justify-content-between align-items-center bg-color2 border-radius-25 box-shadow-light' : 'py-20 d-flex px-10 justify-content-between align-items-center'">

                                    <div class="rank mr-10 w-70">
                                        <p *ngIf="i == 0" class=""
                                            [ngClass]="toplist.StudentId === profileDetails.StudentId ? 'fs-20 fw-900 text-black position-relative mb-0' : 'fs-20 fw-900 color2 position-relative mb-0'">
                                            {{toplist.RowIndex}} <sup
                                                class="fs-12 fw-900 position-absolute top-0 mt-10 mb-0">st
                                            </sup>
                                        </p>
                                        <p *ngIf="i == 1" class=""
                                            [ngClass]="toplist.StudentId === profileDetails.StudentId ? 'fs-20 fw-900 text-black position-relative mb-0' : 'fs-20 fw-900 color2 position-relative mb-0'">
                                            {{toplist.RowIndex}} <sup
                                                class="fs-12 fw-900 position-absolute top-0 mt-10 mb-0">nd
                                            </sup>
                                        </p>
                                        <p *ngIf="i == 2" class=""
                                            [ngClass]="toplist.StudentId === profileDetails.StudentId ? 'fs-20 fw-900 text-black position-relative mb-0' : 'fs-20 fw-900 color2 position-relative mb-0'">
                                            {{toplist.RowIndex}} <sup
                                                class="fs-12 fw-900 position-absolute top-0 mt-10 mb-0">rd
                                            </sup>
                                        </p>
                                        <p *ngIf="i != 0 && i != 1 && i != 2" class=""
                                            [ngClass]="toplist.StudentId === profileDetails.StudentId ? 'fs-20 fw-900 text-black position-relative mb-0' : 'fs-20 fw-900 color2 position-relative mb-0'">
                                            {{toplist.RowIndex}} <sup
                                                class="fs-12 fw-900 position-absolute top-0 mt-10 mb-0">th
                                            </sup>
                                        </p>
                                    </div>
                                    <div
                                        class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                        <!-- <img src="assets/upload/Mohan-Singh.png" height="40" width="40"
                                            class="rounded-circle border-theme-2px mr-10" alt="" srcset=""> -->
                                            <img [src]="stuPhotoUrl+toplist.StudentPhoto" height="40" width="40"
                                            class="rounded-circle border-theme-2px mr-10" alt="" srcset="">
                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">{{toplist.StudentName}}
                                        </p>

                                    </div>
                                    <div class="ranker-points">
                                        <p class="fs-20 fw-900 px-1 text-black mb-0 mt-0">{{toplist.StudentPoints}}</p>
                                    </div>
                                </div>




                                <div class="divider px-10">
                                    <div class="border-theme-1px"></div>
                                </div>
                            </div>




                        </div>


                    </div>
                    <!-- <div class="px-10 mt-50 max-w-800 mx-auto" *ngIf="VTDetails">
                        <p class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-10 line-h-30 mt-0">
                            Vocational Trainer Details</p>

                        <div class="border-theme-2px border-radius-25 overflow-hidden px-15 py-15">



                            <p
                                class="fs-16 fw-600 text-black mb-0 d-flex justify-content-between align-items-center mt-0 border-bottom-grey-light pb-10">
                                {{VTDetails.VTName}}

                            </p>





                            <div class="mt-10 d-flex align-items-center mb-15">
                                <svg class="fill-color-2 mr-10 width-lg-51" xmlns="http://www.w3.org/2000/svg"
                                    height="30px" viewBox="0 0 24 24" width="30px" fill="#000000">
                                    <path
                                        d="M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z" />
                                </svg>
                                <p class="fs-18 text-black fw-600 mb-0"><a href="#" class="color6 " target="_blank"
                                        rel="noopener noreferrer">
                                        {{VTDetails.VTMobile}}
                                    </a>
                                </p>

                            </div>

                        </div>

                    </div> -->

                    <div class="px-10 mt-50 max-w-800 mx-auto" *ngIf="Internship">
                        <p class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-10 line-h-30 mt-0">
                            Company Details</p>

                        <div class="border-theme-2px border-radius-25 overflow-hidden px-15 py-15">



                            <p
                                class="fs-16 fw-600 text-black mb-0 d-flex justify-content-between align-items-center mt-0 border-bottom-grey-light pb-10">
                                {{Internship.Title}} <span
                                    class="border-grey-light border-radius-5  h-50px w-50 d-flex justify-content-center align-items-center"><img
                                        src="assets/upload/MLE-l0go.png" alt="MLE LOGO" class="img-fluid"
                                        srcset=""></span>
                            </p>


                            <div class="d-flex mt-15">
                                <svg class="mr-10 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                    enable-background="new 0 0 24 24" height="42px" viewBox="2 0 20 27" width="51px"
                                    fill="#000000">
                                    <g>
                                        <rect fill="none" height="24" width="24"></rect>
                                    </g>
                                    <g>
                                        <path
                                            d="M12,2c-4.2,0-8,3.22-8,8.2c0,3.18,2.45,6.92,7.34,11.23c0.38,0.33,0.95,0.33,1.33,0C17.55,17.12,20,13.38,20,10.2 C20,5.22,16.2,2,12,2z M12,12c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C14,11.1,13.1,12,12,12z">
                                        </path>
                                    </g>
                                </svg>
                                <p class="fs-14 text-black fw-600 mb-0">{{Internship.CityName}}
                                    <a class="color6 ml-10" target="_blank" rel="noopener noreferrer">View on Map -
                                        coming soon</a>

                                </p>

                            </div>


                            <div class="mt-10 d-flex align-items-center mb-15">
                                <svg class="fill-color-2 mr-10 width-lg-51" xmlns="http://www.w3.org/2000/svg"
                                    height="30px" viewBox="0 0 24 24" width="30px" fill="#000000">
                                    <path
                                        d="M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z" />
                                </svg>
                                <p class="fs-18 text-black fw-600 mb-0"><a href="#" class="color6 " target="_blank"
                                        rel="noopener noreferrer">{{Internship.POCMobile}}</a></p>

                            </div>

                        </div>

                    </div>




                    <div class="px-0 mt-50 max-w-800 mx-auto">
                        <p class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-10 line-h-30 mt-0">
                            Get Support</p>

                        <div class="row mx-0 px-0">

                            <div class="col-6 px-10">
                                <div class="border-theme-2px border-radius-25 px-20 py-10"
                                    [routerLink]="['/raise-safety-concer']" style="cursor:pointer;">

                                    <div class="px-4 py-30 w-100 text-center bg-light-theme2 border-radius-25 mx-auto">
                                        <img src="assets/Content/images/Alert-icon.png" alt="" srcset="">
                                    </div>
                                    <p class="text-center fs-16 color1 line-h-25 mt-10 mb-0 fw-600">Emergency Help</p>
                                </div>
                            </div>



                            <div class="col-6 px-10">
                                <div class="border-theme-2px border-radius-25 px-20 py-10"
                                    [routerLink]="['/raise-safety-concer']" style="cursor:pointer;">

                                    <div class="px-4 py-30 w-100 text-center bg-light-theme2 border-radius-25 mx-auto">
                                        <img src="assets/Content/images/call-with-vt.png" alt="" srcset="">
                                    </div>
                                    <p class="text-center fs-16 color1 line-h-25 mt-10 mb-0 fw-600">Talk With Trainer
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="px-10 mt-50 max-w-800 mx-auto">
                        <p class="fs-20 line-h-17 fw-900 text-center text-black  px-10 text-black mb-10 line-h-30 mt-0">
                            Know Your Rights</p>

                        <div class="border-radius-25 bg-light-theme2 py-20 px-15 d-flex align-items-center max-w-420 mx-auto justify-content-lg-center"
                            [routerLink]="['/students-right']" style="cursor:pointer;">
                            <img src="assets/Content/images/Basic-rights-icon.png" class="mr-20" height="80px"
                                alt="Your Rights" srcset="">
                            <div>
                                <p class="fs-16 text-black fw-600 mb-0 line-h-25 max-w-180">Do You Know About
                                    Your Rights?</p>

                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1 mt-5px"
                                    enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24" width="40px"
                                    fill="#000000">
                                    <rect fill="none" height="24" width="24" />
                                    <path
                                        d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z" />
                                </svg>

                            </div>

                        </div>

                    </div>





                </div>
            </div>
        </div>
    </div>
</section>