import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class OpprtunitiesListingService {
  

    constructor(private http: BaseService) { }
    GetStudentsInternshipByFilter(filters: any): Observable<any> {
   
        return this.http
            .HttpPost(this.http.Services.Interests.GetStudentsInternshipByFilter, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetInterestsByCriteria(filters: any): Observable<any> {
   
        return this.http
            .HttpPost(this.http.Services.Interests.GetInterestsByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetStudentsInternshipByCriteria(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Interests.GetStudentsInternshipByCriteria, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    GetInternshipById(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Interests.GetInternshipById, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetStudentInternshipsByCriteria(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Interests.GetStudentInternshipsByCriteria, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    UpdateStudentInternshipsStatus(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Interests.UpdateStudentInternshipsStatus, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    allowDashboard(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Account.Allowed, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetStudentsInternshipApprovalStatus(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Account.GetStudentsInternshipApprovalStatus, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    CreateStudentInternships(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Interests.CreateStudentInternships, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetInternship() {
        return this.http
            .HttpGet(this.http.Services.Interests.GetInternship)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetStudentDashboard(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Dashboard.GetStudentDashboard, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = "Unknown error!";
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }
 
}
