

import { FuseUtils } from '@fuse/utils';

export class RaiseSafetyConcerModel{
  
    RequestType: any;
    AuthUserId: string;
    IsActive: boolean;
    IsApproved: boolean;
    IsActionTaken: boolean;
    RequestFrom: string;
    TypeOfRequest: string;
    RequestTitle: string;
    RequestDesc: string;
   


    constructor(RaiseSafetyConcerItem?: any) {
        RaiseSafetyConcerItem = RaiseSafetyConcerItem || {};
        this.AuthUserId = RaiseSafetyConcerItem.AuthUserId    || '';
        this.IsActive =  true;
        this.IsApproved =  false;
        this.IsActionTaken =  false;
        this.RequestFrom =  RaiseSafetyConcerItem.RequestFrom ||'';
        this.TypeOfRequest = RaiseSafetyConcerItem.TypeOfRequest || '';
        this.RequestTitle = RaiseSafetyConcerItem.RequestTitle || '';
        this.RequestDesc =  RaiseSafetyConcerItem.RequestDesc ||'';
        this.RequestType = 0; // New
    }
}
