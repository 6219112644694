import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class RightsDetailsService {
   

    constructor(private http: BaseService) { }

    GetResourceStudentRightsById(studentRightsId: any) {
        let requestParams = {
          DataId: studentRightsId
        };
        return this.http.HttpPost(this.http.Services.Resources.GetResourcesById, requestParams)
          .pipe(
            retry(this.http.Services.RetryServieNo),
            catchError(this.http.HandleError),
            tap(function (response: any) {
              return response.Results;
            })
          );
      }
   
}
