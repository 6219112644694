import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NewPasswordModel } from './new-password.model';
import { NewPasswordService } from './new-password.service';

import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';

@Component({
  providers: [AppConstants],
  selector: 'igmite-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class NewPasswordComponent extends BaseComponent<NewPasswordModel> implements OnInit {
  public changePasswordForm: FormGroup;
  public newPasswordModel: NewPasswordModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  UserTypeId: any
  AuthToken: any
  hideConfirmPassword: boolean = true;


  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private dialogService: DialogService,
    private newPasswordService: NewPasswordService,

    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };

    // Redirect to home if already logged in
    if (this.authenticationService.authUser) {
      this.router.navigate(['/']);
    }

    this.authenticationService.resetLogin();

    // Set the default login Model
    this.newPasswordModel = new NewPasswordModel();
  }

  ngOnInit(): void {
    let element: any = document.querySelectorAll(".displayLogout");
    element.forEach(element => {
      element.setAttribute('style', 'display: none !important');
    });
    let elementIn: any = document.querySelectorAll(".withinlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });
    let elementIn1: any = document.querySelectorAll(".within-employee-login");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });
    this.UserTypeId = JSON.parse(localStorage.getItem('UserTypeId'));
    // this.AuthToken=JSON.parse(localStorage.getItem('AuthToken'));


    this.changePasswordForm = this.createChangePasswordForm();
  }
  setVisibleConfirmPassword(): void {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

  saveOrUpdateChangePasswordDetails() {
    // this.newPasswordModel.UserId = this.UserTypeId.UserTypeId;
    // this.newPasswordModel.NewPassword = this.changePasswordForm.value.NewPassword;
    // this.newPasswordModel.ConfirmPassword = this.changePasswordForm.value.ConfirmPassword;
    // this.AuthToken=this.UserTypeId.AuthToken
    var data = {
      UserId: this.UserTypeId.UserTypeId,
      NewPassword: this.changePasswordForm.value.NewPassword,
      ConfirmPassword: this.changePasswordForm.value.ConfirmPassword,

    }
    var AuthToken = {
      AuthToken: this.UserTypeId.AuthToken
    }
    this.newPasswordService.createOrUpdatePassword(data, AuthToken.AuthToken)
      .subscribe((accountResp: any) => {

        if (accountResp.Success) {
          //this.router.navigateByUrl(RouteConstants.Login);

          this.zone.run(() => {
            // this.showActionMessage(
            //   this.Constants.Messages.PasswordChangeMessage,
            //   this.Constants.Html.SuccessSnackbar
            // );
            let NewPassword = 'NewPassword'
            localStorage.setItem('ThankYou', NewPassword);
          
            this.router.navigateByUrl(RouteConstants.ThankYouReset);
           
            // this.router.navigate([this.UserModel.LandingPageUrl]);
            //this.router.navigateByUrl(RouteConstants.Login);
            // 

          });
        }
        else {
          var errorMessages = this.getHtmlMessage(accountResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
      });
  }

  onStrengthChanged(strength: number) {
  }



  //Create account form and returns {FormGroup}
  createChangePasswordForm(): FormGroup {
    return this.formBuilder.group({
      // UserId: new FormControl({ value: this.UserModel.LoginId, disabled: true }, Validators.required),
      NewPassword: new FormControl({ value: this.newPasswordModel.NewPassword, disabled: false }, [Validators.required, Validators.pattern(this.Constants.Regex.Password)]),
      ConfirmPassword: new FormControl({ value: this.newPasswordModel.ConfirmPassword, disabled: false }, [Validators.required, Validators.pattern(this.Constants.Regex.Password)])
    }, { validator: this.checkIfMatchingPasswords('NewPassword', 'ConfirmPassword') });
  }
}
