import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { DashboardService } from './dashboard.service';
import { FileUploadModel } from 'app/models/file.upload.model';
import { DatePipe } from '@angular/common'


@Component({
  providers: [AppConstants],
  selector: 'igmite-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class DashboardComponent extends BaseComponent<LoginModel> implements OnInit {
  public dashboardForm: FormGroup;
  public fileUploadModel: FileUploadModel;

  public loginModel: LoginModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  dashboardData: any
  dashboardTopData: any
  profileDetails: any
  color = 'primary';
  mode = 'buffer';
  value = 100;
  bufferValue = 100;
  DailyDiary: any;
  myFiles: string[] = [];
  listQuestionResponse: any = []
  Internship: any
  InternshipById: any
  todayDate: any
  FileName: any
  maxDate: any
  minDate: any
  urls = [];
  filesArray: any = [];
  VTDetails: any;
  statusData: any
  fileType: any;
  stuPhotoUrl = this.Constants.CommonImageUrl.Account;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private dashboardService: DashboardService,
    public zone: NgZone,
    public datepipe: DatePipe,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);
    this.fileUploadModel = new FileUploadModel();

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };
    const firstTime = localStorage.getItem('key')
    if (!firstTime) {
      localStorage.setItem('key', 'loaded')
      window.location.reload();

    } else {
      localStorage.removeItem('key')
    }
    this.dashboardForm = this.createdashboardForm();



  }

  createdashboardForm(): FormGroup {
    return this.formBuilder.group({
      Date: new FormControl('', [Validators.required]),
      HoursSpent: new FormControl('', Validators.required,),
      TodayLearning: new FormControl('', [Validators.required]),
    });

  }

  ngOnInit(): void {
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');
    });

    let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
    employeeMenuItems.forEach(employeeMenuItems => {
      employeeMenuItems.setAttribute('style', 'display: none !important');
    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    var date = new Date();
    this.maxDate = this.datepipe.transform(date, 'yyyy-MM-dd');
    // this.maxDate =this.datepipe.transform(date, 'dd-MM-yyyy');

    this.dashboardForm.get('Date').setValue(this.maxDate);
    this.todayDate = this.maxDate
    var proId = { DataId: this.UserModel.UserTypeId }
    this.dashboardService.GetStudentProfileConfirmationById(proId).subscribe((profileResp: any) => {
      if (profileResp.Success == true) {
        this.zone.run(() => {
          this.profileDetails = profileResp.Results[0]
          this.profileDetails.PhotoUrl = this.Constants.CommonImageUrl.Account + this.profileDetails.PhotoUrl;
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid UpdateStudentInternshipsStatus ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

    this.dashboardService.GetTopPerformerStudentList(proId).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.zone.run(() => {
          this.dashboardTopData = dashboardResp.Results

          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid GetTopPerformerStudentList ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

    this.dashboardService.GetStudentDashboard(proId).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.zone.run(() => {
          this.dashboardData = dashboardResp.Results[0]

          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid GetStudentDashboard ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

    this.dashboardService.GetDailyDiary().subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        var Inter = JSON.parse(sessionStorage.getItem('item'));
        if (Inter !== null) {
          this.InternshipById = Inter

        } else {
          this.InternshipById = this.UserModel.InternshipId
        }
        this.zone.run(() => {
          this.DailyDiary = dashboardResp.Results
          this.DailyDiary.map(item => {
            if (item.QuestionAnswerType === 'Date') {
              item.AnswerText = this.maxDate
            } else if (item.QuestionAnswerType === 'File') {
              item.AnswerText = 'No Image'
              item.QuestionResponseId = "00000000-0000-0000-0000-000000000000",
                item.QuestionAnswerOptionId = "00000000-0000-0000-0000-000000000000",
                item.EntryDate = this.todayDate,
                item.AttachmentsFile = this.fileUploadModel
            } else {
              item.AnswerText = ''

            }

            item.UserId = this.UserModel.UserTypeId
            item.UserRoleId = this.UserModel.UserRoleId
            item.AuthUserId = this.UserModel.UserTypeId
            item.InternshipId = this.InternshipById
            item.UserRole = this.UserModel.RoleCode

          })
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid GetDailyDiary ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

    var stuId = { DataId: this.UserModel.UserTypeId }
    this.dashboardService.GetVTByStudentId(stuId).subscribe((vtResp: any) => {
      if (vtResp.Success == true) {
        this.zone.run(() => {
          this.VTDetails = vtResp.Results[0]
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid UpdateStudentInternshipsStatus ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

    var Inter = JSON.parse(sessionStorage.getItem('item'));
    if (Inter !== null) {
      this.InternshipById = Inter

    } else {
      this.InternshipById = this.UserModel.InternshipId
    }

    var InternshipById = { DataId: this.InternshipById }

    this.dashboardService.GetInternshipById(InternshipById).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.zone.run(() => {
          this.Internship = dashboardResp.Result
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid GetInternshipById ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });


    // var int = {
    //   DataId: this.UserModel.UserTypeId,
    //   DataId1: this.InternshipById
    // }
    // this.dashboardService.GetStudentInternshipsById(int).subscribe((StutusResp: any) => {
    //   if (StutusResp.Success == true) {
    //     this.statusData = StutusResp.Result
    //     this.zone.run(() => {
    //       this.IsLoading = false;

    //     });

    //   }
    //   else {

    //   }


    // }, error => {
    // });

  }




  CreateDailyDiaryEntry(items) {
    var Inter = JSON.parse(sessionStorage.getItem('item'));
    if (Inter !== null) {
      this.InternshipById = Inter

    } else {
      this.InternshipById = this.UserModel.InternshipId
    }
    if (items) {
      items.map(items => {
        if (items.EntryDate !== undefined) {
          items.EntryDate
        } else {
          items.EntryDate = this.todayDate
        }


      })
    }
    let Question = {
      QuestionId: '00000000-0000-0000-0000-000000000000',
      StudentID: this.UserModel.UserTypeId,
      UserId: this.UserModel.UserTypeId,
      UserRole: this.UserModel.RoleCode,
      QuestionResponseId: '00000000-0000-0000-0000-000000000000',
      AuthUserId: this.UserModel.UserTypeId,
      QuestionAnswerOptionId: '00000000-0000-0000-0000-000000000000',
      Remarks: 'Question Answer',
      InternshipId: this.InternshipById,
      RequestType: 0,
      IsActive: true,
      listQuestionResponse: items


    }
    this.CreateAttachments();
    //this.filesArray=[];
    this.dashboardService.CreateDailyDiaryEntry(Question).subscribe((highlightOneResp: any) => {
      if (highlightOneResp.Success == true) {
        this.zone.run(() => {
          sessionStorage.setItem('EntryDate', JSON.stringify(this.todayDate));

          this.router.navigateByUrl(RouteConstants.HighlightOne);
          this.filesArray = [];
          this.ngOnInit;
          this.IsLoading = false;
          this.showActionMessage(
            this.Constants.Messages.RecordSavedMessage,
            this.Constants.Html.SuccessSnackbar
          );
        });
      }
      else {
        // this.showErrorMessage(
        //   'Invalid CreateQuestion ',
        //   'info-snackbar'
        // );
        this.filesArray = [];
        this.zone.run(() => {
          this.snackBar.open("Record already exists", "Dismiss", {
            duration: 50000000,
            panelClass: ["info-snackbar"]
          });
        });

      }


    }, error => {
    });
  }

  getFileDetails(item, e) {
    this.getUploadedFileData(e, this.Constants.DocumentType.Attachments).then((response: FileUploadModel) => {
      this.fileUploadModel = response;
      this.FileName = this.fileUploadModel.FileName
      if (this.fileUploadModel) {
        if (this.fileUploadModel.FileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          this.fileType = 'Document'
        } else if (this.fileUploadModel.FileType == 'video/mp4') {
          this.fileType = 'Video'
        } else if (this.fileUploadModel.FileType == 'application/pdf') {
          this.fileType = 'PDF'
        } else if (this.fileUploadModel.FileType == 'image/png') {
          this.fileType = 'Image'
        } else if (this.fileUploadModel.FileType == 'image/jpeg') {
          this.fileType = 'Image'
        }else if(this.fileUploadModel.FileType == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'){
          this.fileType = 'PPT'
        }
      }

      if (this.fileType) {
        this.filesArray.push({
          AuthUserId: this.UserModel.UserTypeId,
          RequestType: 0,
          IsActive: true,

          AttatchmentId: "00000000-0000-0000-0000-000000000000",
          SectionType: "DailyDiary",
          SectionId: "12345678-9012-3456-7890-123456789012",

          Sequence: 1,
          AttachmentDate: this.todayDate,
          AccountId: this.UserModel.UserTypeId,
          AttachmentsFile: this.fileUploadModel,
          AttachmentType: this.fileType,
          AttachmentPath: this.fileUploadModel.FileName,
          AttachmentDesc: this.fileUploadModel.FileName,
        })
      }

      if (response) {
        item.AnswerText = this.FileName
        item.QuestionResponseId = "00000000-0000-0000-0000-000000000000",
          item.QuestionAnswerOptionId = "00000000-0000-0000-0000-000000000000",
          item.EntryDate = this.todayDate,
          item.AttachmentsFile = this.fileUploadModel

      }
      //this.CreateAttachments()
    });
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
  }

  onSelectFile(item, event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.urls.push(event.target.result);

        }

        reader.readAsDataURL(event.target.files[i]);
        this.getFileDetails(item, event)
      }
    } ``
  }

  removeSelectedFile(index) {

    // Delete the item from fileNames list
    this.urls.splice(index, 1);
    this.filesArray.splice(index, 1);
    // delete file from FileList
  }


  CreateAttachments() {

    if (this.filesArray.length != 0) {
      for (const i of this.filesArray) {
        let file = {
          AuthUserId: this.UserModel.UserTypeId,
          RequestType: 0,
          IsActive: true,

          AttatchmentId: "00000000-0000-0000-0000-000000000000",
          SectionType: "DailyDiary",
          SectionId: "12345678-9012-3456-7890-123456789012",

          Sequence: 1,
          AttachmentDate: this.todayDate,
          AccountId: this.UserModel.UserTypeId,
          AttachmentsFile: this.fileUploadModel,
          AttachmentType: i.AttachmentType,
          AttachmentPath: i.AttachmentPath,
          AttachmentDesc: i.AttachmentDesc,


        }


        this.dashboardService.CreateAttachments(file).subscribe((highlightOneResp: any) => {
          if (highlightOneResp.Success == true) {
            this.zone.run(() => {
              this.IsLoading = false;
              this.showActionMessage(
                this.Constants.Messages.ImageUploadMessage,
                this.Constants.Html.SuccessSnackbar
              );
            });
          }
          else {

            this.zone.run(() => {
              this.snackBar.open("AttachmentsFile Not Upload", "Dismiss", {
                duration: 50000000,
                panelClass: ["info-snackbar"]
              });
            });
          }


        }, error => {
        });
      }
    }

    // let file = {
    //   AuthUserId: this.UserModel.UserTypeId,
    //   RequestType: 0,
    //   IsActive: true,

    //   AttatchmentId: "00000000-0000-0000-0000-000000000000",
    //   SectionType: "DailyDiary",
    //   SectionId: "12345678-9012-3456-7890-123456789012",

    //   Sequence: 1,
    //   AttachmentDate: this.todayDate,
    //   AccountId: this.UserModel.UserTypeId,
    //   AttachmentsFile: this.fileUploadModel,
    //   AttachmentType: this.fileUploadModel.FileType,
    //   AttachmentPath: this.fileUploadModel.FileName,
    //   AttachmentDesc: this.fileUploadModel.FileName,


    // }


  }

  onChange(item, value) {

    if (item.QuestionText == 'Date') {
      item.EntryDate = value

    }
    item.AnswerText = value
    item.QuestionResponseId = "00000000-0000-0000-0000-000000000000",
      item.QuestionAnswerOptionId = "00000000-0000-0000-0000-000000000000",
      item.EntryDate = this.todayDate




  }


}
