<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu-emp></igmite-side-menu-emp>


                </div>
                <div class="col-12 col-lg-10 right-content px-lg-1 overflow-hidden px-5px pb-5 bg-white py-5px">

                    <div
                        class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 overflow-hidden max-w-800 mx-auto ">

                        <h1
                            class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-15  line-h-30 mb-30">
                            Application Received</h1>

                        <div class="border-theme-2px border-radius-20 px-20 py-15">

                            <div>
                                <p class="fs-16 text-black fw-900 mb-0 "> {{internshipDetails.Title}} </p>

                                <p class="fs-14 fw-600 text-black fw-600 mb-0 "> Posted on -
                                    {{internshipDetails.PublishDate | date:"dd MMM yyyy"}}</p>
                            </div>
                            <div class="row mx-0 p-0">


                                <div class="col-6 pl-0 mt-20 pr-5px">
                                    <div class="d-flex align-items-center">

                                        <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                                            width="24px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24"></rect>
                                            </g>
                                            <g>
                                                <path
                                                    d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z">
                                                </path>
                                            </g>
                                        </svg>
                                        <div>
                                            <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Start Date</p>
                                            <p class="fs-14 text-black fw-600 mb-0">{{internshipDetails.StartDate |
                                                date:"dd MMM yyyy"}}</p>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-6 pl-0 mt-20 pr-5px">
                                    <div class="d-flex align-items-center">

                                        <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                                            width="24px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24"></rect>
                                            </g>
                                            <g>
                                                <path
                                                    d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z">
                                                </path>
                                            </g>
                                        </svg>
                                        <div>
                                            <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Duration
                                            </p>
                                            <p class="fs-14 text-black fw-600 mb-0"> {{internshipDetails.Duration}}
                                                <!-- 2Hrs | 40 days 6 months -->
                                            </p>
                                        </div>
                                    </div>



                                </div>


                            </div>


                        </div>


                        <section class="mt-20 position-relative mb-0">
                            <div id="tabs-design-2" class="filter-tabs">
                                <ul
                                    class="mx-auto p-0 py-0 row mb-0 d-flex filter-tabs-key justify-content-between align-items-center">
                                    <li
                                        class="col-6 p-0 h-100 d-flex border-grey-light-right justify-content-center align-items-center">
                                        <a class="fs-14 tabs-caller tabulour-links py-15 fw-700 color1 h-100 d-flex justify-content-center align-items-center"
                                            (click)="toggle('AllApplications')"
                                            [ngClass]="current === 'AllApplications' ? 'tabulous_active' : ''" title=""
                                            (click)="toggle('AllApplications');">

                                            <svg class="mr-10 fill-color-1" version="1.1" id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28px"
                                                height="28px" viewBox="0 0 44 44" enable-background="new 0 0 44 44"
                                                xml:space="preserve">
                                                <g>
                                                    <path
                                                        d="M38.5,10.167H23.834c-0.553,0-1-0.448-1-1s0.447-1,1-1H38.5c0.553,0,1,0.448,1,1S39.053,10.167,38.5,10.167z" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="M33,17.5h-9.166c-0.553,0-1-0.448-1-1s0.447-1,1-1H33c0.553,0,1,0.448,1,1S33.553,17.5,33,17.5z" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="M38.5,28.5H23.834c-0.553,0-1-0.447-1-1s0.447-1,1-1H38.5c0.553,0,1,0.447,1,1S39.053,28.5,38.5,28.5z" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="M33,35.834h-9.166c-0.553,0-1-0.447-1-1s0.447-1,1-1H33c0.553,0,1,0.447,1,1S33.553,35.834,33,35.834z" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="M14.666,19.333H7.333c-1.562,0-2.833-1.271-2.833-2.833V9.167c0-1.563,1.271-2.834,2.833-2.834h7.333
                                               c1.562,0,2.833,1.271,2.833,2.833V16.5C17.499,18.062,16.228,19.333,14.666,19.333z M7.333,8.333C6.874,8.333,6.5,8.707,6.5,9.167
                                               V16.5c0,0.459,0.374,0.833,0.833,0.833h7.333c0.459,0,0.833-0.374,0.833-0.833V9.166c0-0.459-0.374-0.833-0.833-0.833H7.333z" />
                                                </g>
                                                <g>
                                                    <path d="M14.666,37.666H7.333c-1.562,0-2.833-1.271-2.833-2.832V27.5c0-1.563,1.271-2.834,2.833-2.834h7.333
                                               c1.562,0,2.833,1.271,2.833,2.834v7.334C17.499,36.396,16.228,37.666,14.666,37.666z M7.333,26.666
                                               C6.874,26.666,6.5,27.04,6.5,27.5v7.334c0,0.459,0.374,0.832,0.833,0.832h7.333c0.459,0,0.833-0.373,0.833-0.832V27.5
                                               c0-0.46-0.374-0.834-0.833-0.834H7.333z" />
                                                </g>
                                            </svg>
                                            All Applications
                                        </a>
                                    </li>
                                    <li class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"><a
                                            class="fs-14 tabs-caller tabulour-links py-15  fw-700 color1 h-100 d-flex justify-content-center align-items-center"
                                            [ngClass]="current === 'Shortlisted' ? 'tabulous_active' : ''" title=""
                                            (click)="toggle('Shortlisted');">

                                            <svg class="mr-10 fill-color-1" version="1.1" id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px"
                                                height="30px" viewBox="0 0 44 44" enable-background="new 0 0 44 44"
                                                xml:space="preserve">
                                                <g>
                                                    <path
                                                        d="M17.416,19.334h-7.333c-1.057,0-1.917-0.86-1.917-1.917v-7.333c0.002-1.058,0.862-1.917,1.917-1.917h7.333
                                               c1.057,0,1.917,0.86,1.917,1.917v7.333C19.333,18.474,18.473,19.334,17.416,19.334z M10.166,17.334h7.167v-7.167h-7.167V17.334z" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="M17.416,35.834h-7.333c-1.057,0-1.917-0.86-1.917-1.918v-7.332c0.002-1.059,0.862-1.918,1.917-1.918h7.333
                                               c1.057,0,1.917,0.86,1.917,1.918v7.332C19.333,34.974,18.473,35.834,17.416,35.834z M10.166,33.834h7.167v-7.168h-7.167V33.834z" />
                                                </g>
                                                <path d="M37.373,26.793c-0.391-0.391-1.023-0.391-1.414,0l-3.793,3.793V11c0-0.552-0.447-1-1-1s-1,0.448-1,1v19.586l-3.793-3.793
                                           c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l5.499,5.499c0.092,0.093,0.203,0.166,0.326,0.217
                                           C30.906,33.974,31.036,34,31.166,34s0.26-0.026,0.382-0.077c0.123-0.051,0.234-0.124,0.326-0.217l5.499-5.499
                                           C37.764,27.816,37.764,27.184,37.373,26.793z" />
                                            </svg>
                                            Shortlisted
                                        </a>
                                    </li>

                                </ul>
                                <div id="tabs_container" class="filter-tabs-pannel px-0 mt-30 ">
                                    <div id="tabs-1" class="filter-tabs-slide max-w-420 mx-auto"
                                        *ngIf="current == 'AllApplications'">

                                        <!-- <p class="text-black fw-600 fs-15 mb-15">Total Hired -
                                            <strong>{{totalHired}}</strong>
                                        </p> -->

                                        <div class=" border-radius-20 px-20 py-15 mb-30"
                                            *ngFor="let application of allApplicationData;index as i;"
                                            ngClass="{{application.IsPlaced==true ? 'border-color3-2px' : 'border-theme-2px'}}">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div
                                                    class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                                    <img [src]="ApiBaseUrlImage + application.StudentProfilePhoto"
                                                        class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                        width="40" height="40">
                                                    <div>
                                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                            {{application.StudentName}}</p>
                                                        <p class="fs-14 fw-600 color1 mb-0 mt-0">
                                                            {{application.StudentMobile}}</p>
                                                    </div>

                                                </div>
                                                <a (click)="callStudentId(application.StudentId)"
                                                    class="ml-auto d-block">
                                                    <i
                                                        class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                                </a>

                                            </div>

                                            <p class="fs-14 color5 mb-0 fw-600 line-h-20 mt-20">Intrest</p>
                                            <p
                                                class="text-black fw-600 mb-0 fs-15 border-theme-2px border-start-0 border-top-0 border-end-0 pb-2">
                                                {{application.StudentInterests}}</p>

                                            <div class="row px-0 mx-0 mt-20" *ngIf="application.IsPlaced==false">
                                                <!-- <div class="col-6 pl-0"
                                                    (click)="onClickShortListApplication(application.StudentId,application.InternshipId)">
                                                    <a
                                                        class="d-block  bg-white py-12 fs-14 border-radius-15 fw-600 w-100 border-theme-2px text-black text-uppercase text-center">
                                                        Shortlist
                                                    </a>
                                                </div>

                                                <div class="col-6 pl-0"
                                                    (click)="onClickHireStudent(application.StudentId,application.InternshipId)">
                                                    <a
                                                        class="d-block py-12 border-radius-15 fw-600 w-100 text-white text-uppercase text-center upto-down-gradient-clr-1 fs-14">
                                                        Hire
                                                    </a>
                                                </div> -->

                                                <div class="col-6 pl-0">
                                                    <span
                                                        *ngIf="(application.IsPlaced==false&& application.IsApprovedByEmployer==true)">
                                                        <a class="d-block  bg-white py-12 fs-14 border-radius-15 fw-600 w-100 border-theme-2px text-black text-uppercase text-center"
                                                            style="background-image: linear-gradient(0deg, #eaeaeb, #f0f2f3);color: #7c78787a !important;">
                                                            Shortlist
                                                        </a>
                                                    </span>
                                                    <span
                                                        *ngIf="(application.IsPlaced==false&& application.IsApprovedByEmployer==false)">
                                                        <a (click)="onClickShortListApplication(application.StudentId,application.InternshipId)"
                                                            class="d-block  bg-white py-12 fs-14 border-radius-15 fw-600 w-100 border-theme-2px text-black text-uppercase text-center">
                                                            Shortlist
                                                        </a>
                                                    </span>
                                                </div>

                                                <div class="col-6 pl-0">
                                                    <span
                                                        *ngIf="(application.IsPlaced==false&& application.IsApprovedByEmployer==true)">
                                                        <a class="d-block py-12 border-radius-15 fw-600 w-100 text-white text-uppercase text-center upto-down-gradient-clr-1 fs-14"
                                                            style="background-image: linear-gradient(0deg, #eaeaeb, #f0f2f3);color: #7c78787a !important;">
                                                            Hire
                                                        </a>
                                                    </span>
                                                    <span
                                                        *ngIf="(application.IsPlaced==false&& application.IsApprovedByEmployer==false)">
                                                        <a (click)="onClickHireStudent(application.StudentId,application.InternshipId)"
                                                            class="d-block py-12 border-radius-15 fw-600 w-100 text-white text-uppercase text-center upto-down-gradient-clr-1 fs-14">
                                                            Hire
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>


                                            <button
                                                *ngIf="application.IsPlaced==false && application.IsApprovedByVT== false && application.IsApprovedByEmployer==false "
                                                class="fw-600 text-center w-100 border-0 fs-15 text-danger bg-none mb-5px mt-15"
                                                (click)="onClickRejectApplicant(application.StudentId,application.InternshipId)">Remove
                                                Application
                                            </button>


                                            <div class="row px-0 mx-0 mt-20" *ngIf="application.IsPlaced==true">
                                                <div class="col-12 pl-0"
                                                    (click)="onClickHireStudent(shartlistedStudent.StudentId,shartlistedStudent.InternshipId)">
                                                    <a
                                                        class="d-block py-12 fs-14 border-radius-15 fw-600 w-100 border-color3 bg-color-3-light color3 text-uppercase text-center">
                                                        Hired
                                                    </a>
                                                </div>


                                            </div>

                                        </div>




                                        <!-- <div class="border-theme-2px border-radius-20 px-20 py-15 mb-30">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div
                                                    class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                                    <img src="assets/upload/Mohan-Singh.png"
                                                        class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                        width="40" height="40">
                                                    <div>
                                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                            Sourav Kumar</p>
                                                        <p class="fs-14 fw-600 color1 mb-0 mt-0">9811000000</p>
                                                    </div>

                                                </div>
                                                <a href="#" class="ml-auto d-block">
                                                    <i
                                                        class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                                </a>

                                            </div>

                                            <p class="fs-14 color5 mb-0 fw-600 line-h-20 mt-20">Intrest</p>
                                            <p
                                                class="text-black fw-600 mb-0 fs-15 border-theme-2px border-start-0 border-top-0 border-end-0 pb-2">
                                                Electronics Technology, Web designing, IT</p>

                                            <div class="row px-0 mx-0 mt-20">
                                                <div class="col-6 pl-0">
                                                    <a href="#"
                                                        class="d-block  bg-white py-12 fs-14 border-radius-15 fw-600 w-100 border-theme-2px text-black text-uppercase text-center">
                                                        ShortlistED
                                                    </a>
                                                </div>

                                                <div class="col-6 pl-0">
                                                    <a href="#"
                                                        class="d-block py-12 border-radius-15 fw-600 w-100 text-white text-uppercase text-center upto-down-gradient-clr-1 fs-14">
                                                        Hire
                                                    </a>
                                                </div>
                                            </div>


                                            <button
                                                class="fw-600 text-center w-100 border-0 fs-15 text-danger bg-none mb-5px mt-15">Remove
                                                Application</button>


                                        </div>

                                        <div class="border-theme-2px border-radius-20 px-20 py-15 mb-30">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div
                                                    class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                                    <img src="assets/upload/Manisha-Mishra.png"
                                                        class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                        width="40" height="40">
                                                    <div>
                                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                            Manisha Singhania</p>
                                                        <p class="fs-14 fw-600 color1 mb-0 mt-0">9811000000</p>
                                                    </div>

                                                </div>
                                                <a href="#" class="ml-auto d-block">
                                                    <i
                                                        class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                                </a>

                                            </div>

                                            <p class="fs-14 color5 mb-0 fw-600 line-h-20 mt-20">Intrest</p>
                                            <p
                                                class="text-black fw-600 mb-0 fs-15 border-theme-2px border-start-0 border-top-0 border-end-0 pb-2">
                                                Electronics Technology, Web designing, IT</p>

                                            <div class="row px-0 mx-0 mt-20">
                                                <div class="col-6 pl-0">
                                                    <a href="#"
                                                        class="d-block  bg-white py-12 fs-14 border-radius-15 fw-600 w-100 border-theme-2px text-black text-uppercase text-center">
                                                        ShortlistED
                                                    </a>
                                                </div>

                                                <div class="col-6 pl-0">
                                                    <a href="#"
                                                        class="d-block py-12 border-radius-15 fw-600 w-100 text-white text-uppercase text-center upto-down-gradient-clr-1 fs-14">
                                                        Hire
                                                    </a>
                                                </div>
                                            </div>


                                            <button
                                                class="fw-600 text-center w-100 border-0 fs-15 text-danger bg-none mb-5px mt-15">Remove
                                                Application</button>


                                        </div> -->

                                    </div>





                                    <div id="tabs-2" class="filter-tabs-slide max-w-420 mx-auto"
                                        *ngIf="current == 'Shortlisted'">
                                        <p class="text-black fw-600 fs-15 mb-15">Total Hired -
                                            <strong>{{totalHired}}</strong>
                                        </p>
                                        <div class="border-radius-20 px-20 py-15 mb-30"
                                            *ngFor="let shartlistedStudent of shartlistedStudentData;index as i;"
                                            ngClass="{{shartlistedStudent.IsPlaced==true ? 'border-color3-2px' : 'border-theme-2px'}}">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div
                                                    class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                                    <img [src]="ApiBaseUrlImage + shartlistedStudent.StudentProfilePhoto"
                                                        class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                        width="40" height="40">
                                                    <div>
                                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                            {{shartlistedStudent.StudentName}}</p>
                                                        <p class="fs-14 fw-600 text-black mb-0 mt-0">Class -
                                                            {{shartlistedStudent.ClassName}}
                                                        </p>
                                                    </div>

                                                </div>
                                                <a (click)="callStudentId(shartlistedStudent.StudentId)"
                                                    class="ml-auto d-block">
                                                    <i
                                                        class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                                </a>

                                            </div>

                                            <p class="fs-14 color5 mb-0 fw-600 line-h-20 mt-20">Intrest</p>
                                            <p
                                                class="text-black fw-600 mb-0 fs-15 border-theme-2px border-start-0 border-top-0 border-end-0 pb-2">
                                                {{shartlistedStudent.StudentInterests}}</p>

                                            <div class="row px-0 mx-0 mt-20"
                                                *ngIf="shartlistedStudent.IsPlaced==true && shartlistedStudent.IsShortListedByEmp==true">
                                                <div class="col-12 pl-0">
                                                    <a
                                                        class="d-block py-12 fs-14 border-radius-15 fw-600 w-100 border-color3 bg-color-3-light color3 text-uppercase text-center">
                                                        Hired
                                                    </a>
                                                </div>


                                            </div>


                                            <div class="row px-0 mx-0 mt-20 align-items-center"
                                                *ngIf="shartlistedStudent.IsPlaced==false">
                                                <div class="col-9 pl-0"
                                                    (click)="onClickHireStudent(shartlistedStudent.StudentId,shartlistedStudent.InternshipId)">
                                                    <a
                                                        class="d-block py-12 border-radius-15 fw-600 w-100 text-white text-uppercase text-center upto-down-gradient-clr-1 fs-14">
                                                        Hire
                                                    </a>
                                                </div>

                                                <div class="col-3">
                                                    <button
                                                        (click)="onClickRejectApplicant(shartlistedStudent.StudentId,shartlistedStudent.InternshipId)"
                                                        class="border-theme-2px border-radius-10 px-2 py-2 bg-white text-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                            viewBox="0 0 24 24" width="24px" fill="#e62e2f">
                                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                                            <path
                                                                d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>


                                        </div>


                                        <!-- <div class="border-theme-2px border-radius-20 px-20 py-15 mb-30">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div
                                                    class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                                    <img src="assets/upload/Mohan-Singh.png"
                                                        class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                        width="40" height="40">
                                                    <div>
                                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">Mohan
                                                            Baghel</p>
                                                        <p class="fs-14 fw-600 text-black mb-0 mt-0">Class - XIIth
                                                        </p>
                                                    </div>

                                                </div>
                                                <a href="#" class="ml-auto d-block">
                                                    <i
                                                        class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                                </a>

                                            </div>

                                            <p class="fs-14 color5 mb-0 fw-600 line-h-20 mt-20">Intrest</p>
                                            <p
                                                class="text-black fw-600 mb-0 fs-15 border-theme-2px border-start-0 border-top-0 border-end-0 pb-2">
                                                Data Entry, Web designing, Typography</p>

                                            <div class="row px-0 mx-0 mt-20 align-items-center">
                                                <div class="col-9 pl-0">
                                                    <a href="#"
                                                        class="d-block py-12 border-radius-15 fw-600 w-100 text-white text-uppercase text-center upto-down-gradient-clr-1 fs-14">
                                                        Hire
                                                    </a>
                                                </div>

                                                <div class="col-3">
                                                    <button
                                                        class="border-theme-2px border-radius-10 px-2 py-2 bg-white text-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                            viewBox="0 0 24 24" width="24px" fill="#e62e2f">
                                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                                            <path
                                                                d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                                                        </svg>
                                                    </button>
                                                </div>


                                            </div>




                                        </div>


                                        <div class="border-theme-2px border-radius-20 px-20 py-15 mb-30">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div
                                                    class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                                    <img src="assets/upload/Manisha-Mishra.png"
                                                        class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                        width="40" height="40">
                                                    <div>
                                                        <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                            Manisha Mishra</p>
                                                        <p class="fs-14 fw-600 text-black mb-0 mt-0">Class - XIIth
                                                        </p>
                                                    </div>

                                                </div>
                                                <a href="#" class="ml-auto d-block">
                                                    <i
                                                        class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                                </a>

                                            </div>

                                            <p class="fs-14 color5 mb-0 fw-600 line-h-20 mt-20">Intrest</p>
                                            <p
                                                class="text-black fw-600 mb-0 fs-15 border-theme-2px border-start-0 border-top-0 border-end-0 pb-2">
                                                Beauty and Wellness, Operations, IT</p>

                                            <div class="row px-0 mx-0 mt-20 align-items-center">
                                                <div class="col-9 pl-0">
                                                    <a href="#"
                                                        class="d-block py-12 border-radius-15 fw-600 w-100 text-white text-uppercase text-center upto-down-gradient-clr-1 fs-14">
                                                        Hire
                                                    </a>
                                                </div>

                                                <div class="col-3">
                                                    <button
                                                        class="border-theme-2px border-radius-10 px-2 py-2 bg-white text-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                            viewBox="0 0 24 24" width="24px" fill="#e62e2f">
                                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                                            <path
                                                                d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                                                        </svg>
                                                    </button>
                                                </div>


                                            </div>




                                        </div> -->


                                    </div>










                                </div><!--End tabs container-->
                            </div><!--End tabs-->




                        </section>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>