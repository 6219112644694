import { FuseUtils } from '@fuse/utils';

export class EmployerProfileEditModel {
    EmployerBranchId: string;
    POCName: any;
    PhotoUrl: any;
    IsPreferred: boolean;
    POCMobile: any;
    POCEmailId: any;
    OrganizationName: any;
    EmployerName: string;
    BranchName: string;
    BranchAddress: any;
    DistrictCode: any;
    DistrictName: any;
    Pincode: any;
    StateCode: any;
    Longitude: any;
    Lattitude: any;
    EmployerId: string;
    AccountId: string;
    ActiveInternships: 0;
    TotalInterns: 0;
    IsActive: boolean;
    RequestType: any;
    InterestId: string
    LoginId: any;
    Map: string;
    PhotoFileURL: string;

    constructor(InterestsItem?: any) {
        InterestsItem = InterestsItem || {};

        this.EmployerBranchId = InterestsItem.EmployerBranchId || '';
        this.POCName = InterestsItem.POCName || '';
        this.PhotoUrl = InterestsItem.PhotoUrl || '';
        this.IsPreferred = InterestsItem.IsPreferred || '';
        this.POCMobile = InterestsItem.POCMobile || '';
        this.POCEmailId = InterestsItem.POCEmailId || '';
        this.OrganizationName = InterestsItem.OrganizationName || '';
        this.EmployerName = InterestsItem.EmployerName || '';
        this.BranchName = InterestsItem.BranchName || '';
        this.BranchAddress = InterestsItem.BranchAddress || '';
        this.DistrictCode = InterestsItem.DistrictCode || '';
        this.DistrictName = InterestsItem.DistrictName || '';
        this.Pincode = InterestsItem.Pincode || '';
        this.StateCode = InterestsItem.StateCode || '';
        this.Longitude = InterestsItem.Longitude || '';
        this.Lattitude = InterestsItem.Lattitude || '';
        this.EmployerId = InterestsItem.EmployerId || '';
        this.AccountId = InterestsItem.AccountId || '';
        this.ActiveInternships = InterestsItem.ActiveInternships || '';
        this.TotalInterns = InterestsItem.TotalInterns || '';
        this.PhotoFileURL = InterestsItem.PhotoFileURL || '';
        this.LoginId = InterestsItem.PhotoFileURL || '';
        this.Map = InterestsItem.PhotoFileURL || '';
        this.IsActive = true;
        this.RequestType = 0; // New

    }
} 