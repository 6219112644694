import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import {of} from 'rxjs'
import {delay} from 'rxjs/operators'

@Injectable({
    providedIn: "root"
})
export class CertificateListingService {


    constructor(private http: BaseService) { }

    

    GetCertificateByEmployerId(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Certificate.GetCertificateByEmployerId, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    

}
