<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-lg-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side" *ngIf="roleCode == 'ST'">
                    <igmite-side-menu></igmite-side-menu>

                </div>

                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side" *ngIf="roleCode == 'EMP'">


                    <igmite-side-menu-emp></igmite-side-menu-emp>


                </div>



                <div class="col-md-9 col-lg-10 right-content px-lg-35 px-0 pb-5 bg-white py-18">


                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto" *ngIf="profileDetails">

                        <!-- <div class="col-3 cursor-pointer" [routerLink]="['/raise-safety-concer']"
                            style="cursor:pointer;">
                            <img src="assets/Content/images/Alert-icon.png" class="" alt="Alert" height="25" srcset="">
                        </div> -->
                        <div class="col-3 cursor-pointer">

                        </div>


                        <div class="col-6 px-0 text-center">

                            <div class="user-profile-dash mt-lg-0"> <img [src]="profileDetails.PhotoUrl" alt=""
                                    srcset=""> </div>
                        </div>

                        <div class="col-3 text-end cursor-pointer">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1 h-30" viewBox="0 0 24 24"
                                width="28px" fill="#000000">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path
                                    d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92-1.31-2.92-2.92-2.92z" />
                            </svg> -->


                        </div>
                    </div>
                    <div class="user-name text-center mt-15 max-w-800 mx-auto" *ngIf="profileDetails">
                        <h2 class="color2 fw-900 fs-25"> <span class="text-black fs-26 font-pacific fw-400">
                                {{profileDetails.FullName}}</span> </h2>
                    </div>


                    <div class="px-10 mt-20 max-w-800 mx-auto" *ngIf="dashboardData">
                        <div class="row mx-0 px-0 dash-scorecard border-theme-2px border-radius-40">
                            <!-- <mat-progress-bar mode="determinate" value="{{dashboardData.StudentPoints}}">
                            </mat-progress-bar> -->
                            <div class="col-4 text-center py-10 ">

                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10"
                                    enable-background="new 0 0 24 24" fill="#000000" height="35" width="35"
                                    viewBox="0 0 22 22">
                                    <rect fill="none" height="24" width="24"></rect>
                                    <path
                                        d="M19,5h-2V4c0-0.55-0.45-1-1-1H8C7.45,3,7,3.45,7,4v1H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94 c0.63,1.5,1.98,2.63,3.61,2.96V19H8c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-3v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z">
                                    </path>
                                </svg>
                                <a href="/dashboard">
                                    <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Points</p>

                                    <strong
                                        class="fs-20 line-h-17 fw-900 text-center text-black ">{{dashboardData.StudentPoints}}</strong>
                                </a>
                            </div>


                            <div class="col-4 text-center py-10 active">





                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10"
                                    enable-background="new 0 0 24 24" fill="#000000" height="35" width="35"
                                    viewBox="1 0 22 22">
                                    <g>
                                        <rect fill="none" height="24" width="24" />
                                        <rect fill="none" height="24" width="24" />
                                    </g>
                                    <g>
                                        <path
                                            d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M9.38,16.01L7,13.61c-0.39-0.39-0.39-1.02,0-1.41 l0.07-0.07c0.39-0.39,1.03-0.39,1.42,0l1.61,1.62l5.15-5.16c0.39-0.39,1.03-0.39,1.42,0l0.07,0.07c0.39,0.39,0.39,1.02,0,1.41 l-5.92,5.94C10.41,16.4,9.78,16.4,9.38,16.01z" />
                                    </g>
                                </svg>

                                <a href="/diary-entry-listing">
                                    <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Attendance</p>

                                    <strong
                                        class="fs-20 line-h-17 fw-900 text-center text-black ">{{dashboardData.Attendance}}
                                        D</strong>
                                </a>

                            </div>


                            <div class="col-4 text-center py-10">


                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10" height="35"
                                    width="35" viewBox="0 0 22 22">
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path
                                        d="M16.24 7.76C15.07 6.59 13.54 6 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
                                </svg>
                                <a href="/internship-3">

                                    <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Total Duration</p>

                                    <strong class="fs-20 line-h-17 fw-900 text-center text-black ">{{dashboardData.Hours
                                        |
                                        slice:0:5}}
                                        H</strong>
                                </a>

                            </div>

                        </div>
                    </div>
                    <div class="px-10 mt-20 max-w-800 mx-auto" *ngIf="!dashboardData">
                        <div class="row mx-0 px-0 dash-scorecard border-theme-2px border-radius-40">

                            <div class="col-4 text-center py-10 active">

                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10"
                                    enable-background="new 0 0 24 24" fill="#000000" height="35" width="35"
                                    viewBox="0 0 22 22">
                                    <rect fill="none" height="24" width="24"></rect>
                                    <path
                                        d="M19,5h-2V4c0-0.55-0.45-1-1-1H8C7.45,3,7,3.45,7,4v1H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94 c0.63,1.5,1.98,2.63,3.61,2.96V19H8c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-3v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z">
                                    </path>
                                </svg>
                                <a href="/dashboard">
                                    <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Points</p>

                                    <strong class="fs-20 line-h-17 fw-900 text-center text-black ">0</strong>
                                </a>
                            </div>


                            <div class="col-4 text-center py-10">





                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10"
                                    enable-background="new 0 0 24 24" fill="#000000" height="35" width="35"
                                    viewBox="1 0 22 22">
                                    <g>
                                        <rect fill="none" height="24" width="24" />
                                        <rect fill="none" height="24" width="24" />
                                    </g>
                                    <g>
                                        <path
                                            d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M9.38,16.01L7,13.61c-0.39-0.39-0.39-1.02,0-1.41 l0.07-0.07c0.39-0.39,1.03-0.39,1.42,0l1.61,1.62l5.15-5.16c0.39-0.39,1.03-0.39,1.42,0l0.07,0.07c0.39,0.39,0.39,1.02,0,1.41 l-5.92,5.94C10.41,16.4,9.78,16.4,9.38,16.01z" />
                                    </g>
                                </svg>

                                <a href="/diary-entry-listing">
                                    <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Attendance</p>

                                    <strong class="fs-20 line-h-17 fw-900 text-center text-black ">0
                                        D</strong>
                                </a>


                            </div>


                            <div class="col-4 text-center py-10">


                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-2 mt-10" height="35"
                                    width="35" viewBox="0 0 22 22">
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path
                                        d="M16.24 7.76C15.07 6.59 13.54 6 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
                                </svg>

                                <a href="/internship-3">
                                    <p class="fs-12 fw-400 mt-1 text-black mb-0 text-center">Internship</p>

                                    <strong class="fs-20 line-h-17 fw-900 text-center text-black ">0
                                        H</strong>
                                </a>

                            </div>

                        </div>
                    </div>

                    <div>




                        <p class="fs-18 fw-900 text-center px-10 text-black mb-20 line-h-30 mt-10">
                            Mark your attendance and fill diary entry
                        </p>
                    </div>


                    <div class="px-10 max-w-800 mx-auto ">


                        <div class="py-20 px-15 border-theme-2px border-radius-25">

                            <form name="diaryEntryForm" novalidate [formGroup]="diaryEntryForm">
                                <div class="mb-20">
                                    <p class="fs-16 text-black mb-1 px-1 fw-900">Select Date </p>
                                    <div class="input-with-icon position-relative dynamic-text-input-date">
                                        <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                            <input [min]="minDate" [max]="maxDate" matInput type="date"
                                                (change)="GetDailyDiaryEntries()" formControlName="StartDateTime"
                                                name="StartDateTime" class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                                                fw-600 cursor-pointer input-top">
                                            <mat-error *ngIf="diaryEntryForm.controls.StartDateTime.invalid">
                                                {{getErrorMessage(diaryEntryForm, 'StartDateTime')}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>




                    <div class="px-10 max-w-800 mx-auto mb-30 for-edit "
                        [ngClass]="ActiveTab === 'Close' ? 'd-none' : ''">
                        <div *ngFor="let list of listDiaryEntry;">

                            <p
                                class="fs-15 text-black mb-5px px-1 fw-900 d-flex justify-content-between align-items-center">
                                {{list.dailyDiaryDateTime | date :'mediumDate'}}
                                <a *ngIf="flag.length !== 0 && studentLogin=='Student'"
                                    class="diary-entry-editor color1 cursor-pointer" (click)="Edit(listDiaryEntry)">
                                    Edit
                                </a>
                                <a *ngIf="flag.length === 0 && studentLogin=='Student'"
                                    class="diary-entry-editor color1 cursor-pointer" (click)="Add()">
                                    Add
                                </a>
                            </p>

                            <div class="py-20 px-15 border-theme-2px border-radius-25" *ngIf="flag.length !== 0">
                                <div *ngFor="let listEntrie of list.listEntries;">
                                    <p class="fs-15 text-black mb-1 px-1 fw-900" style="word-break: break-all;"
                                        *ngIf="listEntrie.QuestionAnswerType === 'TextArea'">{{listEntrie.QuestionText}}
                                    </p>
                                    <p class="fs-14 text-black mb-1 px-1 fw-600" style="word-break: break-all;"
                                        *ngIf="listEntrie.QuestionAnswerType === 'TextArea'">{{listEntrie.AnswerText}}
                                    </p>

                                    <p class="fs-15 text-black mb-1 px-1 fw-900 mt-20"
                                        *ngIf="listEntrie.QuestionAnswerType === 'Dropdown'">{{listEntrie.QuestionText}}
                                        <span class="fw-600"
                                            *ngIf="listEntrie.QuestionAnswerType === 'Dropdown'">{{listEntrie.AnswerText}}</span>
                                    </p>
                                </div>

                                <div *ngIf="flag.length !== 0">
                                    <p class="fs-15 text-black mb-1 px-1 fw-900 mt-20">Media</p>
                                    <div class="d-flex flex-wrap align-items-center">
                                        <div class=" mr-10 w-80" *ngFor="let attItem of list.listAttachments;">



                                            <!-- <div class="video-caller" id="gallery-video-2" hidden="">
                                            <video class="w-100 h-100" controls="" width="100%" height="100%">

                                                <source src="../Upload/sample-video.mp4" type="video/mp4">
                                                <source src="../Upload/sample-video.mp4" type="video/ogg">

                                            </video>

                                        </div> -->

                                            <a class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0 vbox-item"
                                                data-gall="myGallery" data-vbtype="inline"
                                                *ngIf="attItem.AttachmentPath != ''">
                                                <div class="position-relative">
                                                    <span *ngIf="attItem.AttachmentType=='Image'">
                                                        <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                                            href="{{ imageBasePath + attItem.AttachmentPath }}"
                                                            target="_blank">


                                                            <img class="object-fit-cover h-80"
                                                                [src]="imageBasePath + attItem.AttachmentPath" alt=""
                                                                srcset="">
                                                        </a>
                                                    </span>
                                                    <span *ngIf="attItem.AttachmentType=='PDF'">
                                                        <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                                            href="{{ imageBasePath + attItem.AttachmentPath }}"
                                                            target="_blank">
                                                            <i class="fa fa-file-pdf-o" aria-hidden="true"
                                                                *ngIf="attItem.AttachmentType =='PDF'"></i>


                                                        </a>
                                                    </span>
                                                    <span *ngIf="attItem.AttachmentType=='Document'">
                                                        <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                                            href="{{ imageBasePath + attItem.AttachmentPath }}"
                                                            target="_blank">

                                                            <i class="fa fa-file" aria-hidden="true"
                                                                *ngIf="attItem.AttachmentType =='Document'"></i>


                                                        </a>
                                                    </span>
                                                    <!-- <span
                                                    class="fs-13 text-white mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600"><i
                                                        class="fa-solid fa-circle-play"></i> <span
                                                        class="video-timing">02:00</span> </span> -->
                                                    <!-- <img class="w-100 object-fit-cover h-80"
                                                    [src]="listAttachment.AttachmentPath" alt="" srcset=""> -->
                                                    <!-- <span
                                                        *ngIf="(attItem.AttachmentPath!=null)&& (attItem.AttachmentPath.split('.')[1]=='jpg' || attItem.AttachmentPath.split('.')[1]=='jpeg' || attItem.AttachmentPath.split('.')[1]=='iso' 
                                                    ||attItem.AttachmentPath.split('.')[1]=='png' ||attItem.AttachmentPath.split('.')[1]=='gif'
                                                    ||attItem.AttachmentPath.split('.')[1]=='docx'||attItem.AttachmentPath.split('.')[1]=='doc'||attItem.AttachmentPath.split('.')[1]=='pdf'
                                                    ||attItem.AttachmentPath.split('.')[1]=='mp3' ||attItem.AttachmentPath.split('.')[1]=='mp4'||attItem.AttachmentPath.split('.')[1]=='mpeg'||attItem.AttachmentPath.split('.')[1]=='pptx')">
                                                        <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                                            href="{{ imageBasePath + attItem.AttachmentPath }}"
                                                            target="_blank">
                                                            <i class="fa fa-file-pdf-o" aria-hidden="true"
                                                                *ngIf="attItem.AttachmentType =='PDF'"></i>
                                                            
                                                            <i class="fa fa-file" aria-hidden="true"
                                                                *ngIf="attItem.AttachmentType =='Document'"></i>

                                                            <img class="object-fit-cover h-80"
                                                                *ngIf="attItem.AttachmentType=='Image'||'image/png'"
                                                                [src]="imageBasePath + attItem.AttachmentPath" alt=""
                                                                srcset="">
                                                        </a>
                                                    </span> -->

                                                    <!-- <span
                                                        *ngIf="attItem.AttachmentPath !=null && attItem.AttachmentPath.includes('https://')">
                                                        <a href="{{attItem.AttachmentPath }}" target="_blank">
                                                            <i class="fa fa-file-pdf-o" aria-hidden="true"
                                                                *ngIf="attItem.AttachmentType=='PDF||pdf'"></i>
                                                            <i class="fa fa-file" aria-hidden="true"
                                                                *ngIf="attItem.AttachmentType=='DOC||doc'"></i>

                                                            <img class="object-fit-cover h-80"
                                                                *ngIf="attItem.AttachmentType=='Image'||'image/png'"
                                                                [src]="attItem.AttachmentPath" alt="" srcset="">
                                                        </a>
                                                    </span> -->
                                                </div>


                                            </a>



                                        </div>

                                        <!-- <a class="mr-10 w-80" href="../Upload/samplepptx.pptx" data-gall="myGallery"
                                        data-vbtype="video">
                                        <div class="position-relative">
                                            <div
                                                class="resources-extention h-80 w-100 border-theme-2px d-flex justify-content-center align-items-center position-relative ">
                                                <div
                                                    class="h-45 w-45 rounded-circle bg-light-purple d-flex justify-content-center align-items-center">
                                                    <p class="mb-0 fs-24 fw-900 color9">P</p>


                                                </div>


                                                <span
                                                    class="fs-11 color9 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600">ppt</span>

                                            </div>

                                        </div>


                                    </a>


                                    <a href="../Upload/file-sample_100kB.doc" data-gall="myGallery" class="w-80"
                                        data-vbtype="video">
                                        <div class="position-relative">
                                            <div
                                                class="resources-extention h-80 w-100 border-theme-2px d-flex justify-content-center align-items-center position-relative">
                                                <div
                                                    class="h-45 w-45 rounded-circle bg-theme-light-grey d-flex justify-content-center align-items-center">
                                                    <svg class="fill-color-7" xmlns="http://www.w3.org/2000/svg"
                                                        height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                        <path
                                                            d="M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z">
                                                        </path>
                                                    </svg>

                                                </div>


                                                <span
                                                    class="fs-11 color7 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600">doc</span>

                                            </div>

                                        </div>


                                    </a> -->
                                    </div>
                                </div>



                            </div>

                        </div>

                    </div>


                    <div class="px-10 max-w-800 mx-auto " *ngIf="DailyDiary ">


                        <div class="py-20 px-15 border-theme-2px border-radius-25">

                            <form name="dashboardForm" novalidate [formGroup]="dashboardForm">
                                <div *ngFor="let Daily of DailyDiary;">
                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'Date'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}}</p>
                                        <div class="input-with-icon position-relative dynamic-text-input-date">
                                            <!-- <input type="date" name="" id="date"
                                            class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                                            fw-600 cursor-pointer input-top"> -->
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <input [(ngModel)]="Daily.AnswerText" [min]="todayDate"
                                                    [max]="todayDate" disabled
                                                    (change)="onChangeAdd(Daily,$event.target.value)" matInput
                                                    type="date" id="date" formControlName="Date" name="date" class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 
                                                fw-600 cursor-pointer input-top">
                                                <mat-error *ngIf="dashboardForm.controls.Date.invalid">
                                                    {{getErrorMessage(dashboardForm, 'Date')}}
                                                </mat-error>
                                            </mat-form-field>





                                        </div>
                                    </div>


                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'Dropdown'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}}
                                            <!-- <span class="fs-12"> ( Hours : Minutes ) </span> -->
                                        </p>
                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <select matNativeControl name=""
                                                    (change)="onChangeAdd(Daily,$event.target.value)" matInput
                                                    id="timing" formControlName="HoursSpent" name="hoursSpent"
                                                    class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">

                                                    <option value="">Select ( Hours : Min. ) </option>
                                                    <option value="00:30">00:30</option>
                                                    <option value="1:00">1:00</option>
                                                    <option value="1:30">1:30</option>
                                                    <option value="2:00">2:00</option>
                                                    <option value="2:30">2:30</option>
                                                    <option value="3:00">3:00</option>
                                                    <option value="3:30">3:30</option>
                                                    <option value="4:00">4:00</option>
                                                    <option value="4:30">4:30</option>
                                                    <option value="5:00">5:00</option>
                                                    <option value="5:30">5:30</option>
                                                    <option value="6:00">6:00</option>
                                                    <option value="6:30">6:30</option>
                                                    <option value="7:00">7:00</option>
                                                    <option value="7:30">7:30</option>
                                                    <option value="8:00">8:00</option>



                                                </select>

                                                <label for="timing" class="right-icon-input z-index-div-bottm">

                                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1" height="30px"
                                                    width="30px" fill="#000000" viewBox="2 8 15 7">
                                                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                    <path
                                                        d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z">
                                                    </path>
                                                </svg> -->
                                                </label>
                                                <mat-error *ngIf="dashboardForm.controls.HoursSpent.invalid">
                                                    <!-- {{getErrorMessage(dashboardForm, 'HoursSpent')}} -->
                                                    Hours Spent is required

                                                </mat-error>
                                            </mat-form-field>


                                        </div>
                                    </div>



                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'TextArea'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}} </p>
                                        <div class="input-with-icon position-relative">
                                            <mat-form-field appearance="outline"
                                                class="input-with-icon position-relative">
                                                <textarea matInput name="" id=""
                                                    (change)="onChangeAdd(Daily,$event.target.value)" cols="30"
                                                    rows="10" formControlName="TodayLearning" name="todayLearning"
                                                    class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15">
                                                Lorem ipsum dolor sit amet, conse Ctetur amet, conse adip iscing elit. 
                                                The psum dolor sit amet. Sconse adi piscing elit. Apsum dolor sit amet. Sconse adi piscing elit. 
                                                Apsum dolor sit amet. Lorem ipsum dolor sit amet, conse Ctetur amet, conse adip iscing elit.</textarea>
                                                <mat-error *ngIf="dashboardForm.controls.TodayLearning.invalid">
                                                    <!-- {{getErrorMessage(dashboardForm, 'TodayLearning')}} -->
                                                    Today Learning is required

                                                </mat-error>
                                            </mat-form-field>






                                        </div>
                                    </div>


                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'File'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}}
                                            <span class="fs-12">(Photos, Videos, Documents etc.)</span>
                                        </p>
                                        <div class="input-with-icon position-relative">



                                            <label
                                                class="cstm-file-upload border-theme-2px border-radius-10 px-10 py-10 d-flex align-items-center w-100">
                                                <!-- <input type="file" id="file" multiple aria-label="File browser example"  (change)="getFileDetailsAdd(Daily,$event)"> -->
                                                <input type='file' id="file" multiple aria-label="File browser example"
                                                    (change)="onSelectFile(Daily,$event)" multiple>
                                                <span class="file-custom"></span>
                                                <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                                    enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24"
                                                    width="30px" fill="#000000">
                                                    <g>
                                                        <rect fill="none" height="24" width="24" />
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M20,6h-8l-1.41-1.41C10.21,4.21,9.7,4,9.17,4H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M13,13v3c0,0.55-0.45,1-1,1h0c-0.55,0-1-0.45-1-1v-3H9.21c-0.45,0-0.67-0.54-0.35-0.85l2.8-2.79 c0.2-0.2,0.51-0.19,0.71,0l2.79,2.79C15.46,12.46,15.24,13,14.8,13H13z" />
                                                    </g>
                                                </svg>

                                            </label>



                                        </div>
                                    </div>
                                </div>
                                <!-- <p
                                class="fs-16 text-black fw-600 d-flex justify-content-between align-items-center mb-10" *ngIf="FileName">
                                {{FileName}} <a (click)="removeImg()"><mat-icon> cancel_presentation</mat-icon></a>
                               
                                </p> -->
                                <p class="fs-16 text-black fw-600 d-flex justify-content-between align-items-center mb-10"
                                    *ngFor='let url of urls; let index = index'>
                                    <a (click)="removeSelectedFile(index)">

                                        <img [src]="url" height="50">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </p>



                                <button
                                    class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-25 text-uppercase box-shadow-hard mb-10"
                                    [disabled]="dashboardForm.invalid"
                                    (click)="CreateDailyDiaryEntry(DailyDiary)">Sumbit</button>



                            </form>
                        </div>
                    </div>

                    <div class="px-10 max-w-800 mx-auto for-fill "
                        [ngClass]="ActiveTabCancle ==='open' ? 'd-none' : ''">

                        <p
                            class="fs-15 text-black mb-5px px-1 fw-900 d-flex justify-content-between align-items-center">
                            {{todayDate | date :'mediumDate'}} <a
                                class="diary-entry-editor color1 cursor-pointer uncheck" (click)="Cancle()">Cancel</a>
                        </p>
                        <div class="py-20 px-15 border-theme-2px border-radius-25">

                            <form action="">
                                <div *ngFor="let Daily of editItem;">
                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'Date'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}}</p>
                                        <div class="input-with-icon position-relative dynamic-text-input-date">
                                            <input type="date" [min]="todayDate" [max]="todayDate" disabled name="Date"
                                                id="date" (change)="CreateQuestionResponse(Daily,$event.target.value)"
                                                [(ngModel)]="Daily.AnswerText"
                                                class="border-radius-10 text-black border-theme-2px fs-16 py-10 w-100 px-15 fw-600 cursor-pointer input-top">


                                        </div>
                                    </div>


                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'Dropdown'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}}</p>
                                        <div class="input-with-icon position-relative">
                                            <select name="Dropdown" id="timing"
                                                (change)="CreateQuestionResponse(Daily,$event.target.value)"
                                                [(ngModel)]="Daily.AnswerText"
                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
                                                <option value="">Select ( Hours : Min. ) </option>
                                                <option value="00:30">00:30</option>
                                                <option value="1:00">1:00</option>
                                                <option value="1:30">1:30</option>
                                                <option value="2:00">2:00</option>
                                                <option value="2:30">2:30</option>
                                                <option value="3:00">3:00</option>
                                                <option value="3:30">3:30</option>
                                                <option value="4:00">4:00</option>
                                                <option value="4:30">4:30</option>
                                                <option value="5:00">5:00</option>
                                                <option value="5:30">5:30</option>
                                                <option value="6:00">6:00</option>
                                                <option value="6:30">6:30</option>
                                                <option value="7:00">7:00</option>
                                                <option value="7:30">7:30</option>
                                                <option value="8:00">8:00</option>

                                            </select>

                                            <label for="timing" class="right-icon-input z-index-div-bottm">

                                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1"
                                                    height="30px" width="30px" fill="#000000" viewBox="2 8 15 7">
                                                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                    <path
                                                        d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z">
                                                    </path>
                                                </svg></label>

                                        </div>
                                    </div>



                                    <div class="mb-20" *ngIf="Daily.QuestionAnswerType === 'TextArea'">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">{{Daily.QuestionText}}</p>
                                        <div class="input-with-icon position-relative">
                                            <textarea name="TextArea" id=""
                                                (change)="CreateQuestionResponse(Daily,$event.target.value)" cols="30"
                                                rows="10" [(ngModel)]="Daily.AnswerText"
                                                class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea>





                                        </div>
                                    </div>
                                </div>



                                <div class="mb-20">
                                    <p class="fs-16 text-black mb-1 px-1 fw-900">Attach File <span
                                            class="fs-12">(Photos, Videos, Documents etc.)</span></p>
                                    <div class="input-with-icon position-relative">



                                        <label
                                            class="cstm-file-upload border-theme-2px border-radius-10 px-10 py-10 d-flex align-items-center w-100">
                                            <!-- <input type="file" id="file" multiple aria-label="File browser example"  (change)="getFileDetails($event)"> -->
                                            <input type='file' id="file" multiple aria-label="File browser example"
                                                (change)="onSelectFileEdit($event)" multiple>
                                            <span class="file-custom"></span>
                                            <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg"
                                                enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24"
                                                width="30px" fill="#000000">
                                                <g>
                                                    <rect fill="none" height="24" width="24" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="M20,6h-8l-1.41-1.41C10.21,4.21,9.7,4,9.17,4H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M13,13v3c0,0.55-0.45,1-1,1h0c-0.55,0-1-0.45-1-1v-3H9.21c-0.45,0-0.67-0.54-0.35-0.85l2.8-2.79 c0.2-0.2,0.51-0.19,0.71,0l2.79,2.79C15.46,12.46,15.24,13,14.8,13H13z" />
                                                </g>
                                            </svg>



                                        </label>



                                    </div>
                                </div>


                                <!-- <p
                                class="fs-16 text-black fw-600 d-flex justify-content-between align-items-center mb-10" *ngIf="FileName">
                                {{FileName}} 
                               
                                </p> -->
                                <p class="fs-16 text-black fw-600 d-flex justify-content-between align-items-center mb-10"
                                    *ngFor='let url of urls; let index = index'>
                                    <a (click)="removeSelectedFileEdit(index)">

                                        <img [src]="url" height="50">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </p>

                                <p class="fs-16 text-black fw-600 d-flex justify-content-between align-items-center mb-10"
                                    *ngFor="let attItem of editItem1;">

                                    <!-- <img class="object-fit-cover h-80"
                                                    [src]="Daily.AttachmentPath" alt="" srcset=""> -->

                                    <span *ngIf="attItem.AttachmentType=='Image'">
                                        <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                            href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">


                                            <img class="object-fit-cover h-80"
                                                [src]="imageBasePath + attItem.AttachmentPath" alt="" srcset="">
                                        </a>
                                    </span>
                                    <span *ngIf="attItem.AttachmentType=='PDF'">
                                        <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                            href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                            <i class="fa fa-file-pdf-o" aria-hidden="true"
                                                *ngIf="attItem.AttachmentType =='PDF'"></i>


                                        </a>
                                    </span>
                                    <span *ngIf="attItem.AttachmentType=='Document'">
                                        <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                            href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">

                                            <i class="fa fa-file" aria-hidden="true"
                                                *ngIf="attItem.AttachmentType =='Document'"></i>


                                        </a>
                                    </span>
                                    <span *ngIf="attItem.AttachmentType=='PPT'">
                                        <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                            href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">

                                            <i class="fa fa-file-powerpoint-o" aria-hidden="true"
                                                ></i>


                                        </a>
                                    </span>


                                    <!-- <span
                                        *ngIf="(attItem.AttachmentPath!=null)&& (attItem.AttachmentPath.split('.')[1]=='jpg' || attItem.AttachmentPath.split('.')[1]=='jpeg' || attItem.AttachmentPath.split('.')[1]=='iso' 
                                    ||attItem.AttachmentPath.split('.')[1]=='png' ||attItem.AttachmentPath.split('.')[1]=='gif'
                                    ||attItem.AttachmentPath.split('.')[1]=='docx'||attItem.AttachmentPath.split('.')[1]=='doc'||attItem.AttachmentPath.split('.')[1]=='pdf'
                                    ||attItem.AttachmentPath.split('.')[1]=='mp3' ||attItem.AttachmentPath.split('.')[1]=='mp4'||attItem.AttachmentPath.split('.')[1]=='mpeg'||attItem.AttachmentPath.split('.')[1]=='pptx')">
                                        <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                            href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">

                                            <i class="fa fa-file-pdf-o" aria-hidden="true"
                                                *ngIf="attItem.AttachmentType=='PDF||pdf'"></i>
                                            <i class="fa fa-file" aria-hidden="true"
                                                *ngIf="attItem.AttachmentType=='DOC||doc'"></i>

                                            <img class="object-fit-cover h-80"
                                                *ngIf="attItem.AttachmentType=='Image'||'image/png'"
                                                [src]="imageBasePath + attItem.AttachmentPath" alt="" srcset="">
                                        </a>
                                    </span>

                                    <span
                                        *ngIf="attItem.AttachmentPath !=null && attItem.AttachmentPath.includes('https://')">
                                        <a href="{{attItem.AttachmentPath }}" target="_blank">

                                            <i class="fa fa-file-pdf-o" aria-hidden="true"
                                                *ngIf="attItem.AttachmentType=='PDF||pdf'"></i>
                                            <i class="fa fa-file" aria-hidden="true"
                                                *ngIf="attItem.AttachmentType=='DOC||doc'"></i>

                                            <img class="object-fit-cover h-80"
                                                *ngIf="attItem.AttachmentType=='Image'||'image/png'"
                                                [src]=" attItem.AttachmentPath" alt="" srcset="">
                                        </a>
                                    </span> -->


                                </p>


                                <button [disabled]="valueT == ''"
                                    class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-25 text-uppercase box-shadow-hard mb-10"
                                    (click)="NextPage()"> Save & GO TO HOME </button>



                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>