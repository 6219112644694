<div class="container">
    <div class="content-section">


        <div class="px-lg-35 px-15 pb-5 bg-white py-18 border-radius-30">


            <div class="row px-2 mx-0 justify-content-center max-w-420 mx-auto" *ngIf="profileAll">
                <div class="col-6 px-0 text-center" *ngIf="!webcamImage">

                    <div class="user-profile-dash position-relative max-w-max-cont mx-auto"
                        *ngIf="profileAll.PhotoUrl == ''">
                        <img src="assets/upload/student.jpg" class="theme-bx-shadow" alt="Profile Image" srcset="">

                        <div
                            class="remove-profile-pic position-absolute cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-30 w-30">
                            <i class="fa-solid fa-camera"></i>
                        </div>
                    </div>
                    <div class="user-profile-dash position-relative max-w-max-cont mx-auto"
                        *ngIf="profileAll.PhotoUrl !== ''">
                        <img [src]="profileAll.PhotoUrl" onError="assets/upload/student.jpg" class="theme-bx-shadow"
                            alt="Profile Image" srcset="">
                    </div>
                </div>
                <div class="col-6 px-0 text-center" *ngIf="webcamImage">

                    <div class="user-profile-dash position-relative max-w-max-cont mx-auto"
                        >
                        <img [src]="webcamImage.imageAsDataUrl" class="theme-bx-shadow" alt="Profile Image" srcset="">

                       
                    </div>
                  
                </div>


            </div>

            <p class="fs-22 text-black fw-900  line-h-22 mt-25 mb-20 px-10 text-center"> Upload Photo </p>



            <div class="row mx-0 px-0 border-theme-2px py-15 border-radius-20 max-w-420 mx-auto">

                <div class="col-6 cursor-pointer text-center d-flex justify-content-center border-theme-2px-right">
                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1 mr-10" height="26px" viewBox="0 0 24 24"
                        width="26px" fill="#000000">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <circle cx="12" cy="12" r="3" />
                        <path
                            d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                    </svg>
                    <p class="mb-0 fs-16 fw-500 color1 borderright" (click)="toggleWebcam();">Camera</p>



                </div>
                <div class="col-6 cursor-pointer text-center d-flex justify-content-center border-theme-2px-right">

                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1 mr-10" height="26" width="26"
                        viewBox="0 10 45 24">
                        <path
                            d="M6.542 34.583Q4.708 34.583 3.417 33.271Q2.125 31.958 2.125 30.167V9.292Q2.125 7.5 3.417 6.167Q4.708 4.833 6.542 4.833H14.875Q15.75 4.833 16.562 5.229Q17.375 5.625 17.958 6.167L20.042 8.208H33.458Q35.333 8.208 36.625 9.521Q37.917 10.833 37.917 12.625H10.958Q9.083 12.625 7.812 13.875Q6.542 15.125 6.542 16.583V30.292Q6.542 30.292 6.542 30.292Q6.542 30.292 6.542 30.292L9.708 18.583Q10.167 17.167 11.333 16.292Q12.5 15.417 13.958 15.417H34.292Q36.5 15.417 37.833 17.208Q39.167 19 38.5 21.125L36.125 30.333Q35.375 32.667 34 33.625Q32.625 34.583 30.25 34.583Z">
                        </path>
                    </svg>
                    <!-- <input type="file" accept="image/*"   (change)="changeListener($event)"> -->
                    <input type="file" id="file" accept="image/*" (change)="changeListener($event)" />
                    <label for="file" class="mb-0 fs-16 fw-500 color1 borderright"
                        style="cursor:pointer ;">Device</label>
                    <!-- <p class="mb-0 fs-16 fw-500 color1 borderright" >Device</p> -->


                </div>

            </div>
            <div class="row mx-0 px-0  py-15 border-radius-20 max-w-420 mx-auto" *ngIf="showWebcam">
                <div id="web-camera" style="margin: 0 auto;text-align: center;">
                    <webcam [height]="400" [width]="400" [trigger]="triggerObservable"
                        (imageCapture)="handleImage($event)" *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch"
                        [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" [imageQuality]="1"
                        (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)"></webcam>
                    <!-- <br>

                    <button *ngIf="showWebcam" class="actionBtn" (click)="triggerSnapshot();">Take A
                        Snapshot</button>
                    <button *ngIf="showWebcam" class="actionBtn" (click)="toggleWebcam();">Close Camera</button> -->

                </div>

                    <div class="col-6 cursor-pointer text-center d-flex justify-content-center border-theme-2px-right" *ngIf="showWebcam">
                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1 mr-10" height="26px"
                            viewBox="0 0 24 24" width="26px" fill="#000000">
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <circle cx="12" cy="12" r="3" />
                            <path
                                d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                        </svg>
                        <!-- <p class="mb-0 fs-16 fw-500 color1 borderright" (click)="toggleWebcam();">Camera</p> -->
                        <p *ngIf="showWebcam" class="mb-0 fs-16 fw-500 color1 borderright" (click)="triggerSnapshot();">
                            Take A
                            Snapshot</p>



                    </div>
                    <div class="col-6 cursor-pointer text-center d-flex justify-content-center border-theme-2px-right" *ngIf="showWebcam">

                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1 mr-10" height="26" width="26"
                            viewBox="0 10 45 24">
                            <path
                                d="M6.542 34.583Q4.708 34.583 3.417 33.271Q2.125 31.958 2.125 30.167V9.292Q2.125 7.5 3.417 6.167Q4.708 4.833 6.542 4.833H14.875Q15.75 4.833 16.562 5.229Q17.375 5.625 17.958 6.167L20.042 8.208H33.458Q35.333 8.208 36.625 9.521Q37.917 10.833 37.917 12.625H10.958Q9.083 12.625 7.812 13.875Q6.542 15.125 6.542 16.583V30.292Q6.542 30.292 6.542 30.292Q6.542 30.292 6.542 30.292L9.708 18.583Q10.167 17.167 11.333 16.292Q12.5 15.417 13.958 15.417H34.292Q36.5 15.417 37.833 17.208Q39.167 19 38.5 21.125L36.125 30.333Q35.375 32.667 34 33.625Q32.625 34.583 30.25 34.583Z">
                            </path>
                        </svg> -->
                        <p *ngIf="showWebcam" class="mb-0 fs-16 fw-500 color1 borderright" (click)="toggleWebcam();">
                            Close Camera</p>



                    </div>

            </div>


            <div class="max-w-420 mx-auto">
                <div class="user-name mt-25">
                    <h2 class="color2 fw-800 fs-22">Hey <span class="text-black fs-23 font-pacific fw-400">{{FirstName}}
                            {{LastName}}!</span> </h2>
                </div>

                <p class="fs-16 text-black fw-400 mb-0 mt-20">As a first step upload your picture.</p>


                <p class="fs-16 text-black fw-400 mb-0 mt-25">
                    Your employer views your picture, so please show your face and use a simple background.</p>


                <p class="fs-16 text-black fw-400 mb-0 mt-25">
                    And Yes, Don't forget to <em class="fw-900"> smile!</em> <img src="assets/upload/smily.png"
                        alt="Smily" class="mb-1" srcset=""> </p>


                <button
                    class="d-block px-3 text-white max-w-400 border-radius-15 mx-auto mt-60  w-100 py-10 fs-18 upto-down-gradient-clr-1 text-uppercase theme-btn box-shadow-light max-w-320"
                    (click)="profileUpload()">continue</button>
            </div>


        </div>

    </div>
</div>