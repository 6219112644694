import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { AppConstants } from 'app/app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { HomeMainService } from './home-main.service';


import * as $ from 'jquery';
import { timeout } from 'rxjs/operators';

@Component({
  providers: [AppConstants],
  selector: 'igmite-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class HomeMainComponent extends BaseComponent<LoginModel> implements OnInit, AfterViewInit {
  public appInfo = environment;
  homeData: any
  IsDirectMappingEnabled:any
  public slides = [
    { 'SlidePreviewUrl': 'assets/upload/Video-1.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" },
    { 'SlidePreviewUrl': 'assets/upload/Video-2.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" },
    { 'SlidePreviewUrl': 'assets/upload/Video-3.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" },
    { 'SlidePreviewUrl': 'assets/upload/Video-4.png', "SlideContentUrl": "https://www.youtube.com/watch?v=Onz7k0G19Gk", "YoutubeIcon": "assets/upload/you-tube-icon.png" }
  ];

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private homeMainService: HomeMainService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    const firstTime = localStorage.getItem('key')
    if (!firstTime) {
      localStorage.setItem('key', 'loaded')
      window.location.reload();

    } else {
      localStorage.removeItem('key')
    }
  }

  ngOnInit(): void {
    this.IsDirectMappingEnabled=this.UserModel.IsDirectMappingEnabled

    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');
    });

    let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
    employeeMenuItems.forEach(employeeMenuItems => {
      employeeMenuItems.setAttribute('style', 'display: none !important');
    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    var proId = { DataId: this.UserModel.UserTypeId }
    this.homeMainService.GetInterestsByStudentId(proId).subscribe((response: any) => {
      this.homeData = response.Results
      this.IsLoading = false;
    }, error => {
    });

  }



  ngAfterViewInit() {


  }
}
