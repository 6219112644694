

 <section class="mb-lg-0 mb-20">
    <div class="container">






        <div class="content-section mt-0">
    
            <div
                class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 overflow-hidden  max-w-800 mx-auto">
    
    
                <div class="text-center mt-20">
                    <img src="assets/upload/Report-Now-icon.png" alt="" srcset="">
    
                    <h1
                        class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-0 line-h-30 mb-30">
                        Raise Safety Concerns</h1>
                </div>
    
    
    
    
                <section class="mt-30 position-relative mb-0">
                    <div id="tabs-design-2" class="filter-tabs">
                        <ul
                            class="mx-auto p-0 py-0 row mb-0 d-flex filter-tabs-key justify-content-between align-items-center">
                            <li
                                class="col-6 p-0 h-100 d-flex border-grey-light-right justify-content-center align-items-center">
                                <a class="fs-15 tabs-caller py-15 fw-700 color1 h-100 d-flex justify-content-center align-items-center"
                                (click)="toggle('Report')" [ngClass]="current === 'Report' ? 'tabulous_active' : ''" title="">
    
                                    <svg class="mr-10 fill-color-1" xmlns="http://www.w3.org/2000/svg" height="24px"
                                        viewBox="0 0 24 24" width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path
                                            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                                    </svg>
    
    
    
    
    
                                    Report</a>
                            </li>
                            <li class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"><a
                                    class="fs-15 tabs-caller py-15  fw-700 color1 h-100 d-flex justify-content-center align-items-center disabled"
                                    
                                    [ngClass]="current === 'Call' ? 'tabulous_active' : ''" title="">
    
    
                                    <svg class="mr-10 fill-color-1" xmlns="http://www.w3.org/2000/svg" height="24px"
                                        viewBox="0 0 24 24" width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path
                                            d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
                                    </svg>
    
    
                                    Call Back</a></li>
    
                        </ul>
                        <div id="tabs_container" class="filter-tabs-pannel px-0 mt-30 ">
    
                            <div *ngIf="current === 'Report'"
                                class="filter-tabs-slide max-w-420 mx-auto border-theme-2px px-10  border-radius-20 py-20">
                                <p class="text-center fw-900 fs-17 text-black">Report Your Concerns</p>
    
                                <form name="RaiseSafetyConcerForm" novalidate [formGroup]="RaiseSafetyConcerForm">
                                    <div class="mb-20">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">Select Type</p>
                                        <div class="input-with-icon position-relative">
                                            <!-- <mat-form-field appearance="outline" class="input-with-icon position-relative"> -->

                                            <select matNativeControl name=""  id="timing" formControlName="TypeOfRequest" 
                                                class="form-select shadow-none out-line-none form-select-lg border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 bg-white cursor-pointer input-top">
        
                                                <option value="" disabled class="w-100 max-w-420">Select Type</option>
                                                <!-- <option value="Raise risk Report" class="w-100 max-w-420">Raise Risk Report</option> -->
                                                <option value="Hazardous/Unsafe activities(Not using safety equipment)" class="w-100 max-w-420">Hazardous/Unsafe activities
                                                    (Not using safety equipment)</option>
                                                <option value="Hazardous /Unsafe environment/workplace" class="w-100 max-w-420">Hazardous /Unsafe environment/workplace</option>
                                                <option value="Harassment (Physical, Verbal or Sexual)" class="w-100 max-w-420">Harassment (Physical, Verbal or Sexual)</option>
                                                <option value="Safety concerns during commute" class="w-100 max-w-420">Safety concerns during commute
                                                </option>
                                                <option value="Other" class="w-100 max-w-420">Other</option>
        
                                            </select>
        
                                            <label for="timing" class="right-icon-input z-index-div-bottm">
        
                                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-color-1"
                                                    height="30px" width="30px" fill="#000000" viewBox="2 8 15 7">
                                                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                    <path
                                                        d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z">
                                                    </path>
                                            </svg></label>
                                            <!-- <mat-error *ngIf="RaiseSafetyConcerForm.controls.TypeOfRequest.invalid" >
                                                {{getErrorMessage(RaiseSafetyConcerForm, 'TypeOfRequest')}}
                                            </mat-error>
                                            </mat-form-field> -->
        
                                        </div>
                                    </div>
        
                                    <div class="mb-20">
                                        <p class="fs-16 text-black mb-1 px-1 fw-900">Share Details With Us</p>
                                        <div class="input-with-icon position-relative">
                                            <textarea name="" id="" cols="30" rows="5" formControlName="RequestDesc"
                                                class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea>
        
                                        </div>
                                    </div>
        
        
        
        
                                    <button class="d-block px-3 text-white max-w-180 border-radius-15 mx-auto mt-30 w-100 py-2 upto-down-gradient-clr-1 theme-btn" [disabled]="RaiseSafetyConcerForm.invalid" (click)="RaiseSafetyConcer()">SUBMIT</button>
        
                                </form>
                                
                            </div>
    
                            <div *ngIf="current === 'Call'"
                                class="filter-tabs-slide max-w-420 mx-auto border-theme-2px px-10  border-radius-20 py-20">
                                <p class="text-center fw-900 fs-17 text-black">Resquest A Call Back</p>
                                <form name="RaiseSafetyConcerForm" novalidate [formGroup]="RaiseSafetyConcerForm">
                                    <div class="mb-20">
                                        <p class="fs-16 text-black mb-0 px-1 fw-600">Your Mobile Number</p>
                                        <div class=" position-relative">
                                            <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                            <input  matInput type="number" name="" formControlName="TypeOfRequest" class="border-radius-10 border-theme-2px fs-18 py-2 w-100 px-5">
                                            <mat-error *ngIf="RaiseSafetyConcerForm.controls.TypeOfRequest.invalid" >
                                                {{getErrorMessage(RaiseSafetyConcerForm, 'TypeOfRequest')}}
                                            </mat-error>
                                            </mat-form-field>

                                        </div>
                                    </div>
        
                                    <div class="mb-20">
                                        <p class="fs-16 text-black mb-1 px-1 fw-600">Write Details <span
                                                class="fs-12 color4"> ( Optional) </span> </p>
                                        <div class="input-with-icon position-relative">
                                            <textarea name="" id="" cols="30" rows="5" formControlName="RequestDesc" class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea>
        
                                        </div>
                                    </div>
        
                                    <button class="d-block px-3 text-white max-w-180 border-radius-15 mx-auto mt-30 w-100 py-2 upto-down-gradient-clr-1 theme-btn" [disabled]="RaiseSafetyConcerForm.invalid" (click)="RaiseSafetyConcer()">SUBMIT</button>
                                    
                                </form>
    
                                
    
                            </div>
    
    
    
    
    
    
    
                        </div>
                        <!--End tabs container-->
                    </div>
                    <!--End tabs-->
                    <a (click)="NextPage()" class="fs-15 mt-70 mb-10 color1 fw-900 d-block text-center" style="cursor: pointer;">GO TO
                        HOME</a>
    
                </section>
    
    
    
            </div>
    
        </div>
    </div>
 </section>
