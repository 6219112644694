import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class ResetPasswordService {

    constructor(private http: BaseService) { }
    
    SendOTPEmail(formData: any): Observable<any> {
       
        return this.http
                .HttpPost("OtpDetails/CreateSendOtpDetails", formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
        }

        handleError(error: HttpErrorResponse) {
            let errorMessage = "Unknown error!";
            if (error.error instanceof ErrorEvent) {
                // Client-side errors
                errorMessage = `Error: ${error.error.message}`;
            } else {
                // Server-side errors
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            window.alert(errorMessage);
            return throwError(errorMessage);
        }
}
