import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class DashboardService {
   

    constructor(private http: BaseService) { }

    GetStudentProfileConfirmationById(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Account.GetStudentProfileConfirmationById, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    
    GetTopPerformerStudentList(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Dashboard.GetTopPerformerStudentList, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    GetStudentInternshipsById(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Dashboard.GetStudentInternshipsById, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetVTByStudentId(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Dashboard.GetVTByStudentId, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    GetStudentDashboard(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Dashboard.GetStudentDashboard, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetInternshipById(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Interests.GetInternshipById, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetDailyDiary() {
        return this.http
            .HttpGet(this.http.Services.Dashboard.GetDailyDiary)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    CreateDailyDiaryEntry(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Dashboard.CreateDailyDiaryEntry,formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    CreateAttachments(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Dashboard.CreateAttachments,formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = "Unknown error!";
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }
   
}
