import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class Internship3Service {
   

    constructor(private http: BaseService) { }

    GetStudentDashboardInternshipData(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Internship3.GetStudentDashboardInternshipData, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetLatestHighlight(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Internship3.GetLatestHighlight, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    GetEmployerBranchDetails(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Internship3.GetEmployerBranchDetails, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    
    

    GetStudentProfileConfirmationById(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Internship3.GetStudentProfileConfirmationById, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    GetTopPerformerStudentList(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Internship3.GetTopPerformerStudentList, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    handleError(error: HttpErrorResponse) {
        let errorMessage = "Unknown error!";
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }
   
}
