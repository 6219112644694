<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">

                <section class="mb-lg-0 mb-20">
                    <div class="container">

                        <div class="content-section mt-3">

                            <div>
                                <div class="d-flex justify-content-between align-items-center max-w-600 mx-auto px-1">
                                    <p class="fs-17 fw-900 mb-0 text-white">0</p>
                                    <p class="fs-17 fw-900 mb-0 text-white">100</p>
                                </div>
                                <div class="progress-outer max-w-600 mx-auto mt-5px px-15 progress-light">
                                    <div class="progress">
                                        <div class="progress-bar position-relative" role="progressbar"
                                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 50%;">
                                            <div class="position-absolute progress-value">
                                                <div class="position-relative d-flex justify-content-center ">
                                                    <img src="assets/upload/progress-bar-pointer.png" alt="" srcset="">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="fs-19 fw-800 text-white mb-0 text-center mt-25">Complete Internship Survey
                                form & collect points!</p>


                            <div
                                class="px-lg-35 px-15 border-radius-30 pb-3 bg-white py-30 pb-0 overflow-hidden max-w-800 mx-auto mt-40">

                                <p class="fs-16 color5 mb-0 fw-900">
                                    Question 4 of 10
                                </p>
                                <form action="">
                                    <p class="fs-19 fw-800 text-black mb-0 mt-0">Lorem ipsum dolor sit amet
                                        conse adipiscing elit?</p>

                                    <textarea name="" id="" cols="20" rows="5"
                                        class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15 mt-20"></textarea>

                                    <div class="row mx-0 p-0 align-items-center mt-90 mb-3 justify-content-center">
                                        <div class="col-5 px-0 ">
                                            <a href="../Student/reset-password.html"
                                                class=" fs-14 fw-800 mb-0 text-decoration-none color1 d-block text-center">PREVIOUS</a>
                                        </div>
                                        <div class="col-7 pr-0"> <button
                                                class="d-block px-3 text-white max-w-180 border-radius-15 mx-auto  w-100 py-2 upto-down-gradient-clr-1 theme-btn box-shadow-light">NEXT</button>
                                        </div>
                                    </div>
                                </form>
                            </div>




                        </div>
                    </div>

                </section>
            </div>
        </div>
    </div>
</section>