<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu-emp></igmite-side-menu-emp>

                </div>

                <div class="col-12 col-lg-10 right-content px-lg-1 overflow-hidden px-5px pb-5 bg-white py-5px">

                    <div
                        class="px-lg-35 px-10 border-radius-30 pb-3 bg-white py-18 pb-0 overflow-hidden max-w-800 mx-auto ">

                        <h1
                            class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-15  line-h-30 mb-30">
                            {{interestsDetails.Title}}</h1>

                        <div class="border-theme-2px border-radius-20 px-20 py-15 mb-30">

                            <div>
                                <p class="fs-16 text-black fw-900 mb-0 "> Working Interns (
                                    {{interestsDetails.NoOfOpenings}}) </p>

                                <p class="fs-14 fw-600 text-black fw-600 mb-0 "> Posted on -
                                    {{interestsDetails.PublishDate | date:"dd MMM
                                    yyyy"}}</p>
                            </div>
                            <div class="row mx-0 p-0">


                                <div class="col-6 pl-0 mt-20 pr-5px">
                                    <div class="d-flex align-items-center">

                                        <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                                            width="24px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24"></rect>
                                            </g>
                                            <g>
                                                <path
                                                    d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z">
                                                </path>
                                            </g>
                                        </svg>
                                        <div>
                                            <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Start Date</p>
                                            <p class="fs-14 text-black fw-600 mb-0"> {{interestsDetails.StartDate |
                                                date:"dd MMM
                                                yyyy"}}</p>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-6 pl-0 mt-20 pr-5px">
                                    <div class="d-flex align-items-center">

                                        <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                            enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                                            width="24px" fill="#000000">
                                            <g>
                                                <rect fill="none" height="24" width="24"></rect>
                                            </g>
                                            <g>
                                                <path
                                                    d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z">
                                                </path>
                                            </g>
                                        </svg>
                                        <div>
                                            <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Duration
                                            </p>
                                            <p class="fs-14 text-black fw-600 mb-0">{{interestsDetails.Duration}}</p>
                                        </div>
                                    </div>



                                </div>


                            </div>

                            <div class="">






                                <div class="internship-full-details">
                                    <div class="row mx-0 px-0">
                                        <div class="col-6 pl-0 mt-20 pr-5px">
                                            <div class="d-flex align-items-center">





                                                <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                                    height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z">
                                                    </path>
                                                </svg>



                                                <div>
                                                    <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Work Type
                                                    </p>
                                                    <p class="fs-14 text-black fw-600 mb-0">
                                                        {{interestsDetails.WorkingType}}</p>
                                                </div>
                                            </div>



                                        </div>

                                        <div class="col-6 pl-0 mt-20 pr-5px">
                                            <div class="d-flex align-items-center">



                                                <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                                    enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                                                    width="24px" fill="#000000">
                                                    <g>
                                                        <rect fill="none" height="24" width="24"></rect>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z">
                                                                </path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>


                                                <div>
                                                    <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Timing
                                                    </p>
                                                    <p class="fs-14 text-black fw-600 mb-0">Morning</p>
                                                    <!-- <p class="fs-14 text-black fw-600 mb-0">{{interestsDetails.WorkingType}}</p> -->
                                                </div>
                                            </div>



                                        </div>

                                        <div class="col-6 pl-0 mt-20 pr-5px">
                                            <div class="d-flex align-items-center">


                                                <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                                    height="28px" viewBox="0 0 24 24" width="28px" fill="#000000">
                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z">
                                                    </path>
                                                </svg>


                                                <div>
                                                    <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Open
                                                        Positions

                                                    </p>
                                                    <p class="fs-14 text-black fw-600 mb-0">
                                                        {{interestsDetails.InternshipOpenPositions}} Interns</p>
                                                </div>
                                            </div>



                                        </div>



                                        <div class="col-6 pl-0 mt-20 pr-5px">
                                            <div class="d-flex align-items-center">

                                                <svg class="mr-5 fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                                    enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                                                    width="24px" fill="#000000">
                                                    <g>
                                                        <rect fill="none" height="24" width="24" y="0"></rect>
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M20,7h-5V4c0-1.1-0.9-2-2-2h-2C9.9,2,9,2.9,9,4v3H4C2.9,7,2,7.9,2,9v11c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V9 C22,7.9,21.1,7,20,7z M9,12c0.83,0,1.5,0.67,1.5,1.5c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5C7.5,12.67,8.17,12,9,12z M12,18H6 v-0.43c0-0.6,0.36-1.15,0.92-1.39C7.56,15.9,8.26,15.75,9,15.75s1.44,0.15,2.08,0.43c0.55,0.24,0.92,0.78,0.92,1.39V18z M13,9h-2V4 h2V9z M17.25,16.5h-2.5c-0.41,0-0.75-0.34-0.75-0.75v0c0-0.41,0.34-0.75,0.75-0.75h2.5c0.41,0,0.75,0.34,0.75,0.75v0 C18,16.16,17.66,16.5,17.25,16.5z M17.25,13.5h-2.5c-0.41,0-0.75-0.34-0.75-0.75v0c0-0.41,0.34-0.75,0.75-0.75h2.5 c0.41,0,0.75,0.34,0.75,0.75v0C18,13.16,17.66,13.5,17.25,13.5z">
                                                        </path>
                                                    </g>
                                                </svg>





                                                <div>
                                                    <p class="fs-12 text-black fw-600 mb-0 line-h-10 mt-1">Onboarded

                                                    </p>
                                                    <p class="fs-14 text-black fw-600 mb-0">
                                                        {{interestsDetails.NoOfOpenings
                                                        -interestsDetails.InternshipOpenPositions }} Interns</p>
                                                </div>
                                            </div>



                                        </div>

                                    </div>

                                    <p class="fs-17 text-black fw-600 mb-0 mt-30">Internship Details</p>
                                    <p class="fs-14 text-black fw-600 mb-0 px-10 mt-5px">
                                        {{interestsDetails.RequiredSkills}}</p>

                                    <!-- <p class="fs-17 text-black fw-600 mb-0 mt-30">Internship Details</p>
                                    <ul class="list-none cstm-list-style pl-10 mt-5px">
                                        <li class="pl-10 fs-15 fw-400 text-black mb-1">Lorem ipsum dolor sit amet,
                                            conse
                                        </li>
                                        <li class="pl-10 fs-15 fw-400 text-black mb-1">Ctetur amet, conse adipiscing
                                            elit.</li>

                                    </ul> -->
                                </div>














                            </div>


                        </div>

                        <div class="max-w-800 w-100 mx-auto">



                            <div class="border-theme-2px border-radius-30 overflow-hidden">



                                <div class="px-15 py-15">
                                    <div *ngFor="let ongoingInterns of ongoingInternshipData;index as i;">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div
                                                class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                                <img [src]="ApiBaseUrlImage + ongoingInterns.ProfilePhoto"
                                                    class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                    width="40" height="40">
                                                <div>
                                                    <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                        {{ongoingInterns.StudentName}}</p>
                                                    <p class="fs-14 fw-600 color1 mb-0 mt-0">
                                                        {{ongoingInterns.StudentMobile}}</p>
                                                </div>

                                            </div>
                                            <a (click)="callStudentId(ongoingInterns.StudentId)"
                                                class="ml-auto d-block">
                                                <i
                                                    class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                            </a>

                                        </div>


                                        <div class="row mx-0 px-0">

                                            <a class="col-4 py-10">


                                                <p class="fs-13 fw-400 mt-1 text-black mb-0">Attendance</p>

                                                <strong
                                                    class="fs-16 line-h-15 fw-900 text-black ">{{ongoingInterns.TotalAttendance}}
                                                    D</strong>

                                            </a>


                                            <a class="col-4 py-10 ">







                                                <p class="fs-13 fw-400 mt-1 text-black mb-0">Internship</p>

                                                <strong
                                                    class="fs-16 line-h-15 fw-900 text-black ">{{ongoingInterns.Totalhours}}
                                                    H</strong>

                                            </a>


                                            <a class="col-4 py-10 ">



                                                <p class="fs-13 fw-400 mt-1 text-black mb-0">Diary Entry</p>

                                                <strong
                                                    class="fs-16 line-h-15 fw-900 text-black ">{{ongoingInterns.Totalhours
                                                    /
                                                    80 *100 | number}}%</strong>

                                            </a>

                                        </div>
                                        <div
                                            class="border-end-0 border-top-0 border-start-0 border-theme-2px mb-25 mt-20">
                                        </div>
                                    </div>



                                    <!-- <div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div
                                                class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                                <img src="../Upload/Manisha-Mishra.png"
                                                    class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                    width="40" height="40">
                                                <div>
                                                    <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">Manisha
                                                        Mishra</p>
                                                    <p class="fs-14 fw-600 color1 mb-0 mt-0">981105480</p>
                                                </div>

                                            </div>
                                            <a href="#" class="ml-auto d-block">
                                                <i
                                                    class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                            </a>

                                        </div>

                                        <div class="row mx-0 px-0">

                                            <a class="col-4 py-10">


                                                <p class="fs-13 fw-400 mt-1 text-black mb-0">Attendance</p>

                                                <strong class="fs-16 line-h-15 fw-900 text-black ">15D</strong>

                                            </a>


                                            <a href="../Student/diary-entry.html" class="col-4 py-10 ">







                                                <p class="fs-13 fw-400 mt-1 text-black mb-0">Internship</p>

                                                <strong class="fs-16 line-h-15 fw-900 text-black ">50 H</strong>

                                            </a>


                                            <a class="col-4 py-10 ">



                                                <p class="fs-13 fw-400 mt-1 text-black mb-0">Diary Entry</p>

                                                <strong class="fs-16 line-h-15 fw-900 text-black ">40%</strong>

                                            </a>

                                        </div>
                                    </div> -->

                                </div>


                            </div>





                        </div>


                        <!-- Send back to previous page on click-->
                        <a [routerLink]="['/past-internship']"
                            class="bg-transparent fs-17 fw-900 color1 text-center d-block mt-40 w-100 border-0">Back</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>