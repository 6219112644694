import { Injectable } from "@angular/core";
import { HttpErrorResponse,HttpHeaders } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';


@Injectable({
    providedIn: "root"
})
export class LoginVerifyService {

    constructor(private http: BaseService) { }
     

        VerifyOtpEmail(formData: any): Observable<any> {
            return this.http
                .HttpPost("OtpDetails/VerifyOtpDetails", formData)
                .pipe(
                    retry(this.http.Services.RetryServieNo),
                    catchError(this.handleError),
                    tap(logResp => {
                        return logResp;
                    })
                );
        }

        getUserTransactionsById(formData: any): Observable<any> {
            // const httpOptionsAuth = {
            //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': AuthToken })
            // };
            return this.http
                .HttpPost(this.http.Services.Account.GetUserTransactionsByOtp, formData)
                // .post('https://dev.pathways.net.in/LighthouseServices/OtpDetails/GetUserTransactionsByOtp', formData,httpOptionsAuth)
                .pipe(
                    retry(this.http.Services.RetryServieNo),
                    catchError(this.handleError),
                    tap(logResp => {
                        return logResp;
                    })
                );
        }
        
        SendOTPEmail(formData: any): Observable<any> {
            return this.http
                .HttpPost("OtpDetails/CreateSendOtpDetails", formData)
                .pipe(
                    retry(this.http.Services.RetryServieNo),
                    catchError(this.handleError),
                    tap(logResp => {
                        return logResp;
                    })
                );
        }
        handleError(error: HttpErrorResponse) {
            let errorMessage = "Unknown error!";
            if (error.error instanceof ErrorEvent) {
                // Client-side errors
                errorMessage = `Error: ${error.error.message}`;
            } else {
                // Server-side errors
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            window.alert(errorMessage);
            return throwError(errorMessage);
        }
}
