import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { ThankYouService } from './thank-you-reset.service';




@Component({
  providers: [AppConstants],
  selector: 'igmite-thank-you-reset',
  templateUrl: './thank-you-reset.component.html',
  styleUrls: ['./thank-you-reset.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class ThankYouResetComponent extends BaseComponent<LoginModel> implements OnInit {
  public loginForm: FormGroup;
  public loginModel: LoginModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  profileAll: any
  image: any
  interestsByStudent: any
  interests: any
  VTDetails: any;
  StudentID: any;
  stuId: any
  thankYou: string;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private thankYouService: ThankYouService,
    public zone: NgZone,

    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {
    // let elementIn: any = document.querySelectorAll(".withoutlogin");
    // elementIn.forEach(elementIn => {
    //   elementIn.setAttribute('style', 'display: none !important');

    // });

    // if (this.UserModel.RoleCode == 'ST') {
    //   let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
    //   employeeMenuItems.forEach(employeeMenuItems => {
    //     employeeMenuItems.setAttribute('style', 'display: none !important');
    //   });

    //   let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
    //   userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    // }
    // else {
    //   let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
    //   userProfileImage[0].setAttribute('src', this.appInfo.ApiBaseUrlEmployerImage + this.UserModel.PhotoUrl);
    // }

    this.thankYou = sessionStorage.getItem("ThankYou");


  }




}
