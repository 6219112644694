import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ProfileUploadModel } from './profile-upload.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { FileUploadModel } from 'app/models/file.upload.model';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { ProfileUploadService } from './profile-upload.service';
import { Observable, Subject } from 'rxjs';
import { WebcamImage } from './modules/webcam/domain/webcam-image';
import { WebcamUtil } from './modules/webcam/util/webcam.util';
import { WebcamInitError } from './modules/webcam/domain/webcam-init-error';

@Component({
  providers: [AppConstants],
  selector: 'igmite-profile-upload',
  templateUrl: './profile-upload.component.html',
  styleUrls: ['./profile-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ProfileUploadComponent extends BaseComponent<ProfileUploadModel> implements OnInit {
  public ProfileUploadForm: FormGroup;
  profileUploadModel: ProfileUploadModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public fileUploadModel: FileUploadModel;
  currentUser: any
  image: any
  FirstName: any
  LastName: any
  profileAll: any

  // toggle webcam on/off
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public facingMode: string = 'environment';
  public messages: any[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();


  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,

    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private dialogService: DialogService,
    private profileUploadService: ProfileUploadService,

    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);
    this.fileUploadModel = new FileUploadModel();

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };
  
    this.FirstName = this.UserModel.FirstName
    this.LastName = this.UserModel.LastName
  }

  ngOnInit(): void {

    this.readAvailableVideoInputs();

    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.RoleCode == 'ST') {
      let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
      employeeMenuItems.forEach(employeeMenuItems => {
        employeeMenuItems.setAttribute('style', 'display: none !important');
      });
    }
    localStorage.clear();

    this.ProfileUploadForm = this.createProfileUploadForm();
    var First = {
      DataId: this.UserModel.UserTypeId,
    }
    this.profileUploadService.SetFirstLogin(First)
      .subscribe((picResp: any) => {
      }, error => {
      });

    var proId = {
      DataId: this.UserModel.UserTypeId
    }
    this.profileUploadService.GetStudentProfileConfirmationById(proId).subscribe((profileResp: any) => {
      if (profileResp.Success == true) {
        this.zone.run(() => {
          this.profileAll = profileResp.Results[0]
          if (this.profileAll.PhotoUrl != null) {
            this.profileAll.PhotoUrl = this.Constants.CommonImageUrl.Account + this.profileAll.PhotoUrl;
            let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
            userProfileImage[0].setAttribute('src', this.profileAll.PhotoUrl);
          } else {
            this.profileAll.PhotoUrl = ''
          }
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordRetrievedMessage,

          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid UpdateStudentInternshipsStatus ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });
  }

  uploadedMOUDocumentUploadFile(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();
      if (this.AllowedPDFExtensions.indexOf(fileExtn) == -1) {
        this.ProfileUploadForm.get('MOUDocUpload').setValue(null);
        this.dialogService.openShowDialog("Please upload pdf file only.");
        return;
      }



    }
  }
  changeListener($event): void {
    this.readThis($event.target);
    this.getUploadedFileData($event, this.Constants.DocumentType.Account).then((response: FileUploadModel) => {
      this.fileUploadModel = response;



    });
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.profileAll.PhotoUrl = myReader.result;
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.profileAll.PhotoUrl);
    }
    myReader.readAsDataURL(file);
  }


  profileUpload() {

    var data = {
      AccountId: this.UserModel.UserTypeId,
      PhotoFile: this.fileUploadModel,
    }
    if (data.PhotoFile.FileName == '') {


      if (this.profileAll.PhotoUrl != '') {

        this.router.navigateByUrl(RouteConstants.ProfileMobile);
      } else {
        this.showActionMessage(
          this.Constants.Messages.ProfileUpload,

          this.Constants.Html.ErrorSnackbar
        );
      }

    } else {
      this.profileUploadService.UploadPhotoByAccountId(data)
        .subscribe((picResp: any) => {
          var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
          currentUser.PhotoUrl = picResp.Result
          sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
          if (picResp.Success) {
            this.zone.run(() => {
              this.showActionMessage(
                this.Constants.Messages.PhotoMessage,
                this.Constants.Html.SuccessSnackbar
              );
              this.router.navigateByUrl(RouteConstants.ProfileMobile);

            });

          }
          else {
            var errorMessages = this.getHtmlMessage(picResp.Errors)
            this.dialogService.openShowDialog(errorMessages);
          }
        }, error => {
        });
    }

  }

  //Create vocationalTrainingProvider form and returns {FormGroup}
  createProfileUploadForm(): FormGroup {
    return this.formBuilder.group({
      UserTypeId: new FormControl({ value: this.UserModel.UserTypeId }),
      LoginId: new FormControl({ value: this.UserModel.LoginId }),
      Password: new FormControl({ value: this.UserModel.Password }),
      UserId: new FormControl({ value: this.UserModel.UserId }),
      UserName: new FormControl({ value: this.UserModel.UserName }),
      FirstName: new FormControl({ value: this.UserModel.FirstName }),
      LastName: new FormControl({ value: this.UserModel.LastName }),
      EmailId: new FormControl({ value: this.UserModel.EmailId }),
      Mobile: new FormControl({ value: this.UserModel.Mobile }),
      PasswordExpiredOn: new FormControl({ value: this.UserModel.PasswordExpiredOn }),
      PasswordResetToken: new FormControl({ value: this.UserModel.PasswordResetToken }),
      PasswordUpdateDate: new FormControl({ value: this.UserModel.PasswordUpdateDate }),
      // PhotoFile: new FormControl({value:this.profileUploadModel.PhotoFile }),



    });
  }

  NextPage() {
    this.router.navigateByUrl(RouteConstants.ProfileMobile);

  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.messages.push(error);
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      this.addMessage('User denied camera access');
    }
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.addMessage('Received webcam image');
    this.webcamImage = webcamImage;
    var date = new Date();
    this.fileUploadModel.Base64Data = this.webcamImage.imageAsBase64;
    this.fileUploadModel.FileType = "image/png";
    this.fileUploadModel.FileSize = 35889;
    // this.fileUploadModel.ContentType=this.webcamImage[0]._mimeType
    this.fileUploadModel.FileName = this.UserModel.UserName + date + '.png'
    this.fileUploadModel.ContentType = this.Constants.DocumentType.Account
    this.fileUploadModel.UserId = this.UserModel.UserTypeId


    let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
    userProfileImage[0].setAttribute('src', this.webcamImage.imageAsDataUrl);

    this.showWebcam = !this.showWebcam;

  }

  public cameraWasSwitched(deviceId: string): void {
    this.addMessage('Active device: ' + deviceId);
    this.deviceId = deviceId;
    this.readAvailableVideoInputs();
  }

  addMessage(message: any): void {
    this.messages.unshift(message);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }

    return result;
  }

  private readAvailableVideoInputs() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }
}


