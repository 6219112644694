<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-lg-0">
    
            <div class="row mx-0">

                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu></igmite-side-menu>


                </div>
    
                <div class="col-md-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
    
    
                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">
    
    
    
                        <div class="col-12 px-0 text-center">
    
                            <div class="top-circle overflow-hidden mt-lg-0 bg-white mx-auto"> <img
                                    src="assets/upload/Student-basic-rights.png" class="h-80 w-80 object-fit-contain"
                                    height="" alt="" srcset=""></div>
                            <p class="fs-22 text-black fw-900  line-h-22 mt-25 mb-20 px-10 text-center"> Students
                                Rights </p>
                        </div>
    
    
                        <div [innerHTML]="desc"></div>
    
    
    
                        <div
                            class="py-15 px-10 border-radius-10 bg-light-theme border-theme-2px d-flex align-items-center mt-20 mb-30 max-w-420 mx-auto">
                            <p class="bg-color6 px-10 py-1 fs-17 text-white mb-0 fw-600 border-radius-2 mr-15">
                                Quiz?
                            </p>
    
    
                            <div class="d-flex justify-content-between align-items-center w-100 ">
                                <div>
                                    <p class="fs-17 text-black fw-600 mb-0 line-h-20">Students Rights Quiz</p>
                                    <p class="color2 fw-900 fs-13 mb-0">Get 25 Points </p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-color-6" viewBox="0 0 24 24"
                                    stroke-width="2" stroke="#000000" fill="none" stroke-linecap="round"
                                    stroke-linejoin="round" height="30" width="30">
    
                                    <polyline points="9 6 15 12 9 18"></polyline>
                                </svg>
                            </div>
    
                        </div>
    
    
                        <div class="text-center">
                            <a href="/students-right" class="fs-17 fw-900 color1 text-center">GO
                                BACK</a>
                        </div>
    
                    </div>
    
    
    
    
    
    
                </div>
            </div>
        </div>
    </div>
</section>
