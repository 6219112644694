<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu-emp></igmite-side-menu-emp>


                </div>
                <div class="col-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
                    <div class="row px-0 mx-0 align-items-lg-center max-w-800 mx-auto mb-4">
                        <div class="col-12 px-0 text-center">
                            <div
                                class="bg-theme-light-grey top-circle overflow-hidden mt-lg-0 mx-auto d-flex justify-content-center align-items-center">
                                <svg class="fill-color-1" version="1.1" id="certificate"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px" y="0px" width="60px" height="60px" viewBox="0 0 100 100"
                                    enable-background="new 0 0 100 100" xml:space="preserve">
                                    <g id="certificate_1_">
                                        <g>
                                            <g>
                                                <path fill="#0065FE" d="M54.193,53.108c0-3.479,0.079-4.09,0.53-4.833c0.877-1.46,1.646-1.672,6.028-1.672h3.93l2.655-2.656
                                c1.461-1.434,3.026-2.814,3.451-3.027c1.01-0.531,2.071-0.531,3.001-0.053c0.397,0.213,1.912,1.593,3.372,3.054l1.744,1.762
                                V24.996H21.094v32.019h33.099V53.108z M26.986,32.333c0-1.719,1.394-3.113,3.113-3.113H69.9c1.719,0,3.113,1.394,3.113,3.113
                                c0,1.72-1.395,3.113-3.113,3.113H30.1C28.38,35.447,26.986,34.053,26.986,32.333z" />
                                            </g>
                                        </g>
                                        <path fill="#0065FE" d="M91.447,7.875H8.553c-2.897,0-5.247,2.393-5.247,5.343v55.574c0,2.951,2.349,5.344,5.247,5.344h45.641
                        v-1.591l-2.789-2.868c-0.885-0.91-1.54-1.605-2.022-2.175H15.46c-2.415,0-4.372-1.993-4.372-4.452V18.961
                        c0-2.459,1.958-4.453,4.372-4.453h69.079c2.415,0,4.372,1.994,4.372,4.453v28.225c0.505,0.294,0.809,0.733,1.128,1.381
                        c0.451,0.982,0.531,1.646,0.531,4.966v3.823l2.788,2.788c4.302,4.302,4.302,4.992,0,9.294l-2.788,2.788v1.909h0.877
                        c2.897,0,5.246-2.393,5.246-5.344V13.218C96.693,10.268,94.345,7.875,91.447,7.875z" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#0065FE" d="M71.153,45.562c-0.324,0.163-1.521,1.217-2.637,2.313
                        l-2.029,2.029h-3.002c-3.349,0-3.936,0.162-4.606,1.278c-0.345,0.568-0.405,1.034-0.405,3.692v3.043l-1.744,1.725
                        c-2.253,2.231-2.922,3.124-2.922,3.977c0,1.136,0.345,1.663,2.536,3.915l2.13,2.19v3.023c0,4.646,0.324,4.95,5.092,4.95h2.882
                        l2.149,2.09c3.489,3.388,3.916,3.408,7.224,0.121l2.251-2.211h3.063c2.82,0,3.084-0.04,3.732-0.467
                        c1.197-0.791,1.4-1.481,1.4-4.809V69.48l2.131-2.13c3.286-3.287,3.286-3.814,0-7.101l-2.131-2.131v-2.92
                        c0-2.537-0.061-3.044-0.406-3.794c-0.648-1.319-1.217-1.502-4.808-1.502h-3.002l-2.029-2.049c-1.116-1.116-2.272-2.171-2.577-2.333
                        C72.736,45.156,71.924,45.156,71.153,45.562z M75.028,57.247c1.501,0.549,2.779,1.745,3.57,3.368
                        c0.608,1.217,0.67,1.521,0.67,3.185c0,1.664-0.062,1.968-0.67,3.186c-0.73,1.48-1.744,2.495-3.266,3.246
                        c-0.792,0.386-1.258,0.466-2.962,0.466c-1.806,0-2.151-0.06-3.186-0.567c-1.461-0.71-2.84-2.231-3.408-3.753
                        c-0.629-1.644-0.508-4.139,0.223-5.741C67.543,57.349,71.397,55.888,75.028,57.247z" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#0065FE" d="M56.831,83.521c-0.122,0.324-0.71,1.927-1.319,3.569
                        c-1.582,4.362-1.359,4.564,2.86,2.577c1.44-0.67,2.618-1.197,2.638-1.177c0.041,0.04,0.588,1.196,1.237,2.597
                        c0.649,1.399,1.258,2.657,1.359,2.779c0.244,0.345,1.055,0.345,1.318,0c0.224-0.284,2.679-6.958,2.679-7.323
                        c0-0.122-0.751-0.975-1.685-1.908l-1.663-1.663h-3.611h-3.591L56.831,83.521z" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#0065FE" d="M78.578,84.677l-1.684,1.684l1.4,3.833l1.379,3.836h0.69
                        c0.669,0,0.689-0.021,1.947-2.74c0.832-1.744,1.38-2.718,1.563-2.677c0.142,0.04,1.339,0.567,2.657,1.176
                        c4.037,1.866,4.18,1.645,2.354-3.347l-1.258-3.47h-3.672h-3.672L78.578,84.677z" />
                                    </g>
                                </svg>

                            </div>
                        </div>
                    </div>

                    <p class="fs-22 text-black fw-900  line-h-22 mt-15 mb-30 px-10 text-center"> Certificate Listing
                    </p>

                    <!-- <div class="row mx-0 px-0 mb-5" *ngIf="certificateListingData.length != 0">
                        <div class="col-md-6 px-10 mb-30"
                            *ngFor="let certificateList of certificateListingData; let i=index">
                            <div class="border-theme-2px border-radius-20 px-10 py-20">
                                <p class="fs-16 text-black text-center fw-900 mb-5px">
                                    {{certificateList.CertificateType}}
                                </p>
                                <p class="fs-13 text-black fw-400 mb-0 text-center">
                                    {{certificateList.OrganizationName}}
                                </p>
                                <div class="text-center mt-15 mb-15 w-100"><img
                                        [src]="ApiBaseUrlCertificateImage+certificateList.CertificateImage" alt=""
                                        srcset="" class="img-fluid mx-auto text-center"></div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <a href="{{ApiBaseUrlCertificateImage + certificateList.CertificateImage }}">
                                        <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="25"
                                            width="25" viewBox="0 9 46 27">
                                            <path
                                                d="M14 34h20v-3H14Zm9.85-6.3 7.9-7.85-2.1-2.1-4.25 4.2V12h-3v9.95l-4.25-4.2-2.1 2.1ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z">
                                            </path>
                                        </svg>

                                        <span class="fs-14 fw-900 color1">Download Certificate</span>
                                    </a>
                                    <a href="">

                                        <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="25"
                                            width="25" viewBox="0 9 46 27">
                                            <path
                                                d="M36.35 44q-2.35 0-4.025-1.675Q30.65 40.65 30.65 38.3q0-.35.075-.825t.225-.875L15.8 27.8q-.75.85-1.85 1.375t-2.25.525q-2.35 0-4.025-1.675Q6 26.35 6 24q0-2.4 1.675-4.05T11.7 18.3q1.15 0 2.2.45 1.05.45 1.9 1.3l15.15-8.7q-.15-.35-.225-.8-.075-.45-.075-.85 0-2.4 1.675-4.05T36.35 4q2.4 0 4.05 1.65t1.65 4.05q0 2.35-1.65 4.025-1.65 1.675-4.05 1.675-1.15 0-2.225-.375Q33.05 14.65 32.3 13.8l-15.15 8.4q.1.4.175.925.075.525.075.875t-.075.75q-.075.4-.175.8l15.15 8.6q.75-.7 1.75-1.125 1-.425 2.3-.425 2.4 0 4.05 1.65t1.65 4.05q0 2.35-1.65 4.025Q38.75 44 36.35 44Z">
                                            </path>
                                        </svg>



                                        <span class="fs-14 fw-900 color1">Share</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="row mx-0 px-0 mb-5" *ngIf="certificateListingData.length != 0">
                        <div class="mb-35 col-md-6 col-12"
                            *ngFor="let certificateList of certificateListingData; let i=index">

                            <div class="border-theme-2px border-radius-20 px-10 py-20">
                                <p class="fs-16 text-black text-center fw-900 mb-5px">
                                    {{certificateList.CertificateType}}
                                </p>
                                <p class="fs-13 text-black fw-400 read-more-less-text mb-0 text-center"
                                    data-read-more="{ visible: false, useStyledContainer: true }">
                                    Internship @ {{certificateList.OrganizationName}}
                                </p>

                                <div class="text-center mt-15 mb-15" id="certificate-Img">
                                    <p style="z-index: 100;
                                    padding-top: 33%;
                                    font-size: 13px;
                                    font-weight: bolder;
                                    color: #3e67f6;
                                    font-family: sans-serif;">{{certificateList.StudentFirstName}}
                                        {{ certificateList.StudentFatherName}} {{ certificateList.StudentLastName}}</p>
                                </div>

                                <div class="d-flex justify-content-between align-items-center">
                                    <a (click)="downloadCertificate(certificateList.CertificateId)" target="_blank">
                                        <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="25"
                                            width="25" height="30px" viewBox="0 9 46 27" width="30px">
                                            <path
                                                d="M14 34h20v-3H14Zm9.85-6.3 7.9-7.85-2.1-2.1-4.25 4.2V12h-3v9.95l-4.25-4.2-2.1 2.1ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z" />
                                        </svg>
                                        <span class="fs-14 fw-900 color1">Download Certificate</span>
                                    </a>
                                    <a>
                                        <svg class="fill-color-1" xmlns="http://www.w3.org/2000/svg" height="25"
                                            width="25" height="30px" viewBox="0 9 46 27" width="30px">
                                            <path
                                                d="M36.35 44q-2.35 0-4.025-1.675Q30.65 40.65 30.65 38.3q0-.35.075-.825t.225-.875L15.8 27.8q-.75.85-1.85 1.375t-2.25.525q-2.35 0-4.025-1.675Q6 26.35 6 24q0-2.4 1.675-4.05T11.7 18.3q1.15 0 2.2.45 1.05.45 1.9 1.3l15.15-8.7q-.15-.35-.225-.8-.075-.45-.075-.85 0-2.4 1.675-4.05T36.35 4q2.4 0 4.05 1.65t1.65 4.05q0 2.35-1.65 4.025-1.65 1.675-4.05 1.675-1.15 0-2.225-.375Q33.05 14.65 32.3 13.8l-15.15 8.4q.1.4.175.925.075.525.075.875t-.075.75q-.075.4-.175.8l15.15 8.6q.75-.7 1.75-1.125 1-.425 2.3-.425 2.4 0 4.05 1.65t1.65 4.05q0 2.35-1.65 4.025Q38.75 44 36.35 44Z" />
                                        </svg>
                                        <span class="fs-14 fw-900 color1">Share</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0 px-0 mb-5" *ngIf="certificateListingData.length == 0">
                        <p class="fs-16 text-black text-center fw-900 mb-5px">
                            {{certificateListingMsg}}
                        </p>
                    </div><!--certification end-->

                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</section>