import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { NotificationService } from './notification.service';

@Component({
  providers: [AppConstants],
  selector: 'igmite-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class NotificationComponent extends BaseComponent<LoginModel> implements OnInit {
  public loginModel: LoginModel;
  public returnUrl: string;
  public appInfo = environment;
  notifications: any
  roleCode:any
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private notificationService: NotificationService,
    public zone: NgZone,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {
this.roleCode=this.UserModel.RoleCode
if (this.UserModel.RoleCode == 'ST') {
  let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
  employeeMenuItems.forEach(employeeMenuItems => {
    employeeMenuItems.setAttribute('style', 'display: none !important');
  });
}
  

    var tab = JSON.parse(localStorage.getItem('currentTab'));
    var data = {
      Name: tab,
      PageIndex: 0,
      PageSize: 1000
    }
   
    
    this.notificationService.GetNoticesByType(data).subscribe((notResp: any) => {
      if (notResp.Success == true) {
        this.zone.run(() => {
          // if (this.UserModel.RoleCode == 'EMP') {
          //   this.notifications = notResp.Results.filter(eNB => eNB.NoticeType == 'Employer');
          // }
          // else {
          //   this.notifications = notResp.Results.filter(sNB => sNB.NoticeType == 'Student')
          // }

          this.notifications = notResp.Results;

          this.IsLoading = false;
        });
      }

    }, error => {
    });
  }


}
