import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginVerifyModel } from 'app/models/login-verify.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { ResetPasswordOTPService } from './reset-password-otp.service';


@Component({
  providers: [AppConstants],
  selector: 'igmite-reset-password-otp',
  templateUrl: './reset-password-otp.component.html',
  styleUrls: ['./reset-password-otp.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ResetPasswordOTPComponent extends BaseComponent<LoginVerifyModel> implements OnInit {
  public resetotpForm: FormGroup;
  public loginVerifyModel: LoginVerifyModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  display: any;
  ResetEmailID:any

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private resetPasswordOTPService: ResetPasswordOTPService,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
      
        sidepanel: {
          hidden: true
        }
      }
    };

    // Redirect to home if already logged in
    if (this.authenticationService.authUser) {
      this.router.navigate(['/']);
    }

    this.authenticationService.resetLogin();
    
    // Set the default login Model
    this.loginVerifyModel = new LoginVerifyModel();
  }

  ngOnInit(): void {
    let element: any = document.querySelectorAll(".displayLogout");
    element.forEach(element => {
      element.setAttribute('style', 'display: none !important');
    });
    let elementIn: any = document.querySelectorAll(".withinlogin");
    elementIn.forEach(elementIn => {
    elementIn.setAttribute('style', 'display: none !important');

    });
    // reset login status
    this.authenticationService.resetLogin();

    // Get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

   

    this.resetotpForm = this.createLoginForm();
    this.ResetEmailID=JSON.parse(localStorage.getItem('ResetEmailID'));

    let otpData=JSON.parse(localStorage.getItem('logResp'));
    let OtpExpirationDate = new Date(otpData);
    let currentDate = new Date();

    if (OtpExpirationDate < currentDate) {
      this.router.navigateByUrl(RouteConstants.ResetPassword);
      debugger;

    }else{
      debugger;
      this.timer(1);

    }

  }

  SendOTPEmail() {
    var data={
      EmailID:this.ResetEmailID
    }
    this.resetPasswordOTPService.SendOTPEmail(data)
      .subscribe((logResp: any) => {
        if (logResp.Success == true) {
            this.timer(1)
        }
        else {
          this.showErrorMessage(
            'Invalid EmailID ',
            'info-snackbar'
          );
        }
      });
  }

  validateUserAuth() {
    // this.loginVerifyModel.EmailID = this.ResetEmailID;
    // this.loginVerifyModel.EnteredOtp = this.resetotpForm.value.EnteredOtp;
    var data={
      EmailID : this.ResetEmailID,
      EnteredOtp : this.resetotpForm.value.EnteredOtp
    }

    this.resetPasswordOTPService.VerifyOtpEmail(data)
      .subscribe((logResp: any) => {
        if (logResp.Success) {
          var Data={
            UserTypeId:logResp.Result.UserTypeId,
            AuthToken: 'Bearer '+ logResp.Result.AuthToken,
          }
          localStorage.setItem('UserTypeId', JSON.stringify(Data));
          // localStorage.setItem('AuthToken', JSON.stringify(logResp.Result.AuthToken));
          this.router.navigateByUrl(RouteConstants.NewPassword);
        }
        else {
          this.showErrorMessage(
            'Invalid UserId or Password',
            'info-snackbar'
          );
        }
      });
  }

  //Create login form and returns {FormGroup}
  createLoginForm(): FormGroup {
    return this.formBuilder.group({
      EnteredOtp: new FormControl(this.loginVerifyModel.EnteredOtp, [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    });
  }

  showErrorMessage(messageText: string, messageType: string) {
    this.snackBar.open(messageText, "Dismiss", {
      duration: 2000,
      verticalPosition: "bottom",
      horizontalPosition: "center",
      panelClass: [messageType]
    });
  }



  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(timer);
      }
    }, 1000);
    debugger;
  }
}
