import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { ContactUsService } from './contact-us.service';


@Component({
  providers: [AppConstants],
  selector: 'igmite-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ContactUsComponent extends BaseComponent<LoginModel> implements OnInit {
  public contactUsForm: FormGroup;

  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  OfficeDetails: any;
  roleCodeST: boolean = false;
  roleCodeEMP: boolean = false;
  withoutLoginContactUs: boolean = false;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private contactUsService: ContactUsService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    public zone: NgZone,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };

    this.contactUsForm = this.createContactUsFormForm();

  }

  createContactUsFormForm(): FormGroup {
    return this.formBuilder.group({
      ContactUsName: new FormControl('', [Validators.required]),
      Mobile: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      EmailId: new FormControl('', [Validators.maxLength(100), Validators.pattern(this.Constants.Regex.Email), Validators.required]),
      Message: new FormControl(''),
    });

  }



  ngOnInit(): void {

    if (this.UserModel == undefined) {
      this.withoutLoginContactUs = true;
      this.roleCodeST = false;
      this.roleCodeEMP = false;
      let studentMenuItems: any = document.querySelectorAll(".withinlogin");
      studentMenuItems.forEach(studentMenuItems => {
        studentMenuItems.setAttribute('style', 'display: none !important');
      });
      let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
      employeeMenuItems.forEach(employeeMenuItems => {
        employeeMenuItems.setAttribute('style', 'display: none !important');
      });

    } else {
      if (this.UserModel.RoleCode == 'ST') {
        this.roleCodeST = true;
        this.roleCodeEMP = false;
        let elementIn: any = document.querySelectorAll(".withoutlogin");
        elementIn.forEach(elementIn => {
          elementIn.setAttribute('style', 'display: none !important');
        });
        let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
        employeeMenuItems.forEach(employeeMenuItems => {
          employeeMenuItems.setAttribute('style', 'display: none !important');
        });
      }
      if (this.UserModel.RoleCode == 'EMP') {
        this.roleCodeST = false;
        this.roleCodeEMP = true;
        let elementIn: any = document.querySelectorAll(".withoutlogin");
        elementIn.forEach(elementIn => {
          elementIn.setAttribute('style', 'display: none !important');
        });
        let employeeMenuItems: any = document.querySelectorAll(".withinlogin");
        employeeMenuItems.forEach(employeeMenuItems => {
          employeeMenuItems.setAttribute('style', 'display: none !important');
        });
      }
    }





    this.contactUsService.GetPathwayOfficeDetails().subscribe((offResp: any) => {
      if (offResp.Success == true) {
        this.zone.run(() => {
          this.OfficeDetails = offResp.Result
          this.IsLoading = false;
        });

      }

    }, error => {
    });
  }

  CreateContactUs() {
    var date = new Date();
    var data = {
      "AuthUserId": "system",
      "RequestType": 0,
      "IsActive": true,
      "ContactUsName": this.contactUsForm.value.ContactUsName,
      "Message": this.contactUsForm.value.Message,
      "Mobile": this.contactUsForm.value.Mobile,
      "EmailId": this.contactUsForm.value.EmailId,
      "DateOfContact": date,
      "IsActionTaken": true
    }
    this.contactUsService.CreateContactUs(data).subscribe((conResp: any) => {
      if (conResp.Success == true) {
        this.zone.run(() => {
          if (this.roleCodeST == true) {
            // this.showActionMessage(
            //   this.Constants.Messages.RecordSavedMessage,
            //   this.Constants.Html.SuccessSnackbar
            // );
            let ContactUs = 'ContactUs'
            sessionStorage.setItem('ThankYou', ContactUs);
            localStorage.setItem('ThankYou', ContactUs);
            this.router.navigateByUrl(RouteConstants.ThankYou);
            //this.router.navigateByUrl(RouteConstants.DashboardNew);

          }
          if (this.roleCodeEMP == true) {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );
            this.router.navigateByUrl(RouteConstants.HomeEmployer);

          }
          if (this.withoutLoginContactUs == true) {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );
            this.router.navigateByUrl(RouteConstants.Home);

          }

          this.IsLoading = false;

        });
      }
      else {

        this.zone.run(() => {
          this.snackBar.open("Record already exists", "Dismiss", {
            duration: 50000000,
            panelClass: ["info-snackbar"]
          });
        });

      }


    }, error => {
    });
  }
}
