import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import {of} from 'rxjs'
import {delay} from 'rxjs/operators'

@Injectable({
    providedIn: "root"
})
export class AttendanceV2Service {


    constructor(private http: BaseService) { }

    GetStudentProfileConfirmationById(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Account.GetStudentProfileConfirmationById, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetDailyDiaryEntries(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Dashboard.GetDailyDiaryEntries, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetStudentAttandanceDetails(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Dashboard.GetStudentAttandanceDetails, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getAllApplicantsForEmployer(userId: string, internshipId: string, pageIndex: number, pageSize: number): Observable<any> {
        let requestParams = {
            UserId: userId,
            InternshipGuid: internshipId,
            pageIndex: pageIndex,
            pageSize: pageSize
        };

        return this.http
            .HttpPost(this.http.Services.ApplicantListingV2.GetAllApplicantsForEmployer, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }


    getShortlistedApplicantsForEmployer(userId: string, internshipId: string, pageIndex: number, pageSize: number): Observable<any> {
        let requestParams = {
            UserId: userId,
            InternshipGuid: internshipId,
            pageIndex: pageIndex,
            pageSize: pageSize
        };

        return this.http
            .HttpPost(this.http.Services.ApplicantListingV2.GetShortlistedApplicantsForEmployer, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    CreateOrUpdateEmpCommentsForInternshipsDetails(formData: any) {
        return this.http
            .HttpPost(this.http.Services.EmpCommentsForInternships.CreateOrUpdateEmpCommentsForInternshipsDetails, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    // GetInterestsByStudentId(formData: any) {
    //     return this.http
    //         .HttpPost(this.http.Services.Interests.GetInterestsByStudentId, formData)
    //         .pipe(
    //             retry(this.http.Services.RetryServieNo),
    //             catchError(this.http.HandleError),
    //             tap(response => {
    //                 return response;
    //             })
    //         );
    // }

    // handleError(error: HttpErrorResponse) {
    //     let errorMessage = "Unknown error!";
    //     if (error.error instanceof ErrorEvent) {
    //         // Client-side errors
    //         errorMessage = `Error: ${error.error.message}`;
    //     } else {
    //         // Server-side errors
    //         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    //     }
    //     window.alert(errorMessage);
    //     return throwError(errorMessage);
    // }

    getData(month:number,year:number)
  {
    if (month==2 && year==2020)
      return of([
        {day:'2020-02-06',data:'one'},
        {day:'2020-02-16',data:'two'},
        {day:'2020-02-22',data:'three'}
      ]).pipe(delay(500))
    if (month==3 && year==2020)
      return of([
        {day:'2020-03-02',data:'three'},
        {day:'2020-03-11',data:'four'},
      ]).pipe(delay(500))

    return of([
        {day:year+'-'+('00'+month).slice(-2)+'-'+('00'+Math.floor(Math.random()*29)).slice(-2),data:'fool'},
        {day:year+'-'+('00'+month).slice(-2)+'-'+('00'+Math.floor(Math.random()*29)).slice(-2),data:'fool2'},
      ]).pipe(delay(500))

  }

}
