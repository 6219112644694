import { Component, OnInit, Inject ,NgZone} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { fuseAnimations } from '@fuse/animations';
import { AppConstants } from 'app/app.constants';
import { NewPasswordModel } from './change-password.model';
@Component({
  providers: [AppConstants],
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  animations: fuseAnimations
})
export class ChangePasswordDialogComponent implements OnInit {
  public ChangePasswordForm: FormGroup;
  startDate: any;
  hideConfirmPassword: boolean = true;
  public newPasswordModel: NewPasswordModel;

  constructor(public datepipe: DatePipe,public formBuilder: FormBuilder, public dialogRef: MatDialogRef<ChangePasswordDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private zone: NgZone) {  this.newPasswordModel = new NewPasswordModel();}

  ngOnInit(): void {
    this.ChangePasswordForm = this.createChangePasswordForm();
    console.log(this.data.message)
     this.ChangePasswordForm.controls['UserId'].patchValue(this.data.message.UserId);
     this.ChangePasswordForm.controls['Password'].patchValue('');
  }

  closeDialog() {
    this.dialogRef.close(false);

  }

  onSubmit(form) {
    // if (!form.valid) {
    //   this.validateDynamicFormArrayFields(form);
    //   this.validateAllFormFields(form);
    //   return;
    // }
    if(this.ChangePasswordForm.controls.NewPassword.value === this.ChangePasswordForm.controls.ConfirmPassword.value){
      var chagePass={
        UserId:this.data.message.UserId,
        Password:this.ChangePasswordForm.controls.Password.value,
        NewPassword:this.ChangePasswordForm.controls.NewPassword.value,
        ConfirmPassword:this.ChangePasswordForm.controls.ConfirmPassword.value,
      }
      this.dialogRef.close(false);

      console.log(chagePass)
      this.actualStartDateSubmitedBystudent(chagePass);
    }else{
      this.zone.run(() => {
        //@ts-ignore

        //@ts-ignore
        this.snackBar.open("Password Not Matched..", "Dismiss", {
          duration: 50000000,
          panelClass: ["info-snackbar"]
        });
      });
    }
   
  
  }

  actualStartDateSubmitedBystudent(form: any) {
    this.dialogRef.close(form);
  }
  setVisibleConfirmPassword(): void {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }
  // createdateForm(): FormGroup {
  //   return this.formBuilder.group({
  //     StartDate: new FormControl({ value: '', disabled: false }),
  //   });
  // }
    //Create account form and returns {FormGroup}
    createChangePasswordForm(): FormGroup {
      return this.formBuilder.group({
        UserId: new FormControl({ value: this.data.message.UserId, disabled: true } ),
        Password: new FormControl({ value: this.newPasswordModel.Password, disabled: false }, [Validators.required, Validators.pattern("([A-Z][a-z0-9.&+@-`]*\\s*)+$")]),
        NewPassword: new FormControl({ value: this.newPasswordModel.NewPassword, disabled: false }, [Validators.required, Validators.pattern("([A-Z][a-z0-9.&+@-`]*\\s*)+$")]),
        ConfirmPassword: new FormControl({ value: this.newPasswordModel.ConfirmPassword, disabled: false }, [Validators.required, Validators.pattern("([A-Z][a-z0-9.&+@-`]*\\s*)+$")])
      }, { validator: this.checkIfMatchingPasswords('NewPassword', 'ConfirmPassword') });
    }

    checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
      return (group: FormGroup) => {
          let passwordInput = group.controls[passwordKey],
              passwordConfirmationInput = group.controls[passwordConfirmationKey];
          if (passwordInput.value !== passwordConfirmationInput.value) {
              return passwordConfirmationInput.setErrors({ notEquivalent: true })
          }
          else {
              return passwordConfirmationInput.setErrors(null);
          }
      }
  }
}
