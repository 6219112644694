import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError, forkJoin } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from 'app/models/user.model';


@Injectable({
  providedIn: "root"
})
export class GratitudeCornerService {


  constructor(private http: BaseService) { }


  CreateOrUpdateGratitude(formData: any) {

    return this.http
      .HttpPost(this.http.Services.GratitudeCorner.CreateOrUpdateGratitude, formData)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response;
        })
      );
  }


}
