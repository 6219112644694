<section class="">
    <div class="container">

        <div class="content-section mt-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">


                    <igmite-side-menu></igmite-side-menu>


                </div>



                <div class="col-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
                    <section class="mt-20 position-relative mb-0 mt-10">
                        <div id="tabs-design-2" class="filter-tabs">
                            <ul
                                class="mx-auto p-0 py-0 row mb-0 d-flex filter-tabs-key justify-content-between align-items-center">
                                <li
                                    class="col-6 p-0 h-100 d-flex border-grey-light-right justify-content-center align-items-center">
                                    <a class="fs-15 tabs-caller tabulour-links py-15 fw-700 color1 h-100 d-flex justify-content-center align-items-center"
                                        (click)="toggle('Files')"
                                        [ngClass]="current === 'Files' ? 'tabulous_active' : ''" title="">


                                        <svg class="mr-10 fill-color-1" xmlns="http://www.w3.org/2000/svg" height="24px"
                                            width="24px" viewBox="3 11 42 27">
                                            <path
                                                d="M39 40H13q-1.2 0-2.1-.9-.9-.9-.9-2.1V5q0-1.2.9-2.1.9-.9 2.1-.9h17.4L42 13.6V37q0 1.2-.9 2.1-.9.9-2.1.9ZM28.9 14.9V5H13v32h26V14.9ZM7 46q-1.2 0-2.1-.9Q4 44.2 4 43V12.05h3V43h24.9v3Zm6-41h15.9v9.9H39L28.9 5v9.9H39V37H13V5Z" />
                                        </svg>

                                        Files</a>
                                </li>
                                <li class="col-6 p-0 h-100 d-flex justify-content-center align-items-center"><a
                                        class="fs-15 tabs-caller tabulour-links py-15  fw-700 color1 h-100 d-flex justify-content-center align-items-center"
                                        (click)="toggle('Folders')"
                                        [ngClass]="current === 'Folders' ? 'tabulous_active' : ''" title="">

                                        <svg class="mr-10 fill-color-1" xmlns="http://www.w3.org/2000/svg" height="24px"
                                            width="24px" viewBox="3 11 42 27">
                                            <path
                                                d="M7 40q-1.15 0-2.075-.925Q4 38.15 4 37V11q0-1.15.925-2.075Q5.85 8 7 8h14.05l3 3H41q1.15 0 2.075.925Q44 12.85 44 14H7v23l5.1-20H47l-5.35 20.7q-.3 1.2-1.1 1.75T38.5 40Z" />
                                        </svg>

                                        Folders</a></li>

                            </ul>
                            <div id="tabs_container" class="filter-tabs-pannel px-0 mt-30 ">
                                <div *ngIf="current === 'Files'" id="tabs-1"
                                    class="filter-tabs-slide max-w-420 mx-auto">
                                    <div style="width:100%;float:left;display:unset !important;">
                                        <div *ngFor="let imgGalleryItem of galleryData1;">
                                            <p class="fs-14 text-black mb-10 px-1 fw-600">
                                                {{imgGalleryItem.AttachmentDate | date:'mediumDate'}}
                                            </p>

                                            <div class="row px-0 mx-0">
                                                <div class="col-3 px-1 mb-5px cont-checkbox"
                                                    *ngFor="let imgGallery of imgGalleryItem.listAttachments; index as i">
                                                    <div class="selector-by-input">
                                                        <div class="select-the-div position-relative">
                                                            <label class="cstm-checkbox show-hide d-none  ">
                                                                <input class="inp-cbx position-absolute" id="gallery-8"
                                                                    type="checkbox" />
                                                                <label class="cbx" for="gallery-8">

                                                                    <span>
                                                                        <i class="fa-solid fa-check "></i></span>

                                                                </label></label>
                                                            <div>
                                                                <!-- <span
                                                                    *ngIf="(imgGallery.AttachmentPath!=null)&& (imgGallery.AttachmentPath.split('.')[1]=='jpg' || imgGallery.AttachmentPath.split('.')[1]=='jpeg' || imgGallery.AttachmentPath.split('.')[1]=='iso' 
                                                    ||imgGallery.AttachmentPath.split('.')[1]=='png' ||imgGallery.AttachmentPath.split('.')[1]=='gif'
                                                    ||imgGallery.AttachmentPath.split('.')[1]=='docx'||imgGallery.AttachmentPath.split('.')[1]=='doc'||imgGallery.AttachmentPath.split('.')[1]=='pdf'
                                                    ||imgGallery.AttachmentPath.split('.')[1]=='mp3' ||imgGallery.AttachmentPath.split('.')[1]=='mp4'||imgGallery.AttachmentPath.split('.')[1]=='mpeg'||imgGallery.AttachmentPath.split('.')[1]=='pptx')"> -->

                                                                <!-- <a (click)="onSelectPicture(imgGallery.AttatchmentId);downloadImage(imgGallery.AttatchmentId)"
                                                                    target="_blank"
                                                                    *ngIf="imgGallery.SectionType=='IRBestPicture'"
                                                                    data-gall="myGallery">

                                                                    <input type="checkbox"
                                                                        [id]="'myCheckbox-' + i+imgGallery.AttatchmentId" />
                                                                    <label
                                                                        [for]="'myCheckbox-' + i+imgGallery.AttatchmentId">
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:100px;height:80px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                        <span class="cover-checkbox">
                                                                            <svg viewBox="0 0 12 10">
                                                                                <polyline points="1.5 6 4.5 9 10.5 1">
                                                                                </polyline>
                                                                            </svg>
                                                                        </span>
                                                                    </label>

                                                                </a>


                                                                <a (click)="onSelectPicture(imgGallery.AttatchmentId);downloadImage(FinalInternshipImageBaseURL+imgGallery.AttachmentPath)"
                                                                    href="{{FinalInternshipImageBaseURL+imgGallery.AttachmentPath}}"
                                                                    target="_blank" data-gall="myGallery"
                                                                    *ngIf="imgGallery.SectionType=='IRCertificate'">

                                                                    <input type="checkbox"
                                                                        [id]="'myCheckbox-' + i+imgGallery.AttatchmentId" />
                                                                    <label
                                                                        [for]="'myCheckbox-' + i+imgGallery.AttatchmentId">
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                        <span class="cover-checkbox">
                                                                            <svg viewBox="0 0 12 10">
                                                                                <polyline points="1.5 6 4.5 9 10.5 1">
                                                                                </polyline>
                                                                            </svg>
                                                                        </span>
                                                                    </label>


                                                                </a>

                                                                <a (click)="onSelectPicture(imgGallery.AttatchmentId);downloadImage(FinalInternshipImageBaseURL+imgGallery.AttachmentPath)"
                                                                    target="_blank"
                                                                    *ngIf="imgGallery.SectionType=='IRRportPhotos'"
                                                                    data-gall="myGallery">

                                                                    <input type="checkbox"
                                                                        [id]="'myCheckbox-' + i+imgGallery.AttatchmentId" />
                                                                    <label
                                                                        [for]="'myCheckbox-' + i+imgGallery.AttatchmentId">
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                        <span class="cover-checkbox">
                                                                            <svg viewBox="0 0 12 10">
                                                                                <polyline points="1.5 6 4.5 9 10.5 1">
                                                                                </polyline>
                                                                            </svg>
                                                                        </span>
                                                                    </label>


                                                                </a> -->
                                                                <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType);"
                                                                    href="{{FinalInternshipImageBaseURL+imgGallery.AttachmentPath}}"
                                                                    target="_blank" data-gall="myGallery"
                                                                    *ngIf="imgGallery.SectionType !='DailyDiary'">

                                                                    <input type="checkbox"
                                                                        [id]="'myCheckbox-' + i+imgGallery.AttatchmentId" />
                                                                    <label
                                                                        [for]="'myCheckbox-' + i+imgGallery.AttatchmentId">
                                                                        <!-- <div class="container"> -->
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:100px;height:80px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                        <button class="btn"
                                                                            (click)="downloadImage(FinalInternshipImageBaseURL+imgGallery.AttachmentPath)"><img
                                                                                src="assets/upload/download-thumbnail.png"
                                                                                width="52" alt="" srcset=""></button>
                                                                        <!-- </div> -->
                                                                        <span class="cover-checkbox">
                                                                            <svg viewBox="0 0 12 10">
                                                                                <polyline points="1.5 6 4.5 9 10.5 1">
                                                                                </polyline>
                                                                            </svg>
                                                                        </span>
                                                                    </label>

                                                                </a>

                                                                <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType);"
                                                                    href="{{imageAttachments+imgGallery.AttachmentPath}}"
                                                                    target="_blank" data-gall="myGallery"
                                                                    *ngIf="imgGallery.SectionType=='DailyDiary'">


                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='docx'||imgGallery.AttachmentPath.split('.')[1]=='doc'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div class="h-45 w-45 rounded-circle bg-theme-light-grey d-flex justify-content-center align-items-center"
                                                                            style="width:50px;height:50px; float:left; margin:15% 24% 15% 24%; padding: 1px;border: 2px solid #ebf3ff">
                                                                            <svg class="fill-color-7"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                height="24px" viewBox="0 0 24 24"
                                                                                width="24px" fill="#000000">
                                                                                <path d="M0 0h24v24H0V0z" fill="none">
                                                                                </path>
                                                                                <path
                                                                                    d="M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z">
                                                                                </path>
                                                                            </svg>

                                                                        </div>

                                                                    </div>

                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='pdf'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div class="h-45 w-45 rounded-circle bg-theme-light-grey d-flex justify-content-center align-items-center"
                                                                            style="width:50px;height:50px; float:left; margin:15% 24% 15% 24%; padding: 1px;border: 2px solid #ebf3ff">

                                                                            <div
                                                                                class="resources-extention h-80 w-100  d-flex justify-content-center align-items-center position-relative ">
                                                                                <div
                                                                                    class="h-45 w-45 rounded-circle bg-light-purple d-flex justify-content-center align-items-center">
                                                                                    <p class="mb-0 fs-24 fw-900"
                                                                                        style="color:red;">
                                                                                        P
                                                                                    </p>


                                                                                </div>


                                                                                <!-- <span
                                                                                    class="fs-11 color9 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600">pdf</span> -->

                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='mp3' ||imgGallery.AttachmentPath.split('.')[1]=='mp4'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <span
                                                                            class="fs-13 text-white mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600"><i
                                                                                class="fa-solid fa-circle-play"></i>
                                                                            <span class="video-timing">02:00</span>
                                                                        </span>
                                                                        <img class="w-100 h-80 object-fit-cover"
                                                                            src="assets/upload/vedio.png" alt=""
                                                                            srcset="">

                                                                    </div>

                                                                    <div style="width:50px;height:50px; "
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='ppt' || imgGallery.AttachmentPath.split('.')[1]=='pptx'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div
                                                                            class="resources-extention h-80 w-100 border-theme-2px d-flex justify-content-center align-items-center position-relative ">
                                                                            <div
                                                                                class="h-45 w-45 rounded-circle bg-light-purple d-flex justify-content-center align-items-center">
                                                                                <p class="mb-0 fs-24 fw-900 color9">PPT
                                                                                </p>


                                                                            </div>


                                                                            <!-- <span
                                                                                class="fs-11 color9 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600">ppt</span> -->

                                                                        </div>
                                                                    </div>

                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='xlsx'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div class="h-45 w-45 rounded-circle bg-theme-light-grey d-flex justify-content-center align-items-center"
                                                                            style="width:50px;height:50px; float:left; margin:15% 24% 15% 24%; padding: 1px;border: 2px solid #ebf3ff">
                                                                            <!-- <svg class="fill-color-7"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                height="24px" viewBox="0 0 24 24"
                                                                                width="24px" fill="#000000">
                                                                                <path d="M0 0h24v24H0V0z" fill="none">
                                                                                </path>
                                                                                <path
                                                                                    d="M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z">
                                                                                </path>
                                                                            </svg> -->

                                                                            <div
                                                                                class="h-45 w-45 rounded-circle bg-light-purple d-flex justify-content-center align-items-center">
                                                                                <p class="mb-0 fs-24 fw-900"
                                                                                    style="color:#1D6F42">
                                                                                    X
                                                                                </p>


                                                                            </div>


                                                                            <!-- <span
                                                                                class="fs-11 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600" style="color:#1D6F42">exl</span> -->

                                                                        </div>

                                                                    </div>

                                                                    <input type="checkbox"
                                                                        [id]="'myCheckbox-' + i+imgGallery.AttatchmentId" />
                                                                    <label
                                                                        [for]="'myCheckbox-' + i+imgGallery.AttatchmentId">
                                                                        <img [src]="imageAttachments+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            *ngIf="imgGallery.AttachmentPath.split('.')[1]=='jpg' || imgGallery.AttachmentPath.split('.')[1]=='jpeg' || imgGallery.AttachmentPath.split('.')[1]=='iso' 
                                                                        ||imgGallery.AttachmentPath.split('.')[1]=='png' ||imgGallery.AttachmentPath.split('.')[1]=='gif'"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                        <button class="btn"
                                                                            (click)="downloadImage(imageAttachments+imgGallery.AttachmentPath)"><img
                                                                                src="assets/upload/download-thumbnail.png"
                                                                                width="52" alt="" srcset=""></button>

                                                                        <span class="cover-checkbox">
                                                                            <svg viewBox="0 0 12 10">
                                                                                <polyline points="1.5 6 4.5 9 10.5 1">
                                                                                </polyline>
                                                                            </svg>
                                                                        </span>
                                                                    </label>


                                                                </a>



                                                            </div>
                                                            <!-- <div>

                                                                <a class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0  vbox-item"
                                                                    href="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                    data-gall="myGallery">


                                                                    <img *ngIf="imgGallery.SectionType=='IRBestPicture'"
                                                                        [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                        style="width:100px;height:80px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        class="w-100 h-80 object-fit-cover">


                                                                </a>

                                                                <a class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0  vbox-item"
                                                                    href="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                    data-gall="myGallery">


                                                                    <img *ngIf="imgGallery.SectionType=='IRCertificate'"
                                                                        [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                        style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        class="w-100 h-80 object-fit-cover">


                                                                </a>

                                                                <a class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0  vbox-item"
                                                                    href="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                    data-gall="myGallery">


                                                                    <img *ngIf="imgGallery.SectionType=='IRRportPhotos'"
                                                                        [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                        style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        class="w-100 h-80 object-fit-cover">


                                                                </a>

                                                                <a class="venobox auto-play just-open mx-auto d-block w-100 border-radius-0  vbox-item"
                                                                    href="imageAttachments+imgGallery.AttachmentPath"
                                                                    data-gall="myGallery">


                                                                    <img *ngIf="imgGallery.SectionType=='DailyDiary'"
                                                                        [src]="imageAttachments+imgGallery.AttachmentPath"
                                                                        style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        class="w-100 h-80 object-fit-cover">


                                                                </a>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="border-theme-1px mt-35 mb-30"></div>


                                        </div>

                                        <!--End tabs container-->
                                    </div>
                                    <div class="mb-5">
                                        <!-- <button  (click)="onSelect('ShowCheckbox')"  
                                            class="d-block px-3 text-center max-w-400 border-radius-15 mx-auto mt-35 w-100 py-2 bordertheme border-color2-imp fw-500 bg-light-theme color1 line-h-30 text-uppercase max-w-300 select-gallery-images">Select</button> -->
                                        <button (click)="onSelectShowprofile()"
                                            [disabled]="showButton=='' || showButton==undefined"
                                            class="d-block px-3 text-center max-w-400 border-radius-15 mx-auto mt-35 w-100 py-2 bordertheme fw-500 bg-light-theme line-h-30 text-uppercase max-w-300 select-gallery-images upto-down-gradient-clr-1 text-white border-0">Show
                                            on profile</button>
                                    </div>
                                </div>
                                <!--End tabs-->


                                <div *ngIf="current === 'Folders'" id="tabs-2"
                                    class="filter-tabs-slide max-w-420 mx-auto">


                                    <div class="row mx-0 px-0">
                                        <div class="col-4 px-1 mb-10" (click)="scrollPoint1('AllPhotos')">
                                            <img src="https://lendahandindia.org/wp-content/uploads/2021/10/screenshot_20201018_1411272-570x450.jpg"
                                                class="w-100 height-100 h-100px object-fit-cover cursor-pointer"
                                                alt="Folder-1" srcset="">
                                            <p class="fs-14 text-black fw-900 text-center mt-10">All Photos</p>
                                        </div>

                                        <div class="col-4 px-1 mb-10" (click)="scrollPoint1('Internship')">
                                            <img src="https://lendahandindia.org/wp-content/uploads/2021/10/screenshot_20200514_162552_2-570x450.jpg"
                                                class="w-100 height-100 h-100px object-fit-cover cursor-pointer"
                                                alt="Folder-1" srcset="">
                                            <p class="fs-14 text-black fw-900 text-center mt-10">Internship</p>
                                        </div>

                                        <div class="col-4 px-1 mb-10" (click)="scrollPoint1('Docs')">

                                            <div
                                                class="w-100 h-100px border-theme-1px d-flex justify-content-center align-items-center">
                                                <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                                    height="50px" width="50px" viewBox="0 9 46 27">
                                                    <path
                                                        d="M7 40q-1.15 0-2.075-.925Q4 38.15 4 37V11q0-1.15.925-2.075Q5.85 8 7 8h14.05l3 3H41q1.15 0 2.075.925Q44 12.85 44 14H7v23l5.1-20H47l-5.35 20.7q-.3 1.2-1.1 1.75T38.5 40Z" />
                                                </svg>
                                            </div>



                                            <p class="fs-14 text-black fw-900 text-center mt-10">Docs</p>
                                        </div>

                                        <div class="col-4 px-1 mb-10" (click)="scrollPoint1('Profile')">

                                            <img src="https://lendahandindia.org/wp-content/uploads/2021/11/img-1.jpg"
                                                class="w-100 height-100 h-100px object-fit-cover cursor-pointer"
                                                alt="Folder-1" srcset="">
                                            <p class="fs-14 text-black fw-900 text-center mt-10">Profile</p>

                                        </div>

                                        <div class="col-4 px-1 mb-10" (click)="scrollPoint1('Others')">

                                            <img src="https://lendahandindia.org/wp-content/uploads/2021/11/img-4.jpg"
                                                class="w-100 height-100 h-100px object-fit-cover cursor-pointer"
                                                alt="Folder-1" srcset="">
                                            <p class="fs-14 text-black fw-900 text-center mt-10">Others</p>

                                        </div>
                                    </div>

                                    <div class="border-theme-1px mt-25 mb-25"></div>


                                    <!-- <div *ngFor="let imgGallery of imgGalleryItem.listAttachments;"> -->
                                    <div class="image-selctor gallery" *ngIf="profile=='Profile'"
                                        style="overflow: auto;">
                                        <h1
                                            class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-30 line-h-30 mb-30">
                                            Profile Photos</h1>
                                        <div>
                                            <div class="row px-0 mx-0">

                                                <!-- <div class="px-1 mb-5px"
                                                    *ngFor="let imgGallery of imgGalleryItem.listAttachments;"
                                                    style="width:20%;float:left;"> -->
                                                <div class="col-3 px-1 mb-5px cont-checkbox"
                                                    *ngFor="let imgGallery of galleryData3; index as i">
                                                    <div class="selector-by-input">
                                                        <div class="select-the-div position-relative">
                                                            <label class="cstm-checkbox show-hide d-none  ">
                                                                <input class="inp-cbx position-absolute" id="gallery-8"
                                                                    type="checkbox" />
                                                                <label class="cbx" for="gallery-8">

                                                                    <span>
                                                                        <i class="fa-solid fa-check "></i></span>

                                                                </label></label>
                                                            <div>
                                                                <!-- <span
                                                                    *ngIf="(imgGallery.AttachmentPath!=null)&& (imgGallery.AttachmentPath.split('.')[1]=='jpg' || imgGallery.AttachmentPath.split('.')[1]=='jpeg' || imgGallery.AttachmentPath.split('.')[1]=='iso' 
                                                    ||imgGallery.AttachmentPath.split('.')[1]=='png' ||imgGallery.AttachmentPath.split('.')[1]=='gif'
                                                    ||imgGallery.AttachmentPath.split('.')[1]=='docx'||imgGallery.AttachmentPath.split('.')[1]=='doc'||imgGallery.AttachmentPath.split('.')[1]=='pdf'
                                                    ||imgGallery.AttachmentPath.split('.')[1]=='mp3' ||imgGallery.AttachmentPath.split('.')[1]=='mp4'||imgGallery.AttachmentPath.split('.')[1]=='mpeg'||imgGallery.AttachmentPath.split('.')[1]=='pptx')"> -->

                                                                <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                    target="_blank"
                                                                    *ngIf="imgGallery.SectionType=='IRBestPicture'"
                                                                    data-gall="myGallery">

                                                                    <input type="checkbox" [id]="'myCheckbox1-' + i" />
                                                                    <label [for]="'myCheckbox1-' + i">
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:100px;height:80px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                        <span class="cover-checkbox">
                                                                            <svg viewBox="0 0 12 10">
                                                                                <polyline points="1.5 6 4.5 9 10.5 1">
                                                                                </polyline>
                                                                            </svg>
                                                                        </span>
                                                                    </label>


                                                                </a>


                                                                <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                    target="_blank" data-gall="myGallery"
                                                                    *ngIf="imgGallery.SectionType=='IRCertificate'">
                                                                    <input type="checkbox" [id]="'myCheckbox1-' + i" />
                                                                    <label [for]="'myCheckbox1-' + i">
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                        <span class="cover-checkbox">
                                                                            <svg viewBox="0 0 12 10">
                                                                                <polyline points="1.5 6 4.5 9 10.5 1">
                                                                                </polyline>
                                                                            </svg>
                                                                        </span>
                                                                    </label>


                                                                </a>

                                                                <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                    target="_blank"
                                                                    *ngIf="imgGallery.SectionType=='IRRportPhotos'"
                                                                    data-gall="myGallery">
                                                                    <input type="checkbox" [id]="'myCheckbox1-' + i" />
                                                                    <label [for]="'myCheckbox1-' + i">
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                        <span class="cover-checkbox">
                                                                            <svg viewBox="0 0 12 10">
                                                                                <polyline points="1.5 6 4.5 9 10.5 1">
                                                                                </polyline>
                                                                            </svg>
                                                                        </span>
                                                                    </label>


                                                                </a>

                                                                <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                    target="_blank" data-gall="myGallery"
                                                                    *ngIf="imgGallery.SectionType=='DailyDiary'">
                                                                    <input type="checkbox" [id]="'myCheckbox1-' + i" />
                                                                    <label [for]="'myCheckbox1-' + i">
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="imageAttachments+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                        <span class="cover-checkbox">
                                                                            <svg viewBox="0 0 12 10">
                                                                                <polyline points="1.5 6 4.5 9 10.5 1">
                                                                                </polyline>
                                                                            </svg>
                                                                        </span>
                                                                    </label>
                                                                </a>


                                                                <!-- </span> -->
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <!-- profile end -->

                                    <div class="image-selctor gallery" *ngIf="allPhotos=='AllPhotos'"
                                        style="overflow: auto;">
                                        <h1
                                            class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-30 line-h-30 mb-30">
                                            All Photos</h1>
                                        <div>
                                            <div *ngFor="let imgGalleryItem of galleryData1;">
                                                <div class="row px-0 mx-0">
                                                    <div class="col-3 px-1 mb-5px "
                                                        *ngFor="let imgGallery of imgGalleryItem.listAttachments; index as i">
                                                        <div class="selector-by-input">
                                                            <div class="select-the-div position-relative">
                                                                <label class="cstm-checkbox show-hide d-none  ">
                                                                    <input class="inp-cbx position-absolute"
                                                                        id="gallery-8" type="checkbox" />
                                                                    <label class="cbx" for="gallery-8">

                                                                        <span>
                                                                            <i class="fa-solid fa-check "></i></span>

                                                                    </label></label>
                                                                <div>
                                                                    <!-- <span
                                                                    *ngIf="(imgGallery.AttachmentPath!=null)&& (imgGallery.AttachmentPath.split('.')[1]=='jpg' || imgGallery.AttachmentPath.split('.')[1]=='jpeg' || imgGallery.AttachmentPath.split('.')[1]=='iso' 
                                                    ||imgGallery.AttachmentPath.split('.')[1]=='png' ||imgGallery.AttachmentPath.split('.')[1]=='gif'
                                                    ||imgGallery.AttachmentPath.split('.')[1]=='docx'||imgGallery.AttachmentPath.split('.')[1]=='doc'||imgGallery.AttachmentPath.split('.')[1]=='pdf'
                                                    ||imgGallery.AttachmentPath.split('.')[1]=='mp3' ||imgGallery.AttachmentPath.split('.')[1]=='mp4'||imgGallery.AttachmentPath.split('.')[1]=='mpeg'||imgGallery.AttachmentPath.split('.')[1]=='pptx')"> -->
                                                                    <!-- 
                                                                    <a (click)="onSelectPicture(imgGallery.AttatchmentId)"
                                                                        target="_blank"
                                                                        *ngIf="imgGallery.SectionType=='IRBestPicture'"
                                                                        data-gall="myGallery">

                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:100px;height:80px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                    </a>

                                                                    <a (click)="onSelectPicture(imgGallery.AttatchmentId)"
                                                                        href="{{FinalInternshipImageBaseURL+imgGallery.AttachmentPath}}"
                                                                        target="_blank" data-gall="myGallery"
                                                                        *ngIf="imgGallery.SectionType=='IRCertificate'">
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">

                                                                    </a>

                                                                    <a (click)="onSelectPicture(imgGallery.AttatchmentId)"
                                                                        target="_blank"
                                                                        *ngIf="imgGallery.SectionType=='IRRportPhotos'"
                                                                        data-gall="myGallery">
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">

                                                                    </a> -->
                                                                    <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                        href="{{FinalInternshipImageBaseURL+imgGallery.AttachmentPath}}"
                                                                        target="_blank" data-gall="myGallery"
                                                                        *ngIf="imgGallery.SectionType !='DailyDiary'">
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">

                                                                    </a>

                                                                    <a href="{{imageAttachments+imgGallery.AttachmentPath}}"
                                                                        (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                        target="_blank" data-gall="myGallery"
                                                                        *ngIf="imgGallery.SectionType=='DailyDiary'">
                                                                        <img [src]="imageAttachments+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            *ngIf="imgGallery.AttachmentPath.split('.')[1]=='jpg' || imgGallery.AttachmentPath.split('.')[1]=='jpeg' || imgGallery.AttachmentPath.split('.')[1]=='iso' 
                                                                        ||imgGallery.AttachmentPath.split('.')[1]=='png' ||imgGallery.AttachmentPath.split('.')[1]=='gif'"
                                                                            class="w-100 h-80 object-fit-cover">


                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--  -->

                                        </div>
                                    </div>
                                    <!-- profile end -->

                                    <div class="image-selctor gallery" *ngIf="internship=='Internship'"
                                        style="overflow: auto;">
                                        <h1
                                            class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-30 line-h-30 mb-30">
                                            Internship Photos</h1>
                                        <div>
                                            <div *ngFor="let imgGalleryItem of galleryData1;">
                                                <div class="row px-0 mx-0">
                                                    <div class="col-3 px-1 mb-5px"
                                                        *ngFor="let imgGallery of imgGalleryItem.listAttachments; index as i">
                                                        <div class="selector-by-input">
                                                            <div class="select-the-div position-relative">
                                                                <label class="cstm-checkbox show-hide d-none  ">
                                                                    <input class="inp-cbx position-absolute"
                                                                        id="gallery-8" type="checkbox" />
                                                                    <label class="cbx" for="gallery-8">

                                                                        <span>
                                                                            <i class="fa-solid fa-check "></i></span>

                                                                    </label></label>
                                                                <div>
                                                                    <!-- <span
                                                                        *ngIf="(imgGallery.AttachmentPath!=null)&& (imgGallery.AttachmentPath.split('.')[1]=='jpg' || imgGallery.AttachmentPath.split('.')[1]=='jpeg' || imgGallery.AttachmentPath.split('.')[1]=='iso' 
                                                        ||imgGallery.AttachmentPath.split('.')[1]=='png' ||imgGallery.AttachmentPath.split('.')[1]=='gif'
                                                        ||imgGallery.AttachmentPath.split('.')[1]=='docx'||imgGallery.AttachmentPath.split('.')[1]=='doc'||imgGallery.AttachmentPath.split('.')[1]=='pdf'
                                                        ||imgGallery.AttachmentPath.split('.')[1]=='mp3' ||imgGallery.AttachmentPath.split('.')[1]=='mp4'||imgGallery.AttachmentPath.split('.')[1]=='mpeg'||imgGallery.AttachmentPath.split('.')[1]=='pptx')"> -->

                                                                    <!-- <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                        target="_blank"
                                                                        *ngIf="imgGallery.SectionType=='IRBestPicture'"
                                                                        data-gall="myGallery">


                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:100px;height:80px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">


                                                                    </a>


                                                                    <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                        href="{{FinalInternshipImageBaseURL+imgGallery.AttachmentPath}}"
                                                                        target="_blank" data-gall="myGallery"
                                                                        *ngIf="imgGallery.SectionType=='IRCertificate'">


                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">

                                                                    </a>

                                                                    <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                        target="_blank"
                                                                        *ngIf="imgGallery.SectionType=='IRRportPhotos'"
                                                                        data-gall="myGallery">

                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">

                                                                    </a> -->
                                                                    <!-- <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                        href="{{FinalInternshipImageBaseURL+imgGallery.AttachmentPath}}"
                                                                        target="_blank" data-gall="myGallery">


                                                                        <img [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">

                                                                    </a> -->
                                                                    <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType);"
                                                                    href="{{FinalInternshipImageBaseURL+imgGallery.AttachmentPath}}"
                                                                    target="_blank" data-gall="myGallery"
                                                                    *ngIf="imgGallery.SectionType !='DailyDiary'">

                                                                   
                                                                        <!-- <div class="container"> -->
                                                                        <img *ngIf="imgGallery.AttachmentType=='Image'||'image/png'"
                                                                            [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                                                            style="width:100px;height:80px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                        
                                                                        <!-- </div> -->
                                                                      

                                                                </a>

                                                                <a (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType);"
                                                                    href="{{imageAttachments+imgGallery.AttachmentPath}}"
                                                                    target="_blank" data-gall="myGallery"
                                                                    *ngIf="imgGallery.SectionType=='DailyDiary'">


                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='docx'||imgGallery.AttachmentPath.split('.')[1]=='doc'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div class="h-45 w-45 rounded-circle bg-theme-light-grey d-flex justify-content-center align-items-center"
                                                                            style="width:50px;height:50px; float:left; margin:15% 24% 15% 24%; padding: 1px;border: 2px solid #ebf3ff">
                                                                            <svg class="fill-color-7"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                height="24px" viewBox="0 0 24 24"
                                                                                width="24px" fill="#000000">
                                                                                <path d="M0 0h24v24H0V0z" fill="none">
                                                                                </path>
                                                                                <path
                                                                                    d="M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z">
                                                                                </path>
                                                                            </svg>

                                                                        </div>

                                                                    </div>

                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='pdf'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div class="h-45 w-45 rounded-circle bg-theme-light-grey d-flex justify-content-center align-items-center"
                                                                            style="width:50px;height:50px; float:left; margin:15% 24% 15% 24%; padding: 1px;border: 2px solid #ebf3ff">

                                                                            <div
                                                                                class="resources-extention h-80 w-100  d-flex justify-content-center align-items-center position-relative ">
                                                                                <div
                                                                                    class="h-45 w-45 rounded-circle bg-light-purple d-flex justify-content-center align-items-center">
                                                                                    <p class="mb-0 fs-24 fw-900"
                                                                                        style="color:red;">
                                                                                        P
                                                                                    </p>


                                                                                </div>


                                                                                <!-- <span
                                                                                    class="fs-11 color9 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600">pdf</span> -->

                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='mp3' ||imgGallery.AttachmentPath.split('.')[1]=='mp4'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <span
                                                                            class="fs-13 text-white mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600"><i
                                                                                class="fa-solid fa-circle-play"></i>
                                                                            <span class="video-timing">02:00</span>
                                                                        </span>
                                                                        <img class="w-100 h-80 object-fit-cover"
                                                                            src="assets/upload/vedio.png" alt=""
                                                                            srcset="">

                                                                    </div>

                                                                    <div style="width:50px;height:50px; "
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='ppt' || imgGallery.AttachmentPath.split('.')[1]=='pptx'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div
                                                                            class="resources-extention h-80 w-100 border-theme-2px d-flex justify-content-center align-items-center position-relative ">
                                                                            <div
                                                                                class="h-45 w-45 rounded-circle bg-light-purple d-flex justify-content-center align-items-center">
                                                                                <p class="mb-0 fs-24 fw-900 color9">PPT
                                                                                </p>


                                                                            </div>

<!-- 
                                                                            <span
                                                                                class="fs-11 color9 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600">ppt</span> -->

                                                                        </div>
                                                                    </div>

                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='xlsx'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div class="h-45 w-45 rounded-circle bg-theme-light-grey d-flex justify-content-center align-items-center"
                                                                            style="width:50px;height:50px; float:left; margin:15% 24% 15% 24%; padding: 1px;border: 2px solid #ebf3ff">
                                                                            <!-- <svg class="fill-color-7"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                height="24px" viewBox="0 0 24 24"
                                                                                width="24px" fill="#000000">
                                                                                <path d="M0 0h24v24H0V0z" fill="none">
                                                                                </path>
                                                                                <path
                                                                                    d="M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z">
                                                                                </path>
                                                                            </svg> -->

                                                                            <div
                                                                                class="h-45 w-45 rounded-circle bg-light-purple d-flex justify-content-center align-items-center">
                                                                                <p class="mb-0 fs-24 fw-900"
                                                                                    style="color:#1D6F42">
                                                                                    X
                                                                                </p>


                                                                            </div>


                                                                            <!-- <span
                                                                                class="fs-11 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600" style="color:#1D6F42">exl</span> -->

                                                                        </div>

                                                                    </div>

                                                                  
                                                                        <img [src]="imageAttachments+imgGallery.AttachmentPath"
                                                                            style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                            *ngIf="imgGallery.AttachmentPath.split('.')[1]=='jpg' || imgGallery.AttachmentPath.split('.')[1]=='jpeg' || imgGallery.AttachmentPath.split('.')[1]=='iso' 
                                                                        ||imgGallery.AttachmentPath.split('.')[1]=='png' ||imgGallery.AttachmentPath.split('.')[1]=='gif'"
                                                                            class="w-100 h-80 object-fit-cover">
                                                                      

                                                                     


                                                                </a>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <!-- intership end -->

                                    <div class="image-selctor gallery" *ngIf="docs=='Docs'">
                                        <h1
                                            class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-30 line-h-30 mb-30">
                                            Docs</h1>
                                        <div *ngFor="let imgGalleryItem of galleryData1;">
                                            <div class="row px-0 mx-0">
                                                <div class="px-1 mb-5px"
                                                    *ngFor="let imgGallery of imgGalleryItem.listAttachments;"
                                                    style="width:20%;float:left;">

                                                    <div class="selector-by-input">
                                                        <div class="select-the-div position-relative">
                                                            <label class="cstm-checkbox show-hide d-none  ">
                                                                <input class="inp-cbx position-absolute" id="gallery-8"
                                                                    type="checkbox" />
                                                                <label class="cbx" for="gallery-8">

                                                                    <span>
                                                                        <i class="fa-solid fa-check "></i></span>

                                                                </label></label>
                                                            <div>
                                                                <a href="{{imageAttachments+imgGallery.AttachmentPath}}"
                                                                    (click)="onSelectPicture(imgGallery.AttatchmentId,imgGallery.AttachmentType)"
                                                                    target="_blank" data-gall="myGallery">
                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='docx'||imgGallery.AttachmentPath.split('.')[1]=='doc'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div class="h-45 w-45 rounded-circle bg-theme-light-grey d-flex justify-content-center align-items-center"
                                                                            style="width:50px;height:50px; float:left; margin:15% 24% 15% 24%; padding: 1px;border: 2px solid #ebf3ff">
                                                                            <svg class="fill-color-7"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                height="24px" viewBox="0 0 24 24"
                                                                                width="24px" fill="#000000">
                                                                                <path d="M0 0h24v24H0V0z" fill="none">
                                                                                </path>
                                                                                <path
                                                                                    d="M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z">
                                                                                </path>
                                                                            </svg>

                                                                        </div>

                                                                    </div>

                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='pdf'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div class="h-45 w-45 rounded-circle bg-theme-light-grey d-flex justify-content-center align-items-center"
                                                                            style="width:50px;height:50px; float:left; margin:15% 24% 15% 24%; padding: 1px;border: 2px solid #ebf3ff">

                                                                            <div
                                                                                class="resources-extention h-80 w-100  d-flex justify-content-center align-items-center position-relative ">
                                                                                <div
                                                                                    class="h-45 w-45 rounded-circle bg-light-purple d-flex justify-content-center align-items-center">
                                                                                    <p class="mb-0 fs-24 fw-900"
                                                                                        style="color:red;">
                                                                                        P
                                                                                    </p>


                                                                                </div>


                                                                                <!-- <span
                                                                                    class="fs-11 color9 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600">pdf</span> -->

                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='mp3' ||imgGallery.AttachmentPath.split('.')[1]=='mp4'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <span
                                                                            class="fs-13 text-white mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600"><i
                                                                                class="fa-solid fa-circle-play"></i>
                                                                            <span class="video-timing">02:00</span>
                                                                        </span>
                                                                        <img class="w-100 h-80 object-fit-cover"
                                                                            [src]="imageAttachments+imgGallery.AttachmentPath"
                                                                            alt="" srcset="">

                                                                    </div>

                                                                    <div style="width:50px;height:50px; "
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='ppt' || imgGallery.AttachmentPath.split('.')[1]=='pptx'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div
                                                                            class="resources-extention h-80 w-100 border-theme-2px d-flex justify-content-center align-items-center position-relative ">
                                                                            <div
                                                                                class="h-45 w-45 rounded-circle bg-light-purple d-flex justify-content-center align-items-center">
                                                                                <p class="mb-0 fs-24 fw-900 color9">PPT
                                                                                </p>


                                                                            </div>


                                                                            <!-- <span
                                                                                class="fs-11 color9 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600">ppt</span> -->

                                                                        </div>
                                                                    </div>

                                                                    <div style="width:50px;height:50px; float:left; margin:1px; padding: 1px;border: 2px solid #ebf3ff"
                                                                        *ngIf="imgGallery.AttachmentPath.split('.')[1]=='xlsx'"
                                                                        class="w-100 h-80 object-fit-cover">
                                                                        <div class="h-45 w-45 rounded-circle bg-theme-light-grey d-flex justify-content-center align-items-center"
                                                                            style="width:50px;height:50px; float:left; margin:15% 24% 15% 24%; padding: 1px;border: 2px solid #ebf3ff">
                                                                            <!-- <svg class="fill-color-7"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                height="24px" viewBox="0 0 24 24"
                                                                                width="24px" fill="#000000">
                                                                                <path d="M0 0h24v24H0V0z" fill="none">
                                                                                </path>
                                                                                <path
                                                                                    d="M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z">
                                                                                </path>
                                                                            </svg> -->

                                                                            <div
                                                                                class="h-45 w-45 rounded-circle bg-light-purple d-flex justify-content-center align-items-center">
                                                                                <p class="mb-0 fs-24 fw-900"
                                                                                    style="color:#1D6F42">
                                                                                    X
                                                                                </p>


                                                                            </div>


                                                                            <!-- <span
                                                                                class="fs-11 mb-0 position-absolute start-0 bottom-0 d-block p-1 line-h-0 pb-2 fw-600" style="color:#1D6F42">exl</span> -->

                                                                        </div>

                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    <!-- docs end -->
                                    <!-- </div> -->



                                    <div class="">
                                        <button (click)="onSelectRemove()"
                                            class="d-block px-3 color1 text-center max-w-400 border-radius-15 mx-auto mt-35 w-100 py-2 bg-light-theme fw-900 mb-5 theme-btn line-h-30 border-2 text-uppercase max-w-300 click-to-remover">Remove
                                            From Profile</button>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </div>
        </div>
    </div>

</section>