<section class="mb-lg-0 mb-60">
    <div class="container">
        <div class="content-section mt-0 pb-4">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu-emp></igmite-side-menu-emp>

                </div>

                <div class="col-12 col-lg-10 right-content px-lg-35 py-15 pb-5 bg-white px-10">
                    <div class="row px-2 mx-0 align-items-lg-center max-w-800 mx-auto">



                        <div class="col-12 px-0 text-center">

                            <div
                                class="bg-theme-light-grey top-circle overflow-hidden mt-lg-0 mx-auto d-flex justify-content-center align-items-center">

                                <svg xmlns="http://www.w3.org/2000/svg" height="45" class="fill-color-1" width="45">
                                    <path
                                        d="M7 42q-1.2 0-2.1-.9Q4 40.2 4 39V15q0-1.2.9-2.1.9-.9 2.1-.9h9V7q0-1.2.9-2.1.9-.9 2.1-.9h10q1.2 0 2.1.9.9.9.9 2.1v5h9q1.2 0 2.1.9.9.9.9 2.1v24q0 1.2-.9 2.1-.9.9-2.1.9Zm12-30h10V7H19Z" />
                                </svg>




                            </div>

                        </div>




                    </div>


                    <p class="fs-22 text-black fw-900  line-h-22 mt-25 mb-20 px-10 text-center"> Past internship
                    </p>

                    <div class="max-w-800 w-100 mx-auto px-0 mb-5" id="ongoing-internships"
                        *ngFor="let pastInternships of pastInternshipData;index as i;">



                        <div class="border-theme-2px border-radius-30 overflow-hidden">
                            <div class="px-15 py-15 bg-light-theme2 border-radius-30">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p class="fs-16 text-black fw-600 mb-0 fw-900">{{pastInternships.Title}}</p>
                                        <p class="fs-13 fw-400 text-black mb-0">{{pastInternships.StartDate| date:"dd
                                            MMM yyyy"}}</p>
                                        <p class="fs-13 fw-400 text-black mb-0">
                                            Working Interns
                                            <!-- (2) -->
                                            -
                                            <span
                                                *ngIf="pastInternships.PastInternshipsStrudentList !=null">{{pastInternships.PastInternshipsStrudentList.length}}</span>
                                            <span *ngIf="pastInternships.PastInternshipsStrudentList ==null">0</span>
                                        </p>
                                    </div>

                                    <a class="ml-auto d-block"
                                        (click)="internshipDetails(pastInternships.InternshipId)">
                                        <i
                                            class="fa-solid fa-chevron-right cursor-pointer rounded-button theme-btn upto-down-gradient-clr-1 text-white fs-14 h-35 w-35"></i>
                                    </a>
                                </div>
                            </div>

                            <div class="px-10 py-15" style="height:200px; overflow-y:auto;" [attr.id]="'item-' + i">
                                <div *ngFor="let student of pastInternships.CurrentPastInternshipsStrudents">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div
                                            class="ranker-name-profile d-flex max-w-200  max-lg-100 w-100 align-items-center">
                                            <img [src]="ApiBaseUrlImage + student.ProfilePhoto"
                                                class="rounded-circle border-theme-2px mr-10" alt="" srcset=""
                                                width="40" height="40">
                                            <div>
                                                <p class="fs-17 fw-900 text-black mb-0 line-h-25 mt-0">
                                                    {{student.StudentName}}
                                                </p>
                                                <p class="fs-14 fw-600 color1 mb-0 mt-0">
                                                    {{student.StudentMobile}}</p>
                                            </div>

                                        </div>
                                        <a (click)="callStudentId(student.StudentId)" class="ml-auto d-block">
                                            <i
                                                class="fa-solid fa-chevron-right cursor-pointer rounded-circle d-flex justify-content-center align-items-center theme-btn bg-light-theme color1 fs-14 h-35 w-35 shadow-none"></i>
                                        </a>

                                    </div>

                                    <div class="row mx-0 px-0">

                                        <a class="col-4 py-10 px-10">
                                            <p class="fs-13 fw-400 mt-1 text-black mb-0">Attendance</p>
                                            <strong
                                                class="fs-16 line-h-15 fw-900 text-black ">{{student.TotalAttendance}}
                                                D</strong>
                                        </a>

                                        <a [routerLink]="['/attendance-v2']" class="col-4 py-10 px-10 ">

                                            <p class="fs-13 fw-400 mt-1 text-black mb-0">Internship</p>

                                            <strong class="fs-16 line-h-15 fw-900 text-black ">{{student.Totalhours
                                                |number : '1.2-2'}}
                                                H</strong>

                                        </a>

                                        <a [routerLink]="['/attendance-v2']" class="col-4 py-10 px-10 ">

                                            <p class="fs-13 fw-400 mt-1 text-black mb-0">Diary Entry</p>

                                            <strong class="fs-16 line-h-15 fw-900 text-black "> {{student.Totalhours /
                                                80 *100 | number}}%</strong>

                                        </a>

                                    </div>
                                </div>
                                <div class="border-end-0 border-top-0 border-start-0 border-theme-2px mb-25 mt-20">
                                </div>

                                <button (click)="viewMoreInternships(pastInternships)"
                                    class="fs-14 fw-900 color1 d-block text-center bg-none border-0 mx-auto text-center mt-10 mb-5px">View
                                    More</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>