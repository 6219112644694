<section class="mb-lg-0 mb-20">
    <div class="container">
        <div class="content-section mt-0">
            <div class="px-lg-35 px-10 border-radius-30  pb-3 bg-white py-18 pb-5 overflow-hidden max-w-800 mx-auto">
                <section class="mt-20 position-relative mb-0">
                    <div>
                        <img src="assets/upload/reset-circle.png" class="img-fluid d-block text-center mx-auto"
                            alt="Reset Password" srcset="">
                        <p class="fs-22 text-black fw-800 text-center  line-h-22 mt-15 mb-15"> Reset Password </p>
                    </div>

                    <div class="px-20 py-20 mt-40 text-black bg-light-theme border-radius-20">
                        <p class="fs-14 fw-500 text-black mb-0">We will send an one time password (OTP) on your
                            registered email ID and mobile number.</p>
                    </div>

                    <div class="border-theme-2px px-15 border-radius-20 py-40 mt-30 mb-5">
                        <div class="mb-15">
                            <p class="fs-16 text-black mb-0 px-1">Enter Registered Email ID</p>
                            <form name="loginForm" novalidate [formGroup]="loginForm">
                                <div class="input-with-icon position-relative">
                                    <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                        <!-- <mat-label >User Id</mat-label> -->
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            class="fill-color-2 ml-10 start-0 start-0 position-absolute translate-middle-y top-50"
                                            height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                                d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z">
                                            </path>
                                        </svg>
                                        <input matInput name="emailID" formControlName="EmailID"
                                            class="border-radius-10 border-theme-2px fs-18 py-2 w-100 px-5" required>
                                        <mat-error *ngIf="loginForm.controls.EmailID.invalid" >
                                            {{getErrorMessage(loginForm, 'EmailID')}}
                                        </mat-error>
                                    </mat-form-field>
          
                                </div>
                                
                            </form>
                        </div>
                        <button  class="d-block px-3 text-white max-w-180 text-uppercase border-radius-15 mx-auto mt-50 w-100 py-2 upto-down-gradient-clr-1 theme-btn" [disabled]="loginForm.invalid" (click)="SendOTPEmail()">
                            Continue
                    </button>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>