import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { InterestsService } from './interests.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';


import { InterestsModel } from './interests.model';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';


@Component({
  providers: [AppConstants],
  selector: 'igmite-interests',
  templateUrl: './interests.component.html',
  styleUrls: ['./interests.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class InterestsComponent extends BaseListComponent<InterestsModel> implements OnInit {
  public interestsForm: FormGroup;
  public loginModel: LoginModel;
  public interestsModel: InterestsModel;

  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  selectedName: any = []
  InterestsData: any
  selectedId: any = [];
  selectedData: any = [];
  toggle = true;
  toggleLog = false;
  checked: boolean = false
  interestsByStudent: any

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private interestsService: InterestsService,
    private dialogService: DialogService,
    public zone: NgZone,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);


    this.interestsModel = new InterestsModel();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };

    const firstTime = localStorage.getItem('keyAll')
    if (!firstTime) {
      localStorage.setItem('keyAll', 'loaded')
      window.location.reload();

    } else {
      localStorage.removeItem('keyAll')
    }

  }

  ngOnInit(): void {
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');
    });

    let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
    employeeMenuItems.forEach(employeeMenuItems => {
      employeeMenuItems.setAttribute('style', 'display: none !important');
    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    this.GetInterestsByStudentId()

    this.interestsForm = this.formBuilder.group({ filterText: '' });
    this.SearchBy.PageIndex = 0; // delete after script changed
    this.SearchBy.PageSize = 10; // delete after script changed

    this.interestsForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.SearchBy.PageIndex = 0;

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.interests();
          // this.selectedId = []
          return false;
        }

        return res.length > 1
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.interests();
      // this.selectedId = []

    });



  }

  GetInterestsByStudentId() {
    var proId = { DataId: this.UserModel.UserTypeId }
    this.interestsService.GetInterestsByStudentId(proId).subscribe((response: any) => {
      this.interestsByStudent = response.Results

      this.interests();

      this.IsLoading = false;
    }, error => {
    });
  }

  interests() {
    this.interestsService.GetInterestsByCriteria(this.SearchBy).subscribe(response => {
      this.InterestsData = response.Results
      if (this.selectedId.length != 0) {
        this.InterestsData.map(item => {
          this.selectedId.map(set => {
            if (item.InterestId === set.InterestGuidList) {
              item.isChecked = true;

            } else {
              if (item.isChecked !== true) {
                item.isChecked = false
              }

            }

          })
        })

      } else {
        if (this.interestsByStudent.length === 0) {
          this.InterestsData.map(item => {
            item.isChecked = false
          })

        } else {

          this.InterestsData.map(item => {
            this.interestsByStudent.map(set => {
              if (item.InterestId === set.InterestId) {

                item.isChecked = true;

                this.selectedId.push(
                  {
                    InterestGuidList: item.InterestId
                  })

              } else {
                if (item.isChecked !== true) {
                  item.isChecked = false
                }

              }

            })
          })
        }
      }



      this.IsLoading = false;
    }, error => {
    });

  }

  onForInterestId(item) {

    if (item.isChecked == false) {
      if (this.selectedId.length == 3) {
        this.showActionMessage(
          'Interests -Select up to 3 ',
          this.Constants.Html.SuccessSnackbar
        );
      } else {
        item.isChecked = true
        this.selectedId.push({ InterestGuidList: item.InterestId })

      }

    }
    else if (item.isChecked == true) {

      item.isChecked = false
      let ind = this.selectedId.indexOf(item)
      this.selectedId.splice(ind, 1);
    }


  }






  onInterestId(idData) {
    this.selectedData = []
    idData.map(item => {
      if (item.isChecked == true) {
        this.selectedData.push({ InterestGuidList: item.InterestId })
      }

    })
    let selectedIdTostring: any;
    selectedIdTostring = this.selectedData.map((s: any) => s.InterestGuidList).toString();
    this.interestsModel.InterestGuidList = selectedIdTostring;
    this.interestsModel.AuthUserId = this.UserModel.UserTypeId;
    this.interestsModel.StudentId = this.UserModel.UserTypeId;

    this.dialogService
      .openConfirmDialog('Are you sure if you want to add the selected interests to your profile ?')
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.interestsService.CreateInterest(this.interestsModel)
            .subscribe((interestsResp: any) => {
              this.router.navigateByUrl(RouteConstants.OpprtunitiesListing);


              if (interestsResp.Success) {
                this.zone.run(() => {
                  this.showActionMessage(
                    this.Constants.Messages.RecordSavedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                  // this.selectedId = [];
                });
              }
              else {
                var errorMessages = this.getHtmlMessage(interestsResp.Errors)
                this.dialogService.openShowDialog(errorMessages);
              }
            }, error => {
            });

        }
      });




  }


}
