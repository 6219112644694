import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { BaseListComponent } from 'app/common/base-list/base.list.component';


import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { debug } from 'console';
import { InternshipListingMobileV2Model } from '../internship-listing-mobile-v2/internship-listing-mobile-v2.model';
import { GratitudeListingService } from './gratitude-listing.service';
import { GratitudeListingModel } from './gratitude-listing.model';


@Component({
  providers: [AppConstants],
  selector: 'igmite-gratitude-listing',
  templateUrl: './gratitude-listing.component.html',
  styleUrls: ['./gratitude-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class GratitudeListingComponent extends BaseListComponent<GratitudeListingModel> implements OnInit {
  public interestsForm: FormGroup;
  public loginModel: LoginModel;
  public gratitudeListingModel: GratitudeListingModel;

  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  selectedName: any = []
  InterestsData: any
  selectedId: any = [];
  selectedData: any = [];
  toggle = true;
  toggleLog = false;
  checked: boolean = false
  interestsByStudent: any
  pastInternshipData: any;
  gratituteListingData: any;
  //gratituteListingData = [] as any;
  ApiBaseUrlImage: any;
  InternshipStartDate: any;
  workingInterns: number;
  viewMoreIndex = 0;
  gratituteListArray = [];
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private gratitudeListingService: GratitudeListingService,
    private dialogService: DialogService,
    public zone: NgZone,
    public formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);


    this.gratitudeListingModel = new GratitudeListingModel();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


  }

  ngOnInit(): void {
this.ApiBaseUrlImage=this.Constants.CommonImageUrl.Account
    let elementIn1: any = document.querySelectorAll(".withoutlogin");
    elementIn1.forEach(elementIn1 => {
      elementIn1.setAttribute('style', 'display: none !important');

    });

    let elementIn2: any = document.querySelectorAll(".withinlogin");
    elementIn2.forEach(elementIn2 => {
      elementIn2.setAttribute('style', 'display: none !important');

    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }
    this.getVTApprovedEmployerGratitudeByCriteria();
  }

  getVTApprovedEmployerGratitudeByCriteria() {
    // "903a3712-4f5a-4f61-80c1-3259afae263a" this.UserModel.UserTypeId
    this.gratitudeListingService.getVTApprovedEmployerGratitudeByCriteria(this.UserModel.EmployerId, 0, 10000).subscribe(response => {
      this.gratituteListingData = response.Results;

      // for (let i = 0; i < 3; i++) {
      //   this.gratituteListArray.push(this.gratituteListingData[0]);

      // }
    });
  }

}
