import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginModel } from 'app/models/login.model';
import { UserModel } from 'app/models/user.model';
import { AppConstants } from 'app/app.constants';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { OpprtunitiesListingService } from './opprtunities-listing.service';
import { OpprtunitiesListingModel } from './opprtunities-listing.model';

import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DateService } from 'app/common/date-dialog/date.service';
import { DatePipe } from '@angular/common'


@Component({
  providers: [AppConstants],
  selector: 'igmite-opprtunities-listing',
  templateUrl: './opprtunities-listing.component.html',
  styleUrls: ['./opprtunities-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class OpprtunitiesListingComponent extends BaseListComponent<OpprtunitiesListingModel> implements OnInit {
  public interestsForm: FormGroup;

  public loginModel: LoginModel;
  public opprtunitiesListingModel: OpprtunitiesListingModel;
  public returnUrl: string;
  public isVisiblePassword: boolean = false;
  private deviceInfo: any;
  public appInfo = environment;
  tabActive = 'Internships'
  InterestsData: any
  Interest: any
  drop: boolean
  filterOpen: boolean = false
  AppliedData: any
  statusData: any

  tabView: boolean = false
  InterestsDetails: any
  filterDetails: any
  InternshipById: any
  listDiaryEntry: any
  IsDailyDiaryExist: boolean = false
  allowedData: any
  public messageItems: any = {};
  dateUpdatedBystudent: any;
  allStatus: boolean = false


  PendingData: any;
  CompletedData: any;
  ReportReviewedData: any;
  DroppedOutData: any;
  InProgressData: any;

  SortedData= [] as any;
  mDate: any

  public DefaultImageUrl: string = "../assets/images/no-pic2.jpg";
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private fuseConfigService: FuseConfigService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private opprtunitiesListingService: OpprtunitiesListingService,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private dateService: DateService,) {
    super(commonService, router, routeParams, snackBar, zone);
    this.opprtunitiesListingModel = new OpprtunitiesListingModel();
    // Configure the Login layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },

        sidepanel: {
          hidden: true
        }
      }
    };


    const firstTime = localStorage.getItem('key')
    if (!firstTime) {
      localStorage.setItem('key', 'loaded')
      window.location.reload();

    } else {
      localStorage.removeItem('key')
    }

  }



  ngOnInit(): void {
    let elementIn: any = document.querySelectorAll(".withoutlogin");
    elementIn.forEach(elementIn => {
      elementIn.setAttribute('style', 'display: none !important');
    });

    let employeeMenuItems: any = document.querySelectorAll(".within-employee-login");
    employeeMenuItems.forEach(employeeMenuItems => {
      employeeMenuItems.setAttribute('style', 'display: none !important');
    });

    if (this.UserModel.PhotoUrl != null) {
      let userProfileImage: any = document.querySelectorAll(".üser-profile-image");
      userProfileImage[0].setAttribute('src', this.Constants.CommonImageUrl.Account + this.UserModel.PhotoUrl);
    } else {
      this.UserModel.PhotoUrl = ''
    }

    var Inter = JSON.parse(sessionStorage.getItem('item'));
    if (Inter !== null) {
      this.InternshipById = Inter

    } else {
      this.InternshipById = this.UserModel.InternshipId
    }
    this.opprtunitiesListingService.GetInternship().subscribe((response: any) => {
      this.Interest = response.Results
      this.IsLoading = false;
    }, error => {
    });
    this.drop = false
    this.interestsForm = this.createinterestsForm();
    this.GetStudentsInternshipByCriteria()

    this.opprtunitiesListingService.GetInterestsByCriteria(this.SearchBy).subscribe(response => {
      this.filterDetails = response.Results
      this.IsLoading = false;
    }, error => {
    });

    var proId = { DataId: this.UserModel.UserTypeId }

    this.opprtunitiesListingService.GetStudentDashboard(proId).subscribe((dashboardResp: any) => {
      if (dashboardResp.Success == true) {
        this.zone.run(() => {
          this.listDiaryEntry = dashboardResp.Results[0]
          if (this.listDiaryEntry.Attendance > 0) {
            this.IsDailyDiaryExist = true
          }
          this.IsLoading = false;
          // this.showActionMessage(
          //   this.Constants.Messages.RecordSavedMessage,
          //   this.Constants.Html.SuccessSnackbar
          // );
        });

      }
      else {
        // this.showErrorMessage(
        //   'Invalid GetStudentDashboard ',
        //   'info-snackbar'
        // );
      }


    }, error => {
    });

  }

  createinterestsForm(): FormGroup {
    return this.formBuilder.group({
      InterestId: new FormControl('', [Validators.required]),
      InternShipType: new FormControl(''),
      InternshipTiming: new FormControl(''),
      AccountID: new FormControl(this.UserModel.UserTypeId),
    });
  }

  dropDown() {
    this.drop = !this.drop
    this.filterOpen = !this.filterOpen
    if (this.filterOpen === false) {
      this.GetStudentsInternshipByCriteria()
    }
  }

  toggle(tab) {
    this.tabActive = tab;
    if (tab === 'Internships') {
      this.GetStudentsInternshipByCriteria()
    } else {
      this.GetStudentInternshipsByCriteria()
    }

  }

  viewBox(item) {
    console.log(item)
    this.tabView = !this.tabView
    if (item.isChecked == false) {
      item.isChecked = true
    }
    else if (item.isChecked == true) {
      item.isChecked = false
    }

  }



  GetStudentsInternshipByCriteria() {
    var data = { DataId: this.UserModel.UserTypeId }
    this.opprtunitiesListingService.GetStudentsInternshipByCriteria(data).subscribe((response: any) => {
      this.InterestsData = response.Results
      this.InterestsData.map(item => {
        item.isChecked = false
        // item.EmpPhotoUrl = this.Constants.CommonImageUrl.Account+item.EmpPhotoUrl ;


      })

      this.IsLoading = false;
    }, error => {
    });

  }

  GetStudentInternshipsByCriteria() {
    var data = { DataId: this.UserModel.UserTypeId }
    this.opprtunitiesListingService.GetStudentInternshipsByCriteria(data).subscribe((response: any) => {
      this.AppliedData = response.Results

      this.SortedData=[]
  

      this.AppliedData.map(item => {
        if (((item.IsApprovedByVT == false) || (item.IsApprovedByEmployer == false)) || (item.IsPlaced != true)) {
          item.TypeStatus = 'Pending'


        } else if ((item.IsCompleted != null) && (item.IsCompleted != false)) {
          item.TypeStatus = 'Completed'


        } else if ((item.IsIRReviewed != null) && (item.IsIRReviewed != false)) {
          item.TypeStatus = 'Report Reviewed'

        } else if (item.DateOfDropout != null) {
          item.TypeStatus = 'Dropped Out'

        } else if ((item.IsPlaced != null) && (item.IsPlaced != false) && (item.IsCompleted == null || item.IsCompleted == false) && item.DateOfDropout == null && (item.IsIRReviewed == null || item.IsIRReviewed == false)) {
          item.TypeStatus = 'In Progress'
        }


      })

      // this.InProgressData = this.AppliedData.filter(x => x.IsPlaced != null && x.IsPlaced != false && (x.IsCompleted == null || x.IsCompleted == false) && x.DateOfDropout == null && (x.IsIRReviewed == null || x.IsIRReviewed == false));
      // this.PendingData = this.AppliedData.filter(x => x.IsApprovedByVT == false || x.IsApprovedByEmployer == false || x.IsPlaced != true);
      // this.CompletedData = this.AppliedData.filter(x => ((x.IsCompleted != null) && (x.IsCompleted != false)));
      // this.ReportReviewedData = this.AppliedData.filter(x => ((x.IsIRReviewed != null) && (x.IsIRReviewed != false)));
      // this.DroppedOutData = this.AppliedData.filter(x => x.DateOfDropout != null);

      this.InProgressData = this.AppliedData.filter(x => x.TypeStatus == 'In Progress');
      this.PendingData = this.AppliedData.filter(x => x.TypeStatus == 'Pending');
      this.CompletedData = this.AppliedData.filter(x => x.TypeStatus == 'Completed');
      this.ReportReviewedData = this.AppliedData.filter(x => x.TypeStatus == 'Report Reviewed');
      this.DroppedOutData = this.AppliedData.filter(x => x.TypeStatus == 'Dropped Out');
     

    
      this.InProgressData.forEach(item => {
        // item.TypeStatus = 'In Progress';
        this.SortedData.push(item);
      });

      this.PendingData.forEach(item => {
        // item.TypeStatus = 'Pending';
        this.SortedData.push(item);
      });
   

      this.CompletedData.forEach(item => {
        // item.TypeStatus = 'Completed';
        this.SortedData.push(item);
      });
   


      this.ReportReviewedData.forEach(item => {
        // item.TypeStatus = 'Report Reviewed';
        this.SortedData.push(item);
      });
   

      this.DroppedOutData.forEach(item => {
        // item.TypeStatus = 'Dropped Out';
        this.SortedData.push(item);
      });
    
      this.SortedData.forEach(item => {
        if (item.IsPlaced == true && item.IsCompleted != true && item.DateOfDropout == null) {
          this.allStatus = true

        }
      }) 
  

        // this.AppliedData.map(item => {
      //   if (item.IsPlaced == true && item.IsCompleted != true && item.DateOfDropout == null) {
      //     this.allStatus = true

      //   }
      // })
     
    

      this.IsLoading = false;
    }, error => {
    });

  }

  interestsFilter() {
    this.opprtunitiesListingService.GetStudentsInternshipByFilter(this.interestsForm.value).subscribe(response => {
      this.InterestsData = response.Results
      this.IsLoading = false;
    }, error => {
    });

  }

  GetInternshipById(id: any) {
    var data = { DataId: id }
    this.opprtunitiesListingService.GetInternshipById(data).subscribe((response: any) => {
      this.InterestsDetails = response.Result
      this.IsLoading = false;
    }, error => {
    });
  }
  onClickAddNewCollege(item) {
    this.dateService
      .openConfirmDialog(item)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.dateUpdatedBystudent = confResp.startDate.value.StartDate;
          var data1 = {
            "DataId": this.UserModel.UserTypeId,
            "DataId1": item.InternshipId,

          }



          var data = {
            InternshipId: item.InternshipId,
            StudentId: this.UserModel.UserTypeId,
            IsApplied: true,
            IsPlaced: true,
            IsApprovedByVT: true,
            IsApprovedByEmployer: true,
            AuthUserId: this.UserModel.UserTypeId,
            ActualStartDate: this.dateUpdatedBystudent
          }

          this.UpdateStudentInternshipsStatus(data)
          this.allowDashboard(data1)

        }
      });

  }

  UpdateStudentInternshipsStatus(item) {

    // var data = {
    //   InternshipId: item.InternshipId,
    //   StudentId: this.UserModel.UserTypeId,
    //   IsApplied: true,
    //   IsPlaced: true,
    //   IsApprovedByVT: true,
    //   IsApprovedByEmployer: true,
    //   AuthUserId: this.opprtunitiesListingModel.AuthUserId,
    //   ActualStartDate:this.dateUpdatedBystudent
    // }
    this.opprtunitiesListingService.UpdateStudentInternshipsStatus(item).subscribe((response: any) => {
      sessionStorage.setItem('item', JSON.stringify(item.InternshipId));
      var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      currentUser.InternshipActualStartDate = item.ActualStartDate
      currentUser.InternshipId = item.InternshipId
      sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
      if (response.Success == true) {
        this.zone.run(() => {
          this.showActionMessage(
            this.Constants.Messages.RecordSavedMessage,
            this.Constants.Html.SuccessSnackbar
          );
        });
        this.router.navigateByUrl(RouteConstants.Congratulation);
      }
      else {
        this.showErrorMessage(
          response.Errors[0],
          'info-snackbar'
        );
      }
    });
  }

  allowDashboard(data1) {
    this.opprtunitiesListingService.allowDashboard(data1).subscribe((response: any) => {
      this.allowedData = response.Result
      sessionStorage.setItem('allow', JSON.stringify(this.allowedData));

    }, error => {
    });
  }


  CreateStudentInternships(item) {
    var date = new Date();
    this.mDate =this.datepipe.transform(date, 'yyyy-MM-dd');
   
    var date2 =this.datepipe.transform(item.StartDate, 'yyyy-MM-dd');

    if(date2 > this.mDate){
      this.opprtunitiesListingModel.PlannedStartDate = item.StartDate;
      this.opprtunitiesListingModel.ActualStartDate = item.StartDate;

    }else{
      this.opprtunitiesListingModel.PlannedStartDate = this.mDate;
      this.opprtunitiesListingModel.ActualStartDate = this.mDate;
    }
    this.opprtunitiesListingModel.AuthUserId = this.UserModel.UserTypeId;
    this.opprtunitiesListingModel.RequestType = this.opprtunitiesListingModel.RequestType;
    this.opprtunitiesListingModel.IsActive = this.opprtunitiesListingModel.IsActive;
    this.opprtunitiesListingModel.InternshipId = item.InternshipId;
    this.opprtunitiesListingModel.StudentId = this.UserModel.UserTypeId;
    this.opprtunitiesListingModel.SectorId = item.SectorId;
    this.opprtunitiesListingModel.JobRoleId = item.JobRoleId;
    this.opprtunitiesListingModel.Stream = item.Stream;
    this.opprtunitiesListingModel.InternshipAgreed = this.opprtunitiesListingModel.InternshipAgreed;
    this.opprtunitiesListingModel.DoneInternshipEarlier = this.opprtunitiesListingModel.DoneInternshipEarlier;
    this.opprtunitiesListingModel.PlannedEndDate = null;
    this.opprtunitiesListingModel.ActualEndDate = null;
    this.opprtunitiesListingModel.EmployerId = item.EmployerId;
    this.opprtunitiesListingModel.BranchId = item.BranchId;
    this.opprtunitiesListingModel.BranchName = item.BranchName;
    this.opprtunitiesListingModel.IsApplied = this.opprtunitiesListingModel.IsApplied;
    this.opprtunitiesListingModel.IsPlaced = this.opprtunitiesListingModel.IsPlaced;
    this.opprtunitiesListingModel.IsApprovedByVT = this.opprtunitiesListingModel.IsApprovedByVT;
    this.opprtunitiesListingModel.IsApprovedByEmployer = this.opprtunitiesListingModel.IsApprovedByEmployer;
    this.opprtunitiesListingModel.VTId = item.VTId;
    this.opprtunitiesListingModel.Remarks = this.opprtunitiesListingModel.Remarks;
    this.opprtunitiesListingService.CreateStudentInternships(this.opprtunitiesListingModel).subscribe((response: any) => {
      if (response.Success == true) {
        this.tabActive = 'Applied';
        this.GetStudentInternshipsByCriteria()
        this.zone.run(() => {
          this.showActionMessage(
            this.Constants.Messages.RecordSavedMessage,
            this.Constants.Html.SuccessSnackbar
          );
        });
      }
      else {
        this.showErrorMessage(
          // 'Invalid Student Internships ',
          response.Errors[0],
          'info-snackbar'
        );
      }
    });
  }





  showErrorMessage(messageText: string, messageType: string) {
    this.snackBar.open(messageText, "Dismiss", {
      duration: 2000,
      verticalPosition: "bottom",
      horizontalPosition: "center",
      panelClass: [messageType]
    });
  }

}
