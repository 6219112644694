<section class="">
    <div class="container">

        <div class="content-section mt-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <span *ngIf="(roleCodeST==true)"><igmite-side-menu></igmite-side-menu></span>
                    <span *ngIf="(roleCodeEMP==true)"><igmite-side-menu-emp></igmite-side-menu-emp></span>

                    <div class="fixed-left-navigation" *ngIf="(withoutLoginContactUs==true)">
                        <ul>
                            <li>
                                <a class="active fs-16" style="cursor: pointer;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="fill-color-1"
                                        height="48" width="48">
                                        <path
                                            d="M25.2 16.7V6.2Q25.2 5.25 25.85 4.6Q26.5 3.95 27.45 3.95H41.8Q42.75 3.95 43.4 4.6Q44.05 5.25 44.05 6.2V16.7Q44.05 17.7 43.4 18.35Q42.75 19 41.8 19H27.45Q26.5 19 25.85 18.35Q25.2 17.7 25.2 16.7ZM3.9 24.05V6.2Q3.9 5.25 4.575 4.6Q5.25 3.95 6.2 3.95H20.4Q21.35 3.95 22 4.6Q22.65 5.25 22.65 6.2V24.05Q22.65 25 22 25.65Q21.35 26.3 20.4 26.3H6.2Q5.25 26.3 4.575 25.65Q3.9 25 3.9 24.05ZM25.2 41.65V23.85Q25.2 22.85 25.85 22.2Q26.5 21.55 27.45 21.55H41.8Q42.75 21.55 43.4 22.2Q44.05 22.85 44.05 23.85V41.65Q44.05 42.65 43.4 43.325Q42.75 44 41.8 44H27.45Q26.5 44 25.85 43.325Q25.2 42.65 25.2 41.65ZM3.9 41.65V31.15Q3.9 30.2 4.575 29.55Q5.25 28.9 6.2 28.9H20.4Q21.35 28.9 22 29.55Q22.65 30.2 22.65 31.15V41.65Q22.65 42.65 22 43.325Q21.35 44 20.4 44H6.2Q5.25 44 4.575 43.325Q3.9 42.65 3.9 41.65Z" />
                                    </svg>
                                    Home
                                </a>
                            </li>

                            <li>
                                <a class="fs-16" href="/login" style="cursor: pointer;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
                                        class="fill-color-1 h-30" height="48" width="48">
                                        <path
                                            d="M7 42Q5.8 42 4.9 41.1Q4 40.2 4 39V14.35Q4 13.15 4.9 12.25Q5.8 11.35 7 11.35H16.5V7Q16.5 5.8 17.4 4.9Q18.3 4 19.5 4H28.5Q29.7 4 30.6 4.9Q31.5 5.8 31.5 7V11.35H41Q42.2 11.35 43.1 12.25Q44 13.15 44 14.35V39Q44 40.2 43.1 41.1Q42.2 42 41 42ZM19.5 11.35H28.5V7Q28.5 7 28.5 7Q28.5 7 28.5 7H19.5Q19.5 7 19.5 7Q19.5 7 19.5 7Z" />
                                    </svg>
                                    Internships
                                </a>
                            </li>

                            <li>
                                <a class="fs-16" href="/login" style="cursor: pointer;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                        class="fill-color-1 h-30" height="48" width="48" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM8 17.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zM9.5 8c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5S9.5 9.38 9.5 8zm6.5 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
                                    </svg>
                                    Resources
                                </a>
                            </li>

                            <li>
                                <a href="/login" class="fs-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                        viewBox="0 0 24 24" class="fill-color-1 h-30" height="48" width="48"
                                        fill="#000000">
                                        <g>
                                            <rect fill="none" height="24" width="24" />
                                            <rect fill="none" height="24" width="24" />
                                        </g>
                                        <g>
                                            <path
                                                d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,6c1.93,0,3.5,1.57,3.5,3.5S13.93,13,12,13 s-3.5-1.57-3.5-3.5S10.07,6,12,6z M12,20c-2.03,0-4.43-0.82-6.14-2.88C7.55,15.8,9.68,15,12,15s4.45,0.8,6.14,2.12 C16.43,19.18,14.03,20,12,20z" />
                                        </g>
                                    </svg>
                                    Login
                                </a>
                            </li>
                        </ul>
                        <div class="border-theme-2px mt-30 border-bottom-0 border-end-0 border-start-0 py-10 pl-20">
                            <a class="d-block col-6  text-black fw-600 fs-15  py-2 w-100"> AboutUs</a>
                            <a class="d-block col-6  text-black fw-600 fs-15 py-2 w-100" (click)="redirct()"> Contact
                                Us</a>
                        </div>
                    </div>
                </div>



                <div class="col-12 col-lg-10 right-content px-lg-35 pb-5 bg-white py-10 px-10 mb-lg-0 mb-5">

                    <p class="fs-22 text-black fw-800  line-h-22 mt-25 mb-15 text-center">Contact Us </p>

                    <div class="py-20 px-15 border-theme-2px border-radius-25 max-w-600 mx-auto">
                        <form action="" name="contactUsForm" novalidate [formGroup]="contactUsForm">
                            <div class="mb-15">
                                <p class="fs-14 text-black mb-0 px-1 fw-900">Name</p>
                                <!-- <div class=" position-relative">
                                    <input type="text" name="" id=""
                                        class="border-radius-10 border-theme-2px fs-18 py-2 w-100 " required="">
                                </div>
                                <span class="error text-danger mt-1 px-1 d-block d-none">Name is required</span> -->
                                <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                    <input matInput type="text" id="name" formControlName="ContactUsName" name="name"
                                        class="border-radius-10 border-theme-2px fs-18 py-2 w-100 " required="">
                                    <mat-error *ngIf="contactUsForm.controls.ContactUsName.invalid"
                                        class="error text-danger mt-1 px-1 d-block ">
                                        Name is required
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="mb-15">
                                <p class="fs-14 text-black mb-0 px-1 fw-900">Mobile</p>
                                <!-- <div class=" position-relative">
                                    <input type="number" name="" id=""
                                        class="border-radius-10 border-theme-2px fs-18 py-2 w-100 " required="">
                                </div>
                                <span class="error text-danger mt-1 px-1 d-block d-none">Mobile Number is
                                    required</span> -->
                                <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                    <input matInput type="text" id="mobile" formControlName="Mobile" name="mobile"
                                        class="border-radius-10 border-theme-2px fs-18 py-2 w-100 " required="">
                                    <mat-error *ngIf="contactUsForm.controls.Mobile.invalid">
                                        Mobile Number is
                                        required
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="mb-15">
                                <p class="fs-14 text-black mb-0 px-1 fw-900">Email</p>
                                <!-- <div class=" position-relative">
                                    <input type="email" name="" id=""
                                        class="border-radius-10 border-theme-2px fs-18 py-2 w-100 " required="">
                                </div>
                                <span class="error text-danger mt-1 px-1 d-block d-none">Email ID is required</span> -->
                                <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                    <input matInput type="text" id="email" formControlName="EmailId" name="email"
                                        class="border-radius-10 border-theme-2px fs-18 py-2 w-100 " required="">
                                    <mat-error *ngIf="contactUsForm.controls.EmailId.invalid">
                                        Email ID is required
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="mb-20">
                                <p class="fs-16 text-black mb-1 px-1 fw-900">Message </p>
                                <!-- <div class="input-with-icon position-relative">
                                    <textarea name="" id="" cols="30" rows="5"
                                        class="border-radius-10 text-black border-theme-2px fw-600 fs-16 py-10 w-100 px-15"></textarea>

                                    <button
                                        class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-25 text-uppercase box-shadow-hard mb-10">Submit</button>



                                </div> -->
                                <mat-form-field appearance="outline" class="input-with-icon position-relative">
                                    <textarea matInput type="text" id="message" formControlName="Message" name="message"
                                        class="border-radius-10 border-theme-2px fs-18 py-2 w-100 "></textarea>

                                </mat-form-field>
                                <button
                                    class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-25 text-uppercase box-shadow-hard mb-10"
                                    [disabled]="contactUsForm.invalid " (click)="CreateContactUs()">Submit</button>
                            </div>



                        </form>

                    </div>


                    <div class=" mt-50 max-w-600 mx-auto mx-auto max-w-600" *ngIf="OfficeDetails">
                        <div class="bg-light-theme px-20 py-25 border-radius-20 position-relative">

                            <div class="d-flex row mx-0 px-0">
                                <div class="col-2 col-md-1 px-0">

                                    <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="30"
                                        viewBox="0 0 24 24" width="30" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                        <path
                                            d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm1 17H7V4h10v14zm-3 3h-4v-1h4v1z">
                                        </path>
                                    </svg>
                                </div>
                                <div class="col-10 px-0">
                                    <p class="mb-0 line-h-15 fs-14 text-black fw-400">
                                        Phone
                                    </p>

                                    <p class="mb-0 fs-16 color1 fw-900">
                                        <!-- 9999808080 -->
                                        {{OfficeDetails.PathwaysPhone}}
                                    </p>
                                    <p class="mb-0 fs-16 color1 fw-900">
                                        999XXXXXX
                                    </p>
                                </div>
                            </div>


                            <div class="d-flex row mt-20 mx-0 px-0">
                                <div class="col-2 col-md-1 px-0">

                                    <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg" height="30"
                                        viewBox="0 0 24 24" width="30" fill="#000000">
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                        <path
                                            d="M21.99 8c0-.72-.37-1.35-.94-1.7L12 1 2.95 6.3C2.38 6.65 2 7.28 2 8v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2l-.01-10zM12 13L3.74 7.84 12 3l8.26 4.84L12 13z">
                                        </path>
                                    </svg>
                                </div>
                                <div class="col-10 px-0">
                                    <p class="mb-0 line-h-15 fs-14 text-black fw-400">
                                        Email ID
                                    </p>

                                    <p class="mb-0 fs-16 color1 fw-900">
                                        <!-- lahi@lend-a-hand-india.org -->
                                        {{OfficeDetails.PathwaysEmailId}}

                                    </p>
                                </div>
                            </div>


                            <div class="d-flex row mt-20 mx-0 px-0">
                                <div class="col-2 col-md-1 px-0">
                                    <svg class="fill-color-2" xmlns="http://www.w3.org/2000/svg"
                                        enable-background="new 0 0 24 24" height="32px" viewBox="0 0 24 24" width="32px"
                                        fill="#000000">
                                        <g>
                                            <path d="M0,0h24v24H0V0z" fill="none"></path>
                                        </g>
                                        <g>
                                            <path
                                                d="M12,2c-4.2,0-8,3.22-8,8.2c0,3.32,2.67,7.25,8,11.8c5.33-4.55,8-8.48,8-11.8C20,5.22,16.2,2,12,2z M12,12c-1.1,0-2-0.9-2-2 c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C14,11.1,13.1,12,12,12z">
                                            </path>
                                        </g>
                                    </svg>

                                </div>
                                <div class="col-10 px-0 col-md-11">
                                    <p class="mb-0 line-h-15 fs-14 text-black fw-400">
                                        Head Office
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        <!-- 9 Jeevan Vihar Society, Off
                                        Senapati Bapat Road, Near Pride
                                        Panorama, Pune 411 016,
                                        Maharashtra, India -->
                                        {{OfficeDetails.PathwaysHeadOffice}}

                                    </p>
                                    <a class="mb-0 fs-16 color1 fw-900 d-block mt-5px" href="#">
                                        Get Location <svg xmlns="http://www.w3.org/2000/svg" height="20px"
                                            class="fill-color-1" viewBox="0 0 24 24" width="20px" fill="#000000">
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path d="M21 3L3 10.53v.98l6.84 2.65L12.48 21h.98L21 3z" />
                                        </svg>
                                    </a>
                                </div>
                                <div class="col-2 col-md-1 px-0"></div>

                                <div
                                    class="col-10 px-0 border border-light border-top border-2 border-end-0 border-start-0 border-bottom-0 mt-15 pt-15">
                                    <p class="mb-0 line-h-15 fs-14 text-black fw-400">
                                        KARIGAR – School of Applied Learning
                                    </p>

                                    <p class="mb-0 fs-16 text-black fw-900">
                                        <!-- 15, Shivaji Co-operative
                                        Housing Society, Bhageerath,
                                        Gokhalenagar, Pune 411016,
                                        Maharashtra, India -->
                                        {{OfficeDetails.PathwaysOffice}}

                                    </p>
                                    <a class="mb-0 fs-16 color1 fw-900 mb-5 mt-5px d-block" href="#">
                                        Get Location <svg xmlns="http://www.w3.org/2000/svg" height="20px"
                                            class="fill-color-1" viewBox="0 0 24 24" width="20px" fill="#000000">
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path d="M21 3L3 10.53v.98l6.84 2.65L12.48 21h.98L21 3z" />
                                        </svg>
                                    </a>
                                </div>

                            </div>

                        </div>


                        <div class="border-radius-20 mt--30 mb-5">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60527.51308872747!2d73.823051!3d18.5302773!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf70e0513291%3A0x9450ce55e1d12e22!2sLend-A-Hand%20India!5e0!3m2!1sen!2sin!4v1661936372132!5m2!1sen!2sin"
                                width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>


                    </div>

                </div>






            </div>
        </div>
    </div>

</section>