<section class="mb-lg-0 mb-10">
    <div class="container">
        <div class="content-section mt-0">
            <img src="assets/upload/Thumbs-up-vector.png" alt="Thank You Vector" class="img-fluid mx-auto d-block my-3"
                srcset="">
            <div class="px-lg-35 px-10 border-radius-30 bg-white py-5 overflow-hidden max-w-800 mx-auto mt-40">

                <p class="text-center color2 fw-900 mb-0 fs-lg-40 fs-35">
                    Thank You!
                </p>

                <p class="text-center text-black fw-900 fs-19" *ngIf="thankYou== 'GratitudeCorner'">
                    Your gratitude corner message is <br>
                    successfully submitted.
                </p>
                <p class="text-center text-black fw-900 fs-19" *ngIf="thankYou== 'RaiseSafetyConcer'">
                    Safety concern raised successfully!
                </p>
                <p class="text-center text-black fw-900 fs-19" *ngIf="thankYou== 'ContactUs'">
                    Your query is submitted successfully!
                </p>

                <p class="text-center text-black fw-900 fs-19" *ngIf="thankYou== 'EmployerProfileEdit'">
                    Your profile <br>
                    successfully Updated.
                </p>

                <p class="text-center text-black fw-900 fs-19" *ngIf="thankYou== 'NewPassword'">
                    Your password is <br>
                    successfully changed.
                </p>
                
                <a class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-20 text-uppercase box-shadow-hard mb-10" *ngIf="thankYou== 'ContactUs' || thankYou== 'RaiseSafetyConcer' || thankYou== 'GratitudeCorner'"
                    href="/dashboard">go to home</a>

                    <a class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-20 text-uppercase box-shadow-hard mb-10" *ngIf="thankYou== 'NewPassword'"
                    href="/login">go to Login</a>

                    <a class="max-w-250 w-100 mt-30 py-15 px-10 text-white fs-18 fw-600 upto-down-gradient-clr-1 theme-btn d-flex justify-content-center align-content-center mx-auto border-radius-20 text-uppercase box-shadow-hard mb-10" *ngIf="thankYou== 'EmployerProfileEdit'"
                    href="/employer-profile">go to Profile</a>
            </div>
        </div>

    </div>
</section>