<section class="">
    <div class="container">

        <div class="content-section mt-0">

            <div class="row mx-0">
                <div class="col-md-3  col-lg-2 p-0 custm-fixed-side">
                    <igmite-side-menu></igmite-side-menu>
    
                </div>



                <div class="col-12 col-lg-10 right-content px-lg-35 py-18 pb-5 bg-white px-10">
                    <div class="mx-auto max-w-800">
                        <h1
                            class="fs-20 mt-15 line-h-17 fw-900 text-center text-black  px-10 text-black mb-15  line-h-30 mb-30">
                            About Pathways</h1>
                        <p class="fs-16 text-black fw-500 text-center">
                            Students of Grade 11th and 12th are introduced to the “World of Work” through internship
                            opportunities with small and micro enterprises close to their schools or homes. The
                            types of enterprises with which the students intern include retail businesses, auto
                            workshops, restaurants, cafes , electrical repairs shops, financial services, bakeries,
                            and primary health care centers.
                        </p>
                        <p class="fs-16 text-black fw-500 text-center">
                            A typical internship is of 80+ clock hours (two weeks full time / One month part time).
                            Girls have taken up non-traditional internship like elevator maintenance, CNC machine
                            programmer, and electrician.
                        </p>









                        <div class="mt-40">
                            <div class=" h-100">
                                <div class=" h-100">
                                    <div
                                        class="student-video-testimonial border-theme-2px border-radius-30  overflow-hidden h-100 max-w-420 mx-auto">

                                        <iframe class="fade-overlay border-radius-0" width="100%" height="220"
                                            src="https://www.youtube.com/embed/6ehIYXsNMXY?rel=0"
                                            title="YouTube video player" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen>
                                            <p>jfbdjfdjfnfdb</p>
                                        </iframe>
                                        <p class="Video-heading px-15 py-15 mt-0 fs-17 fw-600 text-center">
                                            Multi Skill Vocational Education in Schools

                                        </p>

                                    </div>


                                </div>





                            </div>
                        </div>


                        <h3
                            class="fs-20 mt-50 line-h-17 fw-900 text-center text-black  px-10 text-black mb-15  line-h-30 mb-30">
                            For More Information Visit</h3>
                        <a href="https://lendahandindia.org/" target="_blank" rel="no-referrer"
                            class="d-block px-3 text-white d-block mb-5 text-center max-w-420 mx-auto border-radius-15 mx-auto mt-0 w-100 py-2 upto-down-gradient-clr-1 theme-btn fw-900">www.lendahandindia.org</a>
                    </div>
                </div>






            </div>
        </div>
    </div>

</section>
